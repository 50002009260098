import React, { useState, useEffect } from 'react';
import { Alert } from 'shards-react';
import { Subscribe } from 'unstated';
import ErrorHandler from '../data/ErrorHandler';

const ErrorHandlerView = () => {
	const onDismiss = error => {
		ErrorHandler.getInstance().removeError(error.id);
		// TODO: refresh page
		// if (error.options.shouldRefreshPage) {
		// 	window.location.reload();
		// }
	};

	return (
		<Subscribe to={[ErrorHandler]}>
			{({ state: { errors } }) => {
				return errors.map(error => {
					return (
						<Alert key={error.id} theme={error.options.theme} dismissible={() => onDismiss(error)}>
							{error.message}
						</Alert>
					);
				});
			}}
		</Subscribe>
	);
};

export default ErrorHandlerView;
