import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Badge, ButtonGroup } from 'shards-react';
import AppsContainer from '../../data/AppsContainer';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import waitForContainer from '../../utils/waitForContainer';
import { NavLink, Link } from 'react-router-dom';
import { SortableElement } from 'react-sortable-hoc';
import moment from 'moment';

const Item = ({ item }) => {
	let usersWaiting = item.variants.reduce((total, item) => total + item.usersOnWaitList, 0);
	return (
		<Col lg='4' md='4' sm='4' style={{ margin: 0, padding: 10 }}>
			<Link to={`products/${item._id}`}>
				<Card small className='album-card'>
					<div className='album-art' style={{ backgroundImage: `url('${item.photos[0].media.uri}')` }}>
						{item.isLive ? (
							usersWaiting > 0 && (
								<Badge pill theme='primary'>
									{usersWaiting} on waitlist
								</Badge>
							)
						) : (
							<Badge pill theme='dark'>
								{item.publishDate ? moment(item.publishDate).format('M/D/YY @ h:mm a') : 'Draft'}
							</Badge>
						)}
						<h3>{item.title}</h3>
					</div>
				</Card>
			</Link>
		</Col>
	);
};

const SortableItem = SortableElement(Item);

const Grid = ({ products, sortable }) => (
	<Card small className='mb-3 content-grid' style={{ overflow: 'auto' }}>
		{products.length === 0 ? (
			<div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', height: 300 }}>
				No Products
			</div>
		) : (
			products.map((item, index) =>
				sortable ? (
					<SortableItem item={item} index={index} key={index} />
				) : (
					<Item item={item} index={index} key={index} />
				)
			)
		)}
	</Card>
);

export default class Products extends Component {
	state = {
		loading: true,
		live: [],
		drafts: [],
	};

	componentDidMount = async () => {
		let products = await waitForContainer(AppsContainer, 'products');
		let live = products
			.filter(item => item.isLive)
			.sort((a, b) => {
				if (!b.liveDate && a.liveDate) return 1;
				if (!a.liveDate && b.liveDate) return -1;
				if (!a.liveDate && !b.liveDate) return 0;
				return new Date(b.liveDate) - new Date(a.liveDate);
			});
		let drafts = products
			.filter(item => !item.isLive)
			.sort((a, b) => {
				if (!b.publishDate && a.publishDate) return 1;
				if (!a.publishDate && b.publishDate) return -1;
				if (!a.publishDate && !b.publishDate) return 0;
				return new Date(b.publishDate) - new Date(a.publishDate);
			});
		this.setState({ live, drafts, loading: false });
	};

	render = () => {
		return (
			<Container className='page-container products'>
				{this.state.loading ? (
					<ScreenLoader />
				) : (
					<>
						{this.state.drafts.length > 0 && (
							<div>
								<Row noGutters className='page-header py-4 d-flex'>
									<PageTitle title='Drafts' subtitle='Products' className='text-sm-left mb-3' />
									<Col xs='12' sm='4' className='col d-flex align-items-center'>
										<ButtonGroup size='sm' className='d-inline-flex mb-3 mb-sm-0 mx-auto'>
											<Button tag={NavLink} theme='white' to='./orders'>
												Orders
											</Button>
											<Button tag={NavLink} theme='white' active to='./products'>
												Products
											</Button>
										</ButtonGroup>
									</Col>
								</Row>
								<Row>
									<Col lg='9' md='12'>
										<Grid products={this.state.drafts} />
									</Col>
									{this.state.drafts.length > 0 && (
										<Col lg='3' md='12'>
											<Card small>
												<CardBody>
													<Row>
														<Col sm='12'>
															<Button
																theme='dark'
																size='sm'
																style={{ width: '100%' }}
																tag={NavLink}
																to='new-product'>
																+ Add Product
															</Button>
														</Col>
													</Row>
												</CardBody>
											</Card>
										</Col>
									)}
								</Row>
							</div>
						)}
						{(this.state.drafts.length === 0 || this.state.live.length > 0) && (
							<div>
								<Row noGutters className='page-header py-4 d-flex'>
									<PageTitle title='Products' subtitle='Store' className='text-sm-left mb-3 p-0' />
									<Col xs='12' sm='4' className='col d-flex align-items-center'>
										<ButtonGroup size='sm' className='d-inline-flex mb-3 mb-sm-0 mx-auto'>
											<Button tag={NavLink} theme='white' to='./orders'>
												Orders
											</Button>
											<Button tag={NavLink} theme='white' active to='./products'>
												Products
											</Button>
										</ButtonGroup>
									</Col>
								</Row>
								<Row>
									<Col lg='9' md='12'>
										<Grid
											products={this.state.live}
											axis='xy'
											pressDelay={200}
											helperClass='sortableHelper'
											useDragHandle={false}
											onSortEnd={this.onSortEnd}
										/>
									</Col>
									{this.state.drafts.length === 0 && (
										<Col lg='3' md='12'>
											<Card small>
												<CardBody>
													<Row>
														<Col sm='12'>
															<Button
																theme='dark'
																size='sm'
																style={{ width: '100%' }}
																tag={NavLink}
																to='new-product'>
																+ Add Product
															</Button>
														</Col>
													</Row>
												</CardBody>
											</Card>
										</Col>
									)}
								</Row>
							</div>
						)}
					</>
				)}
			</Container>
		);
	};
}
