import React from 'react';
import { Button } from 'shards-react';
import PasswordAndCommentModal, { useToggleModal } from '../../../components/common/PasswordAndCommentModal';
import RequirePermissions from '../../../components/RequirePermissions';

import API from '../../../data/API';

const AddAuditItem = ({ type, id, onRefresh }) => {
	const [open, toggle] = useToggleModal();

	const onConfirmAddComment = async (password, comment, message) => {
		await API.post(`/banking-admin/${type}/${id}/audit`, {
			password,
			comment,
			message,
		});
		await onRefresh();
	};

	return (
		<div>
			<RequirePermissions permissions="audit.write">
				<Button onClick={toggle}>+ Add Audit Item</Button>
			</RequirePermissions>
			<PasswordAndCommentModal
				showComment
				requireComment
				actionLabel="add audit item"
				onConfirm={onConfirmAddComment}
				toggle={open}
				showMessage
			/>
		</div>
	);
};

export default AddAuditItem;
