import React from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	ListGroup,
	CardFooter,
	Button,
	ListGroupItem,
	Badge,
} from 'shards-react';
import { Subscribe } from 'unstated';
import AppsContainer from '../../data/AppsContainer';
import ScreenLoader from '../../components/ScreenLoader';
import { Redirect } from 'react-router-dom';
import PageTitle from '../../components/common/PageTitle';

export default props => (
	<Container className="page-container">
		<Row noGutters className="page-header py-4">
			<PageTitle title="Nonprofit Checklist" subtitle="Getting Started" className="text-sm-left mb-3" />
		</Row>
		<Row>
			<Col>
				<Subscribe to={[AppsContainer]}>
					{({ state: { npos } }) => {
						if (!npos) return <ScreenLoader />;

						let npo = npos.find(npo => npo._id === props.match.params.npo);
						if (!npo) return <Redirect to={`/logout`} />;

						let checklistItems = [
							{
								label: `Create An Account`,
								description: `Use your login to manage your account.`,
								complete: true,
								buttonLabel: ``,
								buttonIcon: ``,
								buttonLink: ``,
								estimatedMinutes: 2,
							},
							{
								label: `Complete Your Organization's Profile`,
								description: `Enter information we'll display to artists and fans.`,
								complete: !!npo.profile,
								buttonLabel: `Complete Profile`,
								buttonIcon: `📝`,
								buttonLink: `profile`,
								estimatedMinutes: 1,
								completeButtonLabel: `Complete`,
								completeButtonLink: `profile`,
							},
							{
								label: `Set Up Payments`,
								description: `Connect your bank account to get paid.`,
								complete: npo.stripe && npo.stripe.accountId,
								buttonLabel: `Connect Your Bank`,
								buttonIcon: `⚙️`,
								buttonLink: `bank`,
								estimatedMinutes: 3,
							},
						];

						let totalEstimatedMinutes = checklistItems
							.filter(item => !item.complete)
							.reduce((total, item) => total + item.estimatedMinutes, 0);
						let totalEstimatedMinutesUnit = totalEstimatedMinutes === 1 ? 'minute' : 'minutes';

						return (
							<Card className="activation-checklist">
								{/* Card Header */}
								{checklistItems.every(item => item.complete) ? (
									<CardHeader className="border-bottom">
										<p>
											<h5 style={{ marginBottom: -20 }}>
												Nice job! We'll contact you once your nonprofit is verified.
											</h5>
											<br />
											<em>
												Feel free to{' '}
												<strong>
													<a href="mailto:support@manifestfinancial.com">email us</a>
												</strong>{' '}
												if you have any questions in the meantime.
											</em>
										</p>
									</CardHeader>
								) : (
									<CardHeader className="border-bottom">
										<p>
											<h5 style={{ marginBottom: -20 }}>
												Before your nonprofit can receive contributions, there are a few things
												that need to be done.
											</h5>
											<br />
											<em>
												Don't worry, looks like you'll be ready to roll in about{' '}
												{totalEstimatedMinutes} {totalEstimatedMinutesUnit}.
											</em>
										</p>
									</CardHeader>
								)}

								<CardBody className="py-0">
									{/* Goals Overview List Group */}
									<ListGroup flush>
										{checklistItems.map((item, index) => (
											<ListGroupItem className="d-flex row px-0 py-4" key={index}>
												<Col xl="7" lg="8" md="8" sm="7">
													<h6 className="activation-checklist__label mb-1">
														{item.label}
														{!item.complete && (
															<Badge theme="info">~{item.estimatedMinutes} min</Badge>
														)}
													</h6>
													<div className="activation-checklist__meta">
														<span className="mr-2">{item.description}</span>
													</div>
												</Col>
												<Col lg="3" md="4" sm="5" style={{ padding: 6, paddingTop: 8 }}>
													{item.complete ? (
														<Button
															pill
															theme="success"
															onClick={
																item.completeButtonLink &&
																(() => props.history.push(item.completeButtonLink))
															}>
															<i
																className="material-icons"
																style={{ fontSize: 18, verticalAlign: 'sub' }}>
																check_circle
															</i>{' '}
															{item.completeButtonLabel || `Complete`}
														</Button>
													) : (
														<Button
															pill
															theme="white"
															onClick={() => props.history.push(item.buttonLink)}>
															<span style={{ fontSize: 18, verticalAlign: 'sub' }}>
																{item.buttonIcon}
															</span>{' '}
															{item.buttonLabel}
														</Button>
													)}
												</Col>
											</ListGroupItem>
										))}
									</ListGroup>
								</CardBody>

								{/* Footer */}
								<CardFooter className="border-top">
									<Row />
								</CardFooter>
							</Card>
						);
					}}
				</Subscribe>
			</Col>
		</Row>
	</Container>
);
