import React, { Component } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	Button,
	Badge,
	FormCheckbox,
	FormSelect,
	FormInput,
} from 'shards-react';
import AppsContainer from '../../data/AppsContainer';
import Session from '../../data/Session';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import API from '../../data/API';
import { currency } from '../../utils/money';
import waitForContainer from '../../utils/waitForContainer';
import CurrencyInput from 'react-currency-input';
import arrayMove from 'array-move';
import { Prompt } from 'react-router';
import Tip from '../../components/Tip';

export default class FrontMe extends Component {
	state = {
		loading: true,
		shouldBlockNavigation: false,
		agreeToTerms: false,
	};

	componentDidMount = async () => {
		window.addEventListener('focus', this.onFocus);

		let {
			_id: appId,
			frontMe: { offerAmount },
			createdBy,
		} = await waitForContainer(AppsContainer, 'currentApp');
		let user = await waitForContainer(Session, 'user');

		const { method, last4, bankName, brand } = await API.get(`/admin/frontme/${appId}/payout`);
		const { channelLoan } = await API.get(`/admin/frontme/${appId}`);
		const { snapshot } = await API.get(`/admin/dailysnapshot/${appId}/latest`);
		let payoffEstimateDisplay;
		if (channelLoan) {
			const payoffEstimateInHalfMonths = Math.round((2 * -channelLoan.balance) / snapshot.totalMrr) / 2;
			payoffEstimateDisplay =
				payoffEstimateInHalfMonths === 1 ? '1 month' : `${payoffEstimateInHalfMonths} months`;
		} else {
			const payoffEstimateInHalfMonths = Math.round((2 * offerAmount) / snapshot.totalMrr) / 2;
			payoffEstimateDisplay =
				payoffEstimateInHalfMonths === 1 ? '1 month' : `${payoffEstimateInHalfMonths} months`;
		}

		this.setState(
			{
				saveEnabled: false,
				loading: false,
				offerAmount,
				channelLoan,
				snapshot,
				payoffEstimateDisplay,
				method,
				last4,
				bankName,
				brand,
				user,
				createdBy,
				amount: offerAmount,
				shouldBlockNavigation: false,
			},
			() =>
				this.updateValidation(() => {
					//I need to turn this back off since update validation turns it on.
					this.setState({ shouldBlockNavigation: false });
				}),
		);

		let { npos } = await API.get(`/admin/npo/`);
		this.setState({ npos });
	};

	componentDidUpdate = () => {
		if (this.state.shouldBlockNavigation) {
			window.onbeforeunload = () => true;
		} else {
			window.onbeforeunload = undefined;
		}
	};

	componentWillUnmount() {
		window.removeEventListener('focus', this.onFocus);
	}

	onFocus = async () => {
		let { _id: appId } = await waitForContainer(AppsContainer, 'currentApp');
		const { method, last4, bankName, brand } = await API.get(`/admin/frontme/${appId}/payout`);
		this.setState({ method, last4, bankName, brand });
	};

	linkToExpress = e => {
		e.preventDefault();
		var windowReference = window.open('', 'expressDashboard');
		this.linkToExpressAsync(windowReference);
	};

	linkToExpressAsync = async windowReference => {
		let { url } = await API.get(`/admin/bank/stripe-express-link`);
		windowReference.location = url;
	};

	submit = async () => {
		let { amount, offerAmount, agreeToTerms } = this.state;

		try {
			if (amount > offerAmount) {
				throw new Error(
					`Front Me amount cannot be more than the offered amount of ${currency(offerAmount / 100)}.`,
				);
			}
			if (amount < 5000) {
				throw new Error(`Front Me amount cannot be less than the minimum amount of $50.00.`);
			}
			if (!agreeToTerms) {
				throw new Error(`You must agree to the Front Me terms.`);
			}
		} catch (e) {
			alert(e.message);
			return;
		}

		let { _id: appId } = AppsContainer.getInstance().state.currentApp;

		try {
			this.setState({ loading: true });
			await API.post(`/admin/frontme/${appId}`, { amount });

			alert(
				`Congrats, your money is on the way! We've sent you an email confirmation. You can track your Front Me payback right here.`,
			);

			this.setState({ shouldBlockNavigation: false });
			await AppsContainer.getInstance().refresh();
			setTimeout(() => this.componentDidMount(), 100);
		} catch (e) {
			this.setState({ loading: false });
			alert(`Oops! ${e.message}`);
		}
	};

	onFieldChange = (event, field) => {
		let newState = {};
		newState[field] = event.target.value;
		this.setState(newState, this.updateValidation);
	};

	onAmountFieldChange = event => {
		const stringValue = event.target.value.split('$').join('').split(',').join('');
		const newAmount = stringValue === '' ? null : parseFloat(stringValue) * 100;

		const payoffEstimateInHalfMonths = Math.round((2 * newAmount) / this.state.snapshot.totalMrr) / 2;
		const payoffEstimateDisplay =
			payoffEstimateInHalfMonths === 1 ? '1 month' : `${payoffEstimateInHalfMonths} months`;

		let newState = {};
		newState.amount = newAmount;
		newState.payoffEstimateDisplay = payoffEstimateDisplay;
		this.setState(newState, this.updateValidation);
	};

	onCheckChange = field => {
		let newState = {};
		newState[field] = !this.state[field];
		this.setState(newState, this.updateValidation);
	};

	isValid = () => true;
	updateValidation = callback =>
		this.setState({ saveEnabled: this.isValid(), shouldBlockNavigation: true }, callback);

	render = () => {
		return (
			<Container className="page-container payments">
				<Prompt
					when={this.state.shouldBlockNavigation}
					message="You have unsaved changes, are you sure you want to leave?"
				/>
				{this.state.loading ? (
					<ScreenLoader />
				) : (
					<div>
						<Row noGutters className="page-header py-4">
							<PageTitle title="Front Me™" subtitle="Payments" className="text-sm-left mb-3" />
						</Row>
						<Row>
							<Col lg="9" md="12">
								<Row>
									<Col lg="6" md="12">
										{!this.state.channelLoan && (
											<Card small className="mb-4">
												<CardHeader
													className="border-bottom d-flex"
													style={{ alignItems: 'baseline' }}>
													<h6 className="m-0" style={{ flex: 1 }}>
														Your Front Me Offer
													</h6>
												</CardHeader>
												<CardBody style={{ padding: 24, paddingTop: 16, fontSize: 12 }}>
													<h3 style={{ marginTop: 0, marginBottom: 2 }}>
														{currency(this.state.offerAmount / 100)}
													</h3>
													<div style={{ textTransform: 'uppercase' }}>Interest Free</div>
													<div style={{ marginTop: 12 }}>
														<Tip
															prompt="How is this calculated?"
															place="right"
															helpText="We’ve calculated Your Front Me Offer based on factors such<br />as the number of subscribers and referrals (and their<br />subscription type) as well as any splits on your channel<br />impact your Front Me offer. This offer will change over time<br />as your channel grows, and when our calculations change."
														/>
													</div>
												</CardBody>
											</Card>
										)}
										{this.state.channelLoan && (
											<Card small className="mb-4">
												<CardHeader
													className="border-bottom d-flex"
													style={{ alignItems: 'baseline' }}>
													<h6 className="m-0" style={{ flex: 1 }}>
														Your Front Me Balance
													</h6>
												</CardHeader>
												<CardBody style={{ padding: 24, paddingTop: 16, fontSize: 12 }}>
													<h3 style={{ marginTop: 0, marginBottom: 2 }}>
														{currency(this.state.channelLoan.balance / 100)}
													</h3>
													<div style={{ textTransform: 'uppercase' }}>Current Balance</div>
													<div style={{ marginTop: 12 }}>
														<a
															href="https://site.manifestfinancial.com/legal/front-me-terms"
															target="_blank"
															rel="noopener noreferrer"
															style={{ textDecoration: 'underline' }}>
															Front Me Terms
														</a>
													</div>
												</CardBody>
											</Card>
										)}
									</Col>
									<Col lg="6" md="12">
										{!this.state.channelLoan && (
											<Card small className="mb-4">
												<CardHeader
													className="border-bottom d-flex"
													style={{ alignItems: 'baseline' }}>
													<h6 className="m-0" style={{ flex: 1 }}>
														Funding
													</h6>
												</CardHeader>
												{this.state.method === 'bank_account' && (
													<CardBody style={{ padding: 24, paddingTop: 16, fontSize: 12 }}>
														<h3 style={{ marginTop: 0, marginBottom: 2 }}>2-3 days</h3>
														<div style={{ textTransform: 'uppercase' }}>
															to {this.state.bankName} (*{this.state.last4})
														</div>
														<div style={{ marginTop: 12 }}>&nbsp;</div>
													</CardBody>
												)}
												{this.state.method === 'card' && (
													<CardBody style={{ padding: 24, paddingTop: 16, fontSize: 12 }}>
														<h3 style={{ marginTop: 0, marginBottom: 2 }}>2-3 days</h3>
														<div style={{ textTransform: 'uppercase' }}>
															to your {this.state.brand} card (*{this.state.last4})
														</div>

														<div style={{ marginTop: 12 }}>&nbsp;</div>
													</CardBody>
												)}
											</Card>
										)}
										{this.state.channelLoan && (
											<Card small className="mb-4">
												<CardHeader
													className="border-bottom d-flex"
													style={{ alignItems: 'baseline' }}>
													<h6 className="m-0" style={{ flex: 1 }}>
														Payback
													</h6>
												</CardHeader>
												<CardBody style={{ padding: 24, paddingTop: 16, fontSize: 12 }}>
													<h3 style={{ marginTop: 0, marginBottom: 2 }}>
														{this.state.payoffEstimateDisplay}
													</h3>
													<div style={{ textTransform: 'uppercase' }}>Estimated Payback</div>
													<div style={{ marginTop: 12 }}>
														<Tip
															prompt="How is this calculated?"
															place="right"
															helpText="Simple — we just divide your Front Me balance<br />by your monthly revenue to estimate how long<br />it will take for your balance to be paid off<br />by your subscription income."
														/>
													</div>
												</CardBody>
											</Card>
										)}
									</Col>
								</Row>
								<Row>
									<Col lg="12" md="12">
										<Card small className="mb-3">
											<CardHeader
												className="border-bottom d-flex"
												style={{ alignItems: 'baseline' }}>
												<h6 className="m-0" style={{ flex: 1 }}>
													FAQ
												</h6>
											</CardHeader>
											<CardBody style={{ padding: 24, fontSize: 15 }}>
												<strong>What is Front Me?</strong>
												<p>
													Manifest helps you get regular recurring revenue from your fans. But
													we know artists — your expenses aren’t always regular. That’s why we
													made Front Me, a cheap and easy way to get advance access to money
													from your channel. Don’t worry… Front Me Offers are made directly by
													Manifest. No credit checks or banks involved.
												</p>

												<strong>How do I pay it back?</strong>
												<p>
													You don’t! 😅 When you accept a Front Me Offer, subscription revenue
													from your fans will begin going towards paying back your Front Me
													balance. Once your offer has been paid back, your payments will go
													back to normal.{' '}
												</p>

												<strong>
													What if my channel earns more or less while my offer is being paid
													back?
												</strong>
												<p>
													No problem! Any extra money your channel makes will just pay your
													offer back faster. Similarly, if your channel income goes down, your
													offer will just take a little longer to pay back.
												</p>

												<strong>Is it really interest free?</strong>
												<p>
													Yep! We only charge a flat $5.00 fee when you accept your offer, and
													it’s paid back along with the rest of the balance by incoming money
													from your fans.
												</p>

												<strong>What about my nonprofit donations or splits?</strong>
												<p>
													Any donations or splits on your channel will continue to be paid as
													ususal. In fact, they will be completely unaware of your Front Me
													Offer. This information is only shown to channel administrators — we
													take your privacy seriously.
												</p>

												<strong>What about things I sell in my store?</strong>
												<p>
													You'll continue to be paid all of the proceeds from sales of items
													in your store (minus the normal transaction fees, of course). Only
													subscription income is used to pay back your Front Me.
												</p>
											</CardBody>
										</Card>
									</Col>
								</Row>
							</Col>

							<Col lg="3" md="12">
								{!this.state.channelLoan && (
									<Card small className="mb-3">
										<CardHeader className="border-bottom d-flex" style={{ alignItems: 'baseline' }}>
											<h6 className="m-0" style={{ flex: 1 }}>
												Accept Offer
											</h6>
										</CardHeader>
										<CardBody>
											<Row>
												<Col md="12">
													<span className="text-semibold text-fiord-blue">
														Amount (max {currency(this.state.offerAmount / 100)})
													</span>
													<CurrencyInput
														className="form-control form-control-md"
														prefix="$"
														placeholder="$0.00"
														value={this.state.amount / 100}
														onChangeEvent={event =>
															this.onAmountFieldChange(event, 'amount')
														}
														onKeyUp={this.onKeyUp}
														selectAllOnFocus={true}
													/>
													<div
														className="text-fiord-blue"
														style={{ fontSize: 12, fontWeight: '300', marginTop: 24 }}>
														<strong>Estimated Payback:</strong>{' '}
														{this.state.payoffEstimateDisplay}
													</div>
													<div
														className="text-fiord-blue"
														style={{ fontSize: 12, fontWeight: '300' }}>
														<strong>Fee:</strong> $5.00
													</div>
													<div style={{ marginTop: 20, marginBottom: 20 }}>
														<FormCheckbox
															checked={this.state.agreeToTerms}
															onChange={() => this.onCheckChange('agreeToTerms')}>
															<span style={{ fontSize: 12, fontWeight: '300' }}>
																I agree to the{' '}
																<a
																	href="https://site.manifestfinancial.com/legal/front-me-terms"
																	target="_blank"
																	rel="noopener noreferrer"
																	style={{ fontWeight: '400' }}>
																	Front Me terms
																</a>
																.
															</span>
														</FormCheckbox>
													</div>
												</Col>
											</Row>
											<Row>
												<Col sm="12">
													<Button
														theme="success"
														size="sm"
														style={{ width: '100%' }}
														disabled={!this.state.saveEnabled}
														onClick={this.submit}>
														Front Me
													</Button>
												</Col>
											</Row>
										</CardBody>
									</Card>
								)}
								{this.state.channelLoan && (
									<Card small className="mb-3">
										<CardBody>
											<Row>
												<Col sm="12">
													<Button
														theme="dark"
														size="sm"
														style={{ width: '100%' }}
														disabled={!this.state.saveEnabled}
														onClick={() => this.props.history.push(`./`)}>
														Back
													</Button>
												</Col>
											</Row>
										</CardBody>
									</Card>
								)}
							</Col>
						</Row>
					</div>
				)}
			</Container>
		);
	};
}
