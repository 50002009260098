import React, { Component } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	FormTextarea,
	Button,
	ButtonGroup,
	FormInput,
	FormCheckbox,
	DatePicker,
	FormSelect,
	Badge,
} from 'shards-react';
import { Subscribe } from 'unstated';
import AppsContainer from '../../data/AppsContainer';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import API from '../../data/API';
import waitForContainer from '../../utils/waitForContainer';
import moment from 'moment';
import FileDropzone from '../../components/file-manager-list/FileDropzone';
import HLS from 'hls.js';
import Tip from '../../components/Tip';
import colors from '../../utils/colors';
import _ from 'lodash';
import produce from 'immer';
import { Prompt } from 'react-router';
import ManageCollectionsModal from '../../components/ManageCollectionsModal';

export default class NewVideo extends Component {
	state = {
		title: '',
		description: '',
		asset: null,
		saveEnabled: false,
		loading: true,
		videoId: null,
		isLive: true,
		publishDate: null,
		uploadProgress: 0,
		availableForFree: false,
		nerveExclusive: false,
		hasFreeloaders: false,
		chapters: [],
		newChapterIndex: 0,
		shouldBlockNavigation: false,
		showingCollectionsModal: false,
		availableCollections: [],
		collections: [],
		detailsTabIndex: 0,
	};

	updateCollections = async () => {
		let appId = AppsContainer.getInstance().state.currentApp._id;
		const { collections: availableCollections } = await API.get(`/admin/collections/${appId}/video`);
		this.setState({ availableCollections });
	};

	componentDidMount = async () => {
		this.videoRef = React.createRef();
		this.newTitleField = React.createRef();

		const currentApp = await waitForContainer(AppsContainer, 'currentApp');
		this.updateCollections();

		const hasFreeloaders = _.get(currentApp.optionalFeatures, 'freeloaders.enabled');

		if (this.props.match.params && this.props.match.params.videoId) {
			let { videoId } = this.props.match.params;
			const appId = AppsContainer.getInstance().state.currentApp._id;
			const { video } = await API.get(`/admin/video/${appId}/${videoId}`);
			let {
				title,
				description,
				isLive,
				publishDate,
				playbackUrl,
				asset,
				availableForFree,
				chapters,
				collections,
				nerveExclusive,
			} = video;
			this.setState(
				{
					title,
					description,
					videoId,
					isLive,
					publishDate: publishDate ? new Date(publishDate) : null,
					loading: false,
					playbackUrl,
					asset,
					availableForFree,
					hasFreeloaders,
					chapters: chapters || [],
					collections,
					nerveExclusive,
				},
				() => {
					this.updateValidation(() => {
						//I need to turn this back off since update validation turns it on.
						this.setState({ shouldBlockNavigation: false });
					});
					if (!playbackUrl) this.updateAsset();
				},
			);
		} else {
			this.setState({ loading: false, hasFreeloaders });
		}
	};

	componentDidUpdate = (prevProps, prevState) => {
		// HLS.js-specific setup code
		if (prevState.playbackUrl !== this.state.playbackUrl) {
			if (HLS.isSupported()) {
				var hls = new HLS({ maxBufferLength: 5, maxMaxBufferLength: 10 });
				hls.loadSource(this.state.playbackUrl);
				hls.attachMedia(this.videoRef.current);
			} else if (this.videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
				this.videoRef.current.src = this.state.playbackUrl;
			}
		}
		if (this.state.shouldBlockNavigation) {
			window.onbeforeunload = () => true;
		} else {
			window.onbeforeunload = undefined;
		}
	};

	updateAsset = async () => {
		let { _id: appId } = AppsContainer.getInstance().state.currentApp;
		let { _id: assetId } = this.state.asset;
		let { asset, playbackUrl } = await API.get(`/admin/asset/${appId}/${assetId}`);
		this.setState({ asset, playbackUrl });
		if (!playbackUrl) setTimeout(this.updateAsset, 3000);
	};

	delete = async () => {
		if (!window.confirm(`Are you sure you want to delete this video?`)) return;

		this.setState({ loading: true });

		try {
			let { _id: appId, appCode } = AppsContainer.getInstance().state.currentApp;
			let { videoId } = this.state;
			await API.del(`/admin/video/${appId}/${videoId}`);
			await AppsContainer.getInstance().updateCurrentApp();
			this.props.history.push(`/channel/${appCode}/videos`);
		} catch (e) {
			this.setState({ loading: false });
			alert(`Oops! ${e.message}`);
		}
	};

	submit = async () => {
		let {
			videoId,
			title,
			description,
			asset,
			saveEnabled,
			isLive,
			publishDate,
			availableForFree,
			chapters,
			collections,
			nerveExclusive,
		} = this.state;
		let { _id: appId, appCode } = AppsContainer.getInstance().state.currentApp;

		if (!saveEnabled) return;

		try {
			if (videoId) {
				if (title.length === 0) {
					throw new Error(`Please enter a title for your video.`);
				}
			} else {
				if (title.length === 0) {
					throw new Error(`Please enter a title for your new video.`);
				}
			}
			chapters.forEach(chapter => {
				if (chapter.title.length === 0) {
					throw new Error(`Please enter a title for each of your chapters.`);
				}
			});
		} catch (e) {
			alert(e.message);
			return;
		}

		this.setState({ loading: true });

		let url = videoId ? `/admin/video/${appId}/${videoId}` : `/admin/video/${appId}`;

		try {
			await API.post(url, {
				title,
				description,
				asset,
				isLive,
				publishDate,
				availableForFree,
				chapters,
				collections,
				nerveExclusive,
			});
			this.setState({ shouldBlockNavigation: false });
			await AppsContainer.getInstance().updateCurrentApp();
			this.props.history.push(`/channel/${appCode}/videos`);
		} catch (e) {
			this.setState({ loading: false });
			alert(`Oops! ${e.message}`);
		}
	};

	onVideoFile = async files => {
		let file = files[0];

		let appId = AppsContainer.getInstance().state.currentApp._id;
		let asset = await API.post(`/admin/asset/${appId}`);

		let onUploadProgress = ({ loaded, total }) => {
			let uploadProgress = (loaded / total) * 100;
			this.setState({ uploadProgress }, this.updateValidation);
		};

		let upload = async () => {
			await API.putFile(asset.uploadUrl, file, onUploadProgress);
			this.updateAsset();
		};

		upload();

		this.setState({ asset }, this.updateValidation);
	};

	isValid = () => {
		let { uploadProgress, videoId } = this.state;
		return videoId || uploadProgress === 100;
	};

	updateValidation = callback => {
		this.setState({ saveEnabled: this.isValid(), shouldBlockNavigation: true }, callback);
	};

	onFieldChange = (event, field) => {
		let newState = {};
		newState[field] = event.target.value;
		this.setState(newState, this.updateValidation);
	};

	onKeyUp = event => {
		if (event.key === 'Enter') {
			this.submit();
		}
		return false;
	};

	toggleIsLive = () => {
		this.setState({ isLive: !this.state.isLive });
	};

	toggleAvailableForFree = () => {
		this.setState({ availableForFree: !this.state.availableForFree });
	};

	addChapterAtCurrentTime = () => {
		const start = Math.floor(this.videoRef.current.currentTime);
		if (this.state.chapters.some(item => item.start === start)) {
			let formatString = 'm:ss';
			if (this.videoRef && this.videoRef.current && this.videoRef.current.duration > 60 * 60) {
				formatString = 'H:mm:ss';
			}
			alert(
				`You already have a chapter starting at ${moment.duration(start, 'seconds').format(formatString, {
					trim: false,
				})}. Move the video slider to another time to add another chapter.`,
			);
			return;
		}

		let newChapterIndex;

		const newChapters = produce(this.state.chapters, draft => {
			draft.push({ start, title: '' });
			draft.sort((a, b) => a.start - b.start);
			newChapterIndex = draft.findIndex(item => item.start === start);

			if (draft[0].start !== 0) {
				draft.unshift({ start: 0, title: 'Intro' });
				newChapterIndex = 1;
			}
		});

		this.setState({ chapters: newChapters, newChapterIndex });
		setTimeout(() => this.newTitleField.current.select(), 50);
	};

	deleteChapter = index => {
		let newChapters = produce(this.state.chapters, draft => {
			draft.splice(index, 1);
		});

		this.setState({ chapters: newChapters });
	};

	onChapterTitleChange = (event, index) => {
		const newChapters = produce(this.state.chapters, draft => {
			const chapter = draft[index];
			chapter.title = event.target.value;
		});
		this.setState({ chapters: newChapters }, this.updateValidation);
	};

	onChapterStartFocus = index => {
		let formatString = 'm:ss';
		if (this.videoRef && this.videoRef.current && this.videoRef.current.duration > 60 * 60) {
			formatString = 'H:mm:ss';
		}
		this.setState({
			editingChapterStartIndex: index,
			editingChapterStart: moment
				.duration(this.state.chapters[index].start, 'seconds')
				.format(formatString, { trim: false }),
		});
	};

	onChapterStartBlur = index => {
		const duration = Math.floor(this.videoRef.current.duration);
		let changed = false;
		const newChapters = produce(this.state.chapters, draft => {
			const chapter = draft[index];
			const startText = this.state.editingChapterStart;
			const startComponents = startText.split(':');
			let start;
			if (startComponents.length === 1) {
				start = parseInt(startComponents[0], 10);
			} else if (startComponents.length === 2) {
				start = parseInt(startComponents[0], 10) * 60 + parseInt(startComponents[1], 10);
			} else if (startComponents.length === 3) {
				start =
					parseInt(startComponents[0], 10) * 60 * 60 +
					parseInt(startComponents[1], 10) * 60 +
					parseInt(startComponents[2], 10);
			}
			if (!isNaN(start)) {
				if (start > duration - 1) {
					chapter.start = duration - 1;
				}
				if (chapter.start !== start) changed = true;
				chapter.start = start;
			}

			draft.sort((a, b) => a.start - b.start);

			if (draft[0].start !== 0) {
				draft.unshift({ start: 0, title: 'Intro' });
			}
		});
		if (changed) {
			this.setState(
				{ chapters: newChapters, editingChapterStartIndex: undefined, editingChapterStart: undefined },
				this.updateValidation,
			);
		} else {
			this.setState({ editingChapterStartIndex: undefined, editingChapterStart: undefined });
		}
	};

	onChapterStartChange = event => {
		this.setState({ editingChapterStart: event.target.value }, this.updateValidation);
	};

	playFrom = seconds => {
		this.videoRef.current.currentTime = seconds;
		this.videoRef.current.play();
	};

	onPublishDateChange = date => {
		if (!date) {
			let publishDate = null;
			this.setState({ publishDate });
		} else {
			let publishDate = this.state.publishDate;
			if (!publishDate) publishDate = new Date();
			publishDate.setMinutes(0);
			publishDate.setSeconds(0);
			publishDate.setMilliseconds(0);
			publishDate.setFullYear(date.getFullYear());
			publishDate.setMonth(date.getMonth());
			publishDate.setDate(date.getDate());
			this.setState({ publishDate });
		}
	};

	onPublishTimeChange = e => {
		let value = e.target.value;
		if (value === '') {
			let publishDate = null;
			this.setState({ publishDate });
		} else {
			let publishDate = this.state.publishDate;
			if (!publishDate) publishDate = new Date();
			publishDate.setMinutes(0);
			publishDate.setSeconds(0);
			publishDate.setMilliseconds(0);
			publishDate.setHours(value);
			this.setState({ publishDate });
		}
	};

	futureDateFilter = date => {
		let now = new Date();
		return moment(date).isSame(now, 'day') || moment(date).isAfter(now, 'day');
	};

	isInCollection = id => this.state.collections.indexOf(id) > -1;
	toggleIsInCollection = id => {
		let currentlyInCollection = this.isInCollection(id);

		this.setState(({ collections }) => {
			if (currentlyInCollection) {
				collections = collections.filter(item => item !== id);
			} else {
				collections.push(id);
			}
			return { collections };
		});
	};

	render = () => {
		let formatString = 'm:ss';
		if (this.videoRef && this.videoRef.current && this.videoRef.current.duration > 60 * 60) {
			formatString = 'H:mm:ss';
		}

		return (
			<Container className="page-container album-detail">
				<Prompt
					when={this.state.shouldBlockNavigation}
					message="You have unsaved changes, are you sure you want to leave?"
				/>
				{this.state.loading ? (
					<ScreenLoader />
				) : (
					<Subscribe to={[AppsContainer]}>
						{({ state: { currentApp } }) => (
							<div>
								<Row noGutters className="page-header py-4" style={{ alignItems: 'center' }}>
									<PageTitle
										title={this.state.videoId ? 'Edit Video' : 'Add New Video'}
										subtitle="Media"
										className="text-md-left mb-3"
									/>
								</Row>

								<Row>
									<Col lg="9" md="12">
										<Card small className="mb-3">
											<CardBody>
												{/* pre-upload */}
												{!this.state.videoId && this.state.uploadProgress === 0 && (
													<FileDropzone
														label="🍿 Upload video here"
														onFileSelect={this.onVideoFile}
														types="MP4, MOV, etc."
													/>
												)}
												{/* mid-upload */}
												{!this.state.videoId &&
													this.state.uploadProgress > 0 &&
													this.state.uploadProgress < 100 && (
														<div className="dropzone">
															<div
																style={{
																	width: 24,
																	height: 24,
																	display: 'inline-block',
																}}>
																<CircularProgressbar
																	value={this.state.uploadProgress}
																	strokeWidth={16}
																	styles={buildStyles({
																		strokeLinecap: 'butt',
																		trailColor: '#EAEAEA',
																		pathColor: '#17c671',
																	})}
																/>
															</div>
														</div>
													)}
												{/* processing */}
												{((!this.state.videoId &&
													this.state.uploadProgress === 100 &&
													!this.state.playbackUrl) ||
													(this.state.videoId && !this.state.playbackUrl)) && (
													<div
														className="dropzone"
														style={{
															paddingLeft: 40,
															paddingRight: 40,
															display: 'flex',
															flexDirection: 'column',
															alignItems: 'center',
														}}>
														<h6>
															<span role="img" aria-label="Clock">
																🕑
															</span>
															&nbsp;Processing...
														</h6>
														<ul style={{ textAlign: 'left', marginBottom: 0 }}>
															<li>
																Your video has been uploaded, but is still processing
																for streaming.
															</li>
															<li>
																Save when you're ready... we'll continue processing it
																in the background.
															</li>
															<li>We'll notify your subscribers once it's ready!</li>
														</ul>
													</div>
												)}
												{/* post-process */}
												{!this.state.videoId &&
													this.state.uploadProgress === 100 &&
													this.state.playbackUrl && (
														<video
															style={{ outline: 'none' }}
															ref={this.videoRef}
															width="100%"
															controls
														/>
													)}
												{/* post-save, ready */}
												{this.state.videoId && this.state.playbackUrl && (
													<video
														style={{ outline: 'none' }}
														ref={this.videoRef}
														width="100%"
														controls
													/>
												)}
												<FormInput
													size="lg"
													className="mb-3 mt-3"
													placeholder="Title (required)"
													value={this.state.title}
													onChange={event => this.onFieldChange(event, 'title')}
													onKeyUp={this.onKeyUp}
												/>
												<FormTextarea
													style={{ marginTop: 8 }}
													placeholder="Description"
													size="lg"
													rows="2"
													value={this.state.description}
													onChange={event => this.onFieldChange(event, 'description')}
												/>

												<ButtonGroup className="d-none d-md-block mt-4">
													<Button
														active={this.state.detailsTabIndex === 0}
														theme={this.state.detailsTabIndex === 0 ? 'primary' : 'light'}
														style={{ height: 28, padding: '0px 12px' }}
														size="sm"
														onClick={() => this.setState({ detailsTabIndex: 0 })}>
														Details
													</Button>
													<Button
														active={this.state.detailsTabIndex === 1}
														theme={this.state.detailsTabIndex === 1 ? 'primary' : 'light'}
														style={{ height: 28, padding: '0px 12px' }}
														size="sm"
														onClick={() => this.setState({ detailsTabIndex: 1 })}>
														Chapters
													</Button>
												</ButtonGroup>
												{this.state.detailsTabIndex === 0 && (
													<div
														className="pt-4 px-4 pb-3 mt-3"
														style={{
															backgroundColor: 'rgb(230 238 244)',
															borderRadius: 10,
														}}>
														<div
															style={{
																display: 'flex',
																flexDirection: 'row',
																justifyContent: 'space-between',
															}}>
															<p style={{ marginBottom: 10 }}>Video Details </p>
															{this.state.videoId && this.state.asset && (
																<Button
																	theme="white"
																	size="sm"
																	style={{
																		marginLeft: 8,
																		height: 28,
																		padding: '0px 12px',
																		lineHeight: '26px',
																	}}
																	href={`https://manifestfinancial.com/${currentApp.appCode}/video/${this.state.videoId}`}
																	target="_blank"
																	rel="noopener noreferrer">
																	Share Video
																</Button>
															)}
														</div>
														<FormCheckbox
															small
															toggle
															checked={this.state.nerveExclusive}
															onChange={() =>
																this.setState({
																	nerveExclusive: !this.state.nerveExclusive,
																})
															}>
															<span style={{ fontSize: 12 }}>
																<Tip
																	style={{ marginRight: 4 }}
																	color={colors.dark.value}
																	prompt="Manifest Exclusive"
																	place="right"
																	helpText="'Manifest exclusive' means this video isn't<br />offered through other means (like social media<br />or YouTube). Viewers will see a badge on<br />exclusive videos, reinforcing the value<br />that your Manifest channel provides."
																/>
															</span>
														</FormCheckbox>
														{this.state.hasFreeloaders && (
															<>
																<FormCheckbox
																	small
																	toggle
																	checked={this.state.availableForFree}
																	onChange={this.toggleAvailableForFree}>
																	<span style={{ fontSize: 12 }}>
																		<Tip
																			style={{ marginRight: 4 }}
																			color={colors.dark.value}
																			prompt="Available For Free"
																			place="right"
																			helpText="'Available For Free' videos are<br />available to all users in the web."
																		/>
																	</span>
																</FormCheckbox>
															</>
														)}
														<p style={{ marginTop: 24, marginBottom: 10 }}>
															Collections{'  '}
															<Button
																theme="white"
																size="sm"
																style={{
																	marginLeft: 8,
																	height: 28,
																	padding: '0px 12px',
																}}
																onClick={() =>
																	this.setState({ showingCollectionsModal: true })
																}>
																{this.state.availableCollections.length === 0
																	? 'Add'
																	: 'Manage'}
															</Button>
														</p>
														{this.state.availableCollections.length > 0 && (
															<Row>
																{this.state.availableCollections.map(collection => (
																	<Col xl="12" key={collection._id}>
																		<FormCheckbox
																			key={collection._id}
																			small
																			toggle
																			checked={this.isInCollection(
																				collection._id,
																			)}
																			onChange={() =>
																				this.toggleIsInCollection(
																					collection._id,
																				)
																			}>
																			<span style={{ fontSize: 12 }}>
																				{collection.name}
																			</span>
																		</FormCheckbox>
																	</Col>
																))}
															</Row>
														)}
														{this.state.availableCollections.length === 0 && (
															<Row>
																<Col xl="8">
																	<img
																		src={require('../../images/manifest/collectionSampleVideo.png')}
																		style={{
																			marginBottom: 10,
																			width: '100%',
																			cursor: 'pointer',
																		}}
																		onClick={() =>
																			this.setState({
																				showingCollectionsModal: true,
																			})
																		}
																	/>
																</Col>
															</Row>
														)}
													</div>
												)}
												{this.state.detailsTabIndex === 1 && (
													<div
														className="py-4 px-4 mt-3"
														style={{
															backgroundColor: 'rgb(230 238 244)',
															borderRadius: 10,
														}}>
														<p
															style={{
																marginBottom: 10,
															}}>
															Chapters{'  '}
															<Button
																theme="white"
																size="sm"
																style={{
																	marginLeft: 8,
																	height: 28,
																	padding: '0px 12px',
																}}
																disabled={!this.state.playbackUrl}
																onClick={this.addChapterAtCurrentTime}>
																Add
															</Button>
														</p>
														{!this.state.playbackUrl && (
															<p
																style={{
																	fontSize: 14,
																	fontWeight: '300',
																	marginBottom: 16,
																}}>
																You can add chapters after your video finishes
																processing.
															</p>
														)}
														{this.state.playbackUrl && (
															<p
																style={{
																	fontSize: 14,
																	fontWeight: '300',
																	marginBottom: 16,
																}}>
																Move the slider in your video above to where you want
																your chapter to start and click <em>Add</em>. Create as
																many chapters as makes sense for your video (eg. for
																each song in your concert, create a chapter), but don’t
																over-do it. 🙂
															</p>
														)}

														{this.state.chapters.length > 0 &&
															this.state.chapters.map((chapter, index) => (
																<Row key={index}>
																	<Col
																		sm="12"
																		style={{
																			flexDirection: 'row',
																			display: 'flex',
																			alignItems: 'center',
																		}}>
																		<FormInput
																			size="sm"
																			style={{ flex: 4, marginRight: 4 }}
																			className="mb-1 mt-1"
																			placeholder="Chapter Title"
																			value={chapter.title}
																			onChange={event =>
																				this.onChapterTitleChange(event, index)
																			}
																			innerRef={
																				index === this.state.newChapterIndex
																					? this.newTitleField
																					: null
																			}
																		/>
																		<FormInput
																			size="sm"
																			style={{ flex: 1, marginRight: 6 }}
																			className="mb-1 mt-1"
																			placeholder="Start Time"
																			value={
																				this.state.editingChapterStartIndex ===
																				index
																					? this.state.editingChapterStart
																					: moment
																							.duration(
																								chapter.start,
																								'seconds',
																							)
																							.format(formatString, {
																								trim: false,
																							})
																			}
																			onFocus={() =>
																				this.onChapterStartFocus(index)
																			}
																			onBlur={() =>
																				this.onChapterStartBlur(index)
																			}
																			onChange={event =>
																				this.onChapterStartChange(event, index)
																			}
																		/>
																		<i
																			className="material-icons"
																			onClick={() => this.playFrom(chapter.start)}
																			style={{
																				fontSize: 29,
																				top: -0.5,
																				left: -2.5,
																				cursor: 'pointer',
																				color: 'rgb(144 176 212)',
																				marginRight: 20,
																			}}>
																			play_circle
																		</i>
																		<Button
																			theme="white"
																			disabled={
																				this.state.chapters.length > 1 &&
																				chapter.start === 0
																			}
																			size="sm"
																			style={{
																				borderWidth: 0,
																				height: 28,
																				padding: '0px 8px',
																			}}
																			onClick={() => this.deleteChapter(index)}>
																			Delete
																		</Button>
																	</Col>
																</Row>
															))}
														{this.state.chapters.length === 0 && (
															<Row>
																<Col xl="8">
																	<img
																		src={require('../../images/manifest/chapterSample.png')}
																		style={{ marginBottom: 4, width: '100%' }}
																	/>
																</Col>
															</Row>
														)}
													</div>
												)}
											</CardBody>
										</Card>
									</Col>
									<Col lg="3" md="12">
										<Card small>
											<CardBody>
												<Row>
													<Col
														sm="12"
														style={{
															paddingTop: 5,
															marginBottom: -5,
														}}>
														<FormCheckbox
															checked={this.state.isLive}
															onChange={this.toggleIsLive}
															toggle
															small>
															Live
														</FormCheckbox>
													</Col>
												</Row>
												{!this.state.isLive && (
													<Row className="mt-4">
														<Col sm="12">
															<strong className="text-muted form-text d-block mb-2">
																Auto-publish (optional)
															</strong>
															<DatePicker
																size="md"
																selected={this.state.publishDate}
																onChange={this.onPublishDateChange}
																placeholderText="Publish Date"
																dropdownMode="select"
																filterDate={this.futureDateFilter}
															/>
															<FormSelect
																size="md"
																className="w-100 mt-2"
																value={moment(this.state.publishDate).format('HH')}
																onChange={this.onPublishTimeChange}>
																<option value="">Publish Time</option>
																<option value="09">9:00 a.m.</option>
																<option value="10">10:00 a.m.</option>
																<option value="11">11:00 a.m.</option>
																<option value="12">12:00 p.m.</option>
																<option value="13">1:00 p.m.</option>
																<option value="14">2:00 p.m.</option>
																<option value="15">3:00 p.m.</option>
																<option value="16">4:00 p.m.</option>
																<option value="17">5:00 p.m.</option>
																<option value="18">6:00 p.m.</option>
																<option value="19">7:00 p.m.</option>
																<option value="20">8:00 p.m.</option>
																<option value="21">9:00 p.m.</option>
																<option value="22">10:00 p.m.</option>
																<option value="23">11:00 p.m.</option>
																<option value="00">12:00 a.m.</option>
																<option value="01">1:00 a.m.</option>
																<option value="02">2:00 a.m.</option>
																<option value="03">3:00 a.m.</option>
																<option value="04">4:00 a.m.</option>
																<option value="05">5:00 a.m.</option>
																<option value="06">6:00 a.m.</option>
																<option value="07">7:00 a.m.</option>
																<option value="08">8:00 a.m.</option>
															</FormSelect>
														</Col>
													</Row>
												)}
											</CardBody>
										</Card>
										<Row className="mt-4">
											<Col sm="12">
												<Button
													theme="dark"
													size="sm"
													style={{ width: '100%' }}
													disabled={!this.state.saveEnabled}
													onClick={this.submit}>
													Save
												</Button>
											</Col>
										</Row>
										{this.state.videoId && (
											<Row className="mt-2">
												<Col sm="12">
													<Button
														theme="white"
														size="sm"
														style={{ width: '100%' }}
														onClick={this.delete}>
														Delete Video
													</Button>
												</Col>
											</Row>
										)}
									</Col>
								</Row>
								<ManageCollectionsModal
									collectionType="video"
									open={this.state.showingCollectionsModal}
									toggle={() => {
										this.updateCollections();
										this.setState({ showingCollectionsModal: false });
									}}
								/>
							</div>
						)}
					</Subscribe>
				)}
			</Container>
		);
	};
}
