import React from 'react';
import { Container, Row, Col, Nav, Collapse } from 'shards-react';
import MenuItem from './MenuItem';
import { Subscribe } from 'unstated';
import AppsContainer from '../../../data/AppsContainer';
import Session from '../../../data/Session';
import { checkPermissionsForUser } from '../../../components/RequirePermissions';

export default props => (
	<Collapse className="header-navbar d-md-flex p-0 bg-white border-top">
		<Container>
			<Row>
				<Col>
					<Nav tabs className="border-0 flex-column flex-md-row">
						<Subscribe to={[Session, AppsContainer]}>
							{({ state: { user } }, { state: { currentApp, currentNpo } }) => {
								if (currentApp) {
									return props.items.map((item, index) => <MenuItem key={index} item={item()} />);
								} else if (currentNpo) {
									return props.items.map((item, index) => <MenuItem key={index} item={item()} />);
								} else {
									return props.items.map((item, index) => <MenuItem key={index} item={item()} />);
								}
							}}
						</Subscribe>
					</Nav>
				</Col>
			</Row>
		</Container>
	</Collapse>
);
