import React, { Component } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	Button,
	FormSelect,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	FormInput,
	FormTextarea,
} from 'shards-react';
import AppsContainer from '../../data/AppsContainer';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import API from '../../data/API';
import waitForContainer from '../../utils/waitForContainer';
import ImageDropzone from '../../components/ImageDropzone';

const bioMaxCharacters = 500;
const introMaxCharacters = 150;

export default class Theme extends Component {
	state = {
		loading: true,
		saveEnabled: false,
		scheme: 'dark',
		pronouns: 'singular',
		gender: 'male',
		useLogoInsteadOfName: false,
		hero: null,
		existingHero: null,
		deleteHero: 'false',
		logo: null,
		existingLogo: null,
		deleteLogo: 'false',
		backgroundColor: 'rgba(0,0,0,.7)',
		headlineColor: 'rgba(255,255,255,1)',
		textColor: 'rgba(255,255,255,1)',
		linkColor: 'rgba(101,124,157,1)',
		buttonColor: 'rgba(255,249,115,1)',
		buttonTextColor: 'rgba(34,35,45,1)',
		tabBackgroundColor: 'rgba(34,35,45,1)',
		tabIconColor: 'rgba(170,170,170,1)',
		tabSelectedIconColor: 'rgba(255,249,115,1)',
		formBackgroundColor: 'rgba(34,35,45,1)',
		formTextColor: 'rgba(255,255,255,1)',
		navBarBackgroundColor: 'rgba(34,35,45,1)',
		navBarTitleColor: 'rgba(255,255,255,1)',
		navBarButtonColor: 'rgba(255,249,115,1)',
		facebook: '',
		instagram: '',
		twitter: '',
		snapchat: '',
		tiktok: '',
		youtube: '',
		soundcloud: '',
		website: '',
		bio: '',
		intro: '',
	};

	componentDidMount = async () => {
		this.refresh();
	};

	refresh = async () => {
		let { theme = {}, socialAccounts = {} } = await waitForContainer(AppsContainer, 'currentApp');
		let { hero: existingHero, logo: existingLogo, ...rest } = theme;

		this.setState(
			{
				existingHero,
				deleteHero: 'false',
				existingLogo,
				deleteLogo: 'false',
				...rest,
				...socialAccounts,
				loading: false,
			},
			this.updateValidation,
		);
	};

	submit = async (redirect = true) => {
		let { loading, saveEnabled, existingHero, existingLogo, ...postBody } = this.state;

		if (!saveEnabled) return;

		let { _id: appId, appCode } = AppsContainer.getInstance().state.currentApp;

		try {
			this.setState({ loading: true });

			let formData = new FormData();
			let keys = Object.keys(postBody);
			keys.forEach(key => {
				if (postBody[key]) formData.append(key, postBody[key]);
			});
			//if these are falsey they don't get in
			formData.append('deleteHero', postBody.deleteHero);
			formData.append('deleteLogo', postBody.deleteLogo);
			let updatedApp = await API.multipartPost(`/admin/${appId}/theme`, formData);

			AppsContainer.getInstance().refresh();

			if (redirect) {
				this.props.history.push(`/channel/${appCode}`);
			} else {
				let { hero: existingHero, logo: existingLogo, ...rest } = updatedApp.theme;
				let { socialAccounts } = updatedApp;
				this.setState(
					{
						existingHero,
						existingLogo,
						...rest,
						...socialAccounts,
						loading: false,
					},
					this.updateValidation,
				);
			}
		} catch (e) {
			this.setState({ loading: false });
			alert(`Oops! ${e.message}`);
		}
	};

	isValid = () => {
		return true;
	};

	updateValidation = () => {
		this.setState({ saveEnabled: this.isValid() });
	};

	onFieldChange = (event, field) => {
		let newState = {};
		newState[field] = event.target.value;
		this.setState(newState, this.updateValidation);
	};

	onColorChange = (color, field) => {
		let newState = {};
		newState[field] = color;
		this.setState(newState, this.updateValidation);
	};

	onImageChange = (file, field) => {
		let newState = {};
		if (field === 'hero') newState.deleteHero = file === null ? 'true' : 'false';
		if (field === 'logo') newState.deleteLogo = file === null ? 'true' : 'false';
		newState[field] = file;
		this.setState(newState, this.updateValidation);
	};

	onKeyUp = event => {
		if (event.key === 'Enter') {
			this.submit();
		}
	};

	previewIntro = () => {
		var windowReference = window.open('', 'nervePreview');
		this.previewIntroAsync(windowReference);
	};

	previewIntroAsync = async windowReference => {
		await this.submit(false);
		let appCode = AppsContainer.getInstance().state.currentAppCode;
		windowReference.location = `https://manifestfinancial.com/${appCode}?preview`;
	};

	render = () => {
		let bioCharactersLeft = !!this.state.bio ? bioMaxCharacters - this.state.bio.length : 500;
		let introCharactersLeft = !!this.state.intro ? introMaxCharacters - this.state.intro.length : 150;

		return (
			<Container className="page-container theme">
				{this.state.loading ? (
					<ScreenLoader />
				) : (
					<div>
						<Row noGutters className="page-header py-4">
							<PageTitle title="About My Channel" subtitle="Personalize" className="text-sm-left mb-3" />
						</Row>
						<Row>
							<Col lg="9" md="12">
								<Card small className="mb-3">
									<CardHeader className="border-bottom">
										<h6 className="m-0">General</h6>
									</CardHeader>
									<CardBody>
										<Row className="px-3">
											<span className="text-semibold text-fiord-blue">Pronouns</span>
										</Row>
										<FormSelect
											size="sm"
											value={this.state.pronouns}
											onChange={event => this.onFieldChange(event, 'pronouns')}
											style={{ maxWidth: 200, marginBottom: 12, marginTop: 4 }}>
											<option value="singular">Singular (me, my)</option>
											<option value="plural">Plural (us, our)</option>
										</FormSelect>
										&nbsp;&nbsp;
										{this.state.pronouns === 'singular' && (
											<FormSelect
												size="sm"
												value={this.state.gender}
												onChange={event => this.onFieldChange(event, 'gender')}
												style={{ maxWidth: 200, marginBottom: 12, marginTop: 4 }}>
												<option value="male">Male (he, him)</option>
												<option value="female">Female (she, her)</option>
												<option value="nonbinary">Non-Binary (they, them)</option>
											</FormSelect>
										)}
										<Row className="px-3 pt-2">
											<span className="text-semibold text-fiord-blue">Channel Intro</span>
											<span style={{ flex: 1 }} />
											<span
												className="text-semibold"
												style={{
													fontSize: 12,
													float: 'right',
													color: introCharactersLeft < 10 && 'red',
												}}>
												{introCharactersLeft} characters remaining
											</span>
										</Row>
										<FormTextarea
											id="intro"
											size="lg"
											rows="4"
											value={this.state.intro || ''}
											onChange={event => this.onFieldChange(event, 'intro')}
											maxLength={introMaxCharacters}
											style={{
												marginBottom: 12,
											}}
										/>
										<Row className="px-3 pt-2">
											<span className="text-semibold text-fiord-blue">Channel Bio</span>
											<span style={{ flex: 1 }} />
											<span
												className="text-semibold"
												style={{
													fontSize: 12,
													float: 'right',
													color: bioCharactersLeft < 10 && 'red',
												}}>
												{bioCharactersLeft} characters remaining
											</span>
										</Row>
										<FormTextarea
											id="bio"
											size="lg"
											rows="8"
											value={this.state.bio || ''}
											onChange={event => this.onFieldChange(event, 'bio')}
											maxLength={bioMaxCharacters}
										/>
									</CardBody>
								</Card>
								<Card small className="mb-3">
									<CardHeader className="border-bottom">
										<h6 className="m-0">My Links</h6>
									</CardHeader>
									<CardBody>
										<InputGroup size="lg" className="mb-3">
											<InputGroupAddon type="prepend">
												<InputGroupText
													style={{
														width: 160,
														borderRightWidth: 0,
														fontSize: 12,
														fontWeight: 400,
														color: '#333',
														textAlign: 'right',
														display: 'inline-block',
														lineHeight: 'unset',
													}}>
													www.
												</InputGroupText>
											</InputGroupAddon>
											<FormInput
												style={{ maxWidth: 400, height: 46 }}
												placeholder="mywebsite.com"
												value={this.state.website || ''}
												onChange={event => this.onFieldChange(event, 'website')}
												onKeyUp={this.onKeyUp}
											/>
										</InputGroup>
										<InputGroup size="lg" className="mb-3">
											<InputGroupAddon type="prepend">
												<InputGroupText
													style={{
														width: 160,
														borderRightWidth: 0,
														fontSize: 12,
														fontWeight: 400,
														color: '#333',
														textAlign: 'right',
														display: 'inline-block',
														lineHeight: 'unset',
													}}>
													facebook.com/
												</InputGroupText>
											</InputGroupAddon>
											<FormInput
												style={{ maxWidth: 400, height: 46 }}
												placeholder="username"
												value={this.state.facebook || ''}
												onChange={event => this.onFieldChange(event, 'facebook')}
												onKeyUp={this.onKeyUp}
											/>
										</InputGroup>
										<InputGroup size="lg" className="mb-3">
											<InputGroupAddon type="prepend">
												<InputGroupText
													style={{
														width: 160,
														borderRightWidth: 0,
														fontSize: 12,
														fontWeight: 400,
														color: '#333',
														textAlign: 'right',
														display: 'inline-block',
														lineHeight: 'unset',
													}}>
													instagram.com/
												</InputGroupText>
											</InputGroupAddon>
											<FormInput
												style={{ maxWidth: 400, height: 46 }}
												placeholder="username"
												value={this.state.instagram || ''}
												onChange={event => this.onFieldChange(event, 'instagram')}
												onKeyUp={this.onKeyUp}
											/>
										</InputGroup>
										<InputGroup size="lg" className="mb-3">
											<InputGroupAddon type="prepend">
												<InputGroupText
													style={{
														width: 160,
														borderRightWidth: 0,
														fontSize: 12,
														fontWeight: 400,
														color: '#333',
														textAlign: 'right',
														display: 'inline-block',
														lineHeight: 'unset',
													}}>
													twitter.com/
												</InputGroupText>
											</InputGroupAddon>
											<FormInput
												style={{ maxWidth: 400, height: 46 }}
												placeholder="username"
												value={this.state.twitter || ''}
												onChange={event => this.onFieldChange(event, 'twitter')}
												onKeyUp={this.onKeyUp}
											/>
										</InputGroup>
										<InputGroup size="lg" className="mb-3">
											<InputGroupAddon type="prepend">
												<InputGroupText
													style={{
														width: 160,
														borderRightWidth: 0,
														fontSize: 12,
														fontWeight: 400,
														color: '#333',
														textAlign: 'right',
														display: 'inline-block',
														lineHeight: 'unset',
													}}>
													snapchat.com/add/
												</InputGroupText>
											</InputGroupAddon>
											<FormInput
												style={{ maxWidth: 400, height: 46 }}
												placeholder="username"
												value={this.state.snapchat || ''}
												onChange={event => this.onFieldChange(event, 'snapchat')}
												onKeyUp={this.onKeyUp}
											/>
										</InputGroup>
										<InputGroup size="lg" className="mb-3">
											<InputGroupAddon type="prepend">
												<InputGroupText
													style={{
														width: 160,
														borderRightWidth: 0,
														fontSize: 12,
														fontWeight: 400,
														color: '#333',
														textAlign: 'right',
														display: 'inline-block',
														lineHeight: 'unset',
													}}>
													tiktok.com/@
												</InputGroupText>
											</InputGroupAddon>
											<FormInput
												style={{ maxWidth: 400, height: 46 }}
												placeholder="username"
												value={this.state.tiktok || ''}
												onChange={event => this.onFieldChange(event, 'tiktok')}
												onKeyUp={this.onKeyUp}
											/>
										</InputGroup>
										<InputGroup size="lg" className="mb-3">
											<InputGroupAddon type="prepend">
												<InputGroupText
													style={{
														width: 160,
														borderRightWidth: 0,
														fontSize: 12,
														fontWeight: 400,
														color: '#333',
														textAlign: 'right',
														display: 'inline-block',
														lineHeight: 'unset',
													}}>
													youtube.com/c/
												</InputGroupText>
											</InputGroupAddon>
											<FormInput
												style={{ maxWidth: 400, height: 46 }}
												placeholder="channel id"
												value={this.state.youtube || ''}
												onChange={event => this.onFieldChange(event, 'youtube')}
												onKeyUp={this.onKeyUp}
											/>
										</InputGroup>
										<InputGroup size="lg" className="mb-3">
											<InputGroupAddon type="prepend">
												<InputGroupText
													style={{
														width: 160,
														borderRightWidth: 0,
														fontSize: 12,
														fontWeight: 400,
														color: '#333',
														textAlign: 'right',
														display: 'inline-block',
														lineHeight: 'unset',
													}}>
													soundcloud.com/
												</InputGroupText>
											</InputGroupAddon>
											<FormInput
												style={{ maxWidth: 400, height: 46 }}
												placeholder="username"
												value={this.state.soundcloud || ''}
												onChange={event => this.onFieldChange(event, 'soundcloud')}
												onKeyUp={this.onKeyUp}
											/>
										</InputGroup>
									</CardBody>
								</Card>
							</Col>
							<Col lg="3" md="12">
								<Card small>
									<div className="background-art">
										<ImageDropzone
											label="Upload Artwork"
											allowRemoval
											defaultImage={this.state.existingHero}
											onFileSelect={file => this.onImageChange(file, 'hero')}
											accept=".png, .jpg"
										/>
									</div>
									<CardBody>
										<Row className="mb-2">
											<Col sm="12">
												<Button
													theme="accent"
													size="sm"
													style={{ width: '100%' }}
													disabled={!this.state.saveEnabled}
													onClick={this.submit}>
													Save
												</Button>
											</Col>
										</Row>
										<Row className="mb-2">
											<Col sm="12">
												<Button
													theme="white"
													size="sm"
													style={{ width: '100%' }}
													disabled={!this.state.saveEnabled}
													onClick={this.previewIntro}>
													Save + Preview Landing Page
												</Button>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</div>
				)}
			</Container>
		);
	};
}
