import React, { Component } from "react";
import { Button } from "shards-react";
import copy from 'copy-to-clipboard';

export default class extends Component {

	static defaultProps = {
		successMessage: 'copied!',
	}

	constructor(props) {
		super(props);
		this.state = {
			overrideLabel: null,
			copyText: props.copyText,
		}
	}

	onClick = () => {
		copy(this.state.copyText);
		this.setState({ overrideLabel: this.props.successMessage });
		setTimeout(() => {
			this.setState({ overrideLabel: null });
		}, 1000);
	}

	render = () => <Button
		size={this.props.size}
		pill={this.props.pill}
		theme={this.props.theme}
		style={this.props.style}
		onClick={this.onClick}>
		{this.state.overrideLabel || this.props.children}
	</Button>
}