import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'shards-react';
import { Subscribe } from 'unstated';
import Session from '../../data/Session';
import AppsContainer from '../../data/AppsContainer';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import API from '../../data/API';

let clientId;
if (window.location.hostname === 'localhost') {
	clientId = `ca_FhGUS3KpRgXNc8xoLOfioKdQhrMc4bQE`;
} else {
	clientId = `ca_FhGUzpeXMeLekeHqKzGIsLjWbjJfAPn1`;
}

export default class extends Component {
	submit = () => {
		var windowReference = window.open('', 'stripeConnection');
		this.submitAsync(windowReference);
	};

	submitAsync = async windowReference => {
		let { user } = Session.getInstance().state;

		try {
			this.setState({ loading: true });
			let { csrfToken } = await API.post(`/admin/npo/${this.props.match.params.npo}/stripe-connection-init`);
			let isDev = window.location.hostname === 'localhost';

			let returnUrl = isDev
				? encodeURIComponent(`http://localhost:3002/npo/bank/complete`)
				: encodeURIComponent(`https://admin.manifestfinancial.com/npo/bank/complete`);

			let url = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${returnUrl}&client_id=${clientId}&state=${csrfToken}&stripe_user[email]=${encodeURIComponent(
				user.email,
			)}`;

			AppsContainer.getInstance().refresh();
			windowReference.location = url;
		} catch (e) {
			this.setState({ loading: false });
			alert(`Oops! ${e.message}`);
		}
	};

	render = () => (
		<Container className="page-container">
			<Row noGutters className="page-header py-4">
				<PageTitle title={`Connect My Organization's Bank`} subtitle="Payments" className="text-sm-left mb-3" />
			</Row>
			<Row>
				<Col>
					<Subscribe to={[Session, AppsContainer]}>
						{({ state: { user } }, { state: { currentNpo } }) => {
							if (!user || !currentNpo) {
								return <ScreenLoader />;
							} else if (currentNpo.stripe && currentNpo.stripe.accountId) {
								return (
									<Card>
										<CardBody
											className="d-flex"
											style={{
												flexDirection: 'column',
												alignItems: 'center',
												justifyContent: 'center',
												paddingTop: 120,
												paddingBottom: 120,
												textAlign: 'center',
											}}>
											<h4>You've connected your bank account.</h4>
											<p>
												Need to make changes?{' '}
												<a href="mailto:support@manifestfinancial.com">Email us.</a>
											</p>
										</CardBody>
									</Card>
								);
							} else {
								return (
									<Card>
										<CardBody
											className="d-flex"
											style={{
												flexDirection: 'column',
												alignItems: 'center',
												justifyContent: 'center',
												paddingTop: 120,
												paddingBottom: 120,
												textAlign: 'center',
											}}>
											<h4>Link your organization's bank account.</h4>
											<p>
												Manifest will send payments to your organization using Stripe. Don't
												have access to your info?{' '}
												<a href="mailto:support@manifestfinancial.com">Email us.</a>
											</p>
											<img
												onClick={this.submit}
												alt="Connect to Stripe"
												src={require('../../images/manifest/connectStripeButton.png')}
												style={{ width: 220, marginTop: 10 }}
											/>
										</CardBody>
									</Card>
								);
							}
						}}
					</Subscribe>
				</Col>
			</Row>
		</Container>
	);
}
