import React, { Component } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	Form,
	FormGroup,
	FormInput,
	FormCheckbox,
	Button,
} from 'shards-react';
import API from '../../data/API';
import Session from '../../data/Session';
import queryString from 'query-string';
import { Link } from 'react-router-dom';

export default class ChangePassword extends Component {
	constructor(props) {
		super(props);
		let { redirect, mobileLast4, mobileNumberVerified, ...otherQueryParams } = queryString.parse(
			this.props.location.search,
		);
		this.state = {
			code: '',
			rememberMe: false,
			submitEnabled: false,
			loading: false,
			redirect,
			otherQueryParams,
			mobileLast4,
			mobileNumberVerified: mobileNumberVerified === 'true',
		};
	}

	isValid = () => {
		let { code } = this.state;
		return code.length === 6;
	};

	submit = async () => {
		if (!this.state.submitEnabled || this.state.loading) return;

		let { code, rememberMe } = this.state;

		this.setState({ loading: true });

		try {
			let { adminToken } = await API.post(`/auth/verify-mobile`, { code, rememberMe });
			Session._instance.setToken(adminToken, rememberMe, () => {
				let { otherQueryParams, redirect } = this.state;
				if (redirect) {
					if (otherQueryParams) {
						const stringified = queryString.stringify(otherQueryParams);
						this.props.history.push(redirect + '?' + stringified);
					} else {
						this.props.history.push(redirect);
					}
				} else {
					this.props.history.push(`/profile`);
				}
			});
		} catch (e) {
			alert(`Oops! ${e.message}`);
			this.setState({ loading: false });
		}
	};

	onFieldChange = (event, field) => {
		let newState = {};
		newState[field] = event.target.value;
		this.setState(newState, () => {
			this.setState({ submitEnabled: this.isValid() });
		});
	};

	onCheckChange = field => {
		let newState = {};
		newState[field] = !this.state[field];
		this.setState(newState, () => {
			this.setState({ submitEnabled: this.isValid() });
		});
	};

	onKeyUp = event => {
		if (event.key === 'Enter') {
			this.submit();
		}
	};

	render = () => (
		<Container fluid className="main-content-container h-100 px-4">
			<Row noGutters className="h-100">
				<Col lg="3" md="5" className="auth-form mx-auto my-auto">
					<Card>
						<CardHeader>
							<img
								className="auth-form__logo d-table mx-auto mb-3"
								src={require('../../images/manifest/logo.png')}
								alt="Manifest"
							/>
							<h5 className="auth-form__title text-center mb-4">We texted you a code.</h5>
						</CardHeader>
						<CardBody>
							<Form onSubmit={e => e.preventDefault()}>
								<FormGroup>
									<label htmlFor="codeInput">6-digit code</label>
									<FormInput
										autoFocus
										id="codeInput"
										type="number"
										maxLength={6}
										value={this.state.code}
										onChange={event => this.onFieldChange(event, 'code')}
										onKeyUp={this.onKeyUp}
									/>
								</FormGroup>
								<FormGroup>
									<FormCheckbox
										checked={this.state.rememberMe}
										onChange={() => this.onCheckChange('rememberMe')}>
										Keep me logged in for today.
									</FormCheckbox>
								</FormGroup>
								<Button
									pill
									theme="accent"
									className="d-table mx-auto"
									onClick={this.submit}
									disabled={!this.state.submitEnabled || this.state.loading}>
									Verify
								</Button>
							</Form>
						</CardBody>
					</Card>
					{!this.state.mobileNumberVerified && (
						<div className="auth-form__meta d-flex mt-4">
							<Link
								to={{ pathname: `./modify-mobile`, search: this.props.location.search }}
								className="mx-auto">
								Didn't receive the code?
							</Link>
						</div>
					)}
				</Col>
			</Row>
		</Container>
	);
}
