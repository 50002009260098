import React, { forwardRef } from 'react';
import { FormInput, InputGroup, FormSelect } from 'shards-react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

const SelectComponent = ({ value, onChange, iconComponent, options, ...props }) => (
	<FormSelect
		style={{ fontSize: 16, lineHeight: 1, maxWidth: 62, color: '#FFF' }}
		value={value}
		onChange={event => onChange(event.target.value)}
		{...props}>
		{options.map((option, index) => (
			<option value={option.value} key={index}>
				{option.value && getUnicodeFlagIcon(option.value)} {option.label}
			</option>
		))}
	</FormSelect>
);

const InputComponent = forwardRef(({ value, onChange, invalid, onBlur, ...rest }, ref) => (
	<FormInput
		innerRef={ref}
		value={value}
		onBlur={onBlur}
		invalid={invalid}
		onChange={event => onChange(event.target.value)}
		{...rest}
	/>
));

export default ({ value, onChange, invalid, onBlur }) => {
	return (
		<PhoneInput
			defaultCountry="US"
			placeholder="mobile number"
			countrySelectComponent={SelectComponent}
			inputComponent={InputComponent}
			containerComponent={InputGroup}
			id="mobileInput"
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			invalid={invalid}
		/>
	);
};
