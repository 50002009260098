import React, { Component } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, FormInput, Button } from 'shards-react';
import queryString from 'query-string';
import API from '../../data/API';

export default class ChangePassword extends Component {
	constructor(props) {
		super(props);
		let { email } = queryString.parse(this.props.location.search);
		email = decodeURIComponent(email);
		this.state = {
			email,
			password: '',
			code: '',
			submitEnabled: false,
			loading: false,
		};
	}

	isValid = () => {
		let { email, password, code } = this.state;
		return /\S+@\S+\.\S+/.test(email) && password.length >= 8 && code.length === 6;
	};

	submit = async () => {
		if (!this.state.submitEnabled) return;

		let { email, password, code } = this.state;

		this.setState({ loading: true });

		try {
			await API.post(`/auth/reset-password`, { email, password, code });
			alert(`Your password has been reset!`);
			this.props.history.push(`/login`);
		} catch (e) {
			alert(`Oops! ${e.message}`);
			this.setState({ loading: false });
		}
	};

	onFieldChange = (event, field) => {
		let newState = {};
		newState[field] = event.target.value;
		this.setState(newState, () => {
			this.setState({ submitEnabled: this.isValid() });
		});
	};

	onKeyUp = event => {
		if (event.key === 'Enter') {
			this.submit();
		}
	};

	render = () => (
		<Container fluid className="main-content-container h-100 px-4">
			<Row noGutters className="h-100">
				<Col lg="3" md="5" className="auth-form mx-auto my-auto">
					<Card>
						<CardHeader>
							<img
								className="auth-form__logo d-table mx-auto mb-3"
								src={require('../../images/manifest/logo.png')}
								alt="Manifest"
							/>
							<h5 className="auth-form__title text-center mb-4">Check your email.</h5>
						</CardHeader>
						<CardBody>
							<Form onSubmit={e => e.preventDefault()}>
								<FormGroup>
									<label htmlFor="emailInput">Email address</label>
									<FormInput
										id="emailInput"
										type="email"
										autoComplete="email"
										value={this.state.email}
										onChange={event => this.onFieldChange(event, 'email')}
										onKeyUp={this.onKeyUp}
									/>
								</FormGroup>
								<FormGroup>
									<label htmlFor="codeInput">6-digit code</label>
									<FormInput
										autoFocus
										id="codeInput"
										type="number"
										maxlength={6}
										value={this.state.code}
										onChange={event => this.onFieldChange(event, 'code')}
										onKeyUp={this.onKeyUp}
									/>
								</FormGroup>
								<FormGroup>
									<label htmlFor="passwordInput">Password</label>
									<FormInput
										id="passwordInput"
										type="password"
										autoComplete="new-password"
										value={this.state.password}
										onChange={event => this.onFieldChange(event, 'password')}
										onKeyUp={this.onKeyUp}
									/>
								</FormGroup>
								<Button
									pill
									theme="accent"
									className="d-table mx-auto"
									onClick={this.submit}
									disabled={!this.state.submitEnabled}>
									Change Password
								</Button>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
