import React, { useState } from 'react';
import { Row, Col, Card, CardBody, CardHeader, FormInput, FormRadio } from 'shards-react';
import PasswordAndCommentModal, { useToggleModal } from '../../../components/common/PasswordAndCommentModal';
import RequirePermissions from '../../../components/RequirePermissions';

import ReactTable from 'react-table';
import API from '../../../data/API';

const OwnersDisplay = ({ canOrderCard, owners, history, onRefresh, businessProfileId }) => {
	const [action, setAction] = useState('');
	const [selectedUserId, setSelectedUserId] = useState('');
	const [cardType, setCardType] = useState('');

	const [open, toggle] = useToggleModal();

	const onRowSlected = (id, cardProductType) => {
		setSelectedUserId(id);
		setCardType(cardProductType);
	};

	const activateCard = async (password, comment) => {
		await API.post(`/banking-admin/business/activate-card/`, {
			userId: selectedUserId,
			businessProfileId,
			password,
			comment,
		});
		onRefresh();
	};

	const suspendCard = async (password, comment) => {
		await API.post(`/banking-admin/business/suspend-card/`, {
			userId: selectedUserId,
			businessProfileId,
			password,
			comment,
		});
		onRefresh();
	};

	const revokeCard = async (password, comment) => {
		await API.post(`/banking-admin/business/revoke-card/`, {
			userId: selectedUserId,
			businessProfileId,
			password,
			comment,
		});
		onRefresh();
	};

	const reorderCard = async (password, comment) => {
		await API.post(`/banking-admin/business/reorder-card/`, {
			userId: selectedUserId,
			businessProfileId,
			password,
			comment,
			cardProductTypeOverwrite: cardType,
		});
		onRefresh();
	};

	const orderCard = async (password, comment) => {
		await API.post(`/banking-admin/business/order-card/`, {
			userId: selectedUserId,
			businessProfileId,
			password,
			comment,
		});
		onRefresh();
	};

	const tableColumns = [
		{
			Header: '',
			Cell: row => (
				<div>
					<div
						style={{
							width: 10,
							height: 10,
							background: row.original.isFlaggedForFraud ? '#E32727' : 'transparent',
							borderRadius: '50%',
						}}
					/>
				</div>
			),
			className: 'text-center d-flex align-items-center',
			maxWidth: 34,
			minWidth: 34,
		},
		{
			Header: 'Name',
			accessor: 'name',
			className: 'text-center d-flex align-items-center',
			maxWidth: 100,
		},
		{
			Header: 'Opt-in',
			accessor: 'optIn',
			className: 'text-center d-flex align-items-center',
			maxWidth: 80,
			Cell: row => (
				<div>
					{row.original.optIn ? (
						<img
							src={require('../../../images/icons/check.png')}
							alt="icon_check"
							style={{
								width: 28,
								height: 28,
							}}
						/>
					) : null}
				</div>
			),
		},
		{
			Header: 'Admin',
			accessor: 'admin',
			className: 'text-center d-flex align-items-center',
			maxWidth: 80,
			Cell: row => (
				<div>
					{row.original.admin ? (
						<img
							src={require('../../../images/icons/check.png')}
							alt="icon_check"
							style={{
								width: 28,
								height: 28,
							}}
						/>
					) : null}
				</div>
			),
		},
		{
			Header: 'Percentage',
			accessor: 'ownershipPercentage',
			className: 'text-center d-flex align-items-center',
			maxWidth: 80,
		},
		{
			Header: 'Title',
			accessor: 'title',
			className: 'text-center d-flex align-items-center',
			maxWidth: 100,
		},
		{
			Header: 'Card',
			accessor: 'card',
			className: 'text-center d-flex align-items-center',
			maxWidth: 80,
			Cell: row => (
				<div>
					{row.original.card ? (
						<img
							src={require('../../../images/icons/check.png')}
							alt="icon_check"
							style={{
								width: 28,
								height: 28,
							}}
						/>
					) : null}
				</div>
			),
		},
		{
			Header: 'Card Status',
			accessor: 'cardStatus',
			className: 'text-center d-flex align-items-center capitalize',
		},
		{
			Header: 'Card Last 4',
			accessor: 'last4',
			className: 'text-center d-flex align-items-center',
		},
		{
			Header: 'Type',
			accessor: 'cardType',
			className: 'text-center d-flex align-items-center',
		},
		{
			Header: '',
			accessor: 'cardId',
			className: 'text-center d-flex align-items-center card-action',
			minWidth: 180,
			Cell: row =>
				row.original.card ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							userSelect: 'none',
						}}>
						{row.original.isPinSet && row.original.cardStatus === 'active' && (
							<RequirePermissions permissions="card.write">
								<div
									className="card-action"
									onClick={() => {
										onRowSlected(row.original.userId, row.original.cardType);
										setAction('suspendCard');
										toggle();
									}}
									style={{
										textDecorationLine: 'underline',
										cursor: 'pointer',
										padding: 5,
									}}>
									Suspend
								</div>
							</RequirePermissions>
						)}
						{row.original.isPinSet && row.original.cardStatus === 'suspended' && (
							<RequirePermissions permissions="card.write">
								<div
									className="card-action"
									onClick={() => {
										onRowSlected(row.original.userId, row.original.cardType);
										setAction('activateCard');
										toggle();
									}}
									style={{
										textDecorationLine: 'underline',
										cursor: 'pointer',
										padding: 5,
									}}>
									Activate
								</div>
							</RequirePermissions>
						)}

						{row.original.cardStatus !== 'terminated' && (
							<RequirePermissions permissions="card.write">
								<div
									className="card-action"
									onClick={() => {
										onRowSlected(row.original.userId, row.original.cardType);
										setAction('revokeCard');
										toggle();
									}}
									style={{
										textDecorationLine: 'underline',
										cursor: 'pointer',
										padding: 5,
									}}>
									Revoke
								</div>
							</RequirePermissions>
						)}
						<RequirePermissions permissions="card.write">
							<div
								className="card-action"
								onClick={() => {
									onRowSlected(row.original.userId, row.original.cardType);
									setAction('reorderCard');
									toggle();
								}}
								style={{
									textDecorationLine: 'underline',
									cursor: 'pointer',
									padding: 5,
								}}>
								Reorder
							</div>
						</RequirePermissions>
					</div>
				) : row.original.shouldIssueDebitCard && canOrderCard ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							userSelect: 'none',
						}}>
						<RequirePermissions permissions="card.write">
							<div
								className="card-action"
								onClick={() => {
									onRowSlected(row.original.userId, row.original.cardType);
									setAction('orderCard');
									toggle();
								}}
								style={{
									textDecorationLine: 'underline',
									cursor: 'pointer',
									padding: 5,
								}}>
								Order card
							</div>
						</RequirePermissions>
					</div>
				) : null,
		},
	];

	const submitAction = async (password, comment) => {
		switch (action) {
			case 'revokeCard':
				revokeCard(password, comment);
				break;
			case 'reorderCard':
				reorderCard(password, comment);
				break;
			case 'orderCard':
				orderCard(password, comment);
				break;
			case 'activateCard':
				activateCard(password, comment);
				break;
			case 'suspendCard':
				suspendCard(password, comment);
				break;
			default:
				break;
		}
	};

	return (
		<>
			<PasswordAndCommentModal
				prependContent={
					<Col md="12">
						{action === 'reorderCard' && (
							<Col md="12">
								<FormRadio
									inline
									name="virtual"
									checked={cardType === 'virtual'}
									onChange={() => {
										setCardType('virtual');
									}}>
									virtual
								</FormRadio>
								<FormRadio
									inline
									name="physical"
									checked={cardType === 'physical'}
									onChange={() => {
										setCardType('physical');
									}}>
									physical
								</FormRadio>
							</Col>
						)}
					</Col>
				}
				actionLabel={action}
				onConfirm={submitAction}
				toggle={open}
			/>

			<Row className="py-4 d-flex justify-content-between">
				<Col
					xs="12"
					sm="12"
					md="12"
					lg="12"
					xl="12"
					// className="col d-flex align-items-center justify-content-between"
				>
					<Card small className="mb-3">
						<CardHeader className="border-bottom">
							<h6 className="m-0">Owners</h6>
						</CardHeader>
						<CardBody className="p-0">
							<ReactTable
								getTdProps={() => ({
									style: { borderLeft: `none`, borderRight: `none` },
								})}
								className="-highlight"
								columns={tableColumns}
								data={owners}
								resizable={false}
								usePagination={true}
								getTrProps={(state, rowInfo) => {
									if (rowInfo && rowInfo.row) {
										return {
											onClick: e => {
												if (e.target.className === 'card-action') {
													return;
												}
												history.push(
													`/profile/banking-admin/details/${rowInfo.original.userId}`,
												);
											},
											style: {
												cursor: 'pointer',
											},
										};
									} else {
										return {};
									}
								}}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</>
	);
};

const styles = {
	cardContainer: {
		// minWidth: 558,
		height: 200,
		background: '#F1F1F1',
		display: 'flex',
		flexDirection: 'column',
		marginTop: 20,
		flex: 1,
	},
	cardTitle: {
		height: 76,
		borderBottom: '1px solid #CCCCCC',
		fontWeight: '500',
		fontSize: 18,
		color: '#000',
		paddingLeft: 26,
		paddingRight: 26,
		alignItems: 'center',
		display: 'flex',
	},
	cardBody: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		paddingLeft: 26,
		paddingRight: 26,
	},
	itemWrapper: {
		marginRight: 50,
		marginTop: 10,
	},
	itemLabel: {
		fontSize: 12,
		fontWeight: 'normal',
		color: '#000',
		textTransform: 'capitalize',
		whiteSpace: 'nowrap',
	},
	itemValue: {
		marginTop: 6,
		fontSize: 18,
		fontWeight: '500',
		color: '#000',
		textTransform: 'capitalize',
	},
};
export default OwnersDisplay;
