import React from 'react';
import { Redirect } from 'react-router-dom';

// Layout Types
import { DefaultLayout, HeaderNavigation, IconSidebar, BlankLayout } from './layouts';

// Route Views
import Analytics from './views/examples/Analytics';
import OnlineStore from './views/examples/OnlineStore';
import BlogOverview from './views/examples/BlogOverview';
import UserProfile from './views/examples/UserProfile';
import UserProfileLite from './views/examples/UserProfileLite';
import EditUserProfile from './views/examples/EditUserProfile';
import ArtistSignup from './views/main/AdminSignup';
import NpoSignup from './views/main/NpoSignup';
import Login from './views/main/Login';
import MFA from './views/main/MFA';
import ModifyMobile from './views/main/ModifyMobile';
import Logout from './views/main/Logout';
import Register from './views/examples/Register';
import ForgotPassword from './views/main/ForgotPassword';
import ResetPassword from './views/main/ResetPassword';
import FileManagerList from './views/examples/FileManagerList';
import FileManagerCards from './views/examples/FileManagerCards';
import TransactionHistory from './views/examples/TransactionHistory';
import Calendar from './views/examples/Calendar';
import AddNewPost from './views/examples/AddNewPost';
import Errors from './views/examples/Errors';
import ComponentsOverview from './views/examples/ComponentsOverview';
import Tables from './views/examples/Tables';
import BlogPosts from './views/examples/BlogPosts';
import DashboardLoader from './views/main/DashboardLoader';
import AppDashboardLoader from './views/channel/AppDashboardLoader';
import NpoLoader from './views/npo/NpoLoader';
import NpoActivationChecklist from './views/npo/ActivationChecklist';
import NpoConnectStripeStart from './views/npo/ConnectStripeStart';
import NpoConnectStripeFinish from './views/npo/ConnectStripeFinish';
import NpoProfile from './views/npo/Profile';
import NpoSocialAccounts from './views/npo/SocialAccounts';
import AppActivationChecklist from './views/channel/ActivationChecklist';
import UserConnectStripeStart from './views/user/ConnectStripeStart';
import UserConnectStripeFinish from './views/user/ConnectStripeFinish';
import Splits from './views/channel/Splits';
import FrontMe from './views/channel/FrontMe';
import AlbumDetails from './views/channel/AlbumDetails';
import AlbumPromo from './views/channel/AlbumPromo';
import Albums from './views/channel/Albums';
import VideoDetails from './views/channel/VideoDetails';
import Videos from './views/channel/Videos';
import ProductDetails from './views/channel/ProductDetails';
import OrderDetails from './views/channel/OrderDetails';
import Orders from './views/channel/Orders';
import Products from './views/channel/Products';
import PostDetails from './views/channel/PostDetails';
import Posts from './views/channel/Posts';
import PromoCodes from './views/channel/PromoCodes';
import SocialAccounts from './views/channel/SocialAccounts';
import Theme from './views/channel/Theme';
import UserNotifications from './views/user/Notifications';
import UserHome from './views/user/Home';
import ProfileLoader from './views/user/ProfileLoader';
import IconSidebarView from './views/examples/IconSidebar';
import UsersList from './views/bankingAdmin/UsersList';
import BusinessesList from './views/bankingAdmin/BusinessesList';
import AccountsList from './views/bankingAdmin/AccountsList';
import Reports from './views/bankingAdmin/Reports';
import AffiliatesList from './views/bankingAdmin/AffiliatesList';
import BankingAdminUserDetails from './views/bankingAdmin/BankingAdminUserDetails';
import BankingAdminBusinessDetails from './views/bankingAdmin/BankingAdminBusinessDetails';
import AffiliateDetails from './views/bankingAdmin/AffiliateDetails';

const BlankIconSidebarLayout = ({ children }) => (
	<IconSidebar noNavbar noFooter>
		{children}
	</IconSidebar>
);

export default [
	{
		path: '/',
		exact: true,
		public: true,
		redirectToDashboardIfLoggedIn: true,
		layout: BlankLayout,
		component: () => <Redirect to="/login" />,
	},
	{
		path: '/admin-signup',
		public: true,
		layout: BlankLayout,
		component: ArtistSignup,
	},
	{
		path: '/nonprofit-signup',
		public: true,
		layout: BlankLayout,
		component: NpoSignup,
	},
	{
		path: '/login',
		public: true,
		redirectToDashboardIfLoggedIn: true,
		layout: BlankLayout,
		component: Login,
	},
	{
		path: '/verify-login',
		public: true,
		redirectToDashboardIfLoggedIn: true,
		layout: BlankLayout,
		component: MFA,
	},
	{
		path: '/modify-mobile',
		public: true,
		redirectToDashboardIfLoggedIn: true,
		layout: BlankLayout,
		component: ModifyMobile,
	},
	{
		path: '/forgot-password',
		public: true,
		layout: BlankLayout,
		component: ForgotPassword,
	},
	{
		path: '/reset-password',
		public: true,
		layout: BlankLayout,
		component: ResetPassword,
	},
	{
		path: '/logout',
		public: true,
		layout: BlankLayout,
		component: Logout,
	},
	{
		path: '/profile',
		exact: true,
		layout: HeaderNavigation,
		component: ProfileLoader,
	},
	{
		path: '/profile/accounts',
		exact: true,
		layout: HeaderNavigation,
		component: AccountsList,
	},
	{
		path: '/profile/reports',
		exact: true,
		layout: HeaderNavigation,
		component: Reports,
	},
	{
		path: '/profile/affiliates',
		exact: true,
		layout: HeaderNavigation,
		component: AffiliatesList,
	},
	{
		path: '/profile/affiliates/details/:affiliateId',
		exact: true,
		layout: HeaderNavigation,
		component: AffiliateDetails,
	},
	{
		path: '/profile/business-banking-admin',
		exact: true,
		layout: HeaderNavigation,
		component: BusinessesList,
	},
	{
		path: '/profile/business-banking-admin/details/:businessProfileId',
		exact: true,
		layout: HeaderNavigation,
		component: BankingAdminBusinessDetails,
	},
	{
		path: '/profile/banking-admin',
		exact: true,
		layout: HeaderNavigation,
		component: UsersList,
	},
	{
		path: '/profile/banking-admin/details/:userId',
		exact: true,
		layout: HeaderNavigation,
		component: BankingAdminUserDetails,
	},
	{
		path: '/channel/:appCode',
		exact: true,
		layout: HeaderNavigation,
		component: DashboardLoader, //HeaderNav
	},
	{
		path: '/channel/:appCode/overview',
		exact: true,
		layout: HeaderNavigation,
		component: AppDashboardLoader,
	},
	{
		path: '/channel/:appCode/activation-checklist',
		exact: true,
		layout: HeaderNavigation,
		component: AppActivationChecklist,
	},
	{
		path: '/channel/:appCode/new-album',
		exact: true,
		layout: HeaderNavigation,
		component: AlbumDetails,
	},
	{
		path: '/channel/:appCode/albums',
		exact: true,
		layout: HeaderNavigation,
		component: Albums,
	},
	{
		path: '/channel/:appCode/albums/:albumId',
		exact: true,
		layout: HeaderNavigation,
		component: AlbumDetails,
	},
	{
		path: '/channel/:appCode/albums/:albumId/promo',
		exact: true,
		layout: HeaderNavigation,
		component: AlbumPromo,
	},
	{
		path: '/channel/:appCode/new-video',
		exact: true,
		layout: HeaderNavigation,
		component: VideoDetails,
	},
	{
		path: '/channel/:appCode/videos',
		exact: true,
		layout: HeaderNavigation,
		component: Videos,
	},
	{
		path: '/channel/:appCode/videos/:videoId',
		exact: true,
		layout: HeaderNavigation,
		component: VideoDetails,
	},
	{
		path: '/channel/:appCode/store',
		exact: true,
		layout: BlankLayout,
		component: () => <Redirect to="./store/orders" />,
	},
	{
		path: '/channel/:appCode/store/new-product',
		exact: true,
		layout: HeaderNavigation,
		component: ProductDetails,
	},
	{
		path: '/channel/:appCode/store/products',
		exact: true,
		layout: HeaderNavigation,
		component: Products,
	},
	{
		path: '/channel/:appCode/store/products/:productId',
		exact: true,
		layout: HeaderNavigation,
		component: ProductDetails,
	},
	{
		path: '/channel/:appCode/store/orders',
		exact: true,
		layout: HeaderNavigation,
		component: Orders,
	},
	{
		path: '/channel/:appCode/store/orders/:orderId',
		exact: true,
		layout: HeaderNavigation,
		component: OrderDetails,
	},
	{
		path: '/channel/:appCode/new-post',
		exact: true,
		layout: HeaderNavigation,
		component: PostDetails,
	},
	{
		path: '/channel/:appCode/posts',
		exact: true,
		layout: HeaderNavigation,
		component: Posts,
	},
	{
		path: '/channel/:appCode/promolinks',
		exact: true,
		layout: HeaderNavigation,
		component: PromoCodes,
	},
	{
		path: '/channel/:appCode/posts/:postId',
		exact: true,
		layout: HeaderNavigation,
		component: PostDetails,
	},
	{
		path: '/channel/:appCode/splits',
		exact: true,
		layout: HeaderNavigation,
		component: Splits,
	},
	{
		path: '/channel/:appCode/front-me',
		exact: true,
		layout: HeaderNavigation,
		component: FrontMe,
	},
	{
		path: '/channel/:appCode/verify-social-accounts',
		exact: true,
		layout: HeaderNavigation,
		component: SocialAccounts,
	},
	{
		path: '/channel/:appCode/about',
		exact: true,
		layout: HeaderNavigation,
		component: Theme,
	},
	{
		path: '/npo/:npo',
		exact: true,
		layout: HeaderNavigation,
		component: NpoLoader,
	},
	{
		path: '/npo/:npo/activation-checklist',
		exact: true,
		layout: HeaderNavigation,
		component: NpoActivationChecklist,
	},
	{
		path: '/npo/:npo/bank',
		exact: true,
		layout: HeaderNavigation,
		component: NpoConnectStripeStart,
	},
	{
		path: '/npo/bank/complete',
		exact: true,
		layout: HeaderNavigation,
		component: NpoConnectStripeFinish,
	},
	{
		path: '/npo/:npo/profile',
		exact: true,
		layout: HeaderNavigation,
		component: NpoProfile,
	},
	{
		path: '/npo/:npo/verify-social-accounts',
		exact: true,
		layout: HeaderNavigation,
		component: NpoSocialAccounts,
	},
	{
		path: '/analytics',
		layout: DefaultLayout,
		component: Analytics,
	},
	{
		path: '/ecommerce',
		layout: DefaultLayout,
		component: OnlineStore,
	},
	{
		path: '/blog-overview',
		layout: DefaultLayout,
		component: BlogOverview,
	},
	{
		path: '/user-profile',
		layout: DefaultLayout,
		component: UserProfile,
	},
	{
		path: '/user-profile-lite',
		layout: DefaultLayout,
		component: UserProfileLite,
	},
	{
		path: '/edit-user-profile',
		layout: DefaultLayout,
		component: EditUserProfile,
	},
	{
		path: '/register',
		layout: BlankIconSidebarLayout,
		component: Register,
	},
	{
		path: '/file-manager-list',
		layout: DefaultLayout,
		component: FileManagerList,
	},
	{
		path: '/file-manager-cards',
		layout: DefaultLayout,
		component: FileManagerCards,
	},
	{
		path: '/transaction-history',
		layout: DefaultLayout,
		component: TransactionHistory,
	},
	{
		path: '/calendar',
		layout: DefaultLayout,
		component: Calendar,
	},
	{
		path: '/add-new-post',
		layout: DefaultLayout,
		component: AddNewPost,
	},
	{
		path: '/errors',
		layout: BlankIconSidebarLayout,
		component: Errors,
	},
	{
		path: '/components-overview',
		layout: DefaultLayout,
		component: ComponentsOverview,
	},
	{
		path: '/tables',
		layout: DefaultLayout,
		component: Tables,
	},
	{
		path: '/blog-posts',
		layout: DefaultLayout,
		component: BlogPosts,
	},
	{
		path: '/icon-sidebar-nav',
		layout: IconSidebar,
		component: IconSidebarView,
	},
];
