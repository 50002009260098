import { Container } from 'unstated';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

export default class ErrorHandler extends Container {
	static _instance = null;

	state = {
		errors: [],
	};

	static getInstance = () => {
		if (ErrorHandler._instance) {
			return ErrorHandler._instance;
		}
		return new ErrorHandler();
	};

	constructor(props) {
		super(props);
		ErrorHandler._instance = this;
	}

	addError = error => {
		const id = uuidv4();
		this.setState({
			errors: [
				...this.state.errors,
				{
					id,
					...error,
				},
			],
		});
		if (_.get(error, 'options.dismissAfter')) {
			setTimeout(() => {
				this.removeError(id);
			}, error.options.dismissAfter);
		}
	};

	removeError = id => {
		this.setState({ errors: this.state.errors.filter(error => error.id !== id) });
	};

	clear = () => {
		this.setState({
			errors: [],
		});
	};
}
