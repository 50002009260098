import React, { useState } from 'react';
import { Row, Col, Card, CardBody, CardHeader, Button, Modal, ModalBody, ModalHeader, FormInput } from 'shards-react';
import PasswordAndCommentModal, { useToggleModal } from '../../../components/common/PasswordAndCommentModal';
import moment from 'moment';
import ReactTable from 'react-table';
import API from '../../../data/API';
import RequirePermissions from '../../../components/RequirePermissions';

const tableColumns = [
	{
		id: 'createdAt',
		Header: 'Created',
		accessor: row => moment(row.createdAt).format('MM/DD/YYYY'),
		className: 'text-center d-flex align-items-center',
		maxWidth: 120,
	},

	{
		Header: 'Description',
		accessor: 'description',
		className: 'text-center d-flex align-items-center',
		// maxWidth: 120,
	},
	{
		Header: 'Status',
		accessor: 'status',
		className: 'text-center d-flex align-items-center capitalize',
		// maxWidth: 120,
	},
	{
		Header: 'Checking',
		accessor: 'checking',
		className: 'text-center d-flex align-items-center',
		maxWidth: 100,
		Cell: row => (
			<div>
				{row.original.checking ? (
					<img
						src={require('../../../images/icons/check.png')}
						alt="icon_check"
						style={{
							width: 28,
							height: 28,
						}}
					/>
				) : null}
			</div>
		),
	},
	{
		Header: 'Savings',
		accessor: 'savings',
		className: 'text-center d-flex align-items-center',
		maxWidth: 100,
		Cell: row => (
			<div>
				{row.original.savings ? (
					<img
						src={require('../../../images/icons/check.png')}
						alt="icon_check"
						style={{
							width: 28,
							height: 28,
						}}
					/>
				) : null}
			</div>
		),
	},
	{
		Header: 'Type',
		accessor: 'type',
		className: 'text-center d-flex align-items-center',
		maxWidth: 120,
	},
];

const UserDisplay = ({
	businesses,
	history,
	isAccountClosed,
	isFlaggedForFraud,
	isLocked,
	onRefresh,
	userId,
	isReadyToDelete,
	reasons,
}) => {
	const [action, setAction] = useState('');
	const [open, toggle] = useToggleModal();
	const [showReasons, setShowReasons] = useState(false);

	const reasonsColumns = [
		{
			Header: 'Need to handle these before user can be deleted',
			accessor: 'message',
			className: 'text-center d-flex align-items-center',
		},
		{
			Header: '',
			accessor: 'link',
			className: 'text-center d-flex align-items-center',
			maxWidth: 100,
			maxWidth: 100,
			Cell: row => (
				<div>
					{row.original.link ? (
						<span
							onClick={() => {
								history.push(row.original.link);
							}}
							style={{
								cursor: 'pointer',
								textDecorationLine: 'underline',
							}}>
							{row.original.linkText || 'View'}
						</span>
					) : null}
				</div>
			),
		},
	];

	const flagUser = async (password, comment) => {
		await API.post(`/banking-admin/fraudCenter/flag-user/`, {
			userId,
			password,
			comment,
		});
		onRefresh();
	};

	const unflagUser = async (password, comment) => {
		await API.post(`/banking-admin/fraudCenter/unflag-user/`, {
			userId,
			password,
			comment,
		});

		onRefresh();
	};

	const terminateAccounts = async (password, comment) => {
		await API.post(`/banking-admin/fraudCenter/terminate-accounts/`, {
			userId,
			password,
			comment,
		});
		onRefresh();
	};

	const lockUser = async (password, comment) => {
		await API.post(`/banking-admin/user/lock-user/`, {
			userId,
			password,
			comment,
		});
		onRefresh();
	};

	const unlockUser = async (password, comment) => {
		await API.post(`/banking-admin/user/unlock-user/`, {
			userId,
			password,
			comment,
		});
		onRefresh();
	};

	const deleteUser = async (password, comment) => {
		await API.post(`/banking-admin/user/remove/`, {
			userId,
			password,
			comment,
		});
		// onRefresh();
		history.replace('/profile/banking-admin');
	};

	const submitAction = async (password, comment) => {
		switch (action) {
			case 'flagUser':
				await flagUser(password, comment);
				break;
			case 'unflagUser':
				await unflagUser(password, comment);
				break;
			case 'terminateAccounts':
				await terminateAccounts(password, comment);
				break;
			case 'lockUser':
				await lockUser(password, comment);
				break;
			case 'unlockUser':
				await unlockUser(password, comment);
				break;
			case 'deleteUser':
				await deleteUser(password, comment);
				break;
			default:
				break;
		}
	};

	return (
		<>
			<PasswordAndCommentModal actionLabel={action} onConfirm={submitAction} toggle={open} />

			<Row className="py-4 d-flex justify-content-between">
				<Col
					xs="12"
					sm="12"
					md="12"
					lg="12"
					xl="12"
					// className="col d-flex align-items-center justify-content-between"
				>
					<Card small className="mb-3">
						<CardHeader className="border-bottom col d-flex align-items-center justify-content-between">
							<h6 className="m-0">Login</h6>

							{isAccountClosed ? (
								<div>Account is closed</div>
							) : (
								<div>
									{isReadyToDelete ? (
										<RequirePermissions permissions={['user.write']}>
											<Button
												style={{
													marginRight: 10,
												}}
												theme="danger"
												onClick={() => {
													setAction('deleteUser');
													toggle();
												}}
												disabled={!isReadyToDelete}>
												Delete User
											</Button>
										</RequirePermissions>
									) : (
										<RequirePermissions permissions={['user.write']}>
											<Button
												style={{
													marginRight: 10,
												}}
												theme="warning"
												onClick={() => {
													setShowReasons(true);
												}}
												disabled={isReadyToDelete || showReasons}>
												Delete User
											</Button>
										</RequirePermissions>
									)}
									{isLocked ? (
										<RequirePermissions permissions={['fraud.write']}>
											<Button
												style={{
													marginRight: 10,
												}}
												theme="success"
												onClick={() => {
													setAction('unlockUser');
													toggle();
												}}>
												Unlock User
											</Button>
										</RequirePermissions>
									) : (
										<RequirePermissions permissions={['fraud.write']}>
											<Button
												style={{
													marginRight: 10,
												}}
												theme="light"
												onClick={() => {
													setAction('lockUser');
													toggle();
												}}>
												Lock User
											</Button>
										</RequirePermissions>
									)}
									{isFlaggedForFraud ? (
										<RequirePermissions permissions={['fraud.write']}>
											<Button
												theme="success"
												onClick={() => {
													setAction('unflagUser');
													toggle();
												}}>
												Unflag User
											</Button>
										</RequirePermissions>
									) : (
										<RequirePermissions permissions={['fraud.write']}>
											<Button
												theme="light"
												onClick={() => {
													setAction('flagUser');
													toggle();
												}}>
												Flag User
											</Button>
										</RequirePermissions>
									)}
								</div>
							)}
						</CardHeader>
						{!isAccountClosed && !isReadyToDelete && showReasons && (
							<CardBody>
								<ReactTable
									getTdProps={() => ({
										style: { borderLeft: `none`, borderRight: `none` },
									})}
									className="-highlight"
									columns={reasonsColumns}
									data={reasons}
									resizable={false}
									usePagination={true}
									defaultPageSize={reasons.length}
								/>
							</CardBody>
						)}
					</Card>
				</Col>
			</Row>

			<RequirePermissions showRequiredPermissions permissions={['user.read', 'business.read']}>
				<Row className="py-4 d-flex justify-content-between">
					<Col
						xs="12"
						sm="12"
						md="12"
						lg="12"
						xl="12"
						// className="col d-flex align-items-center justify-content-between"
					>
						<Card small className="mb-3">
							<CardHeader className="border-bottom">
								<h6 className="m-0">Businesses</h6>
							</CardHeader>
							<CardBody className="p-0">
								<ReactTable
									getTdProps={() => ({
										style: { borderLeft: `none`, borderRight: `none` },
									})}
									className="-highlight"
									columns={tableColumns}
									data={businesses}
									resizable={false}
									usePagination={true}
									defaultPageSize={businesses.length}
									getTrProps={(state, rowInfo) => {
										if (rowInfo && rowInfo.row) {
											return {
												onClick: e => {
													history.push(
														`/profile/business-banking-admin/details/${rowInfo.original.id}`,
													);
												},
												style: {
													cursor: 'pointer',
												},
											};
										} else {
											return {};
										}
									}}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</RequirePermissions>
		</>
	);
};

const styles = {
	cardContainer: {
		// minWidth: 558,
		height: 200,
		background: '#F1F1F1',
		display: 'flex',
		flexDirection: 'column',
		marginTop: 20,
		flex: 1,
	},
	cardTitle: {
		height: 76,
		borderBottom: '1px solid #CCCCCC',
		fontWeight: '500',
		fontSize: 18,
		color: '#000',
		paddingLeft: 26,
		paddingRight: 26,
		alignItems: 'center',
		display: 'flex',
	},
	cardBody: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		paddingLeft: 26,
		paddingRight: 26,
	},
	itemWrapper: {
		marginRight: 50,
		marginTop: 10,
	},
	itemLabel: {
		fontSize: 12,
		fontWeight: 'normal',
		color: '#000',
		textTransform: 'capitalize',
		whiteSpace: 'nowrap',
	},
	itemValue: {
		marginTop: 6,
		fontSize: 18,
		fontWeight: '500',
		color: '#000',
		textTransform: 'capitalize',
	},
};
export default UserDisplay;
