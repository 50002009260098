import React from 'react';
import { Row, Col, Card, CardBody, Button } from 'shards-react';

export default ({ message, ctaButtonLabel, onCtaButtonClick }) => (
	<Card small className='mb-3' style={{ backgroundColor: '#FFECA5', marginTop: 40 }}>
		<CardBody>
			<Row>
				<Col md='12'>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'row',
						}}>
						<Button theme='accent' size='sm' onClick={onCtaButtonClick} style={{ marginRight: 16 }}>
							{ctaButtonLabel}
						</Button>
						<p style={{ fontSize: 15, margin: 0, marginRight: 20, color: '#333' }}>{message}</p>
					</div>
				</Col>
			</Row>
		</CardBody>
	</Card>
);
