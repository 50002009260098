import React, { Component } from 'react';
import { Container, Row, Col, Nav, Collapse } from 'shards-react';
import AppsContainer from '../../data/AppsContainer';
import Session from '../../data/Session';
import ScreenLoader from '../../components/ScreenLoader';
import API from '../../data/API';

export default class extends Component {
	constructor(props) {
		super(props);
		void API.post('/auth/logout');
		AppsContainer.getInstance().clear();
		Session.getInstance().clear(() => {
			this.props.history.push(`/`);
		});
		return null;
	}

	render = () => (
		<Collapse className="header-navbar d-lg-flex p-0 bg-white border-top">
			<Container>
				<Row>
					<Col>
						<Nav tabs className="border-0 flex-column flex-lg-row">
							<ScreenLoader />
						</Nav>
					</Col>
				</Row>
			</Container>
		</Collapse>
	);
}
