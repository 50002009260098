import React, { useState, useEffect } from 'react';
import { Row, Col, FormTextarea, Button, Modal, ModalBody, ModalHeader, FormInput } from 'shards-react';

const PasswordAndCommentModal = ({
	prependContent,
	confirmButtonLabel = 'Confirm',
	cancelButtonLabel = 'Cancel',
	actionLabel = '',
	action,
	onConfirm,
	showComment = true,
	requireComment = false,
	toggle = 0,
	showMessage = false,
}) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [password, setPassword] = useState('');
	const [comment, setComment] = useState('');
	const [message, setMessage] = useState('');
	const [error, setError] = useState('');

	const resetData = () => {
		setPassword('');
		setComment('');
		setMessage('');
		setError('');
	};

	const modalToggle = () => {
		_toggle(!modalOpen);
	};

	const _toggle = value => {
		resetData();
		setModalOpen(value);
	};

	useEffect(() => {
		if (toggle > 0) {
			_toggle(true);
		}
	}, [toggle]);

	const onConfirmClick = async () => {
		try {
			action && (await action());
			onConfirm && (await onConfirm(password, showComment ? comment : null, showMessage ? message : null));
			modalToggle();
		} catch (error) {
			setError(error?.response?.data?.message || error?.message || 'An error occurred');
		}
	};

	return (
		<Modal open={modalOpen} toggle={modalToggle}>
			<ModalHeader>Confirm {actionLabel}</ModalHeader>
			<ModalBody>
				<Row className="m-2">
					{prependContent}
					<Col md="12">
						<span className="text-semibold text-fiord-blue">
							Enter your password to confirm action: {actionLabel && <b>{actionLabel}</b>}
						</span>
						<FormInput
							type={'password'}
							size="md"
							className="mb-3"
							value={password}
							onChange={event => setPassword(event.target.value)}
							required
						/>
					</Col>
				</Row>

				{showMessage && (
					<Row className="m-2">
						<Col md="12">
							<FormInput
								placeholder="Message"
								type={'text'}
								size="md"
								className="mb-3"
								value={message}
								onChange={event => setMessage(event.target.value)}
								required
							/>
						</Col>
					</Row>
				)}

				{showComment && (
					<Row className="m-2">
						<Col md="12">
							<FormTextarea
								size="lg"
								rows="8"
								value={comment}
								placeholder={`Comment${requireComment ? '' : ' (optional)'}`}
								onChange={event => setComment(event.target.value)}
								required={showComment && requireComment}
							/>
						</Col>
					</Row>
				)}
				<Row className="m-2">
					<Col md="12" className="text-danger">
						{error}
					</Col>
				</Row>

				<Row className="m-2 d-flex justify-content-end">
					<Button
						style={{ margin: 5 }}
						onClick={onConfirmClick}
						disabled={
							!password || (showComment && requireComment && !comment) || (showMessage && !message)
						}>
						{confirmButtonLabel}
					</Button>

					<Button style={{ margin: 5 }} onClick={modalToggle} theme="light">
						{cancelButtonLabel}
					</Button>
				</Row>
			</ModalBody>
		</Modal>
	);
};

export const useToggleModal = () => {
	const [count, setCount] = useState(0);
	const toggle = () => setCount(count + 1);

	return [count, toggle];
};

export default PasswordAndCommentModal;
