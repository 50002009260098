import React, { Component, useState } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	Button,
	Badge,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	FormInput,
	FormTextarea,
	FormCheckbox,
} from 'shards-react';
import AppsContainer from '../../data/AppsContainer';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import waitForContainer from '../../utils/waitForContainer';
import { NavLink, Link } from 'react-router-dom';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import API from '../../data/API';
import Tip from '../../components/Tip';
import moment from 'moment';
import CopyButton from '../../components/CopyButton';
import _ from 'lodash';
import colors from '../../utils/colors';

const VideoItem = ({ item }) => {
	let [hover, setHover] = useState(false);

	return (
		<Col lg="4" md="4" sm="4" style={{ margin: 0, padding: 10 }}>
			<Link to={`videos/${item._id}`}>
				<Card small className="video-card">
					<div
						className="video-art"
						style={{
							backgroundImage: `url('${item.thumbnailUrl}')`,
							backgroundSize: item.aspectRatio >= 1.33 ? 'cover' : 'contain',
						}}
						onMouseEnter={() => setHover(true)}
						onMouseLeave={() => setHover(false)}>
						<div
							className="video-gif"
							style={{
								backgroundImage: `url('${item.gifUrl}')`,
								backgroundSize: item.aspectRatio >= 1.33 ? 'cover' : 'contain',
								display: hover ? 'block' : 'none',
							}}
						/>
						{!item.thumbnailUrl && (
							<Badge pill theme="dark">
								<span role="img" aria-label="Clock">
									🕑
								</span>
								&nbsp;Processing...
							</Badge>
						)}
						{item.thumbnailUrl && !item.isLive && (
							<Badge pill theme="dark">
								{item.publishDate ? moment(item.publishDate).format('M/D/YY @ h:mm a') : 'Draft'}
							</Badge>
						)}
						<h3>{item.title}</h3>
					</div>
				</Card>
			</Link>
		</Col>
	);
};

const SortableVideoItem = SortableElement(VideoItem);

const VideoGrid = ({ videos, sortable }) => (
	<Card small className="mb-3 content-grid" style={{ overflow: 'auto' }}>
		{videos.length === 0 ? (
			<div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', height: 300 }}>
				No Videos
			</div>
		) : (
			videos.map((item, index) =>
				sortable ? (
					<SortableVideoItem item={item} index={index} key={index} />
				) : (
					<VideoItem item={item} index={index} key={index} />
				),
			)
		)}
	</Card>
);

const SortableVideoGrid = SortableContainer(({ videos }) => <VideoGrid videos={videos} sortable={true} />);

export default class Videos extends Component {
	state = {
		loading: true,
		live: [],
		drafts: [],
		youtubeUrl: '',
		showingYoutubeModal: false,
		importLoading: false,
		showingStreamModal: false,
		streamTitle: '',
		streamDescription: '',
		streamAvailableForFree: false,
		streamInfo: null,
		streamLoading: false,
		hasLiveStreaming: false,
		hasFreeloaders: false,
	};

	componentDidMount = async () => {
		let videos = await waitForContainer(AppsContainer, 'videos');
		let hasLiveStreaming = _.get(
			AppsContainer.getInstance().state.currentApp,
			'optionalFeatures.liveStreaming.enabled',
		);
		let hasFreeloaders = _.get(
			AppsContainer.getInstance().state.currentApp,
			'optionalFeatures.freeloaders.enabled',
		);
		let live = videos.filter(item => item.isLive);
		let drafts = videos
			.filter(item => !item.isLive)
			.sort((a, b) => {
				if (!b.publishDate && a.publishDate) return 1;
				if (!a.publishDate && b.publishDate) return -1;
				if (!a.publishDate && !b.publishDate) return 0;
				return new Date(b.publishDate) - new Date(a.publishDate);
			});
		this.setState({ live, drafts, loading: false, hasLiveStreaming, hasFreeloaders });
	};

	onFieldChange = (event, field) => {
		let newState = {};
		newState[field] = event.target.value;
		this.setState(newState, this.updateValidation);
	};

	toggleAvailableForFree = () => {
		this.setState({ streamAvailableForFree: !this.state.streamAvailableForFree });
	};

	createStream = async () => {
		if (this.state.streamTitle.trim() === '') {
			alert(
				`Please enter a title for your stream. This will be used in the notification that's sent out to your subscribers.`,
			);
			return;
		}

		try {
			this.setState({ streamLoading: true });
			let appId = AppsContainer.getInstance().state.currentApp._id;
			let streamInfo = await API.post(`/admin/stream/${appId}`, {
				title: this.state.streamTitle,
				description: this.state.streamDescription,
				availableForFree: this.state.streamAvailableForFree,
			});
			this.setState({ streamInfo, streamLoading: false });
		} catch (e) {
			this.setState({ streamLoading: false });
			alert(`Oops! ${e.message}`);
		}
	};

	resetStream = () => {
		this.setState({
			showingStreamModal: false,
			streamInfo: null,
			streamDescription: '',
			streamTitle: '',
			streamLoading: false,
			streamAvailableForFree: false,
		});
	};

	importFromYoutube = async () => {
		try {
			this.setState({ importLoading: true });
			let appId = AppsContainer.getInstance().state.currentApp._id;
			let video = await API.post(`/admin/video/${appId}/youtube`, {
				youtubeUrl: this.state.youtubeUrl,
			});
			await AppsContainer.getInstance().updateCurrentApp();
			this.props.history.push(`videos/${video._id}`);
		} catch (e) {
			this.setState({ importLoading: false });
			alert(`Oops! ${e.message}`);
		}
	};

	onSortEnd = ({ oldIndex, newIndex }) => {
		this.setState(({ live, drafts }) => {
			live = arrayMove(live, oldIndex, newIndex);
			let appId = AppsContainer.getInstance().state.currentApp._id;
			let videoIds = drafts.map(item => item._id).concat(live.map(item => item._id));
			API.put(`/admin/video/${appId}/sort`, { videoIds });
			return { live };
		});
	};

	render = () => {
		return (
			<Container className="page-container videos">
				{this.state.loading ? (
					<ScreenLoader />
				) : (
					<>
						{this.state.drafts.length > 0 && (
							<div>
								<Row noGutters className="page-header py-4 d-flex">
									<PageTitle title="Drafts" subtitle="Video" className="text-sm-left mb-3" />
								</Row>
								<Row>
									<Col lg="9" md="12">
										<VideoGrid videos={this.state.drafts} />
									</Col>
									{this.state.drafts.length > 0 && (
										<Col lg="3" md="12">
											<Card small>
												<CardBody>
													<Row>
														<Col sm="12">
															<Button
																theme="dark"
																size="sm"
																style={{ width: '100%' }}
																tag={NavLink}
																to="new-video">
																+ Add Video
															</Button>
															{this.state.hasLiveStreaming && (
																<Button
																	theme="accent"
																	size="sm"
																	style={{ width: '100%', marginTop: 10 }}
																	tag={NavLink}
																	to="#"
																	onClick={() =>
																		this.setState({ showingStreamModal: true })
																	}>
																	→ Go Live
																</Button>
															)}
														</Col>
													</Row>
												</CardBody>
											</Card>
										</Col>
									)}
								</Row>
							</div>
						)}
						{(this.state.drafts.length === 0 || this.state.live.length > 0) && (
							<div>
								<Row noGutters className="page-header py-4 d-flex">
									<Col sm="6" md="6" lg="4">
										<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
											<PageTitle
												title="Video"
												subtitle="Media"
												className="text-sm-left mb-3 p-0"
											/>
										</div>
									</Col>
									<Col
										sm="6"
										md="6"
										lg="5"
										className="d-none d-sm-flex"
										style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
										<Tip text="Drag and drop to reorder videos" />
									</Col>
									<Col
										sm="6"
										md="6"
										lg="5"
										className="d-flex d-sm-none"
										style={{ alignItems: 'flex-end', justifyContent: 'center', display: 'none' }}>
										<Tip text="Drag and drop to reorder videos" />
									</Col>
								</Row>
								<Row>
									<Col lg="9" md="12">
										<SortableVideoGrid
											videos={this.state.live}
											axis="xy"
											pressDelay={200}
											helperClass="sortableHelper"
											useDragHandle={false}
											onSortEnd={this.onSortEnd}
										/>
									</Col>
									{this.state.drafts.length === 0 && (
										<Col lg="3" md="12">
											<Card small>
												<CardBody>
													<Row>
														<Col sm="12">
															<Button
																theme="dark"
																size="sm"
																style={{ width: '100%' }}
																tag={NavLink}
																to="new-video">
																+ Add Video
															</Button>
															{this.state.hasLiveStreaming && (
																<Button
																	theme="accent"
																	size="sm"
																	style={{ width: '100%', marginTop: 10 }}
																	tag={NavLink}
																	to="#"
																	onClick={() =>
																		this.setState({ showingStreamModal: true })
																	}>
																	→ Go Live
																</Button>
															)}
														</Col>
													</Row>
												</CardBody>
											</Card>
										</Col>
									)}
								</Row>
							</div>
						)}
					</>
				)}
				<Modal centered size="lg" open={this.state.showingYoutubeModal}>
					<ModalHeader toggle={() => this.setState({ showingYoutubeModal: false })}>
						<span>Import from YouTube</span>
					</ModalHeader>
					<ModalBody>
						<FormInput
							size="lg"
							className="mb-3"
							style={{ fontSize: 16 }}
							placeholder="YouTube URL"
							value={this.state.youtubeUrl}
							onChange={event => this.onFieldChange(event, 'youtubeUrl')}
						/>
					</ModalBody>
					<ModalFooter>
						<Button
							theme="accent"
							size="sm"
							onClick={this.importFromYoutube}
							disabled={this.state.importLoading}>
							{this.state.importLoading ? 'one sec...' : 'Import'}
						</Button>
					</ModalFooter>
				</Modal>
				<Modal centered size="lg" open={this.state.showingStreamModal} toggle={() => {}}>
					<ModalHeader
						toggle={() => {
							if (this.state.streamInfo) {
								this.resetStream();
							} else {
								this.setState({ showingStreamModal: false });
							}
						}}>
						{!this.state.streamInfo && <span>New Live Stream</span>}
						{this.state.streamInfo && <span>New Stream Key</span>}
					</ModalHeader>
					<ModalBody className="p-4">
						{!this.state.streamInfo && (
							<>
								<p className="mb-4">
									<span style={{ fontSize: 16, fontWeight: '600' }}>
										📱 Streaming from your phone?
									</span>
									<br />
									You can stream directly from the Manifest app — just login to the app with this
									admin account, go to your channel's video tab, and tap "Go Live".
								</p>
								<p className="mb-3">
									<span style={{ fontSize: 16, fontWeight: '600' }}>
										💻 Streaming from your computer?
									</span>
									<br />
									Enter information for your stream below. We'll save it and give you the connection
									info to enter into your streaming program (like OBS).
								</p>
								<FormInput
									autoFocus
									size="lg"
									className="mb-3"
									placeholder="Title (required)"
									value={this.state.title}
									onChange={event => this.onFieldChange(event, 'streamTitle')}
								/>
								<FormTextarea
									placeholder="Description"
									size="lg"
									className="mb-2"
									rows="3"
									value={this.state.description}
									onChange={event => this.onFieldChange(event, 'streamDescription')}
								/>
								{this.state.hasFreeloaders && (
									<FormCheckbox
										className="mt-3"
										small
										toggle
										checked={this.state.streamAvailableForFree}
										onChange={this.toggleAvailableForFree}>
										<span style={{ fontSize: 12 }}>
											<Tip
												style={{ marginRight: 4 }}
												color={colors.dark.value}
												prompt="Available For Free"
												place="right"
												helpText="'Available For Free' streams are<br />available to all users in the web."
											/>
										</span>
									</FormCheckbox>
								)}
							</>
						)}
						{this.state.streamInfo && (
							<>
								<p className="mb-3">
									<strong style={{ width: 100, display: 'inline-block' }}>Server:</strong>
									<span
										style={{
											fontFamily: 'monospace',
											borderWidth: 1,
											padding: '4px 8px',
											borderRadius: 3,
											borderColor: '#EAEAEA',
											borderStyle: 'solid',
											backgroundColor: '#F7F7F7',
										}}>
										{this.state.streamInfo.server}
									</span>
									<CopyButton
										style={{
											marginLeft: 8,
											marginRight: 8,
											height: 26,
											paddingTop: 0,
											paddingBottom: 0,
										}}
										size="sm"
										theme="white"
										pill
										copyText={this.state.streamInfo.server}>
										copy
									</CopyButton>
								</p>
								<p className="mb-4">
									<strong style={{ width: 100, display: 'inline-block' }}>Stream Key:</strong>
									<span
										style={{
											fontFamily: 'monospace',
											borderWidth: 1,
											padding: '4px 8px',
											borderRadius: 3,
											borderColor: '#B8DBC5',
											borderStyle: 'solid',
											backgroundColor: '#E6F4EB',
										}}>
										{this.state.streamInfo.streamKey}
									</span>
									<CopyButton
										style={{
											marginLeft: 8,
											marginRight: 8,
											height: 26,
											paddingTop: 0,
											paddingBottom: 0,
										}}
										size="sm"
										theme="white"
										pill
										copyText={this.state.streamInfo.streamKey}>
										copy
									</CopyButton>
									<span style={{ fontSize: 12, fontStyle: 'italic', lineHeight: '26px' }}>
										Expires in 24 hours.
									</span>
								</p>
								<p className="mb-2">
									Here's your new stream key - please note this key is unique for every live stream.
								</p>
								<ul className="mb-0">
									<li>Make sure to update the key in your software before each performance.</li>
									<li>
										When you start broadcasting using this stream key, we'll notify your subscribers
										that your channel is live, streaming <em>"{this.state.streamTitle}"</em>.
									</li>
									<li>
										When you stop broadcasting, this live stream will be saved in the video tab of
										your channel, and this stream key will be deleted.
									</li>
								</ul>
							</>
						)}
					</ModalBody>
					<ModalFooter>
						{!this.state.streamInfo && (
							<Button
								theme="accent"
								size="sm"
								onClick={this.createStream}
								disabled={this.state.streamLoading}>
								{this.state.streamLoading ? 'one sec...' : 'Get Stream Key'}
							</Button>
						)}
						{this.state.streamInfo && (
							<Button theme="accent" size="sm" onClick={this.resetStream}>
								Close
							</Button>
						)}
					</ModalFooter>
				</Modal>
			</Container>
		);
	};
}
