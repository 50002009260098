import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

export default props => {
	const [id, setId] = useState(`tip${Math.random()}`);

	return (
		<span style={props.style}>
			{props.text && <strong>Tip:&nbsp;</strong>}
			{props.text}
			<span
				className='tip-word'
				data-for={id}
				data-tip={props.helpText}
				style={{ color: props.color, borderBottomColor: props.color }}>
				{props.prompt}
			</span>
			<ReactTooltip
				id={id}
				place={props.place || 'left'}
				type='dark'
				effect='solid'
				multiline
				className='tip-contents'
			/>
		</span>
	);
};
