/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Form, FormGroup, FormInput, Button } from 'shards-react';
import API from '../../data/API';
import Session from '../../data/Session';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

class Login extends Component {
	constructor(props) {
		super(props);
		let { redirect, ...otherQueryParams } = queryString.parse(this.props.location.search);
		this.state = {
			email: '',
			password: '',
			submitEnabled: false,
			loading: false,
			redirect,
			otherQueryParams,
		};
	}

	isValid = () => {
		let { email, password } = this.state;
		return /\S+@\S+\.\S+/.test(email) && password.length >= 8;
	};

	submit = async () => {
		let { email, password, submitEnabled, loading, otherQueryParams = {}, redirect } = this.state;

		if (!submitEnabled || loading) return;
		this.setState({ loading: true });

		try {
			const { elevatedToken, mobileLast4, mobileNumberVerified } = await API.post(`/auth/login`, {
				email,
				password,
			});
			Session._instance.setToken(elevatedToken, false, () => {
				if (redirect) {
					const stringified = queryString.stringify({
						redirect,
						...otherQueryParams,
						mobileLast4,
						mobileNumberVerified,
					});
					this.props.history.push('/verify-login?' + stringified);
				} else {
					const stringified = queryString.stringify({ mobileLast4, mobileNumberVerified });
					this.props.history.push('/verify-login?' + stringified);
				}
			});
		} catch (e) {
			alert(`Oops! ${e.message}`);
			this.setState({ loading: false });
		}
	};

	onFieldChange = (event, field) => {
		let newState = {};
		newState[field] = event.target.value;
		this.setState(newState, () => {
			this.setState({ submitEnabled: this.isValid() });
		});
	};

	onKeyUp = event => {
		if (event.key === 'Enter') {
			this.submit();
		}
	};

	render = () => {
		return (
			<Container fluid className="main-content-container h-100 px-4">
				<Row noGutters className="h-100">
					<Col lg="3" md="5" className="auth-form mx-auto my-auto">
						<Card>
							<CardHeader>
								<img
									className="auth-form__logo d-table mx-auto mb-3"
									src={require('../../images/manifest/logo.png')}
									alt="Manifest"
								/>
								<h5 className="auth-form__title text-center mb-4">Admin Login</h5>
							</CardHeader>
							<CardBody>
								<Form>
									<FormGroup>
										<label htmlFor="emailInput">Email address</label>
										<FormInput
											autoFocus
											tabIndex="1"
											disabled={this.state.sentMfa}
											type="email"
											id="emailInput"
											autoComplete="email"
											value={this.state.email}
											onChange={event => this.onFieldChange(event, 'email')}
											onKeyUp={this.onKeyUp}
										/>
									</FormGroup>
									<FormGroup>
										<label htmlFor="passwordInput">Password</label>
										<Link
											tabIndex="4"
											to="/forgot-password"
											style={{ float: 'right', fontWeight: 300, fontSize: 12, lineHeight: 2 }}>
											Forgot?
										</Link>
										<FormInput
											tabIndex="2"
											disabled={this.state.sentMfa}
											type="password"
											id="passwordInput"
											autoComplete="new-password"
											value={this.state.password}
											onChange={event => this.onFieldChange(event, 'password')}
											onKeyUp={this.onKeyUp}
										/>
									</FormGroup>

									<Button
										tabIndex="3"
										pill
										theme="accent"
										className="d-table mx-auto"
										onClick={this.submit}
										disabled={!this.state.submitEnabled || this.state.loading}>
										Login
									</Button>
								</Form>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		);
	};
}

export default Login;
