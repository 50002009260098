import React, { useState, useEffect } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	ButtonGroup,
	Button,
	InputGroupAddon,
	InputGroup,
	FormInput,
} from 'shards-react';
import { Subscribe } from 'unstated';
import Session from '../../data/Session';
import ScreenLoader from '../../components/ScreenLoader';
import RequirePermissions from '../../components/RequirePermissions';

import PageTitle from '../../components/common/PageTitle';
import API from '../../data/API';
import ReactTable from 'react-table';
import moment from 'moment';

const BusinessesList = ({ history }) => {
	const [searchString, setSearchString] = useState('');
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pages, setPages] = useState(-1);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(20);
	const [status, setStatus] = useState('all');

	useEffect(() => {
		(async () => {
			await search();
		})();
	}, [status]);

	const tableColumns = [
		{
			id: 'createdAt',
			Header: 'Created',
			accessor: row => moment(row.createdAt).format('MM/DD/YYYY'),
			className: 'text-center d-flex align-items-center',
			maxWidth: 120,
			sortable: false,
		},
		{
			Header: 'Description',
			accessor: 'description',
			className: 'text-center d-flex align-items-center',
			// maxWidth: 120,
			sortable: false,
		},
		{
			Header: 'Business Info',
			accessor: 'businessInfoVerified',
			className: 'text-center d-flex align-items-center',
			// maxWidth: 120,
			sortable: false,
		},
		{
			Header: 'Status',
			accessor: 'status',
			className: 'text-center d-flex align-items-center capitalize',
			// maxWidth: 120,
			sortable: false,
		},
		{
			Header: 'Checking',
			accessor: 'checking',
			className: 'text-center d-flex align-items-center',
			maxWidth: 100,
			sortable: false,

			Cell: row => (
				<div>
					{row.original.checking ? (
						<img
							src={require('../../images/icons/check.png')}
							alt="icon_check"
							style={{
								width: 28,
								height: 28,
							}}
						/>
					) : null}
				</div>
			),
		},
		{
			Header: 'Savings',
			accessor: 'savings',
			className: 'text-center d-flex align-items-center',
			maxWidth: 100,
			sortable: false,

			Cell: row => (
				<div>
					{row.original.savings ? (
						<img
							src={require('../../images/icons/check.png')}
							alt="icon_check"
							style={{
								width: 28,
								height: 28,
							}}
						/>
					) : null}
				</div>
			),
		},
		{
			Header: 'Type',
			accessor: 'type',
			className: 'text-center d-flex align-items-center',
			maxWidth: 120,
			sortable: false,
		},
	];

	const search = async (tablePage, tablePageSize) => {
		if (loading) {
			return;
		}
		if (!tablePage) {
			setPage(0);
		}
		setLoading(true);
		const response = await API.get(
			`/banking-admin/business/search/${searchString}?page=${tablePage || 0}&pageSize=${
				tablePageSize || pageSize
			}&status=${status}`,
		);
		if (response.data) {
			setData(response.data.data);
			setPages(response.data.totalPages);
		} else {
			setData([]);
			setPages(-1);
		}
		setLoading(false);
	};

	const onKeyUp = event => {
		if (event.key === 'Enter') {
			search();
		}
	};

	return (
		<Container className="page-container">
			<RequirePermissions permissions={['business.read']} showRequiredPermissions>
				<Subscribe to={[Session]}>
					{({ state: { user } }) => {
						if (!user) {
							return <ScreenLoader />;
						} else {
							return (
								<div>
									<Row noGutters className="page-header py-4 d-flex justify-content-between">
										<PageTitle
											title="Businesses"
											subtitle="Banking"
											className="text-sm-left mb-3 p-0"
										/>
									</Row>
									<Row>
										<Col lg="12" md="12">
											<Card small className="mb-3">
												<CardHeader className="border-bottom d-flex flex-row justify-content-end">
													<div
														style={{
															// minWidth: 260 + 200,
															maxWidth: 380 + 200,
															alignSelf: 'flex-end',
														}}>
														<InputGroup seamless={true}>
															<ButtonGroup
																size="sm"
																className="d-inline-flex mb-3 mx-auto">
																<Button
																	onClick={() => setStatus('all')}
																	active={status === 'all'}
																	theme="white">
																	All
																</Button>
																<Button
																	onClick={() => setStatus('active')}
																	active={status === 'active'}
																	theme="white">
																	Active
																</Button>
																<Button
																	onClick={() => setStatus('pending')}
																	active={status === 'pending'}
																	theme="white">
																	Pending
																</Button>
															</ButtonGroup>
														</InputGroup>
														<InputGroup seamless={true}>
															<FormInput
																placeholder="Search"
																type="search"
																value={searchString}
																onChange={event => {
																	if (loading) {
																		return;
																	}
																	setSearchString(event.target.value);
																}}
																onKeyUp={onKeyUp}
																style={{ marginLeft: 10 }}
															/>
															<InputGroupAddon type="append">
																<Button
																	onClick={async () => {
																		await search();
																	}}
																	theme="secondary">
																	Search
																</Button>
															</InputGroupAddon>
														</InputGroup>
													</div>
												</CardHeader>

												<CardBody className="p-0">
													<ReactTable
														className="-highlight"
														NoDataComponent={() =>
															data.length <= 0 && !loading ? (
																<div className="rt-noData">No result found</div>
															) : null
														}
														loadingText="Searching"
														loading={loading}
														columns={tableColumns}
														data={data}
														resizable={false}
														usePagination={true}
														manual
														pages={pages}
														page={page}
														onFetchData={async (state, instance) => {
															setPage(state.page);
															setPageSize(state.pageSize);
															setLoading(true);
															await search(state.page, state.pageSize);
															setLoading(false);
															console.log(state, instance);
														}}
														getTdProps={() => ({
															style: { borderLeft: `none`, borderRight: `none` },
														})}
														getTrProps={(state, rowInfo) => {
															if (rowInfo && rowInfo.row) {
																return {
																	onClick: e => {
																		history.push(
																			`/profile/business-banking-admin/details/${rowInfo.original.id}`,
																		);
																	},
																	style: {
																		cursor: 'pointer',
																	},
																};
															} else {
																return {};
															}
														}}
													/>
												</CardBody>
											</Card>
										</Col>
									</Row>
								</div>
							);
						}
					}}
				</Subscribe>
			</RequirePermissions>
		</Container>
	);
};

export default BusinessesList;
