import React from 'react';
import { Subscribe } from 'unstated';
import { validatePermissions } from '../utils/bankingAdminPermissions';
import Session from '../data/Session';

export const checkPermissionsForUser = permissions => {
	const { user } = Session.getInstance().state;
	return user && user.bankingAdminPermissions && validatePermissions(user.bankingAdminPermissions, permissions);
};
const RequirePermissions = ({ permissions, children, showRequiredPermissions }) => {
	if (!permissions) return children;
	if (typeof permissions === 'string') permissions = [permissions];
	return (
		<Subscribe to={[Session]}>
			{({ state: { user } }) => {
				if (user && validatePermissions(user.bankingAdminPermissions, permissions)) {
					return children;
				}

				return showRequiredPermissions ? (
					<>require the following permissions: {permissions.join(', ')}</>
				) : null;
			}}
		</Subscribe>
	);
};
export default RequirePermissions;
