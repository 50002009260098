import React, { useState, useEffect } from 'react';
import {
	FormInput,
	FormTextarea,
	Row,
	Col,
	Card,
	CardBody,
	CardHeader,
	Button,
	Form,
	FormSelect,
	FormCheckbox,
} from 'shards-react';
import API from '../../../data/API';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import Tip from '../../../components/Tip';
import colors from '../../../utils/colors';
import { COUNTRY_OPTIONS } from '../../../utils/constants';
import RequirePermissions from '../../../components/RequirePermissions';

const formatPersonalInfo = (field, value, option = { reverse: false, citizenshipIndex: 1 }) => {
	const { reverse, citizenshipIndex } = option;
	let _value = value;

	switch (field) {
		case 'citizenship':
			return _value.toUpperCase();
		case 'tin':
			if (reverse) {
				return _value.replace(/\D+/, '');
			}

			if (citizenshipIndex === 1) {
				_value = _value.replace(/[\D]+/gi, '');
				if (_value.length >= 4 && _value.length < 6) {
					_value = _value.replace(/(\d{3})(\d+)/, '$1-$2');
				} else if (_value.length < 10) {
					_value = _value.replace(/(\d{3})(\d{2})(\d+)/, '$1-$2-$3');
				} else {
					_value = _value.replace(/(\d{3})(\d{2})(\d{4})[\d\D]+/, '$1-$2-$3');
				}
			} else if (citizenshipIndex === 0) {
				_value = _value.replace(/[\D]+/gi, '');
				if (_value.length >= 4 && _value.length < 7) {
					_value = _value.replace(/(\d{3})(\d+)/, '$1-$2');
				} else if (_value.length < 10) {
					_value = _value.replace(/(\d{3})(\d{3})(\d+)/, '$1-$2-$3');
				} else {
					_value = _value.replace(/(\d{3})(\d{3})(\d{3})[\d\D]+/, '$1-$2-$3');
				}
			}
			return _value;
		case 'postalCode':
			if (citizenshipIndex === 1) {
				_value = _value.replace(/[\D]+/gi, '');
				if (_value.length > 5) {
					_value = _value.replace(/(\d{5})(\d+)/, '$1');
				}
			}
			return _value;

		default:
			return _value;
	}
};

const PersonalInfoDisplay = ({
	verified = false,
	userId = '',
	personalInfoBeforeVerified = {
		citizenship: '',
		dateOfBirth: '',
		firstName: '',
		middleName: '',
		lastName: '',
		streetLine1: '',
		streetLine2: '',
		city: '',
		state: '',
		postalCode: '',
		tin: '',
		emailAddress: '',
		phoneNumber: '',
		mailingStreetLine1: '',
		mailingStreetLine2: '',
		mailingCity: '',
		mailingState: '',
		mailingPostalCode: '',
	},
	created = false,
}) => {
	const [personalInfo, setPersonalInfo] = useState({
		citizenship: personalInfoBeforeVerified.citizenship,
		dateOfBirth: personalInfoBeforeVerified.dateOfBirth,
		firstName: personalInfoBeforeVerified.firstName,
		middleName: personalInfoBeforeVerified.middleName,
		lastName: personalInfoBeforeVerified.lastName,
		streetLine1: personalInfoBeforeVerified.streetLine1,
		streetLine2: personalInfoBeforeVerified.streetLine2,
		city: personalInfoBeforeVerified.city,
		state: personalInfoBeforeVerified.state,
		postalCode: personalInfoBeforeVerified.postalCode,
		tin: personalInfoBeforeVerified.tin,
		emailAddress: personalInfoBeforeVerified.emailAddress,
		phoneNumber: personalInfoBeforeVerified.phoneNumber,
		mailingStreetLine1: personalInfoBeforeVerified.mailingStreetLine1,
		mailingStreetLine2: personalInfoBeforeVerified.mailingStreetLine2,
		mailingCity: personalInfoBeforeVerified.mailingCity,
		mailingState: personalInfoBeforeVerified.mailingState,
		mailingPostalCode: personalInfoBeforeVerified.mailingPostalCode,
	});
	const [comment, setComment] = useState('');
	const [viewAndEdit, setViewAndEdit] = useState(!created);
	const [personalInfoVerified, setPersonalInfoVerified] = useState(verified);
	const [fieldFirstBlur, setFieldFirstBlur] = useState({
		citizenship: false,
		dateOfBirth: false,
		firstName: false,
		middleName: false,
		lastName: false,
		streetLine1: false,
		streetLine2: false,
		city: false,
		state: false,
		postalCode: false,
		tin: false,
		emailAddress: false,
		phoneNumber: false,
		mailingStreetLine1: false,
		mailingStreetLine2: false,
		mailingCity: false,
		mailingState: false,
		mailingPostalCode: false,
	});
	const [isValid, setIsValid] = useState(false);
	const [citizenshipIndex, setCitizenshipIndex] = useState(personalInfoBeforeVerified.citizenship === 'CA' ? 0 : 1);
	const [stateLabel, setStateLabel] = useState('State');
	const [cityLabel, setCityLabel] = useState('City');
	const [postalCodeLabel, setPostalCodeLabel] = useState('ZIP Code');
	const [taxIdLabel, setTaxIdLabel] = useState('Social Security Number');
	const [saveButtonClicked, setSaveButtonClicked] = useState(false);
	const [useSameAddressForShipping, setUseSameAddressForShipping] = useState(
		// personalInfoBeforeVerified.useSameAddressForShipping,
		true,
	);
	const [lastSavedMailingAddress, setLastSavedMailingAddress] = useState({
		mailingStreetLine1: '',
		mailingStreetLine2: '',
		mailingCity: '',
		mailingState: '',
		mailingCountry: '',
		mailingPostalCode: '',
	});
	useEffect(() => {
		setCitizenshipIndex(personalInfo.citizenship.toUpperCase() === 'CA' ? 0 : 1);
		setIsValid(validateField('all-fields'));
	}, [personalInfo, useSameAddressForShipping]);

	useEffect(() => {
		if (useSameAddressForShipping) {
			setLastSavedMailingAddress({
				...lastSavedMailingAddress,
				mailingStreetLine1: personalInfo.mailingStreetLine1,
				mailingStreetLine2: personalInfo.mailingStreetLine2,
				mailingCity: personalInfo.mailingCity,
				mailingState: personalInfo.mailingState,
				mailingCountry: personalInfo.country,
				mailingPostalCode: personalInfo.mailingPostalCode,
			});

			onPersonalInfoChange('mailingStreetLine1', '');
			onPersonalInfoChange('mailingStreetLine2', '');
			onPersonalInfoChange('mailingCity', '');
			onPersonalInfoChange('mailingState', '');
			onPersonalInfoChange('mailingCountry', '');
			onPersonalInfoChange('mailingPostalCode', '');
		} else {
			onPersonalInfoChange('mailingStreetLine1', lastSavedMailingAddress.mailingStreetLine1);
			onPersonalInfoChange('mailingStreetLine2', lastSavedMailingAddress.mailingStreetLine2);
			onPersonalInfoChange('mailingCity', lastSavedMailingAddress.mailingCity);
			onPersonalInfoChange('mailingState', lastSavedMailingAddress.mailingState);
			onPersonalInfoChange('mailingCountry', lastSavedMailingAddress.mailingCountry);
			onPersonalInfoChange('mailingPostalCode', lastSavedMailingAddress.mailingPostalCode);
		}
	}, [useSameAddressForShipping]);

	useEffect(() => {
		if (citizenshipIndex === 0) {
			//CA
			setStateLabel('Province');
			setCityLabel('Municipality');
			setTaxIdLabel('Social Insurance Number');
			// setTaxIdMask('999-999-999');
			setPostalCodeLabel('Postal Code');
		} else if (citizenshipIndex === 1) {
			//US
			setStateLabel('State');
			setCityLabel('City');
			setTaxIdLabel('Social Security Number');
			// setTaxIdMask('999-99-9999');
			setPostalCodeLabel('ZIP Code');
		}
		setPersonalInfo({
			...personalInfo,
			tin: formatPersonalInfo('tin', personalInfo.tin, { citizenshipIndex }),
			postalCode: formatPersonalInfo('postalCode', personalInfo.postalCode, { citizenshipIndex }),
			mailingPostalCode: formatPersonalInfo('postalCode', personalInfo.mailingPostalCode, { citizenshipIndex }),
		});
	}, [citizenshipIndex]);

	useEffect(() => {
		if (created) {
			return;
		}
		(async () => {
			const response = await API.get(`/banking-admin/user/email-and-phone/${userId}`);

			if (response.personalInfo) {
				const {
					citizenship,
					dateOfBirth,
					firstName,
					middleName,
					lastName,
					streetLine1,
					streetLine2,
					city,
					state,
					country,
					postalCode,
					tin,
					emailAddress,
					phoneNumber,
					mailingStreetLine1,
					mailingStreetLine2,
					mailingCity,
					mailingState,
					mailingPostalCode,
					mailingCountry,
					useSameAddressForShipping,
				} = response.personalInfo;

				setUseSameAddressForShipping(useSameAddressForShipping ? useSameAddressForShipping : true);
				setPersonalInfo({
					...personalInfo,
					citizenship: citizenship ? citizenship : '',
					dateOfBirth: dateOfBirth ? dateOfBirth : '',
					firstName: firstName ? firstName : '',
					middleName: middleName ? middleName : '',
					lastName: lastName ? lastName : '',
					streetLine1: streetLine1 ? streetLine1 : '',
					streetLine2: streetLine2 ? streetLine2 : '',
					city: city ? city : '',
					state: state ? state : '',
					country: country ? country : '',
					postalCode: postalCode ? postalCode : '',
					tin: tin ? tin : '',
					emailAddress: emailAddress ? emailAddress : '',
					phoneNumber: phoneNumber ? phoneNumber : '',
					mailingStreetLine1: mailingStreetLine1 ? mailingStreetLine1 : '',
					mailingStreetLine2: mailingStreetLine2 ? mailingStreetLine2 : '',
					mailingCity: mailingCity ? mailingCity : '',
					mailingState: mailingState ? mailingState : '',
					mailingCountry: mailingCountry ? mailingCountry : '',
					mailingPostalCode: mailingPostalCode ? mailingPostalCode : '',
				});
				setLastSavedMailingAddress({
					...lastSavedMailingAddress,
					mailingStreetLine1,
					mailingStreetLine2,
					mailingCity,
					mailingState,
					mailingPostalCode,
				});
			}
		})();
	}, []);

	const validateField = field => {
		const isDate18orMoreYearsOld = (year, month, day) => {
			return new Date(parseInt(year) + 18, parseInt(month) - 1, parseInt(day)) <= new Date();
		};

		const fields = Object.keys(personalInfo);

		switch (field) {
			case 'citizenship':
				return personalInfo[field] !== null && personalInfo[field] !== undefined;
			case 'dateOfBirth':
				return personalInfo[field] && isDate18orMoreYearsOld(...personalInfo[field].split('-'));
			case 'firstName':
				return personalInfo[field];
			case 'middleName':
				return personalInfo[field];
			case 'lastName':
				return personalInfo[field];
			case 'streetLine1':
				return personalInfo[field];
			case 'streetLine2':
				return true;
			case 'city':
				return personalInfo[field];
			case 'state':
				return personalInfo[field];
			case 'country':
				return personalInfo[field];
			case 'postalCode':
				let postalCodeRegex = /\d{5}/;
				if (citizenshipIndex === 0) {
					postalCodeRegex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
				}
				return personalInfo[field] && postalCodeRegex.test(personalInfo[field]);
			case 'mailingStreetLine1':
				return useSameAddressForShipping ? true : personalInfo[field];
			case 'mailingStreetLine2':
				return true;
			case 'mailingCity':
				return useSameAddressForShipping ? true : personalInfo[field];
			case 'mailingState':
				return useSameAddressForShipping ? true : personalInfo[field];
			case 'mailingPostalCode':
				if (useSameAddressForShipping) {
					return true;
				}
				let mailingPostalCodeRegex = /\d{5}/;
				if (citizenshipIndex === 0) {
					mailingPostalCodeRegex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
				}
				return personalInfo[field] && mailingPostalCodeRegex.test(personalInfo[field]);
			case 'tin':
				let tinRegex = /(\d{3})-(\d{2})-(\d{4})/;
				if (citizenshipIndex === 0) {
					tinRegex = /(\d{3})-(\d{3})-(\d{3})/;
				}

				return (
					personalInfo[field] &&
					tinRegex.test(personalInfo[field]) &&
					!personalInfo[field]
						.replace(/\D+/gi, '')
						.split('')
						.every(digit => digit === personalInfo[field][0])
				);
			case 'emailAddress':
				return created || (personalInfo[field] && /\S+@\S+\.\S+/.test(personalInfo[field]));
			case 'phoneNumber':
				return created || isPossiblePhoneNumber(personalInfo[field]);
			case 'all-fields':
				return fields.every(field => validateField(field));
			default:
				return true;
		}
	};

	const onViewInfo = async () => {
		const response = await API.get(`/banking-admin/user/personal-info/${userId}`);
		if (response.personalInfo) {
			setPersonalInfo({
				...response.personalInfo,
				tin: formatPersonalInfo('tin', response.personalInfo.tin, {
					citizenshipIndex: response.personalInfo.citizenship.toUpperCase() === 'CA' ? 0 : 1,
				}),
			});
		}
		setViewAndEdit(true);
	};

	const onSave = async () => {
		setFieldFirstBlur({
			citizenship: true,
			dateOfBirth: true,
			firstName: true,
			middleName: true,
			lastName: true,
			streetLine1: true,
			streetLine2: true,
			city: true,
			state: true,
			postalCode: true,
			tin: true,
			emailAddress: true,
			phoneNumber: true,
		});
		setSaveButtonClicked(true);
		if (!comment || !isValid) {
			return;
		}
		await API.post(`/banking-admin/user/update-personal-info/${userId}`, {
			comment,
			pii: {
				...personalInfo,
				tin: formatPersonalInfo('tin', personalInfo.tin, {
					citizenshipIndex,
					reverse: true,
				}),
			},
		});
		setPersonalInfoVerified(true);
		setViewAndEdit(false);
	};

	const onPersonalInfoChange = (field, value) => {
		const _value = formatPersonalInfo(field, value, { citizenshipIndex });
		setPersonalInfo({
			...personalInfo,
			[field]: _value,
		});
	};

	return (
		<Row>
			<Col lg={personalInfoVerified ? '12' : '9'} md="12">
				<Card small className="mb-3">
					<CardHeader className="border-bottom d-flex justify-content-between align-items-center">
						<h6 className="m-0">Personal Info</h6>
						{created ? (
							<RequirePermissions permissions={personalInfoVerified ? ['pii.read'] : ['pii.write']}>
								<Button theme="accent" onClick={onViewInfo} disabled={viewAndEdit}>
									{personalInfoVerified ? 'View Info' : 'View + Edit Info'}
								</Button>
							</RequirePermissions>
						) : null}
					</CardHeader>

					<CardBody className="p-0">
						<Form>
							<Row className="m-2">
								<Col md="6">
									<span className="text-semibold text-fiord-blue">First Name</span>
									<FormInput
										type={viewAndEdit ? 'text' : 'password'}
										size="md"
										className="mb-3"
										placeholder="First Name"
										disabled={created ? !viewAndEdit || personalInfoVerified : false}
										value={personalInfo.firstName}
										onChange={event => onPersonalInfoChange('firstName', event.target.value)}
										required
										invalid={fieldFirstBlur.firstName && !validateField('firstName')}
										onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, firstName: true })}
									/>
								</Col>
							</Row>
							<Row className="m-2">
								<Col md="6">
									<span className="text-semibold text-fiord-blue">Middle Name</span>
									<FormInput
										type={viewAndEdit ? 'text' : 'password'}
										size="md"
										className="mb-3"
										placeholder="Middle Name"
										disabled={created ? !viewAndEdit || personalInfoVerified : false}
										value={personalInfo.middleName}
										onChange={event => onPersonalInfoChange('middleName', event.target.value)}
										required
										invalid={fieldFirstBlur.middleName && !validateField('middleName')}
										onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, middleName: true })}
									/>
								</Col>
							</Row>
							<Row className="m-2">
								<Col md="6">
									<span className="text-semibold text-fiord-blue">Last Name</span>
									<FormInput
										type={viewAndEdit ? 'text' : 'password'}
										size="md"
										className="mb-3"
										placeholder="Last Name"
										disabled={created ? !viewAndEdit || personalInfoVerified : false}
										value={personalInfo.lastName}
										onChange={event => onPersonalInfoChange('lastName', event.target.value)}
										required
										invalid={fieldFirstBlur.lastName && !validateField('lastName')}
										onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, lastName: true })}
									/>
								</Col>
							</Row>
							<Row className="m-2">
								<Col md="6">
									<span className="text-semibold text-fiord-blue">
										Date of Birth
										{!viewAndEdit || personalInfoVerified ? null : ` (must be 18 or older)`}
									</span>
									<FormInput
										type={viewAndEdit ? 'date' : 'password'}
										size="md"
										className="mb-3"
										placeholder="MM/DD/YYYY"
										disabled={created ? !viewAndEdit || personalInfoVerified : false}
										value={personalInfo.dateOfBirth}
										onChange={event => onPersonalInfoChange('dateOfBirth', event.target.value)}
										required
										invalid={fieldFirstBlur.dateOfBirth && !validateField('dateOfBirth')}
										onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, dateOfBirth: true })}
									/>
								</Col>
							</Row>
							<Row className="m-2">
								<Col md="6">
									<span className="text-semibold text-fiord-blue">{taxIdLabel}</span>
									<FormInput
										type={viewAndEdit ? 'text' : 'password'}
										size="md"
										className="mb-3"
										placeholder={taxIdLabel}
										disabled={created ? !viewAndEdit || personalInfoVerified : false}
										value={personalInfo.tin}
										onChange={event => onPersonalInfoChange('tin', event.target.value)}
										required
										invalid={fieldFirstBlur.tin && !validateField('tin')}
										onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, tin: true })}
									/>
								</Col>
							</Row>
							<Row className="m-2">
								<Col md="6">
									<span className="text-semibold text-fiord-blue">
										Email{!personalInfoVerified ? ' (read-only)' : null}
									</span>
									<FormInput
										type={viewAndEdit ? 'email' : 'password'}
										size="md"
										className="mb-3"
										placeholder="Email"
										disabled={true}
										value={personalInfo.emailAddress}
										onChange={event => onPersonalInfoChange('emailAddress', event.target.value)}
										required
										invalid={fieldFirstBlur.emailAddress && !validateField('emailAddress')}
										onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, emailAddress: true })}
									/>
								</Col>
							</Row>
							<Row className="m-2">
								<Col md="6">
									<span className="text-semibold text-fiord-blue">
										Phone Number{!personalInfoVerified ? ' (read-only)' : null}
									</span>
									<FormInput
										type={viewAndEdit ? 'tel' : 'password'}
										size="md"
										className="mb-3"
										placeholder="###-###-####"
										disabled={true}
										value={personalInfo.phoneNumber}
										onChange={event => onPersonalInfoChange('phoneNumber', event.target.value)}
									/>
								</Col>
							</Row>
							<Row className="m-2">
								<Col md="6">
									<span className="text-semibold text-fiord-blue">Citizenship</span>
									{viewAndEdit ? (
										<FormSelect
											size="md"
											className="mb-3"
											placeholder="Citizenship"
											disabled={created ? !viewAndEdit || personalInfoVerified : false}
											value={personalInfo.citizenship}
											onChange={event => onPersonalInfoChange('citizenship', event.target.value)}
											onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, citizenship: true })}>
											{COUNTRY_OPTIONS.map(({ value, label }) => (
												<option key={value + label} value={value}>
													{label}
												</option>
											))}
										</FormSelect>
									) : (
										<FormInput
											type="password"
											size="md"
											className="mb-3"
											placeholder="Citizenship"
											disabled={true}
											value={personalInfo.citizenship}
											onChange={event => onPersonalInfoChange('citizenship', event.target.value)}
											required
											invalid={fieldFirstBlur.citizenship && !validateField('citizenship')}
											onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, citizenship: true })}
										/>
									)}
								</Col>
							</Row>
							<Row className="m-2">
								<Col md="6">
									<span className="text-semibold text-fiord-blue">Street Line 1</span>
									<FormInput
										type={viewAndEdit ? 'text' : 'password'}
										size="md"
										className="mb-3"
										placeholder="Street Line 1"
										disabled={created ? !viewAndEdit || personalInfoVerified : false}
										value={personalInfo.streetLine1}
										onChange={event => onPersonalInfoChange('streetLine1', event.target.value)}
										required
										invalid={fieldFirstBlur.streetLine1 && !validateField('streetLine1')}
										onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, streetLine1: true })}
									/>
								</Col>
							</Row>
							<Row className="m-2">
								<Col md="6">
									<span className="text-semibold text-fiord-blue">Street Line 2</span>
									<FormInput
										type={viewAndEdit ? 'text' : 'password'}
										size="md"
										className="mb-3"
										placeholder="Street Line 2"
										disabled={created ? !viewAndEdit || personalInfoVerified : false}
										value={personalInfo.streetLine2}
										onChange={event => onPersonalInfoChange('streetLine2', event.target.value)}
										required
									/>
								</Col>
							</Row>
							<Row className="m-2">
								<Col md="6">
									<span className="text-semibold text-fiord-blue">{cityLabel}</span>
									<FormInput
										type={viewAndEdit ? 'text' : 'password'}
										size="md"
										className="mb-3"
										placeholder={cityLabel}
										disabled={created ? !viewAndEdit || personalInfoVerified : false}
										value={personalInfo.city}
										onChange={event => onPersonalInfoChange('city', event.target.value)}
										required
										invalid={fieldFirstBlur.city && !validateField('city')}
										onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, city: true })}
									/>
								</Col>
							</Row>
							<Row className="m-2">
								<Col md="6">
									<span className="text-semibold text-fiord-blue">{stateLabel}</span>
									<FormInput
										type={viewAndEdit ? 'text' : 'password'}
										size="md"
										className="mb-3"
										placeholder={stateLabel}
										disabled={created ? !viewAndEdit || personalInfoVerified : false}
										value={personalInfo.state}
										onChange={event => onPersonalInfoChange('state', event.target.value)}
										required
										invalid={fieldFirstBlur.state && !validateField('state')}
										onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, state: true })}
									/>
								</Col>
							</Row>
							<Row className="m-2">
								<Col md="6">
									<span className="text-semibold text-fiord-blue">Country</span>
									<FormInput
										type={viewAndEdit ? 'text' : 'password'}
										size="md"
										className="mb-3"
										placeholder="Country"
										disabled={created ? !viewAndEdit || personalInfoVerified : false}
										value={personalInfo.country}
										onChange={event => onPersonalInfoChange('country', event.target.value)}
										required
										invalid={fieldFirstBlur.country && !validateField('country')}
										onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, country: true })}
									/>
								</Col>
							</Row>
							<Row className="m-2">
								<Col md="6">
									<div
										style={{
											marginBottom: '5px',
										}}>
										<Tip
											style={{
												fontSize: 12,
												fontWeight: 'normal',
												textTransform: 'capitalize',
											}}
											color={colors.black.value}
											prompt={postalCodeLabel} //320 for "HD"
											helpText={
												citizenshipIndex === 0
													? 'CA Postal Code rule'
													: 'Must be valid 5 digit zip code'
											}
											place="top"
										/>
									</div>

									{/* <span className="text-semibold text-fiord-blue">{postalCodeLabel}</span> */}
									<FormInput
										type={viewAndEdit ? 'text' : 'password'}
										size="md"
										className="mb-3"
										placeholder={postalCodeLabel}
										disabled={created ? !viewAndEdit || personalInfoVerified : false}
										value={personalInfo.postalCode}
										onChange={event => onPersonalInfoChange('postalCode', event.target.value)}
										required
										invalid={fieldFirstBlur.postalCode && !validateField('postalCode')}
										onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, postalCode: true })}
									/>
								</Col>
							</Row>
							{/* <Row className="m-2">
								<Col md="6">
									<FormCheckbox
										disabled={created ? !viewAndEdit || personalInfoVerified : false}
										checked={useSameAddressForShipping}
										onChange={() => setUseSameAddressForShipping(!useSameAddressForShipping)}>
										Use same address for shipping
									</FormCheckbox>
								</Col>
							</Row> */}

							{useSameAddressForShipping ? null : (
								<>
									<Row className="m-2">
										<Col md="6">
											<span className="text-semibold text-fiord-blue">Mailing Street Line 1</span>
											<FormInput
												type={viewAndEdit ? 'text' : 'password'}
												size="md"
												className="mb-3"
												placeholder="Mailing Street Line 1"
												disabled={created ? !viewAndEdit || personalInfoVerified : false}
												value={personalInfo.mailingStreetLine1}
												onChange={event =>
													onPersonalInfoChange('mailingStreetLine1', event.target.value)
												}
												required
												invalid={
													fieldFirstBlur.mailingStreetLine1 &&
													!validateField('mailingStreetLine1')
												}
												onBlur={() =>
													setFieldFirstBlur({ ...fieldFirstBlur, mailingStreetLine1: true })
												}
											/>
										</Col>
									</Row>
									<Row className="m-2">
										<Col md="6">
											<span className="text-semibold text-fiord-blue">Mailing Street Line 2</span>
											<FormInput
												type={viewAndEdit ? 'text' : 'password'}
												size="md"
												className="mb-3"
												placeholder="Mailing Street Line 2"
												disabled={created ? !viewAndEdit || personalInfoVerified : false}
												value={personalInfo.mailingStreetLine2}
												onChange={event =>
													onPersonalInfoChange('mailingStreetLine2', event.target.value)
												}
												required
											/>
										</Col>
									</Row>
									<Row className="m-2">
										<Col md="6">
											<span className="text-semibold text-fiord-blue">Mailing {cityLabel}</span>
											<FormInput
												type={viewAndEdit ? 'text' : 'password'}
												size="md"
												className="mb-3"
												placeholder={'Mailing ' + cityLabel}
												disabled={created ? !viewAndEdit || personalInfoVerified : false}
												value={personalInfo.mailingCity}
												onChange={event =>
													onPersonalInfoChange('mailingCity', event.target.value)
												}
												required
												invalid={fieldFirstBlur.mailingCity && !validateField('mailingCity')}
												onBlur={() =>
													setFieldFirstBlur({ ...fieldFirstBlur, mailingCity: true })
												}
											/>
										</Col>
									</Row>
									<Row className="m-2">
										<Col md="6">
											<span className="text-semibold text-fiord-blue">Mailing {stateLabel}</span>
											<FormInput
												type={viewAndEdit ? 'text' : 'password'}
												size="md"
												className="mb-3"
												placeholder={'Mailing ' + stateLabel}
												disabled={created ? !viewAndEdit || personalInfoVerified : false}
												value={personalInfo.mailingState}
												onChange={event =>
													onPersonalInfoChange('mailingState', event.target.value)
												}
												required
												invalid={fieldFirstBlur.mailingState && !validateField('mailingState')}
												onBlur={() =>
													setFieldFirstBlur({ ...fieldFirstBlur, mailingState: true })
												}
											/>
										</Col>
									</Row>
									<Row className="m-2">
										<Col md="6">
											<div
												style={{
													marginBottom: '5px',
												}}>
												<Tip
													style={{
														fontSize: 12,
														fontWeight: 'normal',
														textTransform: 'capitalize',
													}}
													color={colors.black.value}
													prompt={'Mailing ' + postalCodeLabel} //320 for "HD"
													helpText={
														citizenshipIndex === 0
															? 'CA Postal Code rule'
															: 'Must be valid 5 digit zip code'
													}
													place="top"
												/>
											</div>

											{/* <span className="text-semibold text-fiord-blue">{postalCodeLabel}</span> */}
											<FormInput
												type={viewAndEdit ? 'text' : 'password'}
												size="md"
												className="mb-3"
												placeholder={'Mailing ' + postalCodeLabel}
												disabled={created ? !viewAndEdit || personalInfoVerified : false}
												value={personalInfo.mailingPostalCode}
												onChange={event =>
													onPersonalInfoChange('mailingPostalCode', event.target.value)
												}
												required
												invalid={
													fieldFirstBlur.mailingPostalCode &&
													!validateField('mailingPostalCode')
												}
												onBlur={() =>
													setFieldFirstBlur({ ...fieldFirstBlur, mailingPostalCode: true })
												}
											/>
										</Col>
									</Row>
								</>
							)}
						</Form>
					</CardBody>
				</Card>
			</Col>

			{personalInfoVerified ? null : (
				<Col lg="3" md="12">
					<Card small>
						<CardBody>
							<Row className="mb-2">
								<Col sm="12">
									<FormTextarea
										size="lg"
										rows="8"
										value={comment}
										placeholder="Comment"
										onChange={event => setComment(event.target.value)}
										disabled={!viewAndEdit}
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="12">
									{viewAndEdit && !isValid && saveButtonClicked ? (
										<div style={{ color: 'red', paddingBottom: '10px' }}>
											Check form for invalid field(s)
										</div>
									) : null}
									<Button
										theme="accent"
										size="sm"
										style={{ width: '100%' }}
										onClick={onSave}
										disabled={!viewAndEdit || !comment}>
										Save + Manually Verify
									</Button>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			)}
		</Row>
	);
};

export default PersonalInfoDisplay;
