import React from 'react';
import ReactDOM from 'react-dom';
import { datadogRum } from '@datadog/browser-rum';
import App from './App';
import { Provider } from 'unstated';
import Session from './data/Session';
import AppsContainer from './data/AppsContainer';
import thisNeedsToBeImported from 'moment-duration-format';
import * as serviceWorker from './serviceWorker';
import ErrorHandlerView from './components/ErrorHandlerView';

let session = new Session();
let appsContainer = new AppsContainer();

if (process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
	datadogRum.init({
		applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
		clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
		// `site` refers to the Datadog site parameter of your organization
		// see https://docs.datadoghq.com/getting_started/site/
		site: process.env.REACT_APP_DATADOG_SITE,
		service: process.env.REACT_APP_DATADOG_SERVICE_NAME,
		env: process.env.REACT_APP_DATADOG_ENVIRONMENT,
		// Specify a version number to identify the deployed version of your application in Datadog
		// version: '1.0.0',
		sessionSampleRate: 100,
		sessionReplaySampleRate: 20,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: 'mask-user-input',
	});
}

ReactDOM.render(
	<Provider inject={[session, appsContainer]}>
		<div
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100%',
				zIndex: 99999,
			}}>
			<ErrorHandlerView />
		</div>
		<App />
	</Provider>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
