export default async function (containerClass, field, callback) {
	if (containerClass.getInstance().state[field]) {
		return containerClass.getInstance().state[field];
	} else {
		let promise = new Promise((resolve, reject) => {
			let interval;
			interval = setInterval(async () => {
				if (containerClass.getInstance().state[field]) {
					clearInterval(interval);
					resolve(containerClass.getInstance().state[field]);
				}
			}, 500);
		});
		return promise;
	}
}