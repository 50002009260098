import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, NavItem, NavLink } from 'shards-react';
import { Dispatcher, Constants } from '../../../../flux';

export default class UserActions extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			visible: false,
		};

		this.handleMenuToggle = this.handleMenuToggle.bind(this);
	}

	handleMenuToggle() {
		if (window.innerWidth <= 824) {
			Dispatcher.dispatch({
				actionType: Constants.TOGGLE_SIDEBAR,
			});
		} else {
			this.setState({
				visible: !this.state.visible,
			});
		}
	}

	render() {
		return (
			<NavItem tag={Dropdown} caret toggle={this.handleMenuToggle}>
				<DropdownToggle tag={NavLink} className='text-nowrap px-3' style={{ cursor: 'pointer' }}>
					<div className='nav-link-icon__wrapper'>
						<i className='material-icons'>menu</i>
					</div>
				</DropdownToggle>
				<Collapse tag={DropdownMenu} right small open={this.state.visible}>
					<DropdownItem divider />
					<DropdownItem tag={Link} to='/logout' className='text-danger'>
						<i className='material-icons text-danger'>&#xE879;</i> Logout
					</DropdownItem>
				</Collapse>
			</NavItem>
		);
	}
}
