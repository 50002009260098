import _ from 'lodash';
import AppsContainer from '../data/AppsContainer';

export default function() {
	if (!AppsContainer.getInstance()) return [];
	let { currentApp, currentNpo } = AppsContainer.getInstance().state;

	let tools;

	if (currentNpo) {
		tools = {
			title: 'Tools',
			items: [
				{
					title: 'Checklist',
					htmlBefore: '<i class="material-icons">check</i>',
					to: `/npo/${currentNpo._id}/activation-checklist`,
					display: !currentNpo.verified,
				},
				{
					title: 'Profile',
					htmlBefore: '<i class="material-icons">color_lens</i>',
					to: `/npo/${currentNpo._id}/profile`,
				},
				{
					title: 'Bank',
					htmlBefore: '<i class="material-icons">account_balance</i>',
					to: `/npo/${currentNpo._id}/bank`,
				},
			],
		};
		tools.items = tools.items.filter(item => item.display === undefined || item.display === true);
	} else if (currentApp) {
		tools = {
			title: 'Tools',
			items: [
				{
					title: 'Home',
					htmlBefore: '<i class="material-icons">home</i>',
					to: `/profile/home`,
				},
				{
					title: 'Checklist',
					htmlBefore: '<i class="material-icons">check</i>',
					to: `/channel/${currentApp.appCode}/activation-checklist`,
					display: !currentApp.verified,
				},
				{
					title: 'Dashboard',
					htmlBefore: '<i class="material-icons">bar_chart</i>',
					to: `/channel/${currentApp.appCode}/overview`,
				},
				{
					title: 'Audio',
					htmlBefore: '<i class="material-icons">album</i>',
					to: `/channel/${currentApp.appCode}/albums`,
				},
				{
					title: 'Video',
					htmlBefore: '<i class="material-icons">videocam</i>',
					to: `/channel/${currentApp.appCode}/videos`,
					display: _.get(currentApp.optionalFeatures, 'video.enabled') === true,
				},
				{
					title: 'Posts',
					htmlBefore: '<i class="material-icons">insert_photo</i>',
					to: `/channel/${currentApp.appCode}/posts`,
				},
				{
					title: 'Store',
					htmlBefore: '<i class="material-icons">shopping_bag</i>',
					to: `/channel/${currentApp.appCode}/store`,
					display: _.get(currentApp.optionalFeatures, 'store.enabled') === true,
				},
				{
					title: 'Splits',
					htmlBefore: '<i class="material-icons">money</i>',
					to: `/channel/${currentApp.appCode}/splits`,
				},
				{
					title: 'About',
					htmlBefore: '<i class="material-icons">color_lens</i>',
					to: `/channel/${currentApp.appCode}/about`,
				},
			],
		};
		tools.items = tools.items.filter(item => item.display === undefined || item.display === true);
	} else {
		tools = {
			title: 'Tools',
			items: [
				{
					title: 'Home',
					htmlBefore: '<i class="material-icons">home</i>',
					to: `/profile/home`,
				},
				{
					title: 'Notifications',
					htmlBefore: '<i class="material-icons">notifications</i>',
					to: `/profile/notifications`,
				},
				{
					title: 'Bank',
					htmlBefore: '<i class="material-icons">account_balance</i>',
					to: `/profile/bank`,
				},
			],
		};
		tools.items = tools.items.filter(item => item.display === undefined || item.display === true);
	}

	let prefs = {
		title: 'User',
		items: [
			{
				title: 'Logout',
				htmlBefore: '<i class="material-icons">&#xE879;</i>',
				to: `/logout`,
			},
		],
	};

	return [tools, prefs].filter(item => !!item);
}
