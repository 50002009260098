import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, FormSelect, Button, ButtonGroup, Badge } from 'shards-react';
import AppsContainer from '../../data/AppsContainer';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import moment from 'moment';
import ReactTable from 'react-table';
import waitForContainer from '../../utils/waitForContainer';
import { NavLink } from 'react-router-dom';
import API from '../../data/API';

export default class Orders extends Component {
	state = {
		loading: true,
		orders: [],
		filter: 'all', // 'unfulfilled', 'fulfilled', 'refunded', 'all'
		hoveredRow: null,
	};

	componentDidMount = async () => {
		let app = await waitForContainer(AppsContainer, 'currentApp');
		let { orders } = await API.get(`/admin/order/${app._id}`);
		orders.forEach(order => {
			if (order.paymentStatus === 'refunded') {
				order.status = 'refunded';
			} else {
				order.status = order.fulfillmentStatus;
			}
		});
		this.setState({ orders, loading: false });
	};

	onFilterChange = e => this.setState({ filter: e.target.value });

	render = () => {
		let orders;
		if (this.state.filter === 'all') {
			orders = this.state.orders;
		} else {
			orders = this.state.orders.filter(item => item.status === this.state.filter);
		}

		const tableColumns = [
			{
				id: 'number',
				Header: 'Order',
				accessor: row => `#${row.number}`,
				maxWidth: 120,
				className: 'text-center d-flex align-items-center',
			},
			{
				Header: 'Status',
				accessor: 'status',
				maxWidth: 120,
				className: 'text-center d-flex align-items-center',
				Cell: row => (
					<div>
						<Badge
							pill
							theme={
								{
									unfulfilled: 'primary',
									fulfilled: 'success',
									refunded: 'dark',
								}[row.original.status]
							}>
							{row.original.status}
						</Badge>
					</div>
				),
			},
			{
				Header: 'Description',
				accessor: 'description',
				className: 'justify-content-start',
			},
			{
				Header: 'Items',
				accessor: 'numberOfItems',
				maxWidth: 60,
				className: 'text-center d-flex align-items-center',
			},
			{
				id: 'amount',
				Header: 'Total',
				accessor: row => `$${(row.amount / 100).toFixed(2)}`,
				maxWidth: 120,
				className: 'text-center d-flex align-items-center',
			},
			{
				id: 'createdAt',
				Header: 'Ordered',
				accessor: row => moment(row.createdAt).fromNow(),
				maxWidth: 120,
				className: 'text-center d-flex align-items-center',
			},
		];

		return (
			<Container className="page-container posts">
				{this.state.loading ? (
					<ScreenLoader />
				) : (
					<div>
						<Row noGutters className="page-header py-4 d-flex">
							<PageTitle title="Orders" subtitle="Store" className="text-sm-left mb-3 p-0" />
							<Col xs="12" sm="4" className="col d-flex align-items-center">
								<ButtonGroup size="sm" className="d-inline-flex mb-3 mb-sm-0 mx-auto">
									<Button tag={NavLink} theme="white" active to="orders">
										Orders
									</Button>
									<Button tag={NavLink} theme="white" to="products">
										Products
									</Button>
								</ButtonGroup>
							</Col>
						</Row>
						<Row>
							<Col lg="12" md="12">
								<Card small className="mb-3">
									<CardHeader className="border-bottom">
										<div className="d-flex flex-row" style={{ alignItems: 'center' }}>
											<FormSelect
												size="lg"
												value={this.state.filter}
												style={{ maxWidth: 240, fontWeight: '400', height: 40, lineHeight: 1 }}
												onChange={this.onFilterChange}>
												<option value={'all'}>All Orders</option>
												<option value={'unfulfilled'}>Unfulfilled</option>
												<option value={'fulfilled'}>Fulfilled</option>
												<option value={'refunded'}>Refunded</option>
											</FormSelect>
											{/* <a
												href='#'
												onClick={this.downloadSubscriberCSV}
												className='view-report'
												style={{ flex: 1, textAlign: 'right' }}>
												Export CSV &rarr;
											</a> */}
										</div>
									</CardHeader>
									<CardBody className="p-0">
										<ReactTable
											columns={tableColumns}
											data={orders}
											resizable={false}
											usePagination={false}
											getTrProps={(state, rowInfo) => {
												if (rowInfo && rowInfo.row) {
													return {
														onClick: e => {
															this.props.history.push(`./orders/${rowInfo.original._id}`);
														},
														onMouseEnter: e => {
															this.setState({
																hoveredRow: rowInfo.index,
															});
														},
														onMouseLeave: e => {
															this.setState({
																hoveredRow: null,
															});
														},
														style: {
															background:
																rowInfo.index === this.state.hoveredRow
																	? '#f0f0f0'
																	: 'white',
															cursor: 'pointer',
														},
													};
												} else {
													return {};
												}
											}}
										/>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</div>
				)}
			</Container>
		);
	};
}
