import React, { Component } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	FormTextarea,
	Button,
	FormInput,
	FormCheckbox,
	DatePicker,
	FormSelect,
	Badge,
} from 'shards-react';
import AppsContainer from '../../data/AppsContainer';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import 'react-circular-progressbar/dist/styles.css';
import API from '../../data/API';
import waitForContainer from '../../utils/waitForContainer';
import moment from 'moment';
import FileDropzone from '../../components/file-manager-list/FileDropzone';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import arrayMove from 'array-move';
import NumericInput from 'react-numeric-input';
import CurrencyInput from 'react-currency-input';

import { Prompt } from 'react-router';
import ImageTools from '../../utils/ImageTools';
const imageTools = new ImageTools();

const labelForVariantType = type =>
	type
		? {
				'12inch': '12-inch',
				'7inch': '7-inch',
				'2lp': '2-LP',
				cassette: 'Cassette',
				vhs: 'VHS',
				cd: 'CD',
				dvd: 'DVD',
		  }[type]
		: null;

const PhotoItem = SortableElement(({ photoItem, albumComponent }) => (
	<div className='sortable-photo-item'>
		{photoItem.uploadProgress < 100 ? (
			<div
				style={{
					width: 100,
					height: 100,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					cursor: 'grab',
				}}>
				<div style={{ width: 24, height: 24 }}>
					<CircularProgressbar
						value={photoItem.uploadProgress}
						strokeWidth={16}
						styles={buildStyles({
							strokeLinecap: 'butt',
							trailColor: '#181818',
							pathColor: '#17c671',
						})}
					/>
				</div>
			</div>
		) : (
			<>
				<i className='material-icons' onClick={() => albumComponent.deleteImage(photoItem.media.key)}>
					highlight_off
				</i>
				<img alt='Product' src={photoItem.media.uri} style={{ height: 100, width: 'auto', cursor: 'grab' }} />
			</>
		)}
	</div>
));

const PhotoList = SortableContainer(({ photos, albumComponent }) => (
	<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', margin: '-4px -8px 0' }}>
		{photos.map((photoItem, index) => (
			<PhotoItem
				key={photoItem.key || photoItem._id}
				index={index}
				photoItem={photoItem}
				albumComponent={albumComponent}
			/>
		))}
	</div>
));

const VariantCard = ({ variant, index, component }) =>
	!variant.type ? (
		<Card small className='my-3'>
			<CardBody>
				<Row className='ml-1'>
					<FormSelect
						value={variant.type}
						onChange={event => component.onVariantFieldChange(index, event, 'type')}
						style={{ maxWidth: 200 }}>
						{[null, '12inch', '7inch', '2lp', 'cassette', 'vhs', 'cd', 'dvd'].map((item, index) => (
							<option value={item} key={index}>
								{index === 0 ? 'Select Type' : labelForVariantType(item)}
							</option>
						))}
					</FormSelect>
					<div style={{ flex: 1 }} />
					<i
						className='material-icons'
						style={{ cursor: 'pointer', color: '#c4183c', marginRight: 20, lineHeight: '32px', fontSize: '16px' }}
						onClick={() => component.removeVariant(index)}>
						highlight_off
					</i>
				</Row>
			</CardBody>
		</Card>
	) : (
		<Card small className='my-3'>
			<CardHeader className='border-bottom'>
				<Row className='ml-1 mb-0'>
					<h6 className='m-0' style={{ flex: 1 }}>
						{labelForVariantType(variant.type)}
						{variant.usersOnWaitList > 0 && (
							<Badge pill theme='primary' style={{ position: 'relative', top: -2, marginLeft: 8 }}>
								{variant.usersOnWaitList} on waitlist
							</Badge>
						)}
					</h6>
					<i
						className='material-icons'
						style={{ cursor: 'pointer', marginRight: 8, lineHeight: '24px', fontSize: '16px' }}
						onClick={() => component.moveVariantDown(index)}>
						arrow_circle_down
					</i>
					<i
						className='material-icons'
						style={{ cursor: 'pointer', marginRight: 8, lineHeight: '24px', fontSize: '16px' }}
						onClick={() => component.moveVariantUp(index)}>
						arrow_circle_up
					</i>
					<i
						className='material-icons'
						style={{ cursor: 'pointer', color: '#c4183c', marginRight: 20, lineHeight: '24px', fontSize: '16px' }}
						onClick={() => component.removeVariant(index)}>
						highlight_off
					</i>
				</Row>
			</CardHeader>
			<CardBody>
				<Row>
					<Col md='4'>
						<span className='text-semibold text-fiord-blue'>SKU</span>
						<FormInput
							size='md'
							className='mb-3'
							placeholder='LP-0001'
							value={variant.sku}
							onChange={event => component.onVariantFieldChange(index, event, 'sku')}
							onKeyUp={component.onKeyUp}
						/>
					</Col>
					<Col md='4'>
						<span className='text-semibold text-fiord-blue'>Price</span>
						<CurrencyInput
							className='mb-3 form-control form-control-md'
							prefix='$'
							placeholder='$0.00'
							value={variant.price / 100}
							onChangeEvent={event => component.onVariantCurrencyFieldChange(index, event, 'price')}
							onKeyUp={component.onKeyUp}
							selectAllOnFocus={true}
						/>
					</Col>
					<Col md='4'>
						<span className='text-semibold text-fiord-blue'>Compare At Price</span>
						<CurrencyInput
							className='mb-3 form-control form-control-md'
							prefix='$'
							placeholder='$0.00'
							value={variant.comparePrice / 100}
							onChangeEvent={event => component.onVariantCurrencyFieldChange(index, event, 'comparePrice')}
							onKeyUp={component.onKeyUp}
							selectAllOnFocus={true}
						/>
					</Col>
				</Row>
				<Row>
					<Col md='12'>
						<FormCheckbox
							checked={variant.trackQuantity}
							onChange={() => component.toggleVariantTrackQuantity(index)}
							toggle
							small>
							Track Quantity
						</FormCheckbox>
					</Col>
					<Col md='4'>
						{variant.trackQuantity && (
							<NumericInput
								className='form-control form-control-sm'
								min={0}
								max={1000}
								value={variant.quantity}
								onChange={number =>
									component.onVariantFieldChange(index, { target: { value: number } }, 'quantity')
								}
							/>
						)}
					</Col>
				</Row>
			</CardBody>
		</Card>
	);

export default class NewProduct extends Component {
	state = {
		title: '',
		description: '',
		photos: [],
		variants: [],
		saveEnabled: false,
		loading: true,
		productId: null,
		isLive: true,
		canBeDeleted: false,
		publishDate: null,
		deletedImageFileKeys: [],
		shouldBlockNavigation: false,
	};

	componentDidMount = async () => {
		if (this.props.match.params && this.props.match.params.productId) {
			let { productId } = this.props.match.params;
			let products = await waitForContainer(AppsContainer, 'products');
			let { title, description, variants, photos, isLive, publishDate, canBeDeleted } = products.find(
				item => item._id === productId
			);

			this.setState(
				{
					title,
					description,
					variants,
					photos,
					productId,
					isLive,
					canBeDeleted,
					publishDate: publishDate ? new Date(publishDate) : null,
					loading: false,
				},
				() => {
					this.updateValidation(() => {
						//I need to turn this back off since update validation turns it on.
						this.setState({ shouldBlockNavigation: false });
					});
				}
			);
		} else {
			this.setState({ loading: false });
		}
	};

	componentDidUpdate = () => {
		if (this.state.shouldBlockNavigation) {
			window.onbeforeunload = () => true;
		} else {
			window.onbeforeunload = undefined;
		}
	};

	onPhotoImageSelect = async files => {
		let appId = AppsContainer.getInstance().state.currentApp._id;

		let newImages = [];

		await Promise.all(
			files.map(async file => {
				let key = Math.floor(Math.random() * 10000000000);
				let resized = await imageTools.resize(file, { width: 1024, height: 1024 });

				let onUploadProgress = ({ loaded, total }) => {
					let percent = (loaded / total) * 100;
					this.setState(({ photos }) => {
						let photoItem = photos.find(item => item.key === key);
						photoItem.uploadProgress = percent * 0.9;
						return { photos };
					}, this.updateValidation);
				};

				let upload = async () => {
					let formData = new FormData();
					formData.append('media', resized);
					let { media } = await API.multipartPost(`/upload/image/${appId}`, formData, onUploadProgress);
					this.setState(({ photos }) => {
						let photoItem = photos.find(item => item.key === key);
						photoItem.uploadProgress = 100;
						console.log(media);
						photoItem.media = media;
						return { photos };
					}, this.updateValidation);
				};

				upload();

				newImages.push({ key, uploadProgress: 0 });
			})
		);

		this.setState(({ photos }) => {
			let newPhotos = photos.concat(newImages);
			return { photos: newPhotos };
		}, this.updateValidation);
	};

	onPhotoSortEnd = ({ oldIndex, newIndex }) => {
		this.setState(({ photos }) => {
			let newPhotos = arrayMove(photos, oldIndex, newIndex);
			return { photos: newPhotos };
		}, this.updateValidation);
	};

	deleteImage = async fileKey => {
		let appId = AppsContainer.getInstance().state.currentApp._id;
		let buff = new Buffer(fileKey);
		let base64encodedFileKey = buff.toString('base64');

		let deferFileDelete = false;
		if (this.state.productId) {
			deferFileDelete = true;
		} else {
			await API.del(`/upload/image/${appId}/${base64encodedFileKey}`);
		}

		this.setState(({ photos, deletedImageFileKeys }) => {
			photos = photos.filter(item => item.media.key !== fileKey);
			if (deferFileDelete) {
				deletedImageFileKeys.push(base64encodedFileKey);
			}
			return { photos, deletedImageFileKeys };
		}, this.updateValidation);
	};

	delete = async () => {
		if (!window.confirm(`Are you sure you want to delete this product?`)) return;

		this.setState({ loading: true });

		try {
			let { _id: appId, appCode } = AppsContainer.getInstance().state.currentApp;
			let { productId } = this.state;
			await API.del(`/admin/product/${appId}/${productId}`);
			this.setState({ shouldBlockNavigation: false });
			await AppsContainer.getInstance().updateCurrentApp();
			this.props.history.push(`/channel/${appCode}/store/products`);
		} catch (e) {
			this.setState({ loading: false });
			alert(`Oops! ${e.message}`);
		}
	};

	submit = async () => {
		let {
			productId,
			title,
			description,
			variants,
			photos,
			saveEnabled,
			isLive,
			publishDate,
			deletedImageFileKeys,
		} = this.state;
		let { _id: appId, appCode } = AppsContainer.getInstance().state.currentApp;

		if (!saveEnabled) return;

		this.setState({ loading: true });

		if (productId) {
			await Promise.all(
				deletedImageFileKeys.map(item => {
					return API.del(`/upload/image/${appId}/${item}`);
				})
			);
		}

		let url = productId ? `/admin/product/${appId}/${productId}` : `/admin/product/${appId}`;

		try {
			await API.post(url, { title, description, variants, photos, isLive, publishDate });
			this.setState({ shouldBlockNavigation: false });
			await AppsContainer.getInstance().updateCurrentApp();
			this.props.history.push(`/channel/${appCode}/store/products`);
		} catch (e) {
			this.setState({ loading: false });
			alert(`Oops! ${e.message}`);
		}
	};

	isValid = () => {
		let { title, photos, variants } = this.state;
		return (
			!!title &&
			photos.length > 0 &&
			photos.every(item => !!item.media) &&
			variants.length > 0 &&
			variants.every(item => item.sku && item.price > 0)
		);
	};

	updateValidation = callback => {
		this.setState({ saveEnabled: this.isValid(), shouldBlockNavigation: true }, callback);
	};

	onFieldChange = (event, field) => {
		let newState = {};
		newState[field] = event.target.value;
		this.setState(newState, this.updateValidation);
	};

	onKeyUp = event => {
		if (event.key === 'Enter') {
			this.submit();
		}
		return false;
	};

	toggleIsLive = () => {
		this.setState({ isLive: !this.state.isLive }, this.updateValidation);
	};

	onPublishDateChange = date => {
		if (!date) {
			let publishDate = null;
			this.setState({ publishDate }, this.updateValidation);
		} else {
			let publishDate = this.state.publishDate;
			if (!publishDate) publishDate = new Date();
			publishDate.setMinutes(0);
			publishDate.setSeconds(0);
			publishDate.setMilliseconds(0);
			publishDate.setFullYear(date.getFullYear());
			publishDate.setMonth(date.getMonth());
			publishDate.setDate(date.getDate());
			this.setState({ publishDate }, this.updateValidation);
		}
	};

	onPublishTimeChange = e => {
		let value = e.target.value;
		if (value === '') {
			let publishDate = null;
			this.setState({ publishDate }, this.updateValidation);
		} else {
			let publishDate = this.state.publishDate;
			if (!publishDate) publishDate = new Date();
			publishDate.setMinutes(0);
			publishDate.setSeconds(0);
			publishDate.setMilliseconds(0);
			publishDate.setHours(value);
			this.setState({ publishDate }, this.updateValidation);
		}
	};

	futureDateFilter = date => {
		let now = new Date();
		return moment(date).isSame(now, 'day') || moment(date).isAfter(now, 'day');
	};

	addVariant = () => {
		if (this.state.variants.filter(item => !item.type).length === 0) {
			this.setState(({ variants }) => {
				variants.unshift({
					type: '',
					description: '',
					sku: '',
					price: null,
					comparePrice: null,
					quantity: 0,
					trackQuantity: false,
				});
				return { variants };
			}, this.updateValidation);
		}
	};

	removeVariant = index => {
		this.setState(({ variants }) => {
			variants.splice(index, 1);
			return { variants };
		}, this.updateValidation);
	};

	moveVariantDown = index => {
		this.setState(({ variants }) => {
			if (index + 1 < variants.length) {
				variants = arrayMove(variants, index, index + 1);
				return { variants };
			}
		}, this.updateValidation);
	};

	moveVariantUp = index => {
		this.setState(({ variants }) => {
			if (index - 1 >= 0) {
				variants = arrayMove(variants, index, index - 1);
				return { variants };
			}
		}, this.updateValidation);
	};

	onVariantFieldChange = (index, event, field) => {
		const newValue = event.target.value;
		this.setState(({ variants }) => {
			// variants[index]._id = undefined;
			variants[index][field] = newValue;
			return { variants };
		}, this.updateValidation);
	};

	onVariantCurrencyFieldChange = (index, event, field) => {
		const stringValue = event.target.value
			.split('$')
			.join('')
			.split(',')
			.join('');
		const newValue = stringValue === '' ? null : parseFloat(stringValue) * 100;
		this.setState(({ variants }) => {
			variants[index]._id = undefined;
			variants[index][field] = newValue;
			return { variants };
		}, this.updateValidation);
	};

	toggleVariantTrackQuantity = index => {
		this.setState(({ variants }) => {
			variants[index].trackQuantity = !variants[index].trackQuantity;
			return { variants };
		}, this.updateValidation);
	};

	render = () => {
		return (
			<Container className='page-container album-detail'>
				<Prompt
					when={this.state.shouldBlockNavigation}
					message='You have unsaved changes, are you sure you want to leave?'
				/>
				{this.state.loading ? (
					<ScreenLoader />
				) : (
					<>
						<Row noGutters className='page-header py-4' style={{ alignItems: 'center' }}>
							<PageTitle
								title={this.state.productId ? 'Edit Product' : `Add New Product`}
								subtitle='Store'
								className='text-md-left mb-3'
							/>
						</Row>
						<Row>
							<Col lg='9' md='12'>
								<Card small className='mb-3'>
									<CardBody style={{ paddingBottom: 0 }}>
										<FormInput
											size='lg'
											className='mb-3'
											placeholder='Title (required)'
											value={this.state.title}
											onChange={event => this.onFieldChange(event, 'title')}
											onKeyUp={this.onKeyUp}
										/>
										<FormTextarea
											style={{ marginTop: 8, marginBottom: 0 }}
											placeholder='Description'
											size='lg'
											className='mb-3'
											rows='10'
											value={this.state.description}
											onChange={event => this.onFieldChange(event, 'description')}
										/>
									</CardBody>
									<CardBody style={{ paddingBottom: 0 }}>
										<PhotoList
											photos={this.state.photos}
											albumComponent={this}
											axis='xy'
											pressDelay={0}
											helperClass='sortableHelper'
											useDragHandle={false}
											onSortEnd={event => this.onPhotoSortEnd(event)}
										/>
										<div style={{ marginBottom: 16, padding: 0 }}>
											<FileDropzone
												label='🖼 Upload images here'
												onFileSelect={this.onPhotoImageSelect}
												types='JPG and PNG'
												accept='.jpg, .png'
											/>
										</div>
									</CardBody>
								</Card>

								<Row noGutters className='pt-4' style={{ alignItems: 'center' }}>
									<h5 className='m-0'>Formats</h5>
									<Button theme='dark' className='ml-3' size='sm' onClick={this.addVariant}>
										Add
									</Button>
								</Row>

								{this.state.variants.map((item, index) => (
									<VariantCard variant={item} key={index} index={index} component={this} />
								))}

								{this.state.variants.length === 0 && (
									<Card small className='my-3 content-grid'>
										<div
											style={{
												display: 'flex',
												flex: 1,
												alignItems: 'center',
												justifyContent: 'center',
												fontSize: 13,
												fontWeight: '300',
												height: 48,
											}}>
											Add at least 1 format for this product.
										</div>
									</Card>
								)}
							</Col>
							<Col lg='3' md='12'>
								<Card small>
									<CardBody>
										<Row>
											<Col
												sm='6'
												style={{
													paddingTop: 5,
													marginBottom: -5,
												}}>
												<FormCheckbox checked={this.state.isLive} onChange={this.toggleIsLive} toggle small>
													Live
												</FormCheckbox>
											</Col>
										</Row>
										{!this.state.isLive && (
											<Row className='mt-4'>
												<Col sm='12'>
													<strong className='text-muted form-text d-block mb-2'>
														Auto-publish (optional)
													</strong>
													<DatePicker
														size='md'
														selected={this.state.publishDate}
														onChange={this.onPublishDateChange}
														placeholderText='Publish Date'
														dropdownMode='select'
														filterDate={this.futureDateFilter}
													/>
													<FormSelect
														size='md'
														className='w-100 mt-2'
														value={moment(this.state.publishDate).format('HH')}
														onChange={this.onPublishTimeChange}>
														<option value=''>Publish Time</option>
														<option value='09'>9:00 a.m.</option>
														<option value='10'>10:00 a.m.</option>
														<option value='11'>11:00 a.m.</option>
														<option value='12'>12:00 p.m.</option>
														<option value='13'>1:00 p.m.</option>
														<option value='14'>2:00 p.m.</option>
														<option value='15'>3:00 p.m.</option>
														<option value='16'>4:00 p.m.</option>
														<option value='17'>5:00 p.m.</option>
														<option value='18'>6:00 p.m.</option>
														<option value='19'>7:00 p.m.</option>
														<option value='20'>8:00 p.m.</option>
														<option value='21'>9:00 p.m.</option>
														<option value='22'>10:00 p.m.</option>
														<option value='23'>11:00 p.m.</option>
														<option value='00'>12:00 a.m.</option>
														<option value='01'>1:00 a.m.</option>
														<option value='02'>2:00 a.m.</option>
														<option value='03'>3:00 a.m.</option>
														<option value='04'>4:00 a.m.</option>
														<option value='05'>5:00 a.m.</option>
														<option value='06'>6:00 a.m.</option>
														<option value='07'>7:00 a.m.</option>
														<option value='08'>8:00 a.m.</option>
													</FormSelect>
												</Col>
											</Row>
										)}
									</CardBody>
								</Card>
								<Row className='mt-4'>
									<Col sm='12'>
										<Button
											theme='dark'
											size='sm'
											style={{ width: '100%' }}
											disabled={!this.state.saveEnabled}
											onClick={this.submit}>
											Save
										</Button>
									</Col>
								</Row>
								{this.state.productId && this.state.canBeDeleted && (
									<Row className='mt-2'>
										<Col sm='12'>
											<Button theme='white' size='sm' style={{ width: '100%' }} onClick={this.delete}>
												Delete Product
											</Button>
										</Col>
									</Row>
								)}
							</Col>
						</Row>
					</>
				)}
			</Container>
		);
	};
}
