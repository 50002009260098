import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Badge } from 'shards-react';
import AppsContainer from '../../data/AppsContainer';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import waitForContainer from '../../utils/waitForContainer';
import { NavLink, Link } from 'react-router-dom';
import moment from 'moment';

const PostItem = ({ item }) => (
	<Col lg='4' md='4' sm='4' style={{ margin: 0, padding: 10 }}>
		<Link to={`posts/${item._id}`}>
			<Card small className='post-card'>
				<div className='post-art' style={item.media && { backgroundImage: `url('${item.media.uri}')` }} />
				{!item.isLive && (
					<Badge pill theme='dark' style={{ bottom: 10, right: 10 }}>
						{item.publishDate ? moment(item.publishDate).format('M/D/YY @ h:mm a') : 'Draft'}
					</Badge>
				)}
				{item.visibility === 'location' && item.location && (
					<Badge pill theme='primary' style={{ bottom: item.isLive ? 10 : 35, right: 10 }}>
						{item.location.name}
					</Badge>
				)}
				<h3>{item.title}</h3>
			</Card>
		</Link>
	</Col>
);

const PostGrid = ({ posts }) => (
	<Card small className='mb-3 content-grid' style={{ overflow: 'auto' }}>
		{posts.length === 0 ? (
			<div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', height: 300 }}>
				No Posts
			</div>
		) : (
			posts.map((item, index) => <PostItem item={item} index={index} key={index} />)
		)}
	</Card>
);

export default class Posts extends Component {
	state = {
		loading: true,
		live: [],
		drafts: [],
	};

	componentDidMount = async () => {
		let posts = await waitForContainer(AppsContainer, 'posts');
		let live = posts.filter(item => item.isLive);
		let drafts = posts
			.filter(item => !item.isLive)
			.sort((a, b) => {
				if (!b.publishDate && a.publishDate) return 1;
				if (!a.publishDate && b.publishDate) return -1;
				if (!a.publishDate && !b.publishDate) return 0;
				return new Date(b.publishDate) - new Date(a.publishDate);
			});
		this.setState({ live, drafts, loading: false });
	};

	render = () => {
		return (
			<Container className='page-container posts'>
				{this.state.loading ? (
					<ScreenLoader />
				) : (
					<>
						{this.state.drafts.length > 0 && (
							<div>
								<Row noGutters className='page-header py-4 d-flex'>
									<PageTitle title='Drafts' subtitle='Connect' className='text-sm-left mb-3' />
								</Row>
								<Row>
									<Col lg='9' md='12'>
										<PostGrid posts={this.state.drafts} />
									</Col>
									{this.state.drafts.length > 0 && (
										<Col lg='3' md='12'>
											<Card small>
												<CardBody>
													<Row>
														<Col sm='12'>
															<Button
																theme='accent'
																size='sm'
																style={{ width: '100%' }}
																tag={NavLink}
																to='new-post'>
																+ Add Post
															</Button>
														</Col>
													</Row>
												</CardBody>
											</Card>
										</Col>
									)}
								</Row>
							</div>
						)}
						{(this.state.live.length > 0 || (this.state.drafts.length === 0 && this.state.live.length === 0)) && (
							<div>
								<Row noGutters className='page-header py-4 d-flex'>
									<Col sm='6' md='6' lg='4'>
										<PageTitle title='Posts' subtitle='Connect' className='text-sm-left mb-3 p-0' />
									</Col>
								</Row>
								<Row>
									<Col lg='9' md='12'>
										<PostGrid posts={this.state.live} />
									</Col>
									{this.state.drafts.length === 0 && (
										<Col lg='3' md='12'>
											<Card small>
												<CardBody>
													<Row>
														<Col sm='12'>
															<Button
																theme='accent'
																size='sm'
																style={{ width: '100%' }}
																tag={NavLink}
																to='new-post'>
																+ Add Post
															</Button>
														</Col>
													</Row>
												</CardBody>
											</Card>
										</Col>
									)}
								</Row>
							</div>
						)}
					</>
				)}
			</Container>
		);
	};
}
