import React from 'react';
import Dropzone from 'react-dropzone';

class FileDropzone extends React.Component {
	handleFileDrop = files => {
		this.props.onFileSelect(files);
	};

	render() {
		return (
			<div className='file-manager__dropzone'>
				<Dropzone onDrop={this.handleFileDrop}>
					{({ getRootProps, getInputProps, isDragActive }) => {
						return (
							<div
								{...getRootProps()}
								className='dropzone'
								style={{
									backgroundColor: isDragActive ? 'rgba(0,0,0,.05)' : 'transparent',
								}}>
								<input {...getInputProps()} accept={this.props.accept} />
								<div style={{ fontSize: 16, marginBottom: this.props.types || this.props.tip ? 16 : 0 }}>
									{this.props.label}
								</div>
								{this.props.types && (
									<div style={{ fontSize: 11, fontWeight: 300 }}>
										<strong style={{ fontWeight: 500 }}>File types accepted: </strong> {this.props.types}
									</div>
								)}
								{this.props.tip && (
									<div style={{ fontSize: 11, fontWeight: 300 }}>
										<strong style={{ fontWeight: 500 }}>Pro tip: </strong> {this.props.tip}
									</div>
								)}
								{this.props.children}
							</div>
						);
					}}
				</Dropzone>
			</div>
		);
	}
}

FileDropzone.defaultProps = {
	label: 'Drop files here to upload',
};

export default FileDropzone;
