import React, { Component } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	Button,
	ButtonGroup,
	Badge,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	FormInput,
} from 'shards-react';
import AppsContainer from '../../data/AppsContainer';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import moment from 'moment';
import API from '../../data/API';
import ReactTable from 'react-table';
import waitForContainer from '../../utils/waitForContainer';
import CopyButton from '../../components/CopyButton';
import NumericInput from 'react-numeric-input';
import copy from 'copy-to-clipboard';

export default class Posts extends Component {
	state = {
		loading: true,
		promocodes: [],
		showingCreateModal: false,
		code: '',
		numberOfUses: 10,
		trialDuration: 1,
		trialUnits: 'weeks',
		submitEnabled: false,
	};

	componentDidMount = async () => {
		let app = await waitForContainer(AppsContainer, 'currentApp');
		let promocodes = await API.get(`/admin/promocode/${app._id}`);
		this.setState({ promocodes, loading: false });
	};

	onFieldChange = (event, field) => {
		let newState = {};
		newState[field] = event.target.value;
		this.setState(newState, this.updateValidation);
	};

	isValid = () => {
		let { code, numberOfUses, trialDuration } = this.state;
		return code.length > 0 && numberOfUses > 0 && trialDuration > 0 && !this.getCodeError();
	};

	getCodeError = () => {
		let { code } = this.state;
		if (code.indexOf(' ') > -1) {
			return `Promo codes can't include spaces!`;
		}
		const alphaNumeric = /[^A-Z0-9-]+/;
		if (alphaNumeric.test(code.toUpperCase())) {
			return `Promo codes can only include letters, numbers, and hyphens!`;
		}
		return null;
	};

	updateValidation = callback => {
		this.setState({ submitEnabled: this.isValid() }, callback);
	};

	submit = async () => {
		let { code, numberOfUses, trialDuration, trialUnits, submitEnabled } = this.state;
		if (!submitEnabled) return;

		this.setState({ submitEnabled: false });

		try {
			let appId = AppsContainer.getInstance().state.currentApp._id;
			await API.post(`/admin/promocode/${appId}`, { code, numberOfUses, trialDuration, trialUnits });
			let promocodes = await API.get(`/admin/promocode/${appId}`);
			this.setState({
				promocodes,
				showingCreateModal: false,
				code: '',
				numberOfUses: 10,
				trialDuration: 1,
				trialUnits: 'weeks',
			});
		} catch (e) {
			this.setState({ submitEnabled: true });
			alert(`Oops! ${e.message}`);
		}
	};

	onKeyUp = event => {
		if (event.key === 'Enter') {
			this.submit();
		}
	};

	delete = async promocode => {
		if (!window.confirm(`Are you sure you want to cancel ${promocode.code}?`)) return;

		try {
			let appId = AppsContainer.getInstance().state.currentApp._id;
			await API.del(`/admin/promocode/${appId}/${promocode._id}`);

			let promocodes = await API.get(`/admin/promocode/${appId}`);
			this.setState({ promocodes });
		} catch (e) {
			this.setState({ submitEnabled: true });
			alert(`Oops! ${e.message}`);
		}
	};

	futureDateFilter = date => {
		let now = new Date();
		return moment(date).isSame(now, 'day') || moment(date).isAfter(now, 'day');
	};

	render = () => {
		let appCode =
			AppsContainer.getInstance().state.currentApp && AppsContainer.getInstance().state.currentApp.appCode;
		let trialButtonTheme = (duration, units) =>
			this.state.trialDuration === duration && this.state.trialUnits === units ? 'primary' : 'white';
		let trialButtonClick = (duration, units) => this.setState({ trialDuration: duration, trialUnits: units });
		let codeError = this.getCodeError();

		const tableColumns = [
			{
				Header: 'Status',
				accessor: 'status',
				maxWidth: 100,
				className: 'text-center d-flex align-items-center',
				Cell: row => (
					<div>
						<Badge
							pill
							theme={
								{
									active: 'success',
									expired: 'light',
									canceled: 'light',
									complete: 'dark',
								}[row.original.status]
							}>
							{row.original.status}
						</Badge>
					</div>
				),
			},
			{
				Header: 'Code',
				accessor: 'code',
				className: 'text-center d-flex align-items-center',
				Cell: row => (
					<CopyButton
						size="md"
						theme="white"
						style={{ border: 'none' }}
						copyText={`https://manifestfinancial.com/${appCode}?code=${row.original.code}`}
						successMessage="copied link!">
						{row.original.code}
					</CopyButton>
				),
			},
			{
				id: 'offer',
				Header: 'Offer',
				accessor: row => `${row.trialDuration}-${row.trialUnits.slice(0, -1)} trial`,
				maxWidth: 120,
				className: 'text-center d-flex align-items-center',
			},
			{
				id: 'redemption',
				Header: 'Redeemed',
				accessor: row => `${row.redemptions}/${row.redemptionLimit}`,
				maxWidth: 120,
				className: 'text-center d-flex align-items-center',
			},
			{
				id: 'expires',
				Header: 'Expiration',
				accessor: row => moment(row.expiration).fromNow(),
				maxWidth: 120,
				className: 'text-center d-flex align-items-center',
			},
			{
				Header: '',
				accessor: 'actions',
				maxWidth: 100,
				sortable: false,
				Cell: row =>
					row.original.status === 'active' && (
						<ButtonGroup size="sm" className="d-table ml-auto">
							<Button
								theme="white"
								onClick={() =>
									window.open(
										`https://manifestfinancial.com/${appCode}?code=${row.original.code}`,
										'promoCodePreview',
									)
								}>
								<i className="material-icons">link</i>
							</Button>
							<Button theme="white" onClick={() => this.delete(row.original)}>
								<i className="material-icons">close</i>
							</Button>
						</ButtonGroup>
					),
			},
		];

		return (
			<Container className="page-container posts">
				{this.state.loading ? (
					<ScreenLoader />
				) : (
					<div>
						<Row noGutters className="page-header py-4 d-flex">
							<Col sm="6" md="6" lg="4">
								<PageTitle
									title="Promo&nbsp;Links"
									subtitle="Connect"
									className="text-sm-left mb-3 p-0"
								/>
							</Col>
						</Row>
						<Row>
							<Col lg="9" md="12">
								<Card className="file-manager file-manager-list p-0">
									<CardBody className="p-0">
										<ReactTable
											columns={tableColumns}
											data={this.state.promocodes}
											resizable={false}
											usePagination={false}
										/>
									</CardBody>
								</Card>
							</Col>
							<Col lg="3" md="12">
								<Card small>
									<CardBody>
										<Row>
											<Col sm="12">
												<Button
													theme="accent"
													size="sm"
													style={{ width: '100%' }}
													onClick={() => this.setState({ showingCreateModal: true })}>
													+ Add Promo Link
												</Button>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</div>
				)}
				<Modal centered size="md" open={this.state.showingCreateModal}>
					<ModalHeader toggle={() => this.setState({ showingCreateModal: false })}>
						New Promo Link
					</ModalHeader>
					<ModalBody>
						<p style={{ marginBottom: 20 }}>
							Create a promo link to offer to your fans. Make it fun and memorable, and keep the number
							fairly limited to make it exciting!
							<br />
							<br />
							<em>
								Example usage: "The first 10 people to use this link get a free month of my Manifest
								channel!"
							</em>
						</p>
						<FormInput
							autoFocus
							size="md"
							className="mb-3"
							style={{ fontSize: 16 }}
							placeholder={`letters, numbers, + hyphens`}
							value={this.state.code}
							onChange={event => this.onFieldChange(event, 'code')}
							onKeyUp={this.onKeyUp}
						/>
						{this.state.code.length === 0 && (
							<p style={{ lineHeight: '40px', color: '#CCC' }}>https://manifestfinancial.com/{appCode}</p>
						)}
						{this.state.code.length !== 0 && !codeError && (
							<p style={{ lineHeight: '40px', color: '#393' }}>
								https://manifestfinancial.com/{appCode}?code={this.state.code.toUpperCase()}&nbsp;
								<Button
									theme="white"
									onClick={() =>
										copy(
											`https://manifestfinancial.com/${appCode}?code=${this.state.code.toUpperCase()}`,
										)
									}>
									<i className="material-icons">file_copy</i>
								</Button>
							</p>
						)}
						{this.state.code.length !== 0 && codeError && (
							<p style={{ lineHeight: '40px', color: '#D33' }}>{codeError}</p>
						)}
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<div style={{ width: 470 }}>
								<label className="mt-3" style={{ display: 'block' }}>
									Offer
								</label>
								<ButtonGroup className="mb-3">
									<Button
										size="sm"
										style={{ height: 36 }}
										theme={trialButtonTheme(1, 'months')}
										onClick={() => trialButtonClick(1, 'months')}>
										1-month trial
									</Button>
									<Button
										size="sm"
										style={{ height: 36 }}
										theme={trialButtonTheme(2, 'months')}
										onClick={() => trialButtonClick(2, 'months')}>
										2-month trial
									</Button>
									<Button
										size="sm"
										style={{ height: 36 }}
										theme={trialButtonTheme(3, 'months')}
										onClick={() => trialButtonClick(3, 'months')}>
										3-month trial
									</Button>
								</ButtonGroup>
							</div>
							<div>
								<label className="mt-3" style={{ display: 'block' }}>
									Number of uses
								</label>
								<NumericInput
									className="form-control form-control-sm"
									min={1}
									max={100}
									value={this.state.numberOfUses}
									onChange={number =>
										this.onFieldChange({ target: { value: number } }, 'numberOfUses')
									}
								/>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<p style={{ marginBottom: 0, marginRight: 10, fontSize: 12 }}>
							This code will expire in 1 month.
						</p>
						<Button theme="accent" size="sm" disabled={!this.state.submitEnabled} onClick={this.submit}>
							Submit
						</Button>
					</ModalFooter>
				</Modal>
			</Container>
		);
	};
}
