import React, { useEffect, useState, useRef } from 'react';
import {
	Row,
	Col,
	Button,
	FormInput,
	FormCheckbox,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Badge,
} from 'shards-react';
import AppsContainer from '../data/AppsContainer';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import API from '../data/API';
import _ from 'lodash';
import produce from 'immer';
import colors from '../utils/colors';

const labelForCollectionType = collectionType => ({ audio: 'Audio', video: 'Video' }[collectionType]);
const itemLabelForCollectionType = collectionType => ({ audio: 'track', video: 'video' }[collectionType]);
const pluralItemLabelForCollectionType = collectionType => ({ audio: 'tracks', video: 'videos' }[collectionType]);

const DragHandle = SortableHandle(() => (
	<i
		className='material-icons'
		style={{
			fontSize: 16,
			color: '#CCC',
			cursor: 'grab',
			userSelect: 'none',
			marginLeft: 16,
			marginRight: 16,
			marginBottom: 4,
		}}>
		reorder
	</i>
));

const CollectionItem = SortableElement(({ item, selected, callback }) => (
	<div
		style={{
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			height: 32,
			backgroundColor: selected ? colors.accent.value : '#FFF',
			cursor: 'pointer',
		}}
		onClick={callback}>
		<DragHandle />
		<span
			style={{
				color: selected ? '#FFF' : '#000',
				opacity: item.isVisible ? 1 : 0.4,
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				marginRight: 10,
			}}>
			{item.name}
		</span>
	</div>
));

const CollectionList = SortableContainer(({ collections, selectedIndex, setSelectedIndex }) => (
	<div style={{ overflowY: 'scroll', height: 230, paddingBottom: 12, borderRight: '1px solid #dfe1e3' }}>
		{collections.map((item, index) => (
			<CollectionItem
				key={index}
				index={index}
				item={item}
				selected={selectedIndex === index}
				callback={() => setSelectedIndex(index)}
			/>
		))}
	</div>
));

export default ({ collectionType, open, toggle }) => {
	const nameField = useRef(null);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [collections, setCollections] = useState([]);
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
	const selectedCollection = collections[selectedIndex];
	const defaultCollectionName = 'New Collection';

	const load = async () => {
		let appId = AppsContainer.getInstance().state.currentApp._id;
		const { collections } = await API.get(`/admin/collections/${appId}/${collectionType}`);
		setCollections(collections);
		if (collections.length === 0) {
			addNewCollection();
			setHasUnsavedChanges(false);
		}
	};

	useEffect(() => {
		setSelectedIndex(0);
		setCollections([]);
		setHasUnsavedChanges(false);
		if (open) load();
	}, [open]);

	const confirmToggle = () => {
		if (hasUnsavedChanges) {
			if (!window.confirm(`Discard collection changes?`)) return;
		}
		toggle();
	};

	const save = async () => {
		try {
			if (!collections.every(item => item.name.length > 0)) {
				throw new Error(`All of your collections need names.`);
			}
			if (!collections.every(item => item.name !== defaultCollectionName)) {
				throw new Error(`Don't leave your collection named "${defaultCollectionName}"!`);
			}
		} catch (e) {
			alert(e.message);
			return;
		}

		let appId = AppsContainer.getInstance().state.currentApp._id;
		await API.post(`/admin/collections/${appId}/${collectionType}`, { collections });
		toggle();
	};

	const addNewCollection = () => {
		const newCollections = produce(collections, draft => {
			draft.push({ name: defaultCollectionName, isVisible: true, itemCount: 0 });
		});
		setCollections(newCollections);
		setSelectedIndex(newCollections.length - 1);
		setHasUnsavedChanges(true);
		setTimeout(() => nameField.current.select(), 50);
	};

	const toggleIsVisible = () => {
		const newCollections = produce(collections, draft => {
			draft[selectedIndex].isVisible = !draft[selectedIndex].isVisible;
		});
		setCollections(newCollections);
		setHasUnsavedChanges(true);
	};

	const onFieldChange = (event, field) => {
		const newCollections = produce(collections, draft => {
			draft[selectedIndex][field] = event.target.value;
		});
		setCollections(newCollections);
		setHasUnsavedChanges(true);
	};

	const onSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex === selectedIndex) {
			setSelectedIndex(newIndex);
		}
		if (oldIndex < selectedIndex && newIndex >= selectedIndex) {
			setSelectedIndex(selectedIndex - 1);
		}
		if (oldIndex > selectedIndex && newIndex <= selectedIndex) {
			setSelectedIndex(selectedIndex + 1);
		}
		setCollections(arrayMove(collections, oldIndex, newIndex));
		setHasUnsavedChanges(true);
	};

	const deleteCollection = async () => {
		if (selectedCollection.itemCount > 0) {
			if (
				!window.confirm(
					`Are you sure you want to delete this collection? No ${pluralItemLabelForCollectionType(
						collectionType
					)} will be deleted, but their relationship to this collection will be removed. If you just want to temporarily hide this collection from your channel, check the 'hidden' checkbox.`
				)
			) {
				return;
			}
		}
		const newCollections = produce(collections, draft => {
			return draft.filter((item, index) => index !== selectedIndex);
		});
		setCollections(newCollections);
		setSelectedIndex(Math.min(selectedIndex, newCollections.length - 1));
		setHasUnsavedChanges(true);
	};

	return (
		<Modal centered size='md' open={open} toggle={confirmToggle}>
			<ModalHeader toggle={confirmToggle}>{labelForCollectionType(collectionType)} Collections</ModalHeader>
			<ModalBody className='px-0 py-0'>
				<Row>
					<Col md='5' className='pr-lg-0 pr-md-1'>
						<CollectionList
							collections={collections}
							selectedIndex={selectedIndex}
							setSelectedIndex={setSelectedIndex}
							pressDelay={0}
							helperClass='sortableHelper'
							useDragHandle={true}
							axis='y'
							lockAxis='y'
							onSortEnd={onSortEnd}
						/>
					</Col>
					<Col md='7' className='pl-md-0 pl-sm-3'>
						{selectedCollection && (
							<div key={selectedCollection._id || selectedIndex} className='px-3 py-3'>
								<FormInput
									size='sm'
									className='mb-1'
									style={{ fontSize: 16 }}
									placeholder='Collection Name'
									value={selectedCollection.name}
									onChange={event => onFieldChange(event, 'name')}
									innerRef={nameField}
								/>

								<p style={{ fontSize: 12 }}>
									This collection contains {selectedCollection.itemCount}{' '}
									{selectedCollection.itemCount === 1
										? itemLabelForCollectionType(collectionType)
										: pluralItemLabelForCollectionType(collectionType)}
									.
								</p>
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
									<FormCheckbox small checked={!selectedCollection.isVisible} onChange={toggleIsVisible}>
										<span style={{ fontSize: 12 }}>Hidden</span>
									</FormCheckbox>
									<div style={{ flex: 1 }} />
									<Button theme='white' size='sm' onClick={deleteCollection}>
										Delete Collection
									</Button>
								</div>
							</div>
						)}
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button theme='accent' size='sm' onClick={addNewCollection}>
					+Add
				</Button>
				<div style={{ flex: 1 }} />
				{hasUnsavedChanges && <p style={{ marginBottom: 0, fontSize: 12 }}>Click save to finalize changes.</p>}
				<Button theme='dark' size='sm' onClick={save}>
					Save
				</Button>
			</ModalFooter>
		</Modal>
	);
};
