import _ from 'lodash';
import Session from './Session';
import axios from 'axios';
import ErrorHandler from './ErrorHandler';

export default class API {
	static baseURL = () => {
		return process.env.REACT_APP_API_BASE_URL;
	};
	static headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	};

	static get = async path => await API.jsonRequest('get', path);
	static del = async path => await API.jsonRequest('delete', path);
	static post = async (path, data) => await API.jsonRequest('post', path, data);
	static put = async (path, data) => await API.jsonRequest('put', path, data);
	static putFile = async (path, data, onUploadProgress) =>
		await API.jsonRequest('put', path, data, false, onUploadProgress);
	static patch = async (path, data) => await API.jsonRequest('patch', path, data);

	static multipartPost = async (path, data, onUploadProgress) => {
		let method = 'post';
		let url = path.indexOf('http') === -1 ? API.baseURL() + path : path;
		let headers = {
			...API.headers,
			'Content-Type': 'multipart/form-data',
		};
		let token = Session._instance.getToken();
		if (token) headers.token = token;
		let config = { method, headers, data, url, onUploadProgress };
		console.log('multipartPost: ' + url + ' ' + (data ? config.data : ''));
		console.log(data);

		return await API.makeRequest(config);
	};

	static jsonRequest = async (method, path, data, jsonEncodeData = true, onUploadProgress) => {
		console.log('request');
		let url = path.indexOf('http') === -1 ? API.baseURL() + path : path;
		let headers = API.headers;
		let token = Session._instance.getToken();
		if (token) headers.token = token;
		let config = { method, headers, url, onUploadProgress };
		if (data) config.data = jsonEncodeData ? JSON.stringify(data) : data;
		console.log(method + ': ' + url + ' ' + (data ? config.data : ''));

		return await API.makeRequest(config);
	};

	static download = async path => {
		let url = path.indexOf('http') === -1 ? API.baseURL() + path : path;
		let headers = API.headers;
		let token = Session._instance.getToken();
		if (token) headers.token = token;
		let config = { method: 'GET', responseType: 'blob', headers, url };
		return await API.makeRequest(config);
	};

	static makeRequest = async config => {
		try {
			return await axios(config).then(response => response.data);
		} catch (e) {
			console.log(e);
			// add error handling
			if (_.get(e, 'response.data.options.shouldShowAlert')) {
				ErrorHandler.getInstance().addError({
					message: _.get(e.response.data, ['message'], 'An unknown error has occurred.'),
					options: { ...e.response.data.options, theme: 'danger' },
					status: e.response.status,
				});
			}

			let status = e.response.status;
			if (status === 401) {
				Session.getInstance().clear();
			}
			let message = _.get(e.response.data, ['message'], 'An unknown error has occurred.');
			let details = _.get(e.response.data, ['details'], null);
			throw { status, message, details };
		}
	};
}
