import React, { Component } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	FormSelect,
	Button,
	ButtonGroup,
	Badge,
} from 'shards-react';
import AppsContainer from '../../data/AppsContainer';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import SmallStats from '../../components/common/SmallStats';
import colors from '../../utils/colors';
import ReactTable from 'react-table';
import moment from 'moment';
import API from '../../data/API';
import waitForContainer from '../../utils/waitForContainer';
import { currency } from '../../utils/money';
import fileDownload from 'js-file-download';
import _ from 'lodash';

const FrontMe = ({ channelLoan, payoffEstimateDisplay, history }) => {
	return (
		<Col md="6" lg="3" className="mb-4">
			<Card small className="stats-small">
				<CardBody className="px-0 py-0">
					<div
						className="d-flex px-3 py-3 stats-small__data"
						style={{ maxWidth: 'unset', position: 'absolute', width: '100%', height: '100%' }}>
						<span className="stats-small__label text-uppercase mb-1" style={{ width: 200 }}>
							Front Me™ Balance
						</span>
						<h6 className="count stats-small__value m-0">{currency(channelLoan.balance / 100)}</h6>
						<span className="stats-small__label text-uppercase mb-1" style={{ marginTop: 6 }}>
							Estimated Payback: {payoffEstimateDisplay}
						</span>
						<div style={{ flex: 1 }} />
						<Button theme="success" size="sm" onClick={() => history.push('front-me')}>
							Learn More
						</Button>
					</div>
				</CardBody>
			</Card>
		</Col>
	);
};

const FrontMeOffer = ({ frontMe, history }) => {
	return (
		<Col md="6" lg="3" className="mb-4">
			<Card small className="stats-small">
				<CardBody className="px-0 py-0">
					<div
						className="d-flex px-3 py-3 stats-small__data"
						style={{ maxWidth: 'unset', position: 'absolute', width: '100%', height: '100%' }}>
						<span className="stats-small__label text-uppercase mb-1" style={{ width: 200 }}>
							Front Me™
						</span>
						<h6 className="count stats-small__value m-0">{currency(frontMe.offerAmount / 100)}</h6>
						<span className="stats-small__label text-uppercase mb-1" style={{ width: 200, marginTop: 6 }}>
							Interest Free
						</span>
						<div style={{ flex: 1 }} />
						<Button theme="success" size="sm" onClick={() => history.push('front-me')}>
							Learn More
						</Button>
					</div>
				</CardBody>
			</Card>
		</Col>
	);
};

const SmallStat = ({ label, data, color, dollarAmount }) => {
	if (!data) data = [0];

	let start = data[0];
	let end = data[data.length - 1];
	let percentage, positive, negative;
	if (start === 0 || start === end) {
		percentage = '';
		positive = false;
		negative = false;
	} else if (end < start) {
		percentage = (((start - end) / start) * 100).toFixed(1);
		positive = false;
		negative = true;
	} else {
		percentage = (((end - start) / start) * 100).toFixed(1);
		positive = true;
		negative = false;
	}

	return (
		<Col md="6" lg="3" className="mb-4">
			<SmallStats
				chartData={[
					{
						borderWidth: 1.5,
						backgroundColor: color.toRGBA(0.1),
						borderColor: color.toRGBA(),
						data: data,
					},
				]}
				chartLabels={data.map(i => null)}
				label={label}
				value={dollarAmount ? currency(end) : end}
				percentage={`${percentage}%`}
				increase={positive}
				decrease={negative}
			/>
		</Col>
	);
};

const supporterColumns = [
	{
		Header: 'Email',
		accessor: 'email',
		className: 'text-left',
		style: { justifyContent: 'flex-start' },
	},
	{
		Header: 'Status',
		accessor: 'status',
		maxWidth: 120,
		className: 'text-center d-flex align-items-center',
		Cell: row => (
			<div>
				<Badge
					pill
					style={{ width: 100 }}
					theme={
						{
							active: 'success',
							cancelled: 'light',
							trialing: 'primary',
						}[row.original.status]
					}>
					{row.original.status === 'trialing'
						? `trial ends ${moment(row.original.expiration).format('MMM D')}`
						: row.original.status}
				</Badge>
			</div>
		),
	},
	{
		id: 'supported',
		Header: 'Supported',
		accessor: row => moment(row.added).format('MM/DD/YY'),
		sortMethod: (a, b) => moment(a).diff(b),
		maxWidth: 120,
		className: 'text-center d-flex align-items-center',
	},
	{
		id: 'ltv',
		Header: 'LTV',
		accessor: row => (!!row.ltv ? currency(row.ltv / 100) : `---`),
		sortMethod: (a, b) => {
			a = a === '---' ? 0 : Number(a.split('$').join(''));
			b = b === '---' ? 0 : Number(b.split('$').join(''));
			return a - b;
		},
		maxWidth: 120,
		className: 'text-center d-flex align-items-center',
	},
];

const freeloaderColumns = [
	{
		Header: 'Email',
		accessor: 'email',
		className: 'text-left',
		style: { justifyContent: 'flex-start' },
	},
	{
		id: 'firstVisit',
		Header: 'First Visit',
		accessor: row => moment(row.createdAt).format('MM/DD/YY'),
		sortMethod: (a, b) => moment(a).diff(b),
		maxWidth: 120,
		className: 'text-center d-flex align-items-center',
	},
];

export default class extends Component {
	state = {
		loading: true,
		subscriberType: 'supporters',
		subscribers: [],
		topType: 'music',
		frontMe: null,
		freeloadersEnabled: false,
	};

	loadDailySnapshots = async appId => {
		let { snapshots } = await API.get(`/admin/dailysnapshot/${appId}/recent`);

		let snapshotTrials = [];
		let snapshotSubscribers = [];
		let snapshotRevenue = [];

		snapshots.forEach(snapshot => {
			snapshotTrials.push(snapshot.activeTrials);
			snapshotSubscribers.push(snapshot.activeSubscribers);
			snapshotRevenue.push(snapshot.estimatedMonthlyRevenue / 100);
		});

		if (snapshotRevenue.length === 0) snapshotRevenue = [0];

		this.setState({ snapshotTrials, snapshotSubscribers, snapshotRevenue });
	};

	loadTopListens = async appId => {
		let { topListens } = await API.get(`/admin/listen/${appId}/top`);
		this.setState({ topListens });
	};

	loadTopWatches = async appId => {
		let { topWatches } = await API.get(`/admin/watch/${appId}/top`);
		this.setState({ topWatches });
	};

	loadPlaySnapshot = async appId => {
		let { snapshotListens } = await API.get(`/admin/listen/${appId}/snapshot`);
		let hasVideo = _.get(AppsContainer.getInstance().state.currentApp, 'optionalFeatures.video.enabled');
		if (hasVideo) {
			let { snapshotWatches } = await API.get(`/admin/watch/${appId}/snapshot`);
			let snapshotPlays = [];
			let l = snapshotListens.length;
			for (let i = 0; i < l; i++) {
				snapshotPlays.push(snapshotListens[i] + snapshotWatches[i]);
			}
			this.setState({ snapshotPlays, hasVideo });
		} else {
			this.setState({ snapshotPlays: snapshotListens, hasVideo });
		}
	};

	loadSubscribers = async appId => {
		let { subscribers } = await API.get(`/admin/subscriber/${appId}/all`);
		this.setState({ subscribers });
	};

	loadFreeloaders = async appId => {
		let { freeloaders } = await API.get(`/admin/freeloader/${appId}`);
		this.setState({ freeloaders });
	};

	subscriberTypeChange = e => this.setState({ subscriberType: e.target.value });

	downloadSubscriberCSV = async () => {
		let data = await API.get(
			`${API.baseURL()}/admin/subscriber/${AppsContainer.getInstance().state.currentApp._id}/all/csv`,
		);
		fileDownload(data, `manifest supporters ${moment().format('YYYY-MM-DD')}.csv`);
	};

	downloadFreeloaderCSV = async () => {
		let data = await API.get(
			`${API.baseURL()}/admin/freeloader/${AppsContainer.getInstance().state.currentApp._id}/csv`,
		);
		fileDownload(data, `manifest freeloaders ${moment().format('YYYY-MM-DD')}.csv`);
	};

	loadFrontMe = async appId => {
		const { channelLoan } = await API.get(`/admin/frontme/${appId}`);
		if (channelLoan) {
			const { snapshot } = await API.get(`/admin/dailysnapshot/${appId}/latest`);
			const payoffEstimateInHalfMonths = Math.round((2 * -channelLoan.balance) / snapshot.totalMrr) / 2;
			const payoffEstimateDisplay =
				payoffEstimateInHalfMonths === 1 ? '1 month' : `${payoffEstimateInHalfMonths} months`;
			this.setState({ channelLoan, payoffEstimateDisplay });
		}
	};

	componentDidMount = async () => {
		let {
			_id: appId,
			optionalFeatures: {
				frontMe: { enabled: frontMeEnabled },
				freeloaders: { enabled: freeloadersEnabled },
			},
			frontMe,
		} = await waitForContainer(AppsContainer, 'currentApp');

		await Promise.all([
			await this.loadTopListens(appId),
			await this.loadTopWatches(appId),
			await this.loadDailySnapshots(appId),
			await this.loadPlaySnapshot(appId),
			await this.loadSubscribers(appId),
			await this.loadFreeloaders(appId),
			await this.loadFrontMe(appId),
		]);
		if (frontMeEnabled) {
			this.setState({ loading: false, frontMe, freeloadersEnabled });
		} else {
			this.setState({ loading: false, freeloadersEnabled });
		}
	};

	render = () => {
		return (
			<Container className="page-container theme">
				{this.state.loading ? (
					<ScreenLoader />
				) : (
					<div>
						<Row noGutters className="page-header py-4 d-flex">
							<Col sm="6" md="6" lg="4">
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
									<PageTitle title="Dashboard" subtitle="Data" className="text-sm-left mb-3 p-0" />
								</div>
							</Col>
						</Row>
						<Row>
							<SmallStat label="Trials" data={this.state.snapshotTrials} color={colors.warning} />
							<SmallStat
								label="Supporters"
								data={this.state.snapshotSubscribers}
								color={colors.primary}
							/>
							<SmallStat
								label="Monthly Revenue"
								data={this.state.snapshotRevenue}
								color={colors.success}
								dollarAmount
							/>
							{this.state.frontMe &&
								!this.state.channelLoan &&
								(this.state.frontMe.offerAmount > 0 ? (
									<FrontMeOffer history={this.props.history} frontMe={this.state.frontMe} />
								) : (
									<SmallStat
										label={this.state.hasVideo ? 'Daily Plays' : 'Daily Listens'}
										data={this.state.snapshotPlays}
										color={colors.salmon}
									/>
								))}
							{this.state.frontMe && this.state.channelLoan && (
								<FrontMe
									history={this.props.history}
									channelLoan={this.state.channelLoan}
									payoffEstimateDisplay={this.state.payoffEstimateDisplay}
								/>
							)}
							{!this.state.frontMe && (
								<SmallStat
									label={this.state.hasVideo ? 'Daily Plays' : 'Daily Listens'}
									data={this.state.snapshotPlays}
									color={colors.salmon}
								/>
							)}
						</Row>
						<Row style={{ height: 900 }}>
							<Col lg="8" md="12">
								<Card small className="mb-3">
									<CardHeader className="border-bottom">
										<div className="d-flex flex-row" style={{ alignItems: 'center' }}>
											{this.state.freeloadersEnabled && (
												<FormSelect
													size="lg"
													value={this.state.subscriberType}
													style={{
														maxWidth: 240,
														fontWeight: '400',
														height: 40,
														lineHeight: 1,
													}}
													onChange={this.subscriberTypeChange}>
													<option value={'supporters'}>Supporters</option>
													<option value={'freeloaders'}>Freeloaders</option>
												</FormSelect>
											)}
											{!this.state.freeloadersEnabled && (
												<span style={{ fontSize: 20, fontWeight: '400', lineHeight: 1 }}>
													Supporters
												</span>
											)}
											<a
												href="#"
												onClick={
													this.state.subscriberType === 'supporters'
														? this.downloadSubscriberCSV
														: this.downloadFreeloaderCSV
												}
												className="view-report"
												style={{ flex: 1, textAlign: 'right' }}>
												Export CSV &rarr;
											</a>
										</div>
									</CardHeader>
									<CardBody className="p-0">
										<ReactTable
											columns={
												this.state.subscriberType === 'supporters'
													? supporterColumns
													: freeloaderColumns
											}
											data={
												this.state.subscriberType === 'supporters'
													? this.state.subscribers
													: this.state.freeloaders
											}
											resizable={false}
											usePagination={true}
											style={{ height: 827 }}
										/>
									</CardBody>
								</Card>
							</Col>
							<Col lg="4" md="6" sm="6" className="mb-3 h-100">
								<Card small className="h-100 sc-stats">
									<CardHeader
										className="border-bottom d-flex flex-row"
										style={{ alignItems: 'center' }}>
										<h6 className="m-0" style={{ flex: 1 }}>
											{{ music: 'Top Listens', video: 'Top Views' }[this.state.topType]}
										</h6>
										{this.state.hasVideo && (
											<ButtonGroup style={{ marginTop: 2, marginBottom: 3 }}>
												<Button
													onClick={() => this.setState({ topType: 'music' })}
													theme="white"
													active={this.state.topType === 'music'}>
													Music
												</Button>
												<Button
													onClick={() => this.setState({ topType: 'video' })}
													theme="white"
													active={this.state.topType === 'video'}>
													Video
												</Button>
											</ButtonGroup>
										)}
									</CardHeader>
									<CardBody className="p-0" style={{ overflow: 'scroll' }}>
										{this.state.topType === 'music' &&
											this.state.topListens.map((item, idx) => (
												<div
													key={idx}
													className="px-3 py-2 border-bottom"
													style={{
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
													}}>
													<div
														alt={item.trackTitle}
														className="border rounded"
														style={{
															width: 50,
															height: 50,
															backgroundImage: `url('${item.albumArtUrl}')`,
															backgroundSize: 'cover',
														}}
													/>
													<div
														style={{
															flex: 1,
															marginLeft: 12,
															marginRight: 16,
															textOverflow: 'ellipsis',
															whiteSpace: 'nowrap',
															overflow: 'hidden',
														}}>
														{item.trackTitle}
													</div>
													<div style={{ color: '#818ea3', fontSize: 15 }}>{item.count}</div>
												</div>
											))}
										{this.state.topType === 'video' &&
											this.state.topWatches.map((item, idx) => (
												<div
													key={idx}
													className="px-3 py-2 border-bottom"
													style={{
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
													}}>
													<div
														alt={item.title}
														className="border rounded"
														style={{
															width: 50,
															height: 50,
															backgroundImage: `url('${item.thumbnail}')`,
															backgroundSize: 'cover',
														}}
													/>
													<div
														style={{
															flex: 1,
															marginLeft: 12,
															marginRight: 16,
															textOverflow: 'ellipsis',
															whiteSpace: 'nowrap',
															overflow: 'hidden',
														}}>
														{item.title}
													</div>
													<div style={{ color: '#818ea3', fontSize: 15 }}>{item.count}</div>
												</div>
											))}
									</CardBody>
									<CardFooter className="border-top">
										<Row>
											{/* Time Span */}
											{/* <Col>
												<FormSelect size='sm' value='last-week' style={{ maxWidth: '130px' }} onChange={() => {}}>
													<option value='last-week'>Last Week</option>
													<option value='today'>Today</option>
													<option value='last-month'>Last Month</option>
													<option value='last-year'>Last Year</option>
												</FormSelect>
											</Col> */}

											{/* View Full Report */}
											{/* <Col className='text-right view-report'>
												<a href='#'>View full report &rarr;</a>
											</Col> */}
										</Row>
									</CardFooter>
								</Card>
							</Col>
						</Row>
					</div>
				)}
			</Container>
		);
	};
}
