import React, { Component, useRef, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Button } from 'shards-react';
import AppsContainer from '../../data/AppsContainer';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import 'react-circular-progressbar/dist/styles.css';
import waitForContainer from '../../utils/waitForContainer';
import { Stage, Layer, Text, Image, Rect } from 'react-konva';
import Konva from 'konva';
import useImage from 'use-image';
import * as Vibrant from 'node-vibrant';
import smallLogo from '../../images/manifest/promo/smallNerveLogo.png';
import moment from 'moment';

const downloadURI = (uri, name) => {
	let link = document.createElement('a');
	link.download = name;
	link.href = uri;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

const zoom = number => Math.round(number / window.devicePixelRatio);

const UrlImage = ({ url, x, y, width, height, tint }) => {
	const [image, status] = useImage(url, 'anonymous');
	const ref = useRef();

	useEffect(() => {
		if (tint && ref.current && status === 'loaded') {
			ref.current.filters([Konva.Filters.RGB]);
			ref.current.cache();
			ref.current.red(tint.r);
			ref.current.green(tint.g);
			ref.current.blue(tint.b);
		}
	}, [tint, status, ref]);

	return <Image ref={ref} image={image} x={x} y={y} width={width} height={height} />;
};

const Base = ({ children, fileName, width, height }) => {
	const stage = useRef(null);

	const click = () => {
		try {
			const base64 = stage.current.toDataURL({ pixelRatio: window.devicePixelRatio });
			downloadURI(base64, fileName);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Col lg="3" md="3" sm="6" style={{ margin: 0, padding: 10 }}>
			<Stage ref={stage} width={width} height={height} style={{ pointerEvents: 'none' }}>
				{children}
			</Stage>
			<Button theme="white" size="sm" onClick={click} pill style={{ position: 'absolute', right: 20, top: 20 }}>
				<i className="material-icons">get_app</i>
			</Button>
		</Col>
	);
};

const InstagramOverlay = ({ album, overlay }) => (
	<Base fileName="instagram_post.png" width={zoom(1024)} height={zoom(1024)}>
		<Layer>
			<UrlImage url={album.media.uri + '?r=123'} width={zoom(1024)} height={zoom(1024)} />
			<UrlImage url={overlay} width={zoom(1024)} height={zoom(1024)} />
		</Layer>
	</Base>
);

const ThemedStory = ({ channelName, appCode, album, text, palette, textColor, backgroundColor, frameColor }) =>
	!palette ? null : (
		<Base fileName="instagram_story.png" width={zoom(1080)} height={zoom(1920)}>
			<Layer>
				<Rect width={zoom(1080)} height={zoom(1920)} fill={palette[backgroundColor].hex} />
				<Rect
					x={zoom(124)}
					y={zoom(212 * 2)}
					width={zoom(832)}
					height={zoom(832)}
					fill={palette[frameColor].hex}
				/>
				<UrlImage
					url={album.media.uri + '?r=123'}
					x={zoom(144)}
					y={zoom(222 * 2)}
					width={zoom(792)}
					height={zoom(792)}
				/>
				<UrlImage
					url={smallLogo}
					x={zoom((1080 - 144) / 2)}
					y={zoom(701 * 2)}
					width={zoom(144)}
					height={zoom(98)}
					tint={palette[textColor]}
				/>
				<Text
					text={album.title.toUpperCase()}
					fill={palette[textColor].hex}
					fontSize={zoom(64)}
					fontFamily="Poppins"
					fontStyle="600"
					x={zoom(20)}
					y={zoom(81 * 2)}
					width={zoom(1040)}
					wrap="none"
					ellipsis={true}
					align="center"
				/>
				<Text
					text={channelName.toUpperCase()}
					fill={palette[textColor].hex}
					fontSize={zoom(40)}
					fontFamily="Poppins"
					fontStyle="300"
					x={zoom(20)}
					y={zoom(128 * 2)}
					width={zoom(1040)}
					wrap="none"
					ellipsis={true}
					align="center"
				/>
				<Text
					text={text.toUpperCase()}
					fill={palette[textColor].hex}
					fontSize={zoom(56)}
					fontFamily="Poppins"
					fontStyle="300"
					x={zoom(20)}
					y={zoom(801 * 2)}
					width={zoom(1040)}
					wrap="none"
					ellipsis={true}
					align="center"
				/>
				<Text
					text={`manifestfinancial.com/${appCode}`}
					fill={palette[textColor].hex}
					fontSize={zoom(48)}
					fontFamily="Poppins"
					fontStyle="600"
					x={zoom(20)}
					y={zoom(847 * 2)}
					width={zoom(1040)}
					wrap="none"
					ellipsis={true}
					align="center"
				/>
			</Layer>
		</Base>
	);

export default class NewAlbum extends Component {
	state = {
		appCode: '',
		title: '',
		channelName: '',
		album: null,
		loading: true,
		albumId: null,
		statusText: '',
	};

	componentDidMount = async () => {
		let { appCode, name: channelName } = await waitForContainer(AppsContainer, 'currentApp');

		if (this.props.match.params && this.props.match.params.albumId) {
			let { albumId } = this.props.match.params;
			let albums = await waitForContainer(AppsContainer, 'albums');
			let album = albums.find(item => item._id === albumId);

			let statusText = '';
			if (album.isLive) {
				statusText = 'Listen on Manifest';
			} else if (album.publishDate) {
				statusText = 'Dropping ' + moment(album.publishDate).format('M/D') + ' on Manifest';
			} else {
				statusText = 'Coming Soon on Manifest';
			}

			let v = new Vibrant(album.media.uri + '?r=123', {
				quality: 1,
			});
			v.getPalette((err, palette) =>
				this.setState({
					palette: {
						...palette,
						Black: {
							hex: '#000',
							r: 0,
							g: 0,
							b: 0,
						},
						White: {
							hex: '#FFF',
							r: 255,
							g: 255,
							b: 255,
						},
					},
				}),
			);
			this.setState({
				appCode,
				channelName,
				albumId,
				album,
				statusText,
				loading: false,
			});
		} else {
			this.setState({ loading: false });
		}
	};

	render = () => (
		<Container className="page-container album-promo">
			{this.state.loading ? (
				<ScreenLoader />
			) : (
				<div>
					<Row noGutters className="page-header py-4 d-flex">
						<PageTitle title="Social Media" subtitle="Promotion" className="text-sm-left mb-3 p-0" />
					</Row>
					<Row>
						<Col>
							<Card small className="mb-3">
								<CardHeader className="border-bottom">
									<h6 className="m-0">Instagram</h6>
								</CardHeader>
								<CardBody style={{ padding: 20 }}>
									<p>
										You should always include the link to your Manifest channel in your Instagram
										bio: <b>https://manifestfinancial.com/{this.state.appCode}</b>
									</p>
								</CardBody>
								<div className="content-grid">
									<InstagramOverlay
										album={this.state.album}
										overlay={require('../../images/manifest/promo/listen2.png')}
									/>
									<InstagramOverlay
										album={this.state.album}
										overlay={require('../../images/manifest/promo/new2.png')}
									/>
									<InstagramOverlay
										album={this.state.album}
										overlay={require('../../images/manifest/promo/only2.png')}
									/>
									<InstagramOverlay
										album={this.state.album}
										overlay={require('../../images/manifest/promo/first2.png')}
									/>
									<ThemedStory
										album={this.state.album}
										channelName={this.state.channelName}
										appCode={this.state.appCode}
										text={this.state.statusText}
										palette={this.state.palette}
										textColor="DarkMuted"
										backgroundColor="LightMuted"
										frameColor="LightVibrant"
									/>
									<ThemedStory
										album={this.state.album}
										channelName={this.state.channelName}
										appCode={this.state.appCode}
										text={this.state.statusText}
										palette={this.state.palette}
										textColor="White"
										backgroundColor="Vibrant"
										frameColor="LightVibrant"
									/>
									<ThemedStory
										album={this.state.album}
										channelName={this.state.channelName}
										appCode={this.state.appCode}
										text={this.state.statusText}
										palette={this.state.palette}
										textColor="White"
										backgroundColor="DarkVibrant"
										frameColor="LightVibrant"
									/>
									<ThemedStory
										album={this.state.album}
										channelName={this.state.channelName}
										appCode={this.state.appCode}
										text={this.state.statusText}
										palette={this.state.palette}
										textColor="White"
										backgroundColor="DarkMuted"
										frameColor="LightVibrant"
									/>
								</div>
							</Card>
						</Col>
					</Row>
				</div>
			)}
		</Container>
	);
}
