import React, { Component } from 'react';
import { Container, Row, Col, Nav, Collapse } from 'shards-react';
import AppsContainer from '../../data/AppsContainer';
import ScreenLoader from '../../components/ScreenLoader';
import waitForContainer from '../../utils/waitForContainer';

export default class extends Component {
	componentDidMount = () => {
		//man,
		setTimeout(async () => {
			let { _id: npoId, verified } = await waitForContainer(AppsContainer, 'currentNpo');
			if (verified) {
				this.props.history.push(`/npo/${npoId}/profile`);
			} else {
				this.props.history.push(`/npo/${npoId}/activation-checklist`);
			}
		}, 10);
	};

	render = () => (
		<Collapse className='header-navbar d-lg-flex p-0 bg-white border-top'>
			<Container>
				<Row>
					<Col>
						<Nav tabs className='border-0 flex-column flex-lg-row'>
							<ScreenLoader />;
						</Nav>
					</Col>
				</Row>
			</Container>
		</Collapse>
	);
}
