import React, { useState, useEffect } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	Button,
	ButtonGroup,
	CardHeader,
	Modal,
	ModalBody,
	ModalHeader,
	FormInput,
	FormSelect,
} from 'shards-react';
import { Subscribe } from 'unstated';
import Session from '../../data/Session';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import API from '../../data/API';
import ReactTable from 'react-table';
import SecureMessageDisplay from './components/SecureMessageDisplay';
import UserDisplay from './components/UserDisplay';
import SocialTabDisplay from './components/SocialTabDisplay';
import AddressDisplay from './components/AddressDisplay';
import PersonalInfoDisplay from './components/PersonalInfoDisplay';
import moment from 'moment';
import ReactJson from 'react-json-view';
import AddAuditItem from './components/AddAuditItem';
import RequirePermissions from '../../components/RequirePermissions';

const convertCentToString = value => '$' + (value / 100).toFixed(2);

const auditTypeOptions = [
	{
		value: 'personal',
		label: 'Personal Audits',
	},
	{
		value: 'business',
		label: 'SoleProp Business Audits',
	},
];
const BankingAdminUserDetails = ({ history, match }) => {
	const [currentTab, setCurrentTab] = useState('user');
	const [name, setName] = useState(null);
	const [loading, setLoading] = useState(false);
	const [audit, setAudit] = useState([]);
	const [open, setOpen] = useState(false);
	const [detailsTitle, setDetailsTitle] = useState('');
	const [detailsBody, setDetailsBody] = useState('');
	const [personalInfoVerified, setPersonalInfoVerified] = useState(null);
	const [personalInfo, setPersonalInfo] = useState({});
	const [betaAccess, setBetaAccess] = useState(false);
	const [businesses, setBusinesses] = useState([]);
	const [isLocked, setIsLocked] = useState(false);
	const [isFlaggedForFraud, setIsFlaggedForFraud] = useState(false);
	const [isAccountClosed, setIsAccountClosed] = useState(false);
	const [socialProfiles, setSocialProfiles] = useState([]);
	const [isReadyToDelete, setIsReadyToDelete] = useState(true);
	const [reasons, setReasons] = useState([]);
	// const [aches, setAches] = useState([]);

	const toggle = () => {
		setOpen(!open);
	};

	const tableColumns = [
		{
			id: 'date',
			Header: 'Date',
			accessor: row => moment(row.date).format('MM/DD/YYYY'),
			className: 'text-center d-flex align-items-center',
			maxWidth: 120,
		},

		{
			Header: 'Message',
			accessor: 'message',
			className: 'text-center d-flex align-items-center',
			// maxWidth: 120,
		},
		{
			Header: 'Action Taken By',
			accessor: 'actionTakenBy',
			className: 'text-center d-flex align-items-center',
			// maxWidth: 120,
		},
		{
			Header: 'Detail Type',
			accessor: 'detailType',
			className: 'text-center d-flex align-items-center',
			// maxWidth: 120,
		},
		{
			Header: '',
			accessor: 'payload',
			className: 'text-center d-flex align-items-center',
			maxWidth: 100,
			Cell: row => (
				<div>
					{(row.original.payload || row.original.comment) && row.original.detailType !== 'personalInfo' ? (
						<span
							onClick={() => {
								setDetailsTitle(row.original.detailType);
								setDetailsBody({
									comment: row.original.comment,
									payload: row.original.payload,
								});
								toggle();
							}}
							style={{
								cursor: 'pointer',
								textDecorationLine: 'underline',
							}}>
							Details
						</span>
					) : null}
				</div>
			),
		},
	];

	const grantAccess = async () => {
		if (loading) {
			return;
		}
		setLoading(true);
		await API.post(`/banking-admin/user/grant-beta-access/`, {
			userId: match.params.userId,
		});
		await refreshPage();

		setLoading(false);
	};
	const removeAccess = async () => {
		if (loading) {
			return;
		}
		setLoading(true);
		await API.post(`/banking-admin/user/remove-beta-access/`, {
			userId: match.params.userId,
		});
		await refreshPage();

		setLoading(false);
	};

	const getPersonalAudits = async () => {
		setLoading(true);
		const response = await API.get(`/banking-admin/user/audits/personal/${match.params.userId}`);
		if (response.audits) {
			setAudit(response.audits);
		}
		setLoading(false);
	};

	const getPlatformProfiles = async () => {
		setLoading(true);
		const response = await API.get(`/banking-admin/phyllo/${match.params.userId}/profiles`);
		if (response) {
			setSocialProfiles(response);
		}
		setLoading(false);
	};

	const refreshPage = async () => {
		setLoading(true);

		const response = await API.get(`/banking-admin/user/details/${match.params.userId}`);
		const {
			businesses,
			nickname,
			personalInfoVerified,
			personalInfo,
			betaAccess,
			isLocked,
			isFlaggedForFraud,
			isAccountClosed,
			isReadyToDelete,
			reasons,
		} = response.data;

		// const { transfers } = await API.get(`/banking-admin/fraudCenter/ach-transfers-by-user/${match.params.userId}/`);
		setBusinesses(businesses);
		setBetaAccess(betaAccess);

		// setAches(transfers);

		setPersonalInfo(personalInfo);
		setCurrentTab('user');
		setName(nickname);
		setPersonalInfoVerified(personalInfoVerified);
		setIsLocked(isLocked);
		setIsFlaggedForFraud(isFlaggedForFraud);
		setIsAccountClosed(isAccountClosed);
		setIsReadyToDelete(isReadyToDelete);
		setReasons(reasons);
		getPlatformProfiles();

		setLoading(false);
	};

	useEffect(() => {
		(async () => {
			await refreshPage();
		})();
	}, []);

	return (
		<Container className="page-container">
			<Subscribe to={[Session]}>
				{({ state: { user } }) => {
					if (!user) {
						return <ScreenLoader />;
					} else {
						return (
							<div>
								<Modal open={open} toggle={toggle}>
									<ModalHeader>{detailsTitle}</ModalHeader>
									{detailsBody.comment ? (
										<ModalBody className="border-bottom">
											User comment:{' '}
											<span
												style={{
													fontWeight: 300,
												}}>
												{detailsBody.comment}
											</span>
										</ModalBody>
									) : null}
									<ModalBody>
										<ReactJson
											src={detailsBody.payload}
											theme="shapeshifter:inverted"
											collapsed={1}
											displayDataTypes={false}
											displayObjectSize={false}
											indentWidth={2}
											enableClipboard={false}
											name="data"
										/>
										{/* <pre>{detailsBody}</pre> */}
									</ModalBody>
								</Modal>
								<Row noGutters className="page-header py-4 d-flex justify-content-between">
									<PageTitle
										title={name || 'Customer'}
										subtitle="ALL CUSTOMERS"
										className="text-sm-left mb-3 p-0"
										subtitleOnClick={() => history.push(`/profile/banking-admin/`)}
									/>

									<Col xs="5" className="col d-flex align-items-center">
										<ButtonGroup size="sm" className="d-inline-flex mb-3 mb-sm-0 mx-auto">
											{/* {checking ? (
												<Button
													onClick={() => setCurrentTab('checking')}
													active={currentTab === 'checking'}
													theme="white">
													Checking
												</Button>
											) : null}
											{savings ? (
												<Button
													onClick={() => setCurrentTab('savings')}
													active={currentTab === 'savings'}
													theme="white">
													Savings
												</Button>
											) : null} */}
											<Button
												onClick={() => setCurrentTab('user')}
												active={currentTab === 'user'}
												theme="white">
												User
											</Button>
											<RequirePermissions permissions={['user.read']}>
												<Button
													onClick={() => {
														setCurrentTab('social');
													}}
													active={currentTab === 'social'}
													theme="white">
													Social ({socialProfiles.length})
												</Button>
											</RequirePermissions>
											<RequirePermissions permissions={['user.read', 'message.read']}>
												<Button
													onClick={() => setCurrentTab('messages')}
													active={currentTab === 'messages'}
													theme="white">
													Messages
												</Button>
											</RequirePermissions>
											<RequirePermissions permissions={['user.read', 'pii.read']}>
												<Button
													onClick={() => setCurrentTab('personalInfo')}
													active={currentTab === 'personalInfo'}
													theme="white">
													Personal Info
												</Button>
											</RequirePermissions>
											<RequirePermissions permissions={['user.read']}>
												<Button
													onClick={() => setCurrentTab('address')}
													active={currentTab === 'address'}
													theme="white">
													Address
												</Button>
											</RequirePermissions>
											<RequirePermissions permissions={['user.read', 'audit.read']}>
												<Button
													onClick={() => {
														setCurrentTab('audit');
														getPersonalAudits();
													}}
													active={currentTab === 'audit'}
													theme="white">
													Audit
												</Button>
											</RequirePermissions>
										</ButtonGroup>
									</Col>
								</Row>

								{loading ? (
									<ScreenLoader />
								) : (
									<>
										<div style={{ marginBottom: 26 }}>
											{currentTab === 'user' ? (
												<UserDisplay
													businesses={businesses}
													history={history}
													isAccountClosed={isAccountClosed}
													isFlaggedForFraud={isFlaggedForFraud}
													isLocked={isLocked}
													isReadyToDelete={isReadyToDelete}
													onRefresh={refreshPage}
													userId={match.params.userId}
													reasons={reasons}
													// aches={aches}
												/>
											) : null}
											{currentTab === 'social' ? (
												<SocialTabDisplay profiles={socialProfiles} />
											) : null}
										</div>
										{currentTab === 'messages' ? (
											<RequirePermissions permissions={['user.read', 'message.read']}>
												<SecureMessageDisplay userId={match.params.userId} />
											</RequirePermissions>
										) : null}
										{currentTab === 'personalInfo' ? (
											<RequirePermissions permissions={['user.read', 'pii.read']}>
												<PersonalInfoDisplay
													created={personalInfoVerified !== null}
													verified={personalInfoVerified}
													userId={match.params.userId}
													personalInfoBeforeVerified={personalInfo}
												/>
											</RequirePermissions>
										) : null}
										{currentTab === 'address' ? (
											<RequirePermissions permissions={['user.read']}>
												<AddressDisplay userId={match.params.userId} />
											</RequirePermissions>
										) : null}
										{currentTab === 'audit' ? (
											<RequirePermissions permissions={['user.read', 'audit.read']}>
												<Row>
													<Col lg="12" md="12">
														<Card small className="mb-3">
															<CardHeader className="border-bottom">
																<Row
																	style={{
																		justifyContent: 'space-between',
																		padding: '0px 15px',
																	}}>
																	<h6 className="m-0">Audit Trail</h6>
																	<AddAuditItem
																		type="user"
																		id={match.params.userId}
																		onRefresh={async () => {
																			await refreshPage();
																			setCurrentTab('audit');
																			await getPersonalAudits();
																		}}
																	/>
																</Row>
															</CardHeader>

															<CardBody className="p-0">
																<ReactTable
																	getTdProps={() => ({
																		style: {
																			borderLeft: `none`,
																			borderRight: `none`,
																		},
																	})}
																	columns={tableColumns}
																	data={audit}
																	resizable={false}
																	usePagination={true}
																/>
															</CardBody>
														</Card>
													</Col>
												</Row>
											</RequirePermissions>
										) : null}
									</>
								)}
							</div>
						);
					}
				}}
			</Subscribe>
		</Container>
	);
};

export default BankingAdminUserDetails;
