import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'shards-react';
import { Subscribe } from 'unstated';
import Session from '../../data/Session';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import API from '../../data/API';
import waitForContainer from '../../utils/waitForContainer';

let clientId;
if (window.location.hostname === 'localhost') {
	clientId = `ca_FhGUS3KpRgXNc8xoLOfioKdQhrMc4bQE`;
} else {
	clientId = `ca_FhGUzpeXMeLekeHqKzGIsLjWbjJfAPn1`;
}

export default class extends Component {
	submit = () => {
		var windowReference = window.open('', 'stripeConnection');
		this.submitAsync(windowReference);
	};

	submitAsync = async windowReference => {
		let user = await waitForContainer(Session, 'user');

		try {
			this.setState({ loading: true });
			let { csrfToken } = await API.post(`/admin/bank/stripe-connection-init`);
			let isDev = window.location.hostname === 'localhost';

			let returnUrl = isDev
				? encodeURIComponent(`http://localhost:3002/profile/bank/complete`)
				: encodeURIComponent(`https://admin.manifestfinancial.com/profile/bank/complete`);
			let url = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${returnUrl}&client_id=${clientId}&state=${csrfToken}&stripe_user[email]=${encodeURIComponent(
				user.email,
			)}`;

			Session.getInstance().refreshUser();
			windowReference.location = url;
		} catch (e) {
			this.setState({ loading: false });
			alert(`Oops! ${e.message}`);
		}
	};

	linkToExpress = () => {
		var windowReference = window.open('', 'expressDashboard');
		this.linkToExpressAsync(windowReference);
	};

	linkToExpressAsync = async windowReference => {
		let { url } = await API.get(`/admin/bank/stripe-express-link`);
		windowReference.location = url;
	};

	render = () => (
		<Container className="page-container">
			<Row noGutters className="page-header py-4">
				<PageTitle title="Connect My Bank" subtitle="Payments" className="text-sm-left mb-3" />
			</Row>
			<Row>
				<Col>
					<Subscribe to={[Session]}>
						{({ state: { user } }) => {
							if (!user) {
								return <ScreenLoader />;
							} else if (user.stripe && user.stripe.accountId) {
								return (
									<Card>
										<CardBody
											className="d-flex"
											style={{
												flexDirection: 'column',
												alignItems: 'center',
												justifyContent: 'center',
												paddingTop: 120,
												paddingBottom: 120,
												textAlign: 'center',
											}}>
											<h4>You've connected your bank account.</h4>
											<p>
												Need to make changes?{' '}
												<a href="mailto:support@manifestfinancial.com">Email us.</a>
											</p>
										</CardBody>
									</Card>
								);
							} else {
								return (
									<Card>
										<CardBody
											className="d-flex"
											style={{
												flexDirection: 'column',
												alignItems: 'center',
												justifyContent: 'center',
												paddingTop: 120,
												paddingBottom: 120,
												paddingLeft: '15%',
												paddingRight: '15%',
												textAlign: 'center',
											}}>
											<h4>
												Grab your debit card or checkbook.
												<br />
												Also have your business tax ID (EIN) or SSN ready.
											</h4>
											<p>
												You'll give this info directly to Stripe after clicking below. Stripe
												handles payments and tax documents for Manifest — just like they do for
												over 100k businesses including Amazon, Shopify, Uber, Lyft, and Zoom.
											</p>
											<img
												onClick={this.submit}
												alt="Connect to Stripe"
												src={require('../../images/manifest/connectStripeButton.png')}
												style={{ width: 220, marginTop: 10 }}
											/>
										</CardBody>
									</Card>
								);
							}
						}}
					</Subscribe>
				</Col>
			</Row>
		</Container>
	);
}
