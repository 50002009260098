import { Container } from 'unstated';
import API from '../data/API';

export default class AppsContainer extends Container {
	static _instance = null;

	state = {
		apps: null,
		npos: null,
		currentAppCode: null,
		currentApp: null,
		albums: null,
		videos: null,
		posts: null,
		products: null,
		needsUpdate: false,
	};

	static getInstance = () => {
		return AppsContainer._instance;
	};

	constructor() {
		super();
		AppsContainer._instance = this;
		this.refresh();
	}

	refresh = async () => {
		let { apps, npos } = await API.get(`/admin/apps`);
		this.setState({ apps, npos, needsUpdate: true }, () => {
			this.updateCurrentApp();
			this.updateCurrentNpo();
		});
	};

	clear = () => {
		this.setState({
			apps: null,
			npos: null,
			currentAppCode: null,
			currentNpoId: null,
			currentApp: null,
			albums: null,
			posts: null,
			products: null,
			needsUpdate: false,
		});
	};

	setCurrentAppCode = currentAppCode => {
		this.setState({ currentAppCode }, this.updateCurrentApp);
	};

	setCurrentNpoId = currentNpoId => {
		this.setState({ currentNpoId }, this.updateCurrentNpo);
	};

	clearCurrentNpo = () => {
		this.setState({ currentNpoId: null, currentNpo: null });
	};

	clearCurrentApp = () => {
		this.setState({
			currentAppCode: null,
			currentApp: null,
			albums: null,
			videos: null,
			posts: null,
			products: null,
		});
	};

	updateCurrentNpo = async () => {
		if (!this.state.npos) return;
		let currentNpo = this.state.npos.find(item => item._id === this.state.currentNpoId);
		this.setState({ currentNpo });
	};

	updateCurrentApp = async () => {
		if (!this.state.apps) return;
		let currentApp = this.state.apps.find(item => item.appCode === this.state.currentAppCode);
		if (
			this.state.needsUpdate ||
			!this.state.currentApp ||
			!currentApp ||
			currentApp._id !== this.state.currentApp._id
		) {
			//the app is actually changing
			this.setState({ currentApp });
			if (currentApp) {
				await Promise.all([
					await (async () => {
						let { albums } = await API.get(`/admin/album/${currentApp._id}`);
						this.setState({ albums });
					})(),
					await (async () => {
						let { videos } = await API.get(`/admin/video/${currentApp._id}`);
						this.setState({ videos });
					})(),
					await (async () => {
						let { posts } = await API.get(`/admin/${currentApp._id}/posts`);
						this.setState({ posts });
					})(),
					await (async () => {
						let { products } = await API.get(`/admin/product/${currentApp._id}`);
						this.setState({ products });
					})(),
				]);
			} else {
				this.setState({ albums: null, videos: null, posts: null, products: null });
			}
		}
	};
}
