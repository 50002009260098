import React from "react";
import { Container, Row, Col } from "shards-react";

const BlankLayout = ({ children }) => (
	<Container fluid>
		<Row>
			<Col className="main-content col" tag="main">
				{children}
			</Col>
		</Row>
	</Container>
);

export default BlankLayout;
