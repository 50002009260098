import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Badge } from 'shards-react';
import AppsContainer from '../../data/AppsContainer';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import waitForContainer from '../../utils/waitForContainer';
import { NavLink, Link } from 'react-router-dom';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import API from '../../data/API';
import Tip from '../../components/Tip';
import moment from 'moment';

const AlbumItem = ({ item }) => (
	<Col lg='4' md='4' sm='4' style={{ margin: 0, padding: 10 }}>
		<Link to={`albums/${item._id}`}>
			<Card small className='album-card'>
				<div className='album-art' style={{ backgroundImage: `url('${item.media.uri}')` }}>
					{!item.isLive && (
						<Badge pill theme='dark'>
							{item.publishDate ? moment(item.publishDate).format('M/D/YY @ h:mm a') : 'Draft'}
						</Badge>
					)}
					<h3>{item.title}</h3>
				</div>
			</Card>
		</Link>
	</Col>
);

const SortableAlbumItem = SortableElement(AlbumItem);

const AlbumGrid = ({ albums, sortable }) => (
	<Card small className='mb-3 content-grid' style={{ overflow: 'auto' }}>
		{albums.length === 0 ? (
			<div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', height: 300 }}>
				No Albums
			</div>
		) : (
			albums.map((item, index) =>
				sortable ? (
					<SortableAlbumItem item={item} index={index} key={index} />
				) : (
					<AlbumItem item={item} index={index} key={index} />
				)
			)
		)}
	</Card>
);

const SortableAlbumGrid = SortableContainer(({ albums }) => <AlbumGrid albums={albums} sortable={true} />);

export default class Albums extends Component {
	state = {
		loading: true,
		live: [],
		drafts: [],
	};

	componentDidMount = async () => {
		let albums = await waitForContainer(AppsContainer, 'albums');
		let live = albums.filter(item => item.isLive);
		let drafts = albums
			.filter(item => !item.isLive)
			.sort((a, b) => {
				if (!b.publishDate && a.publishDate) return 1;
				if (!a.publishDate && b.publishDate) return -1;
				if (!a.publishDate && !b.publishDate) return 0;
				return new Date(b.publishDate) - new Date(a.publishDate);
			});
		this.setState({ live, drafts, loading: false });
	};

	onSortEnd = ({ oldIndex, newIndex }) => {
		this.setState(({ live, drafts }) => {
			live = arrayMove(live, oldIndex, newIndex);
			let appId = AppsContainer.getInstance().state.currentApp._id;
			let albumIds = drafts.map(item => item._id).concat(live.map(item => item._id));
			API.put(`/admin/album/${appId}/sort`, { albumIds });
			return { live };
		});
	};

	render = () => {
		return (
			<Container className='page-container albums'>
				{this.state.loading ? (
					<ScreenLoader />
				) : (
					<>
						{this.state.drafts.length > 0 && (
							<div>
								<Row noGutters className='page-header py-4 d-flex'>
									<PageTitle title='Drafts' subtitle='Audio' className='text-sm-left mb-3' />
								</Row>
								<Row>
									<Col lg='9' md='12'>
										<AlbumGrid albums={this.state.drafts} />
									</Col>
									{this.state.drafts.length > 0 && (
										<Col lg='3' md='12'>
											<Card small>
												<CardBody>
													<Row>
														<Col sm='12'>
															<Button
																theme='accent'
																size='sm'
																style={{ width: '100%' }}
																tag={NavLink}
																to='new-album'>
																+ Add Album
															</Button>
														</Col>
													</Row>
												</CardBody>
											</Card>
										</Col>
									)}
								</Row>
							</div>
						)}
						{this.state.live.length >= 0 && (
							<div>
								<Row noGutters className='page-header py-4 d-flex'>
									<Col sm='6' md='6' lg='4'>
										<PageTitle title='Audio' subtitle='Media' className='text-sm-left mb-3 p-0' />
									</Col>
									<Col
										sm='6'
										md='6'
										lg='5'
										className='d-none d-sm-flex'
										style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
										<Tip text='Drag and drop to reorder albums' />
									</Col>
									<Col
										sm='6'
										md='6'
										lg='5'
										className='d-flex d-sm-none'
										style={{ alignItems: 'flex-end', justifyContent: 'center', display: 'none' }}>
										<Tip text='Drag and drop to reorder albums' />
									</Col>
								</Row>
								<Row>
									<Col lg='9' md='12'>
										<SortableAlbumGrid
											albums={this.state.live}
											axis='xy'
											pressDelay={200}
											helperClass='sortableHelper'
											useDragHandle={false}
											onSortEnd={this.onSortEnd}
										/>
									</Col>
									{this.state.drafts.length === 0 && (
										<Col lg='3' md='12'>
											<Card small>
												<CardBody>
													<Row>
														<Col sm='12'>
															<Button
																theme='accent'
																size='sm'
																style={{ width: '100%' }}
																tag={NavLink}
																to='new-album'>
																+ Add Album
															</Button>
														</Col>
													</Row>
												</CardBody>
											</Card>
										</Col>
									)}
								</Row>
							</div>
						)}
					</>
				)}
			</Container>
		);
	};
}
