import React, { Component } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Button, FormSelect } from 'shards-react';
import AppsContainer from '../../data/AppsContainer';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import API from '../../data/API';
import waitForContainer from '../../utils/waitForContainer';
import Session from '../../data/Session';
import produce from 'immer';
import _ from 'lodash';

const NotificationBlock = ({ adminNotificationPrefs, app, component }) => {
	const channelSetupReminderFrequency = adminNotificationPrefs.channelSetupReminder
		? adminNotificationPrefs.channelSetupReminder.frequency
		: 'weekly';
	const dailySnapshotEnabled = adminNotificationPrefs.dailySnapshot
		? adminNotificationPrefs.dailySnapshot.enabled
			? 'on'
			: 'off'
		: 'on';
	const showChannelSetupReminder = !app.verified;

	return (
		<Card small className='mb-3'>
			<CardHeader className='border-bottom'>
				<h6 className='m-0'>{app.name}</h6>
			</CardHeader>
			<CardBody>
				{showChannelSetupReminder && (
					<>
						<Row className='px-3'>
							<span className='text-semibold text-fiord-blue'>Pre-Launch Checklist Reminders</span>
						</Row>
						<Row className='px-3'>
							<span className='text-muted small'>
								Friendly email reminders to finish setting up your channel.
							</span>
						</Row>
						<FormSelect
							size='sm'
							value={channelSetupReminderFrequency}
							onChange={event =>
								component.onAppFieldChange(event.target.value, app._id, 'channelSetupReminder.frequency')
							}
							style={{ maxWidth: 200, marginBottom: 12, marginTop: 4 }}>
							<option value='weekly'>Weekly</option>
							<option value='biweekly'>Bi-Weekly</option>
							<option value='monthly'>Monthly</option>
							<option value='off'>Off</option>
						</FormSelect>
					</>
				)}
				<Row className='px-3'>
					<span className='text-semibold text-fiord-blue'>Daily Activity Snapshots</span>
				</Row>
				<Row className='px-3'>
					<span className='text-muted small'>Quick email of your projected earnings.</span>
				</Row>
				<FormSelect
					size='sm'
					value={dailySnapshotEnabled}
					onChange={event =>
						component.onAppFieldChange(event.target.value === 'on', app._id, 'dailySnapshot.enabled')
					}
					style={{ maxWidth: 200, marginBottom: 12, marginTop: 4 }}>
					<option value='on'>On</option>
					<option value='off'>Off</option>
				</FormSelect>
			</CardBody>
		</Card>
	);
};

export default class Notifications extends Component {
	state = {
		loading: true,
		saveEnabled: true,
		channelSetupReminderFrequency: 'weekly',
		dailySnapshotEnabled: 'on',
	};

	componentDidMount = async () => {
		let apps = await waitForContainer(AppsContainer, 'apps');
		let { appConfigs } = await waitForContainer(Session, 'user');

		let appAdminNotificationPrefs = [];
		appConfigs.forEach(({ adminNotificationPrefs, app: appId }) => {
			const app = apps.find(app => appId === app._id);
			if (app) appAdminNotificationPrefs.push({ app, adminNotificationPrefs });
		});

		this.setState(
			{
				appAdminNotificationPrefs,
				loading: false,
			},
			this.updateValidation
		);
	};

	submit = async () => {
		let { saveEnabled, appAdminNotificationPrefs } = this.state;

		if (!saveEnabled) return;

		try {
			this.setState({ loading: true });

			appAdminNotificationPrefs = appAdminNotificationPrefs.map(
				({ app: { _id: appId }, adminNotificationPrefs }) => ({
					appId,
					adminNotificationPrefs,
				})
			);

			await API.post(`/admin/notifications/`, {
				appAdminNotificationPrefs,
			});

			await Session.getInstance().refreshUser();
			setTimeout(() => this.componentDidMount(), 500);
			// this.props.history.push(`/dashboard`);
		} catch (e) {
			this.setState({ loading: false });
			alert(`Oops! ${e.message}`);
		}
	};

	isValid = () => {
		return true;
	};

	updateValidation = () => {
		this.setState({ saveEnabled: this.isValid() });
	};

	onAppFieldChange = (value, appId, path) => {
		const appAdminNotificationPrefs = produce(this.state.appAdminNotificationPrefs, draft => {
			const index = draft.findIndex(prefs => prefs.app._id === appId);
			if (index > -1) {
				_.set(draft[index].adminNotificationPrefs, path, value);
			}
		});
		this.setState({ appAdminNotificationPrefs }, this.updateValidation);
	};

	onKeyUp = event => {
		if (event.key === 'Enter') {
			this.submit();
		}
	};

	render = () => {
		return (
			<Container className='page-container theme'>
				{this.state.loading ? (
					<ScreenLoader />
				) : (
					<div>
						<Row noGutters className='page-header py-4'>
							<PageTitle title='My&nbsp;Notifications' subtitle='Connect' className='text-sm-left mb-3' />
						</Row>
						<Row>
							{this.state.appAdminNotificationPrefs.length === 0 && (
								<Col lg='12' md='12'>
									<Card small className='mb-3 content-grid' style={{ overflow: 'auto' }}>
										<div
											style={{
												display: 'flex',
												flex: 1,
												alignItems: 'center',
												justifyContent: 'center',
												paddingTop: 120,
												paddingBottom: 120,
											}}>
											You'll manage your channel notifications here.
										</div>
									</Card>
								</Col>
							)}
							{this.state.appAdminNotificationPrefs.length > 0 && (
								<>
									<Col lg='9' md='12'>
										{this.state.appAdminNotificationPrefs.map(({ app, adminNotificationPrefs }) => {
											return (
												app && (
													<NotificationBlock
														key={app._id}
														adminNotificationPrefs={adminNotificationPrefs}
														app={app}
														component={this}
													/>
												)
											);
										})}
									</Col>
									<Col lg='3' md='12'>
										<Card small>
											<CardBody>
												<Row>
													<Col sm='12'>
														<Button
															theme='accent'
															size='sm'
															style={{ width: '100%' }}
															disabled={!this.state.saveEnabled}
															onClick={this.submit}>
															Save
														</Button>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
								</>
							)}
						</Row>
					</div>
				)}
			</Container>
		);
	};
}
