import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, FormInput, Button } from 'shards-react';
import API from '../../data/API';

export default class Register extends Component {
	state = {
		email: '',
		submitEnabled: false,
		loading: false,
	};

	isValid = () => {
		let { email } = this.state;
		return /\S+@\S+\.\S+/.test(email);
	};

	submit = async () => {
		let { email, submitEnabled } = this.state;

		if (!submitEnabled) return;

		this.setState({ loading: true });

		try {
			await API.post(`/auth/forgot-password`, { email });
			this.props.history.push(`/reset-password?email=${encodeURIComponent(email)}`);
		} catch (e) {
			alert(`Oops! ${e.message}`);
			this.setState({ loading: false });
		}
	};

	onFieldChange = (event, field) => {
		let newState = {};
		newState[field] = event.target.value;
		this.setState(newState, () => {
			this.setState({ submitEnabled: this.isValid() });
		});
	};

	onKeyUp = event => {
		if (event.key === 'Enter') {
			this.submit();
		}
	};

	render = () => (
		<Container fluid className="main-content-container h-100 px-4">
			<Row noGutters className="h-100">
				<Col lg="3" md="5" className="auth-form mx-auto my-auto">
					<Card>
						<CardHeader>
							<img
								className="auth-form__logo d-table mx-auto mb-3"
								src={require('../../images/manifest/logo.png')}
								alt="Manifest"
							/>
							<h5 className="auth-form__title text-center mb-4">Forgot Your Password?</h5>
						</CardHeader>
						<CardBody>
							<Form onSubmit={e => e.preventDefault()}>
								<FormGroup>
									<label htmlFor="emailInput">Email address</label>
									<FormInput
										autoFocus
										id="emailInput"
										type="email"
										autoComplete="email"
										value={this.state.email}
										onChange={event => this.onFieldChange(event, 'email')}
										onKeyUp={this.onKeyUp}
									/>
									<small className="form-text text-muted text-center">
										We'll send you a code to reset your password.
									</small>
								</FormGroup>
								<Button
									pill
									theme="accent"
									className="d-table mx-auto"
									onClick={this.submit}
									disabled={!this.state.submitEnabled}>
									Send Code
								</Button>
							</Form>
						</CardBody>
					</Card>

					{/* Meta Details */}
					<div className="auth-form__meta d-flex mt-4">
						<Link to="/login" className="mx-auto">
							Take me back to login.
						</Link>
					</div>
				</Col>
			</Row>
		</Container>
	);
}
