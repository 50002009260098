import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Button, FormInput, FormTextarea } from 'shards-react';
import AppsContainer from '../../data/AppsContainer';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import API from '../../data/API';
import waitForContainer from '../../utils/waitForContainer';
import ImageDropzone from '../../components/ImageDropzone';

let descriptionMaxCharacters = 280;

export default class Profile extends Component {
	state = {
		loading: true,
		description: '',
		url: '',
		logo: null,
		existingLogo: null,
	};

	componentDidMount = async () => {
		let npos = await waitForContainer(AppsContainer, 'npos');
		let npo = npos.find(npo => npo._id === this.props.match.params.npo);

		let { description = '', url = '', logo: existingLogo } = npo.profile || {};

		this.setState(
			{
				description,
				url,
				existingLogo,
				saveEnabled: false,
				loading: false,
			},
			this.updateValidation
		);
	};

	isValid = () => {
		let { description, url, existingLogo, logo } = this.state;
		return description.length > 0 && url.length > 0 && (existingLogo || logo);
	};

	updateValidation = () => {
		this.setState({ saveEnabled: this.isValid() });
	};

	submit = async () => {
		let { description, url, logo } = this.state;

		try {
			this.setState({ loading: true });

			let formData = new FormData();
			formData.append('description', description);
			formData.append('url', url);
			if (logo) formData.append('logo', logo);
			await API.multipartPost(`/admin/npo/${this.props.match.params.npo}/profile`, formData);

			await AppsContainer.getInstance().refresh();
			this.props.history.push(`/npo/${this.props.match.params.npo}`);
		} catch (e) {
			this.setState({ loading: false });
			alert(`Oops! ${e.message}`);
		}
	};

	onFieldChange = (event, field) => {
		let newState = {};
		newState[field] = event.target.value;
		this.setState(newState, this.updateValidation);
	};

	onImageChange = (file, field) => {
		let newState = {};
		newState[field] = file;
		this.setState(newState, this.updateValidation);
	};

	render = () => {
		let descriptionCharactersLeft =
			this.state.description !== undefined ? descriptionMaxCharacters - this.state.description.length : 0;

		return (
			<Container className='page-container npo-profile'>
				{this.state.loading ? (
					<ScreenLoader />
				) : (
					<div>
						<Row noGutters className='page-header py-4'>
							<PageTitle title='Nonprofit Profile' subtitle='Setup' className='text-sm-left mb-3' />
						</Row>
						<Row>
							<Col lg='9' md='12'>
								<Card small className='mb-3'>
									{/* <CardHeader className="border-bottom d-flex" style={{ alignItems: 'baseline' }}>
									<h6 className="m-0" style={{ flex: 1 }}>Public Info</h6>
								</CardHeader> */}
									<CardBody style={{ padding: 30 }}>
										<Row className='px-3'>
											<span className='text-semibold text-fiord-blue'>URL</span>
										</Row>
										<FormInput
											size='lg'
											type='url'
											className='mb-3'
											placeholder='URL'
											value={this.state.url}
											onChange={event => this.onFieldChange(event, 'url')}
											onKeyUp={this.onKeyUp}
										/>
										<Row className='px-3'>
											<span className='text-semibold text-fiord-blue'>Description</span>
											<span
												style={{
													flex: 1,
													textAlign: 'right',
													color: descriptionCharactersLeft < 10 && 'red',
												}}>
												{descriptionCharactersLeft} characters remaining
											</span>
										</Row>
										<fieldset>
											<FormTextarea
												style={{ marginTop: 8, marginBottom: 0 }}
												id='description'
												maxLength={descriptionMaxCharacters}
												placeholder={`${descriptionMaxCharacters} character max`}
												size='lg'
												className='mb-3'
												rows='4'
												value={this.state.description}
												onChange={event => this.onFieldChange(event, 'description')}
											/>
										</fieldset>
									</CardBody>
								</Card>
							</Col>
							<Col lg='3' md='12'>
								<Card small>
									<div className='logo-art'>
										<ImageDropzone
											label='Drop logo artwork here'
											forceWidth={480}
											forceHeight={240}
											method='fit'
											defaultImage={this.state.existingLogo}
											onFileSelect={file => this.onImageChange(file, 'logo')}
											accept='.png'
										/>
									</div>
									<CardBody>
										<Row>
											<Col sm='12'>
												<Button
													theme='accent'
													size='sm'
													style={{ width: '100%' }}
													disabled={!this.state.saveEnabled}
													onClick={this.submit}>
													Save
												</Button>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</div>
				)}
			</Container>
		);
	};
}
