import React, { useState, useEffect } from 'react';
import {
	FormInput,
	FormTextarea,
	Row,
	Col,
	Card,
	CardBody,
	CardHeader,
	Button,
	Form,
	FormSelect,
	FormCheckbox,
	Modal,
	ModalBody,
	ModalHeader,
	FormRadio,
} from 'shards-react';
import API from '../../../data/API';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import Tip from '../../../components/Tip';
import colors from '../../../utils/colors';
import { COUNTRY_OPTIONS } from '../../../utils/constants';

const formatBusinessInfo = (field, value, option = { reverse: false, countryIndex: 1 }) => {
	const { reverse, countryIndex } = option;
	let _value = value;

	switch (field) {
		case 'country':
			return _value.toUpperCase();
		case 'postalCode':
			if (countryIndex === 1) {
				_value = _value.replace(/[\D]+/gi, '');
				if (_value.length > 5) {
					_value = _value.replace(/(\d{5})(\d+)/, '$1');
				}
			}
			return _value;
		case 'tin':
			if (reverse) {
				return _value.replace(/\D+/, '');
			}

			if (countryIndex === 1) {
				_value = _value.replace(/[\D]+/gi, '');
				_value = _value.replace(/(\d{2})(\d+)/, '$1-$2');
			} else if (countryIndex === 0) {
				_value = _value.replace(/[\D]+/gi, '');
				if (_value.length >= 4 && _value.length < 7) {
					_value = _value.replace(/(\d{3})(\d+)/, '$1-$2');
				} else if (_value.length < 10) {
					_value = _value.replace(/(\d{3})(\d{3})(\d+)/, '$1-$2-$3');
				} else {
					_value = _value.replace(/(\d{3})(\d{3})(\d{3})[\d\D]+/, '$1-$2-$3');
				}
			}
			return _value;

		default:
			return _value;
	}

	// switch (field) {
	// 	// case 'citizenship':
	// 	// 	return _value.toUpperCase();

	// 	default:
	// 		return _value;
	// }
};

const BusinessInfoDisplay = ({
	verified = false,
	businessProfileId = '',
	businessInfoBeforeVerified = {
		citizenship: '',
		dateOfBirth: '',
		firstName: '',
		lastName: '',
		streetLine1: '',
		streetLine2: '',
		city: '',
		state: '',
		country: '',
		postalCode: '',
		tin: '',
		emailAddress: '',
		phoneNumber: '',
		mailingStreetLine1: '',
		mailingStreetLine2: '',
		mailingCity: '',
		mailingState: '',
		mailingPostalCode: '',
	},
	created = false,
}) => {
	const [businessInfo, setBusinessInfo] = useState({
		establishedOn: businessInfoBeforeVerified.establishedOn,
		incorporationState: businessInfoBeforeVerified.incorporationState,
		legalStructure: businessInfoBeforeVerified.legalStructure,
		businessName: businessInfoBeforeVerified.businessName,
		businessPhone: businessInfoBeforeVerified.businessPhone,
		streetLine1: businessInfoBeforeVerified.streetLine1,
		streetLine2: businessInfoBeforeVerified.streetLine2,
		city: businessInfoBeforeVerified.city,
		state: businessInfoBeforeVerified.state,
		country: businessInfoBeforeVerified.country,
		postalCode: businessInfoBeforeVerified.postalCode,
		tin: businessInfoBeforeVerified.tin,
	});
	const [comment, setComment] = useState('');
	const [viewAndEdit, setViewAndEdit] = useState(!created);
	const [businessInfoVerified, setBusinessInfoVerified] = useState(verified);
	const [fieldFirstBlur, setFieldFirstBlur] = useState({
		establishedOn: false,
		incorporationState: false,
		legalStructure: false,
		businessName: false,
		businessPhone: false,
		streetLine1: false,
		streetLine2: false,
		city: false,
		state: false,
		country: false,
		postalCode: false,
		tin: false,
	});
	const [isValid, setIsValid] = useState(false);
	const [stateLabel, setStateLabel] = useState('State');
	const [cityLabel, setCityLabel] = useState('City');
	const [postalCodeLabel, setPostalCodeLabel] = useState('ZIP Code');
	const [taxIdLabel, setTaxIdLabel] = useState('Employer Identification Number');
	const [saveButtonClicked, setSaveButtonClicked] = useState(false);
	const [countryIndex, setCountryIndex] = useState((businessInfo.country.toUpperCase() === 'CA') === 'CA' ? 0 : 1);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [password, setPassword] = useState('');
	const [action, setAction] = useState('');
	const [buttonMode, setButtonMode] = useState('Approve');

	const confirmModalToggle = () => {
		setConfirmModalOpen(!confirmModalOpen);
	};

	const submitAction = async () => {
		switch (action) {
			case 'denyApplication':
				onManuallyDeny();
				break;
			default:
				break;
		}

		confirmModalToggle();
	};

	useEffect(() => {
		setCountryIndex(businessInfo.country.toUpperCase() === 'CA' ? 0 : 1);
		setIsValid(validateField('all-fields'));
	}, [businessInfo]);

	useEffect(() => {
		if (countryIndex === 0) {
			//CA
			setStateLabel('Province');
			setCityLabel('Municipality');
			setTaxIdLabel('Employer Identification Number');
			// setTaxIdMask('999-999-999');
			setPostalCodeLabel('Postal Code');
		} else if (countryIndex === 1) {
			//US
			setStateLabel('State');
			setCityLabel('City');
			setTaxIdLabel('Employer Identification Number');
			// setTaxIdMask('999-99-9999');
			setPostalCodeLabel('ZIP Code');
		}
		setBusinessInfo({
			...businessInfo,
			tin: formatBusinessInfo('tin', businessInfo.tin, { countryIndex }),
			postalCode: formatBusinessInfo('postalCode', businessInfo.postalCode, { countryIndex }),
		});
	}, [countryIndex]);

	const validateField = field => {
		const fields = Object.keys(businessInfo);

		switch (field) {
			case 'establishedOn':
				return businessInfo[field];
			case 'incorporationState':
				return businessInfo[field];
			case 'legalStructure':
				return businessInfo[field];
			case 'businessName':
				return businessInfo[field];
			case 'businessPhone':
				return businessInfo[field];
			case 'streetLine1':
				return businessInfo[field];
			case 'streetLine2':
				return true;
			case 'city':
				return businessInfo[field];
			case 'state':
				return businessInfo[field];
			case 'country':
				return businessInfo[field];
			case 'postalCode':
				let postalCodeRegex = /\d{5}/;
				if (countryIndex === 0) {
					postalCodeRegex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
				}
				return businessInfo[field] && postalCodeRegex.test(businessInfo[field]);
			case 'tin':
				// return businessInfo[field];

				let tinRegex = /(\d{2})-(\d{7})/;
				if (countryIndex === 0) {
					tinRegex = /(\d{3})-(\d{3})-(\d{3})/;
				}

				return (
					businessInfo[field] &&
					tinRegex.test(businessInfo[field]) &&
					!businessInfo[field]
						.replace(/\D+/gi, '')
						.split('')
						.every(digit => digit === businessInfo[field][0])
				);
			case 'all-fields':
				return fields.every(field => {
					if (!validateField(field)) {
						console.log(field, businessInfo[field]);
					}
					return validateField(field);
				});
			default:
				return true;
		}
	};

	const onViewInfo = async () => {
		const response = await API.get(`/banking-admin/business/business-info/${businessProfileId}`);
		console.log('response', response);
		if (response.businessInfo) {
			setBusinessInfo({
				...response.businessInfo,
				tin: formatBusinessInfo('tin', response.businessInfo.tin, { countryIndex }),
				postalCode: formatBusinessInfo('postalCode', response.businessInfo.postalCode, { countryIndex }),
			});
		}
		setViewAndEdit(true);
	};

	const onSaveAndManuallyVerify = async () => {
		setFieldFirstBlur({
			citizenship: true,
			dateOfBirth: true,
			firstName: true,
			lastName: true,
			streetLine1: true,
			streetLine2: true,
			city: true,
			state: true,
			postalCode: true,
			tin: true,
			emailAddress: true,
			phoneNumber: true,
			country: true,
		});
		setSaveButtonClicked(true);
		setIsValid(validateField('all-fields'));

		if (!comment || !isValid) {
			return;
		}
		await API.post(`/banking-admin/business/verify-business-info/${businessProfileId}`, {
			comment,
			pii: {
				...businessInfo,
				tin: formatBusinessInfo('tin', businessInfo.tin, {
					countryIndex,
					reverse: true,
				}),
			},
		});
		setBusinessInfoVerified(true);
		setViewAndEdit(false);
	};

	const onManuallyDeny = async () => {
		await API.post(`/banking-admin/business/deny-business-info/${businessProfileId}`, {
			password,
			comment,
		});
		setViewAndEdit(false);
	};

	const onBusinessInfoChange = (field, value) => {
		const _value = formatBusinessInfo(field, value, { countryIndex });
		setBusinessInfo({
			...businessInfo,
			[field]: _value,
		});
	};

	return (
		<>
			<Modal open={confirmModalOpen} toggle={confirmModalToggle}>
				<ModalHeader>Confirm {action}</ModalHeader>
				<ModalBody>
					<Row className="m-2">
						<Col md="12">
							<span className="text-semibold text-fiord-blue">
								Enter your password to confirm action: <b>{action}</b>
							</span>
							<FormInput
								type={'password'}
								size="md"
								className="mb-3"
								value={password}
								onChange={event => setPassword(event.target.value)}
								required
							/>
						</Col>
					</Row>

					<Row className="m-2 d-flex justify-content-end">
						<Button style={{ margin: 5 }} onClick={submitAction}>
							Confirm
						</Button>

						<Button style={{ margin: 5 }} onClick={confirmModalToggle} theme="light">
							Cancel
						</Button>
					</Row>
				</ModalBody>
			</Modal>
			<Row>
				<Col lg={businessInfoVerified ? '12' : '9'} md="12">
					<Card small className="mb-3">
						<CardHeader className="border-bottom d-flex justify-content-between align-items-center">
							<h6 className="m-0">Business Info</h6>
							{created ? (
								<Button theme="accent" onClick={onViewInfo} disabled={viewAndEdit}>
									{businessInfoVerified ? 'View Info' : 'View + Edit Info'}
								</Button>
							) : null}
						</CardHeader>

						<CardBody className="p-0">
							<Form>
								<Row className="m-2">
									<Col md="6">
										<span className="text-semibold text-fiord-blue">Established On</span>
										<FormInput
											type={viewAndEdit ? 'date' : 'password'}
											size="md"
											className="mb-3"
											placeholder="Established On"
											disabled={created ? !viewAndEdit || businessInfoVerified : false}
											value={businessInfo.establishedOn}
											onChange={event =>
												onBusinessInfoChange('establishedOn', event.target.value)
											}
											required
											invalid={fieldFirstBlur.establishedOn && !validateField('establishedOn')}
											onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, establishedOn: true })}
										/>
									</Col>
								</Row>
								<Row className="m-2">
									<Col md="6">
										<span className="text-semibold text-fiord-blue">Incorporation State</span>
										<FormInput
											type={viewAndEdit ? 'text' : 'password'}
											size="md"
											className="mb-3"
											placeholder="Incorporation State"
											disabled={created ? !viewAndEdit || businessInfoVerified : false}
											value={businessInfo.incorporationState}
											onChange={event =>
												onBusinessInfoChange('incorporationState', event.target.value)
											}
											required
											invalid={
												fieldFirstBlur.incorporationState &&
												!validateField('incorporationState')
											}
											onBlur={() =>
												setFieldFirstBlur({ ...fieldFirstBlur, incorporationState: true })
											}
										/>
									</Col>
								</Row>
								<Row className="m-2">
									<Col md="6">
										<span className="text-semibold text-fiord-blue">Legal Structure</span>
										<FormInput
											type={viewAndEdit ? 'text' : 'password'}
											size="md"
											className="mb-3"
											placeholder="Legal Structure"
											disabled={created ? !viewAndEdit || businessInfoVerified : false}
											value={businessInfo.legalStructure}
											onChange={event =>
												onBusinessInfoChange('legalStructure', event.target.value)
											}
											required
											invalid={fieldFirstBlur.legalStructure && !validateField('legalStructure')}
											onBlur={() =>
												setFieldFirstBlur({ ...fieldFirstBlur, legalStructure: true })
											}
										/>
									</Col>
								</Row>
								<Row className="m-2">
									<Col md="6">
										<span className="text-semibold text-fiord-blue">Business Name</span>
										<FormInput
											type={viewAndEdit ? 'text' : 'password'}
											size="md"
											className="mb-3"
											placeholder="Business Name"
											disabled={created ? !viewAndEdit || businessInfoVerified : false}
											value={businessInfo.businessName}
											onChange={event => onBusinessInfoChange('businessName', event.target.value)}
											required
											invalid={fieldFirstBlur.businessName && !validateField('businessName')}
											onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, businessName: true })}
										/>
									</Col>
								</Row>
								<Row className="m-2">
									<Col md="6">
										<span className="text-semibold text-fiord-blue">Business Phone</span>
										<FormInput
											type={viewAndEdit ? 'text' : 'password'}
											size="md"
											className="mb-3"
											placeholder="Business Phone"
											disabled={created ? !viewAndEdit || businessInfoVerified : false}
											value={businessInfo.businessPhone}
											onChange={event =>
												onBusinessInfoChange('businessPhone', event.target.value)
											}
											required
											invalid={fieldFirstBlur.businessPhone && !validateField('businessPhone')}
											onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, businessPhone: true })}
										/>
									</Col>
								</Row>
								<Row className="m-2">
									<Col md="6">
										<span className="text-semibold text-fiord-blue">{taxIdLabel}</span>
										<FormInput
											type={viewAndEdit ? 'text' : 'password'}
											size="md"
											className="mb-3"
											placeholder={taxIdLabel}
											disabled={created ? !viewAndEdit || businessInfoVerified : false}
											value={businessInfo.tin}
											onChange={event => onBusinessInfoChange('tin', event.target.value)}
											required
											invalid={fieldFirstBlur.tin && !validateField('tin')}
											onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, tin: true })}
										/>
									</Col>
								</Row>
								<Row className="m-2">
									<Col md="6">
										<span className="text-semibold text-fiord-blue">Street Line 1</span>
										<FormInput
											type={viewAndEdit ? 'text' : 'password'}
											size="md"
											className="mb-3"
											placeholder="Street Line 1"
											disabled={created ? !viewAndEdit || businessInfoVerified : false}
											value={businessInfo.streetLine1}
											onChange={event => onBusinessInfoChange('streetLine1', event.target.value)}
											required
											invalid={fieldFirstBlur.streetLine1 && !validateField('streetLine1')}
											onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, streetLine1: true })}
										/>
									</Col>
								</Row>
								<Row className="m-2">
									<Col md="6">
										<span className="text-semibold text-fiord-blue">Street Line 2</span>
										<FormInput
											type={viewAndEdit ? 'text' : 'password'}
											size="md"
											className="mb-3"
											placeholder="Street Line 2"
											disabled={created ? !viewAndEdit || businessInfoVerified : false}
											value={businessInfo.streetLine2}
											onChange={event => onBusinessInfoChange('streetLine2', event.target.value)}
											required
										/>
									</Col>
								</Row>
								<Row className="m-2">
									<Col md="6">
										<span className="text-semibold text-fiord-blue">{cityLabel}</span>
										<FormInput
											type={viewAndEdit ? 'text' : 'password'}
											size="md"
											className="mb-3"
											placeholder={cityLabel}
											disabled={created ? !viewAndEdit || businessInfoVerified : false}
											value={businessInfo.city}
											onChange={event => onBusinessInfoChange('city', event.target.value)}
											required
											invalid={fieldFirstBlur.city && !validateField('city')}
											onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, city: true })}
										/>
									</Col>
								</Row>
								<Row className="m-2">
									<Col md="6">
										<span className="text-semibold text-fiord-blue">{stateLabel}</span>
										<FormInput
											type={viewAndEdit ? 'text' : 'password'}
											size="md"
											className="mb-3"
											placeholder={stateLabel}
											disabled={created ? !viewAndEdit || businessInfoVerified : false}
											value={businessInfo.state}
											onChange={event => onBusinessInfoChange('state', event.target.value)}
											required
											invalid={fieldFirstBlur.state && !validateField('state')}
											onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, state: true })}
										/>
									</Col>
								</Row>
								<Row className="m-2">
									<Col md="6">
										<span className="text-semibold text-fiord-blue">Country</span>
										{viewAndEdit ? (
											<FormSelect
												required
												type={viewAndEdit ? 'text' : 'password'}
												size="md"
												className="mb-3"
												placeholder="Country"
												value={businessInfo.country}
												disabled={created ? !viewAndEdit || businessInfoVerified : false}
												invalid={fieldFirstBlur.country && !validateField('country')}
												onChange={event => onBusinessInfoChange('country', event.target.value)}>
												{COUNTRY_OPTIONS.map(({ value, label }) => (
													<option key={value + label} value={value} disabled={!value}>
														{label}
													</option>
												))}
											</FormSelect>
										) : (
											<FormInput
												size="md"
												className="mb-3"
												placeholder="Country"
												disabled
												value={businessInfo.country}
												required
											/>
										)}
									</Col>
								</Row>
								{/* <Row className="m-2">
								<Col md="6">
									<span className="text-semibold text-fiord-blue">Country</span>
									<FormInput
										type={viewAndEdit ? 'text' : 'password'}
										size="md"
										className="mb-3"
										placeholder="Country"
										disabled={created ? !viewAndEdit || businessInfoVerified : false}
										value={businessInfo.country}
										onChange={event => onBusinessInfoChange('country', event.target.value)}
										required
										invalid={fieldFirstBlur.country && !validateField('country')}
										onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, country: true })}
									/>
								</Col>
							</Row> */}
								<Row className="m-2">
									<Col md="6">
										<div
											style={{
												marginBottom: '5px',
											}}>
											<Tip
												style={{
													fontSize: 12,
													fontWeight: 'normal',
													textTransform: 'capitalize',
												}}
												color={colors.black.value}
												prompt={postalCodeLabel} //320 for "HD"
												helpText={
													countryIndex === 0
														? 'CA Postal Code rule'
														: 'Must be valid 5 digit zip code'
												}
												place="top"
											/>
										</div>

										{/* <span className="text-semibold text-fiord-blue">{postalCodeLabel}</span> */}
										<FormInput
											type={viewAndEdit ? 'text' : 'password'}
											size="md"
											className="mb-3"
											placeholder={postalCodeLabel}
											disabled={created ? !viewAndEdit || businessInfoVerified : false}
											value={businessInfo.postalCode}
											onChange={event => onBusinessInfoChange('postalCode', event.target.value)}
											required
											invalid={fieldFirstBlur.postalCode && !validateField('postalCode')}
											onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, postalCode: true })}
										/>
									</Col>
								</Row>
							</Form>
						</CardBody>
					</Card>
				</Col>

				{businessInfoVerified ? null : (
					<Col lg="3" md="12">
						<Card small>
							<CardBody>
								<Row className="mb-2">
									<Col sm="12">
										<FormTextarea
											size="lg"
											rows="8"
											value={comment}
											placeholder={buttonMode === 'Approve' ? 'Audit Message' : 'Reply to User'}
											onChange={event => setComment(event.target.value)}
											disabled={!viewAndEdit}
										/>
									</Col>
								</Row>
								<Row className="mb-0">
									<Col sm="12">
										<FormRadio
											disabled={!viewAndEdit}
											inline
											name="buttonMode"
											checked={buttonMode === 'Approve'}
											onChange={() => {
												setButtonMode('Approve');
											}}>
											Approve
										</FormRadio>
										<FormRadio
											disabled={!viewAndEdit}
											inline
											name="buttonMode"
											checked={buttonMode === 'Reject'}
											onChange={() => {
												setButtonMode('Reject');
											}}>
											Reject
										</FormRadio>
									</Col>
									<Col sm="12">
										{viewAndEdit && !isValid && saveButtonClicked ? (
											<div style={{ color: 'red', paddingBottom: '10px' }}>
												Check form for invalid field(s)
											</div>
										) : null}
										{buttonMode === 'Approve' && (
											<Button
												theme="accent"
												size="sm"
												style={{ width: '100%', marginTop: 10 }}
												onClick={onSaveAndManuallyVerify}
												disabled={!viewAndEdit}>
												Save + Manually Verify
											</Button>
										)}

										{buttonMode === 'Reject' && (
											<Button
												theme="danger"
												size="sm"
												style={{ width: '100%', marginTop: 10, marginBottom: 0 }}
												onClick={() => {
													setAction('denyApplication');
													confirmModalToggle();
												}}
												disabled={!viewAndEdit}>
												Manually Deny
											</Button>
										)}
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				)}
			</Row>
		</>
	);
};

export default BusinessInfoDisplay;
