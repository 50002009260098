import React, { Component, useState } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	ListGroup,
	CardFooter,
	Button,
	ButtonGroup,
	ListGroupItem,
	Badge,
	FormInput,
	FormCheckbox,
	DatePicker,
	FormSelect,
	FormTextarea,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from 'shards-react';
import { Subscribe } from 'unstated';
import AppsContainer from '../../data/AppsContainer';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import FileDropzone from '../../components/file-manager-list/FileDropzone';
import ImageDropzone from '../../components/ImageDropzone';
import ManageCollectionsModal from '../../components/ManageCollectionsModal';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Tip from '../../components/Tip';
import colors from '../../utils/colors';
import API from '../../data/API';
import waitForContainer from '../../utils/waitForContainer';
import moment from 'moment';
import { Prompt } from 'react-router';
import _ from 'lodash';
import ImageTools from '../../utils/ImageTools';
import BounceLoader from 'react-spinners/BounceLoader';
import { Howl } from 'howler';
import { css } from '@emotion/core';
const imageTools = new ImageTools();

const DragHandle = SortableHandle(({ albumComponent }) => (
	<i
		onMouseDown={albumComponent.closeTrackDetails}
		className="material-icons"
		style={{ fontSize: 22, color: '#CCC', cursor: 'grab', userSelect: 'none', top: 9 }}>
		reorder
	</i>
));

const TrackItem = SortableElement(({ track, albumComponent, collections, products, hasStore, hasFreeloaders }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);

	return (
		<ListGroupItem className="d-flex row py-4 px-3" style={{ alignItems: 'flex-start' }}>
			<DragHandle albumComponent={albumComponent} />
			<Col>
				<FormInput
					className="mb-2"
					style={{ fontSize: 16 }}
					placeholder={track.placeholder}
					value={track.title}
					onChange={event => albumComponent.changeTrackTitle(track.key, event.target.value)}
					onKeyUp={albumComponent.onKeyUp}
				/>
				<Row className="m-0" style={{ alignItems: 'flex-start' }}>
					<Dropdown
						open={dropdownOpen}
						toggle={() => setDropdownOpen(!dropdownOpen)}
						className="d-sm-block d-md-none"
						size="sm">
						<DropdownToggle
							caret
							style={{ height: 28, padding: '0px 12px', marginRight: 10 }}
							theme={
								albumComponent.state.modalTrack && albumComponent.state.modalTrack.key === track.key
									? 'primary'
									: 'light'
							}>
							{albumComponent.state.modalTrack &&
								albumComponent.state.modalTrack.key === track.key &&
								albumComponent.state.showingDetailsModal &&
								`Details`}
							{albumComponent.state.modalTrack &&
								albumComponent.state.modalTrack.key === track.key &&
								albumComponent.state.showingGalleryModal &&
								`Liner Notes`}
							{albumComponent.state.modalTrack &&
								albumComponent.state.modalTrack.key === track.key &&
								albumComponent.state.showingProductModal &&
								`Featured Product`}
							{(!albumComponent.state.modalTrack || albumComponent.state.modalTrack.key !== track.key) &&
								`More`}
						</DropdownToggle>
						<DropdownMenu small>
							{albumComponent.state.modalTrack && albumComponent.state.modalTrack.key === track.key && (
								<>
									<DropdownItem onClick={() => albumComponent.closeModalTrack()}>Close</DropdownItem>
									<DropdownItem divider />
								</>
							)}

							<DropdownItem
								active={
									albumComponent.state.modalTrack &&
									albumComponent.state.modalTrack.key === track.key &&
									albumComponent.state.showingDetailsModal
								}
								onClick={() => albumComponent.toggleModalTrack(track, 'showingDetailsModal')}>
								Details
							</DropdownItem>
							<DropdownItem
								active={
									albumComponent.state.modalTrack &&
									albumComponent.state.modalTrack.key === track.key &&
									albumComponent.state.showingGalleryModal
								}
								onClick={() => albumComponent.toggleModalTrack(track, 'showingGalleryModal')}>
								Liner Notes
							</DropdownItem>
							{hasStore && (
								<DropdownItem
									active={
										albumComponent.state.modalTrack &&
										albumComponent.state.modalTrack.key === track.key &&
										albumComponent.state.showingProductModal
									}
									onClick={() => albumComponent.toggleModalTrack(track, 'showingProductModal')}>
									Featured Product
								</DropdownItem>
							)}
						</DropdownMenu>
					</Dropdown>
					<ButtonGroup style={{ marginRight: 10 }} className="d-none d-md-block">
						<Button
							active={
								albumComponent.state.showingDetailsModal &&
								albumComponent.state.modalTrack &&
								albumComponent.state.modalTrack.key === track.key
							}
							theme={
								albumComponent.state.showingDetailsModal &&
								albumComponent.state.modalTrack &&
								albumComponent.state.modalTrack.key === track.key
									? 'primary'
									: 'light'
							}
							style={{ height: 28, padding: '0px 12px' }}
							size="sm"
							onClick={() => albumComponent.toggleModalTrack(track, 'showingDetailsModal')}>
							Details
						</Button>
						<Button
							active={
								albumComponent.state.showingGalleryModal &&
								albumComponent.state.modalTrack &&
								albumComponent.state.modalTrack.key === track.key
							}
							theme={
								albumComponent.state.showingGalleryModal &&
								albumComponent.state.modalTrack &&
								albumComponent.state.modalTrack.key === track.key
									? 'primary'
									: 'light'
							}
							size="sm"
							style={{ height: 28, padding: '0px 12px' }}
							onClick={() => albumComponent.toggleModalTrack(track, 'showingGalleryModal')}>
							Liner Notes
						</Button>
						{hasStore && (
							<Button
								active={
									albumComponent.state.showingProductModal &&
									albumComponent.state.modalTrack &&
									albumComponent.state.modalTrack.key === track.key
								}
								theme={
									albumComponent.state.showingProductModal &&
									albumComponent.state.modalTrack &&
									albumComponent.state.modalTrack.key === track.key
										? 'primary'
										: 'light'
								}
								size="sm"
								style={{ height: 28, padding: '0px 12px' }}
								onClick={() => albumComponent.toggleModalTrack(track, 'showingProductModal')}>
								Featured Product
							</Button>
						)}
					</ButtonGroup>
					{track.uploadProgress !== undefined && track.uploadProgress !== 100 && (
						<>
							<div style={{ width: 24, height: 24, top: 2, position: 'relative' }}>
								<CircularProgressbar
									value={track.uploadProgress}
									strokeWidth={16}
									styles={buildStyles({
										strokeLinecap: 'butt',
										trailColor: '#EAEAEA',
										pathColor: '#17c671',
									})}
								/>
							</div>
							<span style={{ marginLeft: 5, fontSize: 12, lineHeight: '28px' }}>Uploading...</span>
							<div style={{ flex: 1 }} />
							<Button
								theme="white"
								size="sm"
								style={{ borderWidth: 0, height: 28, padding: '0px 10px' }}
								onClick={() => albumComponent.cancelUpload(track.key)}>
								Cancel
							</Button>
						</>
					)}
					{track.uploadProgress === 100 && track.processingProgress !== 100 && !track.media && (
						<>
							<div style={{ width: 24, height: 24, top: 2, position: 'relative' }}>
								<CircularProgressbar
									value={track.processingProgress}
									strokeWidth={16}
									styles={buildStyles({
										strokeLinecap: 'butt',
										trailColor: '#EAEAEA',
										pathColor: '#007bff',
									})}
								/>
							</div>

							<span style={{ marginLeft: 5, fontSize: 12, lineHeight: '28px' }}>
								Processing...{' '}
								{track.processingSecondsRemaining > 0 && (
									<>
										about {moment.duration(track.processingSecondsRemaining, 'seconds').format()}{' '}
										left
									</>
								)}
							</span>

							<div style={{ flex: 1 }} />
							<Button
								theme="white"
								size="sm"
								style={{ borderWidth: 0, height: 28, padding: '0px 10px' }}
								onClick={() => albumComponent.cancelUpload(track.key)}>
								Cancel
							</Button>
						</>
					)}
					{track.uploadProgress === 100 && track.processingProgress === 100 && !track.media && (
						<>
							<BounceLoader
								size={24}
								color={'#007bff'}
								css={css`
									margin-top: 2px;
									position: relative;
								`}
							/>
							<span style={{ marginLeft: 5, fontSize: 12, lineHeight: '28px' }}>Finishing...</span>
							<div style={{ flex: 1 }} />
							<Button
								theme="white"
								size="sm"
								style={{ borderWidth: 0, height: 28, padding: '0px 10px' }}
								onClick={() => albumComponent.cancelUpload(track.key)}>
								Cancel
							</Button>
						</>
					)}
					{track.media && (
						<>
							{albumComponent.state.playingTrack &&
								albumComponent.state.playingTrack.key === track.key && (
									<i
										className="material-icons"
										onClick={() => albumComponent.stopPlaying()}
										style={{
											fontSize: 29,
											top: -0.5,
											left: -2.5,
											cursor: 'pointer',
											color: colors.primary.value,
										}}>
										stop_circle
									</i>
								)}
							{(!albumComponent.state.playingTrack ||
								albumComponent.state.playingTrack.key !== track.key) && (
								<i
									className="material-icons"
									onClick={() => albumComponent.playTrack(track)}
									style={{
										fontSize: 29,
										top: -0.5,
										left: -2.5,
										cursor: 'pointer',
										color: 'rgb(144 176 212)',
									}}>
									play_circle
								</i>
							)}
							<div style={{ flex: 1 }} />
							<Button
								theme="white"
								size="sm"
								style={{ borderWidth: 0, height: 28, padding: '0px 10px' }}
								onClick={() => albumComponent.deleteTrack(track.key)}>
								Delete
							</Button>
						</>
					)}
				</Row>
				{albumComponent.state.showingDetailsModal &&
					albumComponent.state.modalTrack &&
					albumComponent.state.modalTrack.key === track.key && (
						<div
							className="pt-4 px-4 pb-3 mt-3"
							style={{
								backgroundColor: 'rgb(230 238 244)',
								borderRadius: 10,
							}}>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<p style={{ marginBottom: 10 }}>Track Details </p>
								{track.shareLink && (
									<Button
										theme="white"
										size="sm"
										style={{ marginLeft: 8, height: 28, padding: '0px 12px', lineHeight: '26px' }}
										href={track.shareLink}
										target="_blank"
										rel="noopener noreferrer">
										Share Track
									</Button>
								)}
							</div>

							<FormCheckbox
								small
								toggle
								checked={
									albumComponent.state.modalTrack && albumComponent.state.modalTrack.nerveExclusive
								}
								onChange={() =>
									albumComponent.toggleTrackExclusivity(albumComponent.state.modalTrack.key)
								}>
								<span style={{ fontSize: 12 }}>
									<Tip
										style={{ marginRight: 4 }}
										color={colors.dark.value}
										prompt="Manifest Exclusive"
										place="right"
										helpText="'Manifest exclusive' means this recording isn't<br />offered through other means (like social media<br />or YouTube). Listeners will see a badge on<br />exclusive tracks, reinforcing the value<br />that your Manifest channel provides."
									/>
								</span>
							</FormCheckbox>
							{hasFreeloaders && (
								<FormCheckbox
									small
									toggle
									checked={
										albumComponent.state.modalTrack &&
										albumComponent.state.modalTrack.availableForFree
									}
									onChange={() =>
										albumComponent.toggleAvailableForFree(albumComponent.state.modalTrack.key)
									}>
									<span style={{ fontSize: 12 }}>
										<Tip
											style={{ marginRight: 4 }}
											color={colors.dark.value}
											prompt="Available For Free"
											place="right"
											helpText="'Available For Free' tracks are<br />available to all users in the web."
										/>
									</span>
								</FormCheckbox>
							)}
							<p style={{ marginTop: 24, marginBottom: 10 }}>
								Collections{'  '}
								<Button
									theme="white"
									size="sm"
									style={{ marginLeft: 8, height: 28, padding: '0px 12px' }}
									onClick={() => albumComponent.setState({ showingCollectionsModal: true })}>
									{collections.length === 0 ? 'Add' : 'Manage'}
								</Button>
							</p>
							{collections.length > 0 && (
								<Row>
									{collections.map(collection => (
										<Col xl="12" key={collection._id}>
											<FormCheckbox
												key={collection._id}
												small
												toggle
												checked={albumComponent.modalTrackIsInCollection(collection._id)}
												onChange={() =>
													albumComponent.toggleModalTrackCollection(collection._id)
												}>
												<span style={{ fontSize: 12 }}>{collection.name}</span>
											</FormCheckbox>
										</Col>
									))}
								</Row>
							)}

							{collections.length === 0 && (
								<Row>
									<Col xl="8">
										<img
											src={require('../../images/manifest/collectionSampleAudio.png')}
											style={{ marginBottom: 10, width: '100%', cursor: 'pointer' }}
											onClick={() => albumComponent.setState({ showingCollectionsModal: true })}
										/>
									</Col>
								</Row>
							)}
						</div>
					)}
				{albumComponent.state.showingGalleryModal &&
					albumComponent.state.modalTrack &&
					albumComponent.state.modalTrack.key === track.key && (
						<div
							className="p-4 mt-3"
							style={{
								backgroundColor: 'rgb(230 238 244)',
								borderRadius: 10,
							}}>
							<p style={{ marginBottom: 0 }}>Liner Notes</p>
							<p style={{ fontSize: 12, fontWeight: '300', marginBottom: 10 }}>
								Fans browse liner notes while they're listening to this track. Spend a little time, and
								get creative!
							</p>
							<FormTextarea
								id="memberDescription"
								placeholder="Lyrics, inspiration, stories, etc. (Required)"
								size="lg"
								className="mb-4 mt-3"
								rows="8"
								value={albumComponent.state.modalTrack.description}
								onChange={event =>
									albumComponent.changeTrackDescription(
										albumComponent.state.modalTrack.key,
										event.target.value,
									)
								}
							/>
							<TrackGalleryList
								gallery={albumComponent.state.modalTrack.gallery}
								albumComponent={albumComponent}
								axis="xy"
								pressDelay={0}
								helperClass="sortableHelper"
								useDragHandle={false}
								onSortEnd={event =>
									albumComponent.onGallerySortEnd(albumComponent.state.modalTrack.key, event)
								}
							/>
							<div style={{ padding: 0 }}>
								<FileDropzone
									label="🖼 Upload images"
									onFileSelect={albumComponent.onTrackGalleryImageSelect}
									types="JPG and PNG"
									accept=".jpg, .png"
								/>
							</div>
						</div>
					)}
				{albumComponent.state.showingProductModal &&
					albumComponent.state.modalTrack &&
					albumComponent.state.modalTrack.key === track.key && (
						<div
							className="p-4 mt-3"
							style={{
								backgroundColor: 'rgb(230 238 244)',
								borderRadius: 10,
							}}>
							<p style={{ marginBottom: 0 }}>Featured Product</p>
							<p style={{ fontSize: 12, fontWeight: '300', marginBottom: 10 }}>
								Fans can easily buy a related product while they're listening to this track.
							</p>
							<Row>
								<Col md="6" sm="12">
									<FormSelect
										size="md"
										className="w-100 mt-2"
										value={
											albumComponent.state.modalTrack &&
											albumComponent.state.modalTrack.products &&
											albumComponent.state.modalTrack.products.length > 0 &&
											albumComponent.state.modalTrack.products[0]
										}
										onChange={albumComponent.onTrackProductChange}>
										<option value="">None</option>
										{products &&
											products.map(item => (
												<option key={item._id} value={item._id}>
													{item.title} ({item.variants.length}{' '}
													{item.variants.length > 1 ? 'formats' : 'format'})
												</option>
											))}
									</FormSelect>
								</Col>
							</Row>
						</div>
					)}
			</Col>
		</ListGroupItem>
	);
});

const TrackList = SortableContainer(({ tracks, albumComponent, collections, products, hasStore, hasFreeloaders }) => (
	<CardBody className="py-0">
		<ListGroup flush>
			{tracks.map((track, index) => (
				<TrackItem
					key={index}
					index={index}
					track={track}
					albumComponent={albumComponent}
					collections={collections}
					products={products}
					hasStore={hasStore}
					hasFreeloaders={hasFreeloaders}
				/>
			))}
		</ListGroup>
	</CardBody>
));

const TrackGalleryItem = SortableElement(({ galleryItem, albumComponent }) => (
	<div className="sortable-photo-item">
		{galleryItem.uploadProgress < 100 ? (
			<div
				style={{
					width: 100,
					height: 100,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					cursor: 'grab',
				}}>
				<div style={{ width: 24, height: 24 }}>
					<CircularProgressbar
						value={galleryItem.uploadProgress}
						strokeWidth={16}
						styles={buildStyles({
							strokeLinecap: 'butt',
							trailColor: '#181818',
							pathColor: '#17c671',
						})}
					/>
				</div>
			</div>
		) : (
			<>
				<i className="material-icons" onClick={() => albumComponent.deleteImage(galleryItem.media.key)}>
					remove_circle
				</i>
				<img src={galleryItem.media.uri} style={{ height: 100, width: 'auto', cursor: 'grab' }} />
			</>
		)}
	</div>
));

const TrackGalleryList = SortableContainer(({ gallery, albumComponent }) => (
	<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', margin: '-4px -8px 0' }}>
		{gallery.map((galleryItem, index) => (
			<TrackGalleryItem
				key={galleryItem.key || galleryItem._id}
				index={index}
				galleryItem={galleryItem}
				albumComponent={albumComponent}
			/>
		))}
	</div>
));

export default class NewAlbum extends Component {
	state = {
		title: '',
		artist: '',
		artworkFile: null,
		existingAlbumArt: null,
		tracks: [],
		saveEnabled: true,
		loading: true,
		albumId: null,
		isLive: true,
		publishDate: null,
		deletedTrackFileKeys: [],
		deletedImageFileKeys: [],
		showingGalleryModal: false,
		showingDetailsModal: false,
		showingProductModal: false,
		showingCollectionsModal: false,
		modalTrack: null,
		shouldBlockNavigation: false,
		collections: [],
		sound: null,
		playingTrack: null,
	};

	updateCollections = async () => {
		let appId = AppsContainer.getInstance().state.currentApp._id;
		const { collections } = await API.get(`/admin/collections/${appId}/audio`);
		this.setState({ collections });
	};

	componentDidMount = async () => {
		await waitForContainer(AppsContainer, 'currentApp');
		this.updateCollections();

		let { appCode, verified } = AppsContainer.getInstance().state.currentApp;

		if (this.props.match.params && this.props.match.params.albumId) {
			const { albumId } = this.props.match.params;
			const appId = AppsContainer.getInstance().state.currentApp._id;
			const { album } = await API.get(`/admin/album/${appId}/${albumId}`);
			let { title, artist, tracks, media: existingAlbumArt, isLive, publishDate } = album;

			let albumShareLink = null;
			if (tracks[0]._id && tracks[0].preview && verified) {
				albumShareLink = `https://manifestfinancial.com/${appCode}/preview/${albumId}/${tracks[0]._id}`;
			}

			tracks.forEach(track => {
				track.key = Math.floor(Math.random() * 10000000000);
				track.collections = track.collections || [];
				if (track.preview && verified) {
					track.shareLink = `https://manifestfinancial.com/${appCode}/preview/${albumId}/${track._id}`;
				}
			});
			this.setState(
				{
					title,
					artist,
					tracks,
					albumId,
					existingAlbumArt,
					isLive,
					albumShareLink,
					publishDate: publishDate ? new Date(publishDate) : null,
					loading: false,
				},
				() => {
					this.updateValidation(() => {
						//I need to turn this back off since update validation turns it on.
						this.setState({ shouldBlockNavigation: false });
					});
				},
			);
		} else {
			this.setState({ loading: false });
		}
	};

	componentDidUpdate = () => {
		if (this.state.shouldBlockNavigation) {
			window.onbeforeunload = () => true;
		} else {
			window.onbeforeunload = undefined;
		}
	};

	delete = async () => {
		if (!window.confirm(`Are you sure you want to delete this album?`)) return;

		let { _id: appId, appCode } = AppsContainer.getInstance().state.currentApp;
		let { albumId } = this.state;

		this.setState({ loading: true });

		try {
			await API.del(`/admin/album/${appId}/${albumId}`);
			await AppsContainer.getInstance().updateCurrentApp();
			this.props.history.push(`/channel/${appCode}/albums`);
		} catch (e) {
			this.setState({ loading: false });
			alert(`Oops! ${e.message}`);
		}
	};

	gotoPromoCenter = () => {
		let appCode = AppsContainer.getInstance().state.currentApp.appCode;
		this.props.history.push(`/channel/${appCode}/albums/${this.state.albumId}/promo`);
	};

	submit = async () => {
		let {
			albumId,
			title,
			artist,
			artworkFile: media,
			tracks,
			saveEnabled,
			deletedTrackFileKeys,
			deletedImageFileKeys,
			isLive,
			publishDate,
		} = this.state;

		if (!saveEnabled) return;

		try {
			if (albumId) {
				if (title.length === 0) {
					throw new Error(`Please enter a title for your album.`);
				}
				if (tracks.length === 0) {
					throw new Error(`Please upload at least one track for your album.`);
				}
			} else {
				if (title.length === 0) {
					throw new Error(`Please enter a title for your new album.`);
				}
				if (tracks.length === 0) {
					throw new Error(`Please upload at least one track for your new album.`);
				}
				if (!media) {
					throw new Error(`Please upload artwork for your new album.`);
				}
			}
			tracks.forEach(track => {
				if (track.title.length === 0) {
					throw new Error(`Please enter a title for each of your tracks.`);
				}
				if (!track.media) {
					throw new Error(
						`Please wait for all of your tracks to finish uploading and processing. If a track is stuck, you can cancel it and try again.`,
					);
				}
				if (track.gallery) {
					track.gallery.forEach(item => {
						if (!item.media || !item.media.uri) {
							throw new Error(`Please wait for all of your liner notes images to finish uploading.`);
						}
					});
				}
			});
		} catch (e) {
			alert(e.message);
			return;
		}

		this.setState({ loading: true });
		this.closeTrackDetails();

		if (albumId) {
			tracks = tracks.map(item => ({
				_id: item._id,
				title: item.title,
				media: item.media,
				preview: item.preview,
				nerveExclusive: item.nerveExclusive,
				availableForFree: item.availableForFree,
				description: item.description,
				gallery: item.gallery,
				products: item.products,
				collections: item.collections,
			}));

			let appId = AppsContainer.getInstance().state.currentApp._id;

			try {
				await Promise.all(
					deletedTrackFileKeys.map(item => {
						return API.del(`/audioupload/${appId}/${item}`);
					}),
				);

				await Promise.all(
					deletedImageFileKeys.map(item => {
						return API.del(`/upload/image/${appId}/${item}`);
					}),
				);

				let formData = new FormData();
				formData.append('isLive', isLive);
				formData.append('publishDate', isLive ? null : publishDate);
				formData.append('title', title);
				if (artist) formData.append('artist', artist);
				if (media) formData.append('media', media);
				formData.append('tracks', JSON.stringify(tracks));
				await API.multipartPost(`/admin/album/${appId}/${albumId}`, formData);

				this.setState({ shouldBlockNavigation: false });

				await AppsContainer.getInstance().updateCurrentApp();
				this.componentDidMount();
			} catch (e) {
				this.setState({ loading: false });
				alert(`Oops! ${e.message}`);
			}
		} else {
			tracks = tracks.map(item => ({
				title: item.title,
				media: item.media,
				preview: item.preview,
				nerveExclusive: item.nerveExclusive,
				availableForFree: item.availableForFree,
				description: item.description,
				gallery: item.gallery,
				products: item.products,
				collections: item.collections,
			}));

			let appId = AppsContainer.getInstance().state.currentApp._id;

			try {
				let formData = new FormData();
				formData.append('isLive', isLive);
				formData.append('publishDate', isLive ? null : publishDate);
				formData.append('title', title);
				if (artist) formData.append('artist', artist);
				formData.append('media', media);
				formData.append('tracks', JSON.stringify(tracks));
				let { _id: newAlbumId } = await API.multipartPost(`/admin/album/${appId}`, formData);
				let { appCode, verified } = AppsContainer.getInstance().state.currentApp;

				this.setState({ shouldBlockNavigation: false });
				if (verified) {
					alert(
						`We added share links to all your tracks, and social media images for your new album. Use these to promote your channel.`,
					);
					await AppsContainer.getInstance().updateCurrentApp();
					this.props.history.push(`/channel/${appCode}/albums/${newAlbumId}`);
				} else {
					AppsContainer.getInstance().refresh();
					this.props.history.push(`/channel/${appCode}`);
				}
			} catch (e) {
				this.setState({ loading: false });
				alert(`Oops! ${e.message}`);
			}
		}
	};

	isValid = () => true;

	updateValidation = callback => {
		this.setState({ saveEnabled: this.isValid(), shouldBlockNavigation: true }, callback);
	};

	onAlbumImageChange = artworkFile => {
		this.setState({ artworkFile }, this.updateValidation);
	};

	onTrackFileSelect = files => {
		let newTracks = [];
		files.forEach(file => {
			let key = Math.floor(Math.random() * 10000000000);
			let appId = AppsContainer.getInstance().state.currentApp._id;
			let uploadId;
			let checkProcessingProgress;

			let onUploadProgress = ({ loaded, total }) => {
				let percent = (loaded / total) * 100;
				this.setState(({ tracks }) => {
					let track = tracks.find(item => item.key === key);
					if (track) {
						track.uploadProgress = percent;
					}
					return { tracks };
				});
			};

			checkProcessingProgress = async () => {
				let { upload } = await API.get(`/audioupload/${appId}/${uploadId}`);
				this.setState(({ tracks }) => {
					let track = tracks.find(item => item.key === key);
					if (track) {
						track.processingProgress = upload.processingProgress * 100;
						track.processingSecondsRemaining = upload.processingSecondsRemaining;
						track.media = upload.media;
						track.preview = upload.preview;
					}
					return { tracks };
				});
				if (!upload.media) {
					setTimeout(checkProcessingProgress, 3000);
				}
			};

			let upload = async () => {
				let formData = new FormData();
				formData.append('media', file);
				let { upload } = await API.multipartPost(`/audioupload/${appId}`, formData, onUploadProgress);
				uploadId = upload._id;
				this.setState(({ tracks }) => {
					let track = tracks.find(item => item.key === key);
					if (track) {
						track.uploadProgress = 100;
					}
					return { tracks };
				}, this.updateValidation);
				checkProcessingProgress();
			};

			upload();

			let titleGuess = file.name.toLowerCase();
			titleGuess = titleGuess.split('.mp3').join('');
			titleGuess = titleGuess.split('-').join(' ').split('_').join(' ');
			titleGuess = titleGuess.replace(/\w\S*/g, function (txt) {
				return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
			});

			newTracks.push({
				key,
				title: titleGuess,
				placeholder: `Track Title for ${file.name}`,
				nerveExclusive: false,
				availableForFree: false,
				uploadProgress: 0,
				processingProgress: 0,
				gallery: [],
				collections: [],
			});
		});

		let tracks = this.state.tracks.concat(newTracks);
		this.setState({ tracks }, this.updateValidation);
	};

	onTrackGalleryImageSelect = async files => {
		let trackKey = this.state.modalTrack.key;
		let appId = AppsContainer.getInstance().state.currentApp._id;

		let newImages = [];

		await Promise.all(
			files.map(async file => {
				let key = Math.floor(Math.random() * 10000000000);
				let resized = await imageTools.resize(file, { width: 1024, height: 1024 });

				let onUploadProgress = ({ loaded, total }) => {
					let percent = (loaded / total) * 100;
					this.setState(({ tracks }) => {
						let track = tracks.find(item => item.key === trackKey);
						let galleryItem = track.gallery.find(item => item.key === key);
						galleryItem.uploadProgress = percent * 0.9;
						return { tracks };
					});
				};

				let upload = async () => {
					let formData = new FormData();
					formData.append('media', resized);
					let { media } = await API.multipartPost(`/upload/image/${appId}`, formData, onUploadProgress);
					this.setState(({ tracks }) => {
						let track = tracks.find(item => item.key === trackKey);
						let galleryItem = track.gallery.find(item => item.key === key);
						galleryItem.uploadProgress = 100;
						galleryItem.media = media;
						return { tracks };
					}, this.updateValidation);
				};

				upload();

				newImages.push({ key, uploadProgress: 0 });
			}),
		);

		this.setState(({ tracks }) => {
			let track = tracks.find(item => item.key === trackKey);
			track.gallery = track.gallery.concat(newImages);
			return { tracks };
		}, this.updateValidation);
	};

	onFieldChange = (event, field) => {
		let newState = {};
		newState[field] = event.target.value;
		this.setState(newState, this.updateValidation);
	};

	onKeyUp = event => {
		if (event.key === 'Enter') {
			this.submit();
		}
	};

	onSortEnd = ({ oldIndex, newIndex }) => {
		this.setState(
			({ tracks }) => ({
				tracks: arrayMove(tracks, oldIndex, newIndex),
			}),
			this.updateValidation,
		);
	};

	closeTrackDetails = () => {
		this.setState({
			showingDetailsModal: false,
			showingGalleryModal: false,
			showingProductModal: false,
			modalTrack: null,
		});
	};

	onGallerySortEnd = (key, { oldIndex, newIndex }) => {
		this.setState(({ tracks }) => {
			let track = tracks.find(item => item.key === key);
			track.gallery = arrayMove(track.gallery, oldIndex, newIndex);
			return { tracks };
		}, this.updateValidation);
	};

	changeTrackTitle = (key, title) => {
		this.setState(({ tracks }) => {
			let track = tracks.find(item => item.key === key);
			track.title = title;
			return { tracks };
		}, this.updateValidation);
	};

	changeTrackDescription = (key, description) => {
		this.setState(({ tracks }) => {
			let track = tracks.find(item => item.key === key);
			track.description = description;
			return { tracks };
		}, this.updateValidation);
	};

	toggleTrackExclusivity = key => {
		this.setState(({ tracks }) => {
			let track = tracks.find(item => item.key === key);
			track.nerveExclusive = !track.nerveExclusive;
			return { tracks };
		}, this.updateValidation);
	};

	toggleAvailableForFree = key => {
		this.setState(({ tracks }) => {
			let track = tracks.find(item => item.key === key);
			track.availableForFree = !track.availableForFree;
			return { tracks };
		}, this.updateValidation);
	};

	toggleIsLive = () => {
		this.setState({ isLive: !this.state.isLive }, this.updateValidation);
	};

	onPublishDateChange = date => {
		if (!date) {
			let publishDate = null;
			this.setState({ publishDate }, this.updateValidation);
		} else {
			let publishDate = this.state.publishDate;
			if (!publishDate) publishDate = new Date();
			publishDate.setMinutes(0);
			publishDate.setSeconds(0);
			publishDate.setMilliseconds(0);
			publishDate.setFullYear(date.getFullYear());
			publishDate.setMonth(date.getMonth());
			publishDate.setDate(date.getDate());
			this.setState({ publishDate }, this.updateValidation);
		}
	};

	onPublishTimeChange = e => {
		let value = e.target.value;
		if (value === '') {
			let publishDate = null;
			this.setState({ publishDate }, this.updateValidation);
		} else {
			let publishDate = this.state.publishDate;
			if (!publishDate) publishDate = new Date();
			publishDate.setMinutes(0);
			publishDate.setSeconds(0);
			publishDate.setMilliseconds(0);
			publishDate.setHours(value);
			this.setState({ publishDate }, this.updateValidation);
		}
	};

	cancelUpload = async key => {
		let track = this.state.tracks.find(item => item.key === key);
		let title = track.title !== '' ? track.title : 'this track';
		if (!window.confirm(`Are you sure you want to cancel uploading ${title}?`)) return;

		this.setState(({ tracks }) => {
			tracks = tracks.filter(item => item.key !== key);
			return { tracks };
		}, this.updateValidation);
	};

	deleteTrack = async key => {
		let track = this.state.tracks.find(item => item.key === key);
		let title = track.title !== '' ? track.title : 'this track';
		if (!window.confirm(`Are you sure you want to remove ${title}?`)) return;

		let appId = AppsContainer.getInstance().state.currentApp._id;

		let fileKey = track.media.key;
		let buff = new Buffer(fileKey);
		let base64encodedFileKey = buff.toString('base64');

		let hasPreview = !!track.preview;
		let previewFileKey, previewBuff, previewBase64encodedFileKey;
		if (hasPreview) {
			previewFileKey = track.preview.key;
			previewBuff = new Buffer(previewFileKey);
			previewBase64encodedFileKey = previewBuff.toString('base64');
		}

		let deferFileDelete = false;
		if (this.state.albumId) {
			deferFileDelete = true;
		} else {
			await API.del(`/audioupload/${appId}/${base64encodedFileKey}`);
			if (hasPreview) await API.del(`/audioupload/${appId}/${previewBase64encodedFileKey}`);
		}

		this.setState(({ tracks, deletedTrackFileKeys }) => {
			if (deferFileDelete) {
				deletedTrackFileKeys.push(base64encodedFileKey);
				if (hasPreview) deletedTrackFileKeys.push(previewBase64encodedFileKey);
			}
			let track = tracks.find(item => item.key === key);
			tracks = tracks.filter(item => item !== track);
			return { tracks, deletedTrackFileKeys };
		}, this.updateValidation);
	};

	deleteImage = async fileKey => {
		let trackKey = this.state.modalTrack.key;
		let appId = AppsContainer.getInstance().state.currentApp._id;
		let buff = new Buffer(fileKey);
		let base64encodedFileKey = buff.toString('base64');

		let deferFileDelete = false;
		if (this.state.albumId) {
			deferFileDelete = true;
		} else {
			await API.del(`/upload/image/${appId}/${base64encodedFileKey}`);
		}

		this.setState(({ tracks, deletedImageFileKeys }) => {
			let track = tracks.find(item => item.key === trackKey);
			track.gallery = track.gallery.filter(item => item.media.key !== fileKey);

			if (deferFileDelete) {
				deletedImageFileKeys.push(base64encodedFileKey);
			}

			return { tracks, deletedImageFileKeys };
		}, this.updateValidation);
	};

	futureDateFilter = date => {
		let now = new Date();
		return moment(date).isSame(now, 'day') || moment(date).isAfter(now, 'day');
	};

	onTrackProductChange = e => {
		let trackKey = this.state.modalTrack.key;
		let value = e.target.value;

		if (value === '') {
			this.setState(({ tracks }) => {
				let track = tracks.find(item => item.key === trackKey);
				track.products = null;
				return { tracks };
			});
		} else {
			this.setState(({ tracks }) => {
				let track = tracks.find(item => item.key === trackKey);
				track.products = [value];
				return { tracks };
			});
		}
	};

	modalTrackIsInCollection = id => this.state.modalTrack && this.state.modalTrack.collections.indexOf(id) > -1;
	toggleModalTrackCollection = id => {
		let trackKey = this.state.modalTrack.key;
		let currentlyInCollection = this.modalTrackIsInCollection(id);

		this.setState(({ tracks }) => {
			let track = tracks.find(item => item.key === trackKey);
			if (currentlyInCollection) {
				track.collections = track.collections.filter(item => item !== id);
			} else {
				track.collections.push(id);
			}
			return { tracks };
		});
	};

	closeModalTrack = () => {
		this.setState({
			showingDetailsModal: false,
			showingGalleryModal: false,
			showingProductModal: false,
			modalTrack: null,
		});
	};

	toggleModalTrack = (track, field) => {
		let newState = {
			showingDetailsModal: false,
			showingGalleryModal: false,
			showingProductModal: false,
		};
		if (this.state.modalTrack && this.state.modalTrack.key === track.key && this.state[field]) {
			newState.modalTrack = null;
			this.setState(newState);
		} else if (this.state.modalTrack && this.state.modalTrack.key === track.key) {
			newState[field] = true;
			this.setState(newState);
		} else {
			console.log(track);
			newState.modalTrack = track;
			newState[field] = true;
			this.setState(newState);
		}
	};

	playTrack = track => {
		if (this.state.sound) {
			this.state.sound.stop();
		}
		let sound = new Howl({ src: [track.media.uri] });
		sound.on('end', () => this.setState({ playingTrack: null }));
		sound.play();
		this.setState({ sound, playingTrack: track });
	};

	stopPlaying = () => {
		if (this.state.sound) {
			this.state.sound.stop();
		}
		this.setState({ sound: null, playingTrack: null });
	};

	render = () => {
		const collections = this.state.collections;

		return (
			<Container className="page-container album-detail">
				<Prompt
					when={this.state.shouldBlockNavigation}
					message="You have unsaved changes, are you sure you want to leave?"
				/>
				{this.state.loading ? (
					<ScreenLoader />
				) : (
					<Subscribe to={[AppsContainer]}>
						{({ state: { currentApp, products } }) => (
							<div>
								<Row noGutters className="page-header py-4">
									<PageTitle
										title={this.state.albumId ? 'Edit Album' : 'Add New Album'}
										subtitle="Music"
										className="text-sm-left mb-3"
									/>
								</Row>
								<Row>
									<Col lg="9" md="12">
										<Card small className="mb-3">
											<CardBody style={{ paddingBottom: 0 }}>
												<FormInput
													className="mb-3"
													style={{ fontSize: 16 }}
													placeholder="Album Title"
													value={this.state.title}
													onChange={event => this.onFieldChange(event, 'title')}
													onKeyUp={this.onKeyUp}
												/>
												<FormInput
													className="mb-3"
													style={{ fontSize: 16 }}
													placeholder={currentApp.name}
													value={this.state.artist}
													onChange={event => this.onFieldChange(event, 'artist')}
													onKeyUp={this.onKeyUp}
												/>
											</CardBody>
										</Card>

										<Card small className="mb-3">
											<CardHeader className="border-bottom d-flex">
												<h6 className="m-0" style={{ flex: 1 }}>
													Tracks
												</h6>
												{this.state.albumShareLink && (
													<Button
														theme="white"
														size="sm"
														style={{
															marginLeft: 8,
															height: 28,
															padding: '0px 12px',
															lineHeight: '26px',
														}}
														href={this.state.albumShareLink}
														target="_blank"
														rel="noopener noreferrer">
														Share Album
													</Button>
												)}
											</CardHeader>
											{this.state.tracks.length > 0 && (
												<TrackList
													pressDelay={10}
													helperClass="sortableHelper"
													useDragHandle={true}
													albumComponent={this}
													tracks={this.state.tracks}
													onSortEnd={this.onSortEnd}
													axis="y"
													lockAxis="y"
													collections={collections}
													products={products}
													hasStore={_.get(currentApp.optionalFeatures, 'store.enabled')}
													hasFreeloaders={_.get(
														currentApp.optionalFeatures,
														'freeloaders.enabled',
													)}
												/>
											)}
											<CardFooter>
												<FileDropzone
													label="🔥 Upload tracks (400 MB per track max)"
													types="MP3, WAV, FLAC, M4A, AIFF, MP2, and OGG." //we should add/test AAC
													tip="Upload video files to make sound files of your videos (examples: MP4, MOV)."
													onFileSelect={this.onTrackFileSelect}>
													<Tip
														style={{
															fontSize: 12,
															fontWeight: '300',
															marginTop: 18,
															display: 'inline-block',
														}}
														prompt="File encoding details for nerds. 🤓" //320 for "HD"
														helpText="Uploaded files are converted to 192kbps MP3<br />for streaming. If your files are already encoded as<br />MP3, we won't re-encode, so you won't have to wait!"
														place="top"
													/>
												</FileDropzone>
											</CardFooter>
										</Card>
									</Col>
									<Col lg="3" md="12">
										<Card small>
											<div className="album-art">
												<ImageDropzone
													label="Upload album artwork"
													defaultImage={this.state.existingAlbumArt}
													onFileSelect={this.onAlbumImageChange}
													accept=".png, .jpg"
												/>
											</div>
											<CardBody>
												<Row>
													<Col
														sm="6"
														style={{
															paddingTop: 5,
															marginBottom: -5,
														}}>
														<FormCheckbox
															checked={this.state.isLive}
															onChange={this.toggleIsLive}
															toggle
															small>
															Live
														</FormCheckbox>
													</Col>
												</Row>
												{!this.state.isLive && (
													<Row className="mt-4">
														<Col sm="12">
															<strong className="text-muted form-text d-block mb-2">
																Auto-publish (optional)
															</strong>
															<DatePicker
																size="md"
																selected={this.state.publishDate}
																onChange={this.onPublishDateChange}
																placeholderText="Publish Date"
																dropdownMode="select"
																filterDate={this.futureDateFilter}
															/>
															<FormSelect
																size="md"
																className="w-100 mt-2"
																value={moment(this.state.publishDate).format('HH')}
																onChange={this.onPublishTimeChange}>
																<option value="">Publish Time</option>
																<option value="09">9:00 a.m.</option>
																<option value="10">10:00 a.m.</option>
																<option value="11">11:00 a.m.</option>
																<option value="12">12:00 p.m.</option>
																<option value="13">1:00 p.m.</option>
																<option value="14">2:00 p.m.</option>
																<option value="15">3:00 p.m.</option>
																<option value="16">4:00 p.m.</option>
																<option value="17">5:00 p.m.</option>
																<option value="18">6:00 p.m.</option>
																<option value="19">7:00 p.m.</option>
																<option value="20">8:00 p.m.</option>
																<option value="21">9:00 p.m.</option>
																<option value="22">10:00 p.m.</option>
																<option value="23">11:00 p.m.</option>
																<option value="00">12:00 a.m.</option>
																<option value="01">1:00 a.m.</option>
																<option value="02">2:00 a.m.</option>
																<option value="03">3:00 a.m.</option>
																<option value="04">4:00 a.m.</option>
																<option value="05">5:00 a.m.</option>
																<option value="06">6:00 a.m.</option>
																<option value="07">7:00 a.m.</option>
																<option value="08">8:00 a.m.</option>
															</FormSelect>
														</Col>
													</Row>
												)}
											</CardBody>
										</Card>
										<Row className="mt-4">
											<Col sm="12">
												<Button
													theme="dark"
													size="sm"
													style={{ width: '100%' }}
													disabled={!this.state.saveEnabled}
													onClick={this.submit}>
													Save
												</Button>
											</Col>
										</Row>
										{this.state.albumId && (
											<Row className="mt-2">
												<Col sm="12">
													<Button
														theme="white"
														size="sm"
														style={{ width: '100%' }}
														onClick={this.gotoPromoCenter}>
														Social Media
													</Button>
												</Col>
											</Row>
										)}
										{this.state.albumId && (
											<Row className="mt-2">
												<Col sm="12">
													<Button
														theme="white"
														size="sm"
														style={{ width: '100%' }}
														onClick={this.delete}>
														Delete Album
													</Button>
												</Col>
											</Row>
										)}
									</Col>
								</Row>
								<ManageCollectionsModal
									collectionType="audio"
									open={this.state.showingCollectionsModal}
									toggle={() => {
										this.updateCollections();
										this.setState({ showingCollectionsModal: false });
									}}
								/>
							</div>
						)}
					</Subscribe>
				)}
			</Container>
		);
	};
}
