/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { Component } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	Form,
	FormGroup,
	FormInput,
	FormCheckbox,
	Button,
	CardHeader,
} from 'shards-react';
import API from '../../data/API';
import Session from '../../data/Session';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import PhoneInput from '../../components/PhoneInput';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

class ArtistSignup extends Component {
	constructor(props) {
		super(props);
		let { redirect, ...otherQueryParams } = queryString.parse(this.props.location.search);

		this.state = {
			email: '',
			mobileNumber: '',
			password: '',
			agreeToTerms: false,
			submitEnabled: false,
			loading: false,
			otherQueryParams,
			redirect,
			countryCodeDropdownOpen: false,
		};
	}

	componentDidMount = () => {
		let { otherQueryParams, redirect } = this.state;
		if (Session.getInstance().state.isLoggedIn) {
			if (redirect) {
				if (otherQueryParams) {
					const stringified = queryString.stringify(otherQueryParams);
					this.props.history.push(redirect + '?' + stringified);
				} else {
					this.props.history.push(redirect);
				}
			} else {
				this.props.history.push(`/profile`);
			}
		}
	};

	isValid = () => {
		let { email, password, mobileNumber, agreeToTerms } = this.state;
		return (
			/\S+@\S+\.\S+/.test(email) && password.length >= 8 && agreeToTerms && isPossiblePhoneNumber(mobileNumber)
		);
	};

	updateValidation = callback => {
		this.setState({ submitEnabled: this.isValid() }, callback);
	};

	submit = async () => {
		if (!this.isValid()) return;

		let { email, password, mobileNumber, otherQueryParams = {}, redirect } = this.state;

		this.setState({ loading: true });

		try {
			const { elevatedToken, mobileLast4, mobileNumberVerified } = await API.post(`/auth/register-admin`, {
				email,
				password,
				mobileNumber,
			});
			Session._instance.setToken(elevatedToken, false, () => {
				if (redirect) {
					const stringified = queryString.stringify({
						redirect,
						...otherQueryParams,
						mobileLast4,
						mobileNumberVerified,
					});
					this.props.history.push('/verify-login?' + stringified);
				} else {
					const stringified = queryString.stringify({ mobileLast4, mobileNumberVerified });
					this.props.history.push('/verify-login?' + stringified);
				}
			});
		} catch (e) {
			alert(`Oops! ${e.message}`);
			this.setState({ loading: false });
		}
	};

	onFieldChange = (event, field) => {
		let newState = {};
		newState[field] = event.target.value;
		this.setState(newState, this.updateValidation);
	};

	onCheckChange = field => {
		let newState = {};
		newState[field] = !this.state[field];
		this.setState(newState, this.updateValidation);
	};

	onMobileNumberChange = mobileNumber => {
		this.setState({ mobileNumber }, this.updateValidation);
	};

	onKeyUp = event => {
		if (event.key === 'Enter') {
			this.submit();
		}
	};

	render = () => {
		return (
			<Container fluid className="main-content-container h-100 px-4">
				<Row noGutters className="h-100">
					<Col lg="3" md="5" className="auth-form mx-auto my-auto">
						<Card>
							<CardHeader>
								<img
									className="auth-form__logo d-table mx-auto mb-3"
									src={require('../../images/manifest/logo.png')}
									alt="Manifest"
								/>
								<h5 className="auth-form__title text-center mb-4">Admin Registration</h5>
							</CardHeader>
							<CardBody>
								<Form>
									<FormGroup>
										<label htmlFor="emailInput">Email address</label>
										<FormInput
											type="email"
											id="emailInput"
											placeholder="mick@gmail.com"
											autoComplete="email"
											value={this.state.email}
											onChange={event => this.onFieldChange(event, 'email')}
											onKeyUp={this.onKeyUp}
										/>
									</FormGroup>
									<FormGroup>
										<label htmlFor="mobileInput">Mobile Number</label>
										<PhoneInput
											value={this.state.mobileNumber}
											onChange={this.onMobileNumberChange}
										/>
									</FormGroup>
									<FormGroup>
										<label htmlFor="passwordInput">Password</label>
										<FormInput
											type="password"
											id="passwordInput"
											placeholder="min 8 characters"
											autoComplete="new-password"
											value={this.state.password}
											onChange={event => this.onFieldChange(event, 'password')}
											onKeyUp={this.onKeyUp}
										/>
									</FormGroup>

									<FormGroup>
										<FormCheckbox
											checked={this.state.agreeToTerms}
											onChange={() => this.onCheckChange('agreeToTerms')}>
											I agree to Manifest's{' '}
											<a
												href="https://legal.manifestfinancial.com/terms-of-service"
												target="_blank"
												rel="noopener noreferrer">
												terms of service
											</a>
											.
										</FormCheckbox>
									</FormGroup>

									<Button
										pill
										theme="accent"
										className="d-table mx-auto"
										onClick={this.submit}
										disabled={!this.state.submitEnabled}>
										Create Account
									</Button>
								</Form>
							</CardBody>
						</Card>
						<div className="auth-form__meta d-flex mt-4">
							<Link to={{ pathname: `./login`, search: this.props.location.search }} className="mx-auto">
								Already have an account?
							</Link>
						</div>
					</Col>
				</Row>
			</Container>
		);
	};
}

export default ArtistSignup;
