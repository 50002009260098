import React from "react";
import Loader from 'react-loader-spinner';
import { Container } from "shards-react";

export default () => <Container fluid className="main-content-container px-4 pb-4">
	<div className="screen-loader">
		<div className="screen-loader__content">
			<Loader type="Bars" color="#EAEAEA" height={100} width={100} />
		</div>
	</div>
</Container>