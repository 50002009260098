import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Col } from 'shards-react';

const PageTitle = ({ title, subtitle, className, subtitleOnClick, small = 4, ...attrs }) => {
	const classes = classNames(className, 'text-center', 'text-md-left', 'mb-sm-0');

	return (
		<Col xs="12" sm={small} className={classes} {...attrs}>
			<span
				onClick={() => subtitleOnClick && subtitleOnClick()}
				className="text-uppercase page-subtitle"
				style={{
					cursor: Boolean(subtitleOnClick) ? 'pointer' : 'unset',
					textDecorationLine: Boolean(subtitleOnClick) ? 'underline' : 'none',
				}}>
				{subtitle}
			</span>
			<h3 className="page-title">{title}</h3>
		</Col>
	);
};

PageTitle.propTypes = {
	/**
	 * The page title.
	 */
	title: PropTypes.string,
	/**
	 * The page subtitle.
	 */
	subtitle: PropTypes.string,
};

export default PageTitle;
