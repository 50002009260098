import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container, Navbar, NavbarBrand } from 'shards-react';
import AppsContainer from '../../../data/AppsContainer';
import { Subscribe } from 'unstated';
import { withRouter } from 'react-router-dom';

import NavbarNav from './NavbarNav/NavbarNav';

import { LAYOUT_TYPES } from '../../../utils/constants';

const MainNavbar = ({ layout, stickyTop, match }) => {
	const isHeaderNav = layout === LAYOUT_TYPES.HEADER_NAVIGATION;
	const classes = classNames('main-navbar', stickyTop && 'sticky-top');

	return (
		<div className={classes}>
			<Container fluid={!isHeaderNav || null} className="p-0">
				<Navbar type="light" className="align-items-stretch flex-md-nowrap p-0">
					{isHeaderNav && (
						<div className="navbar-brand">
							<div className="d-table m-auto">
								<img
									id="main-logo"
									className="d-inline-block align-top mr-1 ml-3"
									style={{ maxWidth: '50px' }}
									src={require('../../../images/manifest/logo.png')}
									alt="Manifest"
								/>
								<Subscribe to={[AppsContainer]}>
									{({ state: { currentApp, npos } }) => {
										if (currentApp) {
											return (
												<div
													className="d-none d-md-inline-block ml-1"
													style={{
														color: '#FFF',
														paddingLeft: 8,
														paddingBottom: 4,
													}}>
													<div style={{ display: 'flex', flexDirection: 'column' }}>
														<span>{currentApp.name}</span>

														<span style={{ fontSize: 14 }}>
															<a
																href={`https://manifestfinancial.com/${currentApp.appCode}`}
																target="_blank">
																manifestfinancial.com/{currentApp.appCode}
															</a>
														</span>
													</div>
												</div>
											);
										} else if (npos && match.params.npo) {
											let npo = npos.find(npo => npo._id === match.params.npo);
											if (npo)
												return (
													<span
														className="d-none d-md-inline ml-1"
														style={{ color: '#FFF', paddingLeft: 8 }}>
														{npo.name}
													</span>
												);
										}
										return null;
									}}
								</Subscribe>
							</div>
						</div>
					)}
					<div className="main-navbar__search w-100 d-none d-md-flex d-lg-flex" />
					<NavbarNav />
				</Navbar>
			</Container>
		</div>
	);
};

MainNavbar.propTypes = {
	/**
	 * The layout type where the MainNavbar is used.
	 */
	layout: PropTypes.string,
	/**
	 * Whether the main navbar is sticky to the top, or not.
	 */
	stickyTop: PropTypes.bool,
};

MainNavbar.defaultProps = {
	stickyTop: true,
};

export default withRouter(MainNavbar);
