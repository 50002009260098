import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col } from 'shards-react';

import SidebarMainNavbar from './SidebarMainNavbar';
import SidebarNavItems from './SidebarNavItems';

import { Store } from '../../../flux';

class MainSidebar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			menuVisible: false,
		};

		this.onChange = this.onChange.bind(this);
	}

	componentWillMount() {
		Store.addChangeListener(this.onChange);
	}

	componentWillUnmount() {
		Store.removeChangeListener(this.onChange);
	}

	onChange() {
		this.setState({
			...this.state,
			menuVisible: Store.getMenuState(),
		});
	}

	render() {
		const classes = classNames('main-sidebar', 'px-0', 'd-md-none', this.state.menuVisible && 'open');

		return (
			<Col tag='aside' className={classes} lg={2} md={3}>
				<SidebarMainNavbar hideLogoText={this.props.hideLogoText} />
				<SidebarNavItems />
			</Col>
		);
	}
}

MainSidebar.propTypes = {
	/**
	 * Whether to hide the logo text, or not.
	 */
	hideLogoText: PropTypes.bool,
};

MainSidebar.defaultProps = {
	hideLogoText: false,
};

export default MainSidebar;
