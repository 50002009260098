import React, { Component } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Button } from 'shards-react';
import API from '../../data/API';
import queryString from 'query-string';
import PhoneInput from '../../components/PhoneInput';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

export default class ChangePassword extends Component {
	constructor(props) {
		super(props);
		let { redirect, mobileLast4, mobileNumberVerified, ...otherQueryParams } = queryString.parse(
			this.props.location.search,
		);
		this.state = {
			submitEnabled: false,
			loading: false,
			redirect,
			otherQueryParams,
			mobileLast4,
			mobileNumberVerified,
			mobileNumber: '',
		};
	}

	isValid = () => {
		let { mobileNumber } = this.state;
		return isPossiblePhoneNumber(mobileNumber);
	};

	updateValidation = callback => {
		this.setState({ submitEnabled: this.isValid() }, callback);
	};

	submit = async () => {
		if (!this.state.submitEnabled || this.state.loading) return;

		let { mobileNumber, otherQueryParams = {}, redirect } = this.state;

		this.setState({ loading: true });

		try {
			const { mobileLast4 } = await API.post(`/auth/modify-mobile`, {
				mobileNumber,
			});

			if (redirect) {
				const stringified = queryString.stringify({
					redirect,
					...otherQueryParams,
					mobileLast4,
					mobileNumberVerified: false,
				});
				this.props.history.push('/verify-login?' + stringified);
			} else {
				const stringified = queryString.stringify({ mobileLast4, mobileNumberVerified: false });
				this.props.history.push('/verify-login?' + stringified);
			}
		} catch (e) {
			alert(`Oops! ${e.message}`);
			this.setState({ loading: false });
		}
	};

	onFieldChange = (event, field) => {
		let newState = {};
		newState[field] = event.target.value;
		this.setState(newState, this.updateValidation);
	};

	onCheckChange = field => {
		let newState = {};
		newState[field] = !this.state[field];
		this.setState(newState, this.updateValidation);
	};

	onMobileNumberChange = mobileNumber => {
		this.setState({ mobileNumber }, this.updateValidation);
	};

	onKeyUp = event => {
		if (event.key === 'Enter') {
			this.submit();
		}
	};

	render = () => (
		<Container fluid className="main-content-container h-100 px-4">
			<Row noGutters className="h-100">
				<Col lg="3" md="5" className="auth-form mx-auto my-auto">
					<Card>
						<CardHeader>
							<img
								className="auth-form__logo d-table mx-auto mb-3"
								src={require('../../images/manifest/logo.png')}
								alt="Manifest"
							/>
							<h5 className="auth-form__title text-center mb-4">
								Please enter your mobile number to retry verification.
							</h5>
						</CardHeader>
						<CardBody>
							<Form onSubmit={e => e.preventDefault()}>
								<FormGroup>
									<label htmlFor="mobileInput">Mobile Number</label>
									<PhoneInput value={this.state.mobileNumber} onChange={this.onMobileNumberChange} />
								</FormGroup>
								<Button
									pill
									theme="accent"
									className="d-table mx-auto"
									onClick={this.submit}
									disabled={!this.state.submitEnabled || this.state.loading}>
									Send Another Code
								</Button>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
