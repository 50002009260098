import React, { Component } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	Button,
	Badge,
	FormCheckbox,
	FormSelect,
	FormInput,
} from 'shards-react';
import AppsContainer from '../../data/AppsContainer';
import Session from '../../data/Session';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import API from '../../data/API';
import waitForContainer from '../../utils/waitForContainer';
import NumericInput from 'react-numeric-input';
import arrayMove from 'array-move';
import { Prompt } from 'react-router';
import InfoBox from '../../components/InfoBox';

const labelForRole = role =>
	({ bandMember: 'Band Member', songwriter: 'Songwriter', manager: 'Manager', label: 'Label', other: 'Other' }[role]);

const SplitCard = ({ split, index, component, leftForChannelOwner, hasOpenFrontMe }) => (
	<Card small className="my-3">
		<CardHeader className="border-bottom">
			<Row className="ml-1 mb-0">
				<h6 className="m-0" style={{ flex: 1, lineHeight: 2 }}>
					{!split.role && `New Split`}
					{split.role && labelForRole(split.role)}
					{split.status === 'pending' && (
						<Badge pill theme="success" style={{ marginLeft: 8 }}>
							Invited
						</Badge>
					)}
				</h6>
				{/* <i
					className='material-icons'
					style={{ cursor: 'pointer', marginRight: 8, lineHeight: '24px', fontSize: '16px' }}
					onClick={() => component.moveSplitDown(index)}>
					arrow_circle_down
				</i>
				<i
					className='material-icons'
					style={{ cursor: 'pointer', marginRight: 8, lineHeight: '24px', fontSize: '16px' }}
					onClick={() => component.moveSplitUp(index)}>
					arrow_circle_up
				</i>
				<i
					className='material-icons'
					style={{ cursor: 'pointer', color: '#c4183c', marginRight: 20, lineHeight: '24px', fontSize: '16px' }}
					onClick={() => component.removeSplit(index)}>
					highlight_off
				</i> */}
				<Button
					theme="danger"
					outline
					className="mx-3"
					size="sm"
					onClick={() => component.removeSplit(index)}
					disabled={hasOpenFrontMe}>
					Remove
				</Button>
			</Row>
		</CardHeader>
		<CardBody>
			{!split._id && (
				<Row>
					<Col md="12">
						<p>
							Enter the email address of the other party, and we'll invite them to connect. The split will
							not be paid until they have accepted your invitation and set up their bank account, but your
							monthly projections will be updated immediately.
						</p>
					</Col>
				</Row>
			)}

			<Row>
				{split.status === undefined && (
					<Col md="3">
						<span className="text-semibold text-fiord-blue">Nickname</span>
						<FormInput
							size="md"
							className="mb-3"
							placeholder="Ben"
							value={split.nickname}
							onChange={event => component.onSplitFieldChange(index, event, 'nickname')}
						/>
					</Col>
				)}
				{split.status !== undefined && (
					<Col md="3">
						<span className="text-semibold text-fiord-blue">Nickname</span>
						<div className="text-semibold" style={{ fontSize: 16, marginTop: 6 }}>
							{split.nickname}
						</div>
					</Col>
				)}
				{split.status === undefined && (
					<Col md="5">
						<span className="text-semibold text-fiord-blue">Email Address</span>
						<FormInput
							size="md"
							className="mb-3"
							placeholder="ben@gmail.com"
							value={split.email}
							onChange={event => component.onSplitFieldChange(index, event, 'email')}
						/>
					</Col>
				)}
				{split.status !== undefined && split.user && (
					<Col md="5">
						<span className="text-semibold text-fiord-blue">Manifest User</span>
						<div
							className="text-semibold"
							style={{
								fontSize: 16,
								marginTop: 6,
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
							}}>
							{split.user.email}
						</div>
					</Col>
				)}
				{split.status !== undefined && !split.user && (
					<Col md="5">
						<span className="text-semibold text-fiord-blue">Invite Sent To</span>
						<div
							className="text-semibold"
							style={{
								fontSize: 16,
								marginTop: 6,
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
							}}>
							{split.email}
						</div>
						<span
							onClick={() => component.resendInvitation(split)}
							className="text-semibold text-accent"
							style={{ textDecoration: 'underline', fontWeight: '300', fontSize: 12, cursor: 'pointer' }}>
							Resend Invitation
						</span>
					</Col>
				)}
				{split.status === undefined && (
					<Col md="2">
						<span className="text-semibold text-fiord-blue">Role</span>
						<FormSelect
							size="md"
							value={split.role}
							style={{ fontWeight: '400' }}
							className="mb-3"
							onChange={event => component.onSplitFieldChange(index, event, 'role')}>
							{!split._id && <option value="">Select</option>}
							<option value={'bandMember'}>Band Member</option>
							<option value={'songwriter'}>Songwriter</option>
							<option value={'manager'}>Manager</option>
							<option value={'label'}>Label</option>
							<option value={'other'}>Other</option>
						</FormSelect>
					</Col>
				)}
				{split.status !== undefined && (
					<Col md="2">
						<span className="text-semibold text-fiord-blue">Role</span>
						<div className="text-semibold" style={{ fontSize: 16, marginTop: 6 }}>
							{labelForRole(split.role)}
						</div>
					</Col>
				)}
				<Col md="2">
					<span className="text-semibold text-fiord-blue">Percentage</span>
					<NumericInput
						className="form-control form-control-sm mb-3"
						min={0}
						max={split.percent + leftForChannelOwner}
						value={split.percent}
						onChange={number =>
							component.onSplitFieldChange(
								index,
								{ target: { value: Math.min(number, split.percent + leftForChannelOwner) } },
								'percent',
							)
						}
						disabled={hasOpenFrontMe}
					/>
				</Col>
			</Row>
		</CardBody>
	</Card>
);

export default class Splits extends Component {
	state = {
		loading: true,
		shouldBlockNavigation: false,
	};

	componentDidMount = async () => {
		let {
			_id: appId,
			donation,
			createdBy,
			frontMe: { hasOpenFrontMe },
		} = await waitForContainer(AppsContainer, 'currentApp');
		let user = await waitForContainer(Session, 'user');
		let { splits } = await API.get(`/admin/splits/${appId}`);

		this.setState(
			{
				saveEnabled: false,
				loading: false,
				user,
				createdBy,
				splits,
				hasOpenFrontMe,
				npos: null,
				donationEnabled: donation ? donation.enabled : false,
				donationNpo: donation && donation.npo ? donation.npo : '',
				donationPercent: donation ? donation.percent : 0,
				shouldBlockNavigation: false,
			},
			() =>
				this.updateValidation(() => {
					//I need to turn this back off since update validation turns it on.
					this.setState({ shouldBlockNavigation: false });
				}),
		);

		let { npos } = await API.get(`/admin/npo/`);
		this.setState({ npos });
	};

	componentDidUpdate = () => {
		if (this.state.shouldBlockNavigation) {
			window.onbeforeunload = () => true;
		} else {
			window.onbeforeunload = undefined;
		}
	};

	submit = async () => {
		let { donationEnabled, donationNpo, donationPercent, splits } = this.state;
		if (donationNpo === '') donationNpo = null;

		//validate now
		let leftForChannelOwner = 100;

		try {
			if (donationEnabled) {
				if (!donationNpo) {
					throw new Error(
						'Please select a nonprofit. If you do not want to support a nonprofit, use the checkbox to turn off support.',
					);
				}
				if (donationPercent <= 0) {
					throw new Error(
						'Please enter a percentage over 0 for your donation. If you do not want to support a nonprofit, use the checkbox to turn off support.',
					);
				}
				leftForChannelOwner -= donationPercent;
			}
			if (splits)
				splits.forEach(split => {
					if (!split._id && !split.nickname) {
						throw new Error('Please enter a nickname for each of your new splits.');
					}
					if (!split._id && !split.email) {
						throw new Error('Please enter an email address for each of your new splits.');
					}
					if (!split._id && !/\S+@\S+\.\S+/.test(split.email)) {
						throw new Error(
							`Please enter a valid email address for each of your new splits. ("${split.email}" isn't a valid email address.)`,
						);
					}
					if (split.percent <= 0) {
						throw new Error(
							'Please enter a percentage over 0 for each of your splits. If you do not want to include a particular party, use the red X to remove the split.',
						);
					}
					if (!split.role) {
						throw new Error('Please select a role for each of your splits.');
					}
					leftForChannelOwner -= split.percent;
				});

			if (leftForChannelOwner < 0) {
				throw new Error('Your donation plus your splits cannot be more than 100 percent.');
			}
		} catch (e) {
			alert(e.message);
			return;
		}

		let { _id: appId } = AppsContainer.getInstance().state.currentApp;

		try {
			this.setState({ loading: true });
			await API.post(`/admin/splits/${appId}`, {
				donationEnabled,
				donationNpo,
				donationPercent,
				splits,
			});

			this.setState({ shouldBlockNavigation: false });
			await AppsContainer.getInstance().refresh();
			setTimeout(() => this.componentDidMount(), 100);
		} catch (e) {
			this.setState({ loading: false });
			alert(`Oops! ${e.message}`);
		}
	};

	resendInvitation = async split => {
		try {
			let { _id: appId } = AppsContainer.getInstance().state.currentApp;
			await API.post(`/admin/splits/${appId}/${split.token}/resend-invitation`);
			alert(`We resent the invitation to ${split.email}.`);
		} catch (e) {
			alert(`Oops! ${e.message}`);
		}
	};

	addSplit = () => {
		this.setState(({ splits }) => {
			splits.unshift({
				email: '',
				nickname: '',
				percent: 0,
				key: Math.random(),
			});
			return { splits };
		}, this.updateValidation);
	};

	removeSplit = index => {
		if (this.state.splits[index]._id) {
			if (
				!window.confirm(
					`Are you sure you want to remove this split? This change will take effect when you save this page.`,
				)
			)
				return;
		}
		this.setState(({ splits }) => {
			splits.splice(index, 1);
			return { splits };
		}, this.updateValidation);
	};

	moveSplitDown = index => {
		this.setState(({ splits }) => {
			if (index + 1 < splits.length) {
				splits = arrayMove(splits, index, index + 1);
				return { splits };
			}
		}, this.updateValidation);
	};

	moveSplitUp = index => {
		this.setState(({ splits }) => {
			if (index - 1 >= 0) {
				splits = arrayMove(splits, index, index - 1);
				return { splits };
			}
		}, this.updateValidation);
	};

	onSplitFieldChange = (index, event, field) => {
		const newValue = event.target.value;
		this.setState(({ splits }) => {
			splits[index][field] = newValue;
			return { splits };
		}, this.updateValidation);
	};

	onFieldChange = (event, field) => {
		let newState = {};
		newState[field] = event.target.value;
		this.setState(newState, this.updateValidation);
	};

	isValid = () => true;
	updateValidation = callback =>
		this.setState({ saveEnabled: this.isValid(), shouldBlockNavigation: true }, callback);
	donationNpoChange = e => this.setState({ donationNpo: e.target.value }, this.updateValidation);
	toggleDonationEnabled = () => {
		if (this.state.npos && this.state.npos.length > 0) {
			this.setState({ donationEnabled: !this.state.donationEnabled, donationPercent: 0 }, this.updateValidation);
		}
	};

	gotoFrontMe = () => {
		this.props.history.push(`./front-me`);
	};

	render = () => {
		let leftForChannelOwner = 100;
		if (this.state.donationEnabled) leftForChannelOwner -= this.state.donationPercent;
		if (this.state.splits) this.state.splits.forEach(split => (leftForChannelOwner -= split.percent));

		let hasNpos = this.state.npos && this.state.npos.length > 0;
		let npoOptions =
			this.state.npos &&
			this.state.npos.map(npo => (
				<option key={npo._id} value={npo._id}>
					{npo.name}
				</option>
			));
		if (npoOptions)
			npoOptions.unshift(
				<option key={''} value="">
					Select
				</option>,
			);

		return (
			<Container className="page-container payments">
				<Prompt
					when={this.state.shouldBlockNavigation}
					message="You have unsaved changes, are you sure you want to leave?"
				/>
				{this.state.loading ? (
					<ScreenLoader />
				) : (
					<div>
						{this.state.hasOpenFrontMe && (
							<InfoBox
								message="You cannot change your splits until your Front Me is paid back."
								ctaButtonLabel="Front Me Details"
								onCtaButtonClick={this.gotoFrontMe}
							/>
						)}

						<Row noGutters className="page-header py-4">
							<PageTitle title="Splits" subtitle="Payments" className="text-sm-left mb-3" />
						</Row>
						<Row>
							<Col lg="9" md="12">
								<Card small className="mb-3">
									<CardHeader className="border-bottom d-flex" style={{ alignItems: 'baseline' }}>
										<h6 className="m-0" style={{ flex: 1 }}>
											{this.state.createdBy === this.state.user._id && `My Proceeds`}
											{this.state.createdBy !== this.state.user._id && `Channel Owner Proceeds`}
										</h6>
									</CardHeader>
									<CardBody style={{ padding: 30 }}>
										<h3 style={{ marginTop: 0, marginBottom: -4 }}>
											{leftForChannelOwner}%
											<span style={{ fontSize: 14, marginLeft: 10 }}>
												of this channel's revenue.
											</span>
										</h3>
									</CardBody>
								</Card>
								<Card small className="my-3">
									<CardHeader className="border-bottom d-flex" style={{ alignItems: 'baseline' }}>
										<h6 className="m-0" style={{ flex: 1 }}>
											Nonprofit
										</h6>
										<span style={{ fontWeight: 300, fontSize: 12 }}>
											Know a nonprofit who should be on Manifest?{' '}
											<a
												href="mailto:support@manifestfinancial.com"
												style={{ textDecoration: 'underline' }}>
												Contact us.
											</a>
										</span>
									</CardHeader>
									<CardBody>
										<FormCheckbox
											disabled={!hasNpos || this.state.hasOpenFrontMe}
											checked={this.state.donationEnabled}
											onChange={this.toggleDonationEnabled}>
											Support a nonprofit organization
										</FormCheckbox>
										{this.state.donationEnabled && (
											<Row style={{ marginTop: 20 }}>
												<Col md="10">
													<span className="text-semibold text-fiord-blue">
														Nonprofit Organization
													</span>
													<FormSelect
														size="md"
														value={this.state.donationNpo}
														style={{ fontWeight: '400' }}
														className="mb-3"
														onChange={this.donationNpoChange}>
														{npoOptions}
													</FormSelect>
												</Col>
												<Col md="2">
													<span className="text-semibold text-fiord-blue">Percentage</span>
													<NumericInput
														className="form-control form-control-sm mb-3"
														min={0}
														max={this.state.donationPercent + leftForChannelOwner}
														value={this.state.donationPercent}
														onChange={number =>
															this.onFieldChange(
																{
																	target: {
																		value: Math.min(
																			number,
																			this.state.donationPercent +
																				leftForChannelOwner,
																		),
																	},
																},
																'donationPercent',
															)
														}
													/>
												</Col>
											</Row>
										)}
									</CardBody>
								</Card>
								<Row noGutters className="pt-4" style={{ alignItems: 'center' }}>
									<h5 className="m-0">Other Splits</h5>
									<Button
										theme="dark"
										className="ml-3"
										size="sm"
										onClick={this.addSplit}
										disabled={this.state.hasOpenFrontMe}>
										Add
									</Button>
								</Row>

								{this.state.splits.map((item, index) => (
									<SplitCard
										split={item}
										key={item._id || item.key}
										index={index}
										component={this}
										leftForChannelOwner={leftForChannelOwner}
										hasOpenFrontMe={this.state.hasOpenFrontMe}
									/>
								))}

								{this.state.splits.length === 0 && (
									<Card small className="my-3 content-grid">
										<div
											style={{
												display: 'flex',
												flex: 1,
												alignItems: 'center',
												justifyContent: 'center',
												fontSize: 13,
												fontWeight: '300',
												height: 48,
											}}>
											Add others who should receive proceeds from your channel.
										</div>
									</Card>
								)}
							</Col>
							<Col lg="3" md="12">
								<Card small>
									<CardBody>
										<Row>
											<Col sm="12">
												<Button
													theme="accent"
													size="sm"
													style={{ width: '100%' }}
													disabled={!this.state.saveEnabled || this.state.hasOpenFrontMe}
													onClick={this.submit}>
													Save
												</Button>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</div>
				)}
			</Container>
		);
	};
}
