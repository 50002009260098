import React, { Component } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Button,
	FormInput,
	FormSelect,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Badge,
} from 'shards-react';

import AppsContainer from '../../data/AppsContainer';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';

import 'react-circular-progressbar/dist/styles.css';
import API from '../../data/API';
import waitForContainer from '../../utils/waitForContainer';
import { Prompt } from 'react-router';
import { NavLink } from 'react-router-dom';

const labelForVariantType = type =>
	type
		? {
				'12inch': '12-inch',
				'7inch': '7-inch',
				'2lp': '2-LP',
				cassette: 'Cassette',
				vhs: 'VHS',
				cd: 'CD',
				dvd: 'DVD',
		  }[type]
		: null;

export default class NewProduct extends Component {
	state = {
		status: '',
		items: [],
		amount: 0,
		paymentStatus: null,
		shippingAddress: null,
		carrier: null,
		trackingNumber: null,
		fulfillmentStatus: null,
		user: null,
		trackingSubmitEnabled: false,
		loading: true,
		orderId: null,
		shouldBlockNavigation: false,
		hasTrackingInfo: false,
		showingTrackingModal: false,
		showingRefundModal: false,
		refundSubmitEnabled: false,
		password: '',
		numberOfItems: 0,
	};

	componentDidMount = async () => {
		if (this.props.match.params && this.props.match.params.orderId) {
			let { orderId } = this.props.match.params;

			let app = await waitForContainer(AppsContainer, 'currentApp');
			let order = await API.get(`/admin/order/${app._id}/${orderId}`);

			if (order.paymentStatus === 'refunded') {
				order.status = 'refunded';
			} else {
				order.status = order.fulfillmentStatus;
			}

			let {
				status,
				description,
				items,
				numberOfItems,
				amount,
				paymentStatus,
				shippingAddress,
				carrier,
				trackingNumber,
				fulfillmentStatus,
				user,
			} = order;

			const hasTrackingInfo = carrier || trackingNumber;

			this.setState(
				{
					status,
					description,
					orderId,
					items,
					numberOfItems,
					amount,
					paymentStatus,
					shippingAddress,
					carrier,
					trackingNumber,
					fulfillmentStatus,
					user,
					hasTrackingInfo,
					loading: false,
				},
				() => {
					this.updateValidation(() => {
						//I need to turn this back off since update validation turns it on.
						this.setState({ shouldBlockNavigation: false });
					});
				},
			);
		} else {
			this.setState({ loading: false });
		}
	};

	componentDidUpdate = () => {
		if (this.state.shouldBlockNavigation) {
			window.onbeforeunload = () => true;
		} else {
			window.onbeforeunload = undefined;
		}
	};

	addTrackingInfo = () => {
		this.setState({ showingTrackingModal: true });
	};

	trackingSubmit = async () => {
		this.setState({ loading: true, showingTrackingModal: false });

		try {
			const { _id: appId } = AppsContainer.getInstance().state.currentApp;
			const { carrier, trackingNumber } = this.state;
			await API.put(`/admin/order/${appId}/${this.state.orderId}`, { carrier, trackingNumber });
			this.setState({ shouldBlockNavigation: false });
			this.componentDidMount();
		} catch (e) {
			this.setState({ loading: false });
			alert(`Oops! ${e.message}`);
		}
	};

	refundInit = async () => {
		this.setState({ showingRefundModal: true });
	};

	refund = async () => {
		this.setState({ loading: true, showingRefundModal: false });

		try {
			const { _id: appId } = AppsContainer.getInstance().state.currentApp;
			const { password } = this.state;
			await API.post(`/admin/order/${appId}/${this.state.orderId}/refund`, { password });
			this.setState({ shouldBlockNavigation: false, password: '' });
			this.componentDidMount();
		} catch (e) {
			this.setState({ loading: false, password: '' });
			alert(`Oops! ${e.message}`);
		}
	};

	isTrackingValid = () => {
		return this.state.carrier && this.state.trackingNumber;
	};

	isRefundValid = () => {
		return !!this.state.password;
	};

	updateValidation = callback => {
		this.setState(
			{
				trackingSubmitEnabled: this.isTrackingValid(),
				refundSubmitEnabled: this.isRefundValid(),
				shouldBlockNavigation: true,
			},
			callback,
		);
	};

	onFieldChange = (event, field) => {
		let newState = {};
		newState[field] = event.target.value;
		this.setState(newState, this.updateValidation);
	};

	render = () => {
		return (
			<Container className="page-container album-detail">
				<Prompt
					when={this.state.shouldBlockNavigation}
					message="You have unsaved changes, are you sure you want to leave?"
				/>
				{this.state.loading ? (
					<ScreenLoader />
				) : (
					<>
						<Row noGutters className="page-header py-4" style={{ alignItems: 'center' }}>
							<PageTitle title="Order Details" subtitle="Store" className="text-md-left mb-3" />
						</Row>
						<Row>
							<Col lg="9" md="12">
								<Card small>
									<CardHeader
										className="border-bottom d-flex flex-row"
										style={{ alignItems: 'center' }}>
										<h6 className="m-0" style={{ flex: 1 }}>
											{this.state.description}{' '}
											<Badge
												pill
												style={{ marginLeft: 6 }}
												theme={
													{
														unfulfilled: 'primary',
														fulfilled: 'success',
														refunded: 'dark',
													}[this.state.status]
												}>
												{this.state.status}
											</Badge>
										</h6>
									</CardHeader>
									<CardBody className="p-0">
										{this.state.items.map((item, idx) => (
											<div
												key={idx}
												className="px-4 py-3 border-bottom"
												style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
												{item.product.photos && item.product.photos.length > 0 && (
													<div
														alt={item.product.title}
														className="border rounded"
														style={{
															width: 50,
															height: 50,
															backgroundImage: `url('${item.product.photos[0].media.uri}')`,
															backgroundSize: 'cover',
															marginRight: 16,
														}}
													/>
												)}
												<div style={{ flex: 1 }}>
													{item.product.title}
													<br />
													<span style={{ fontWeight: '300' }}>
														{labelForVariantType(item.variantSnapshot.type)} (
														{item.variantSnapshot.sku})
													</span>
												</div>
												<div style={{ flex: 1, textAlign: 'right', marginRight: 40 }}>
													×{item.quantity}
												</div>
												<div>
													${((item.quantity * item.variantSnapshot.price) / 100).toFixed(2)}
												</div>
											</div>
										))}
										<div
											className="px-4 py-3 border-bottom"
											style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
											<div style={{ flex: 1, textAlign: 'right', marginRight: 40 }}>
												{this.state.numberOfItems === 1
													? '1 Item'
													: `${this.state.numberOfItems} Items`}
											</div>
											<div>${(this.state.amount / 100).toFixed(2)}</div>
										</div>
									</CardBody>
									<CardFooter />
								</Card>
								<Row className="mb-4">
									<Col lg={this.state.hasTrackingInfo ? 6 : 12}>
										<Card small className="mt-4" style={{ height: 170 }}>
											<CardHeader
												className="border-bottom d-flex flex-row"
												style={{ alignItems: 'center' }}>
												<h6 className="m-0" style={{ flex: 1 }}>
													Shipping Info
												</h6>
											</CardHeader>
											<CardBody className="px-4 py-3" style={{ fontWeight: '300' }}>
												{this.state.shippingAddress.name}
												<br />
												{this.state.shippingAddress.address1}
												<br />
												{this.state.shippingAddress.address2 && (
													<>
														{this.state.shippingAddress.address2}
														<br />
													</>
												)}
												{this.state.shippingAddress.city} {this.state.shippingAddress.state},{' '}
												{this.state.shippingAddress.zip}
											</CardBody>
										</Card>
									</Col>
									{this.state.hasTrackingInfo && (
										<Col lg="6">
											<Card small className="mt-4" style={{ height: 170 }}>
												<CardHeader
													className="border-bottom d-flex flex-row"
													style={{ alignItems: 'center' }}>
													<h6 className="m-0" style={{ flex: 1 }}>
														Tracking Info
													</h6>
													<Button theme="white" size="sm" onClick={this.addTrackingInfo}>
														Edit
													</Button>
												</CardHeader>
												<CardBody className="px-4 py-3" style={{ fontWeight: '300' }}>
													{this.state.carrier && (
														<>
															<span>{this.state.carrier}</span>
															<br />
														</>
													)}
													{this.state.carrier === 'FedEx' && (
														<a
															target="_blank"
															rel="noopener noreferrer"
															style={{ textDecoration: 'underline' }}
															href={`https://www.fedex.com/apps/fedextrack/?trkNbr=${this.state.trackingNumber}`}>
															{this.state.trackingNumber}
														</a>
													)}
													{this.state.carrier === 'UPS' && (
														<a
															target="_blank"
															rel="noopener noreferrer"
															style={{ textDecoration: 'underline' }}
															href={`https://www.ups.com/track?loc=null&tracknum=${this.state.trackingNumber}`}>
															{this.state.trackingNumber}
														</a>
													)}
													{this.state.carrier === 'USPS' && (
														<a
															target="_blank"
															rel="noopener noreferrer"
															style={{ textDecoration: 'underline' }}
															href={`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${this.state.trackingNumber}`}>
															{this.state.trackingNumber}
														</a>
													)}
													{this.state.carrier === 'DHL' && (
														<a
															target="_blank"
															rel="noopener noreferrer"
															style={{ textDecoration: 'underline' }}
															href={`https://www.dhl.com/en/express/tracking.html?brand=DHL&AWB=${this.state.trackingNumber}`}>
															{this.state.trackingNumber}
														</a>
													)}
													{this.state.carrier === 'Other' && this.state.trackingNumber}
												</CardBody>
											</Card>
										</Col>
									)}
								</Row>
							</Col>
							<Col lg="3" md="12">
								<Row>
									<Col sm="12">
										<Button
											tag={NavLink}
											theme="dark"
											size="sm"
											style={{ width: '100%' }}
											to="../orders">
											Back
										</Button>
									</Col>
								</Row>
								{this.state.fulfillmentStatus !== 'fulfilled' && (
									<Row className="mt-2">
										<Col sm="12">
											<Button
												theme="white"
												size="sm"
												style={{ width: '100%' }}
												onClick={this.addTrackingInfo}>
												Ship Order
											</Button>
										</Col>
									</Row>
								)}
								{this.state.paymentStatus !== 'refunded' && (
									<Row className="mt-2">
										<Col sm="12">
											<Button
												theme="white"
												size="sm"
												style={{ width: '100%' }}
												onClick={this.refundInit}>
												Refund Order
											</Button>
										</Col>
									</Row>
								)}
							</Col>
						</Row>
					</>
				)}
				<Modal centered size="md" open={this.state.showingTrackingModal}>
					<ModalHeader toggle={() => this.setState({ showingTrackingModal: false })}>
						{this.state.fulfillmentStatus === 'unfulfilled' ? 'Ship Order' : 'Edit Tracking Info'}
					</ModalHeader>
					<ModalBody>
						{this.state.fulfillmentStatus === 'unfulfilled' && (
							<p style={{ marginBottom: 20 }}>
								Once you've shipped the order, enter tracking info, and click below. We'll notify the
								customer that it's on its way!
							</p>
						)}
						{this.state.fulfillmentStatus === 'fulfilled' && (
							<p style={{ marginBottom: 20 }}>
								Edit the tracking info below. We'll notify the customer of the update.
							</p>
						)}
						<FormSelect
							value={this.state.carrier}
							className="mb-2"
							onChange={event => this.onFieldChange(event, 'carrier')}
							style={{ maxWidth: 300 }}>
							{[null, 'USPS', 'UPS', 'FedEx', 'DHL', 'Other'].map((item, index) => (
								<option value={item}>{index === 0 ? 'Select Carrier' : item}</option>
							))}
						</FormSelect>
						<FormInput
							size="md"
							className="mb-2"
							style={{ maxWidth: 300 }}
							placeholder={`Tracking Number`}
							value={this.state.trackingNumber}
							onChange={event => this.onFieldChange(event, 'trackingNumber')}
						/>
					</ModalBody>
					<ModalFooter>
						<Button
							theme="accent"
							size="sm"
							disabled={!this.state.trackingSubmitEnabled}
							onClick={this.trackingSubmit}>
							{this.state.hasTrackingInfo ? 'Update' : 'Order Has Shipped'}
						</Button>
					</ModalFooter>
				</Modal>
				<Modal centered size="md" open={this.state.showingRefundModal}>
					<ModalHeader toggle={() => this.setState({ showingRefundModal: false })}>Refund Order</ModalHeader>
					<ModalBody>
						<p style={{ marginBottom: 20 }}>
							Enter your password to issue a full refund. To issue a partial refund, reach out to{' '}
							<a href="mailto:support@manifestfinancial.com">support@manifestfinancial.com</a>.
						</p>
						<FormInput
							size="md"
							className="mb-2"
							style={{ maxWidth: 300 }}
							placeholder={`Password`}
							type="password"
							value={this.state.password}
							onChange={event => this.onFieldChange(event, 'password')}
						/>
					</ModalBody>
					<ModalFooter>
						<Button
							theme="accent"
							size="sm"
							disabled={!this.state.refundSubmitEnabled}
							onClick={this.refund}>
							Refund ${(this.state.amount / 100).toFixed(2)}
						</Button>
					</ModalFooter>
				</Modal>
			</Container>
		);
	};
}
