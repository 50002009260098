import React, { useState } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	ListGroup,
	CardFooter,
	Button,
	ListGroupItem,
	Badge,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from 'shards-react';
import { Subscribe } from 'unstated';
import AppsContainer from '../../data/AppsContainer';
import Session from '../../data/Session';
import ScreenLoader from '../../components/ScreenLoader';
import { Redirect } from 'react-router-dom';
import PageTitle from '../../components/common/PageTitle';
import API from '../../data/API';
import waitForContainer from '../../utils/waitForContainer';

export default props => {
	const [justWentLive, setJustWentLive] = useState(false);

	const goLive = async () => {
		if (!window.confirm(`Ready to rock?`)) return;
		const { _id: appId } = await waitForContainer(AppsContainer, 'currentApp');
		await API.post(`/admin/channel/${appId}/live`);
		setJustWentLive(true);
	};

	const gotoAudio = async () => {
		const { appCode } = await waitForContainer(AppsContainer, 'currentApp');
		await AppsContainer.getInstance().refresh();
		props.history.push(`/channel/${appCode}/albums`);
	};

	const gotoPost = async () => {
		const { appCode } = await waitForContainer(AppsContainer, 'currentApp');
		await AppsContainer.getInstance().refresh();
		props.history.push(`/channel/${appCode}/posts`);
	};

	const gotoVideo = async () => {
		const { appCode } = await waitForContainer(AppsContainer, 'currentApp');
		await AppsContainer.getInstance().refresh();
		props.history.push(`/channel/${appCode}/videos`);
	};

	return (
		<Container className="page-container">
			<Row noGutters className="page-header py-4">
				{/* Page Header :: Title */}
				<PageTitle title="Pre‑Launch Checklist" subtitle="Getting Started" className="text-sm-left mb-3" />
			</Row>
			<Row>
				<Col>
					<Subscribe to={[AppsContainer, Session]}>
						{({ state: { currentApp } }, { state: { user } }) => {
							if (!currentApp || !user) {
								return <ScreenLoader />;
							} else {
								const paymentsComplete = !!(user.stripe && user.stripe.accountId);
								const profileComplete = !!(
									currentApp.theme &&
									currentApp.theme.hero &&
									currentApp.theme.hero.uri
								);
								const musicComplete = !!(currentApp.albums && currentApp.albums.length > 0);
								const liveButtonEnabled = paymentsComplete && profileComplete && musicComplete;

								let checklistItems = [
									{
										label: `Create An Account`,
										description: `You'll use the same login here and in the Manifest app.`,
										complete: true,
										buttonLabel: ``,
										buttonIcon: ``,
										buttonLink: ``,
										estimatedMinutes: 2,
									},
									{
										label: `Tell Us About Your Channel`,
										description: `Upload your artwork and personalize.`,
										complete: profileComplete,
										buttonLabel: `Personalize`,
										buttonIcon: `👨‍🎤`,
										buttonLink: `about`,
										estimatedMinutes: 2,
										completeButtonLabel: `Complete (tweak?)`,
										completeButtonLink: `about`,
									},
									{
										label: `Add Music`,
										description: `Add at least one album to your Manifest channel to kick things off.`,
										complete: musicComplete,
										buttonLabel: `Add An Album`,
										buttonIcon: `🎶`,
										buttonLink: `new-album`,
										estimatedMinutes: 3,
										completeButtonLabel: `Complete (add more?)`,
										completeButtonLink: `albums`,
									},
									{
										label: `Set Up Payments`,
										description: `Connect your debit card or bank account to get paid.`,
										complete: paymentsComplete,
										buttonLabel: `Connect Your Bank`,
										buttonIcon: `💰`,
										buttonLink: `/profile/bank`,
										estimatedMinutes: 3,
									},
									{
										label: `Launch Your Channel`,
										description: `Make your channel available to the world!`,
										complete: currentApp.verified,
										enabled: liveButtonEnabled,
										buttonLabel: `Launch Your Channel`,
										buttonIcon: `🚀`,
										buttonCallback: goLive,
										estimatedMinutes: 0,
									},
								];

								let totalEstimatedMinutes = checklistItems
									.filter(item => !item.complete)
									.reduce((total, item) => total + item.estimatedMinutes, 0);
								let totalEstimatedMinutesUnit = totalEstimatedMinutes === 1 ? 'minute' : 'minutes';

								return (
									<>
										<Card className="activation-checklist">
											{/* Card Header */}
											{liveButtonEnabled ? (
												<CardHeader className="border-bottom">
													<p>
														<h5 style={{ marginBottom: -20 }}>
															Nice job! The next step is to launch your channel!
															<br />
															You can <a href="albums">upload additional albums</a> before
															you go live.
														</h5>
														<br />
														<em
															style={{ fontWeight: 300 }}
															className="activation-checklist__meta">
															Feel free to{' '}
															<strong>
																<a href="mailto:support@manifestfinancial.com">
																	email us
																</a>
															</strong>{' '}
															if you have any questions about going live.
														</em>
													</p>
												</CardHeader>
											) : (
												<CardHeader className="border-bottom">
													<p>
														<h5 style={{ marginBottom: -20 }}>
															Before you can go live, there are a few things that need to
															be done.
														</h5>
														<br />
														<em
															style={{ fontWeight: 300 }}
															className="activation-checklist__meta">
															Looks like you'll be ready to roll in about{' '}
															{totalEstimatedMinutes} {totalEstimatedMinutesUnit}.
														</em>
													</p>
												</CardHeader>
											)}

											<CardBody className="py-0">
												{/* Goals Overview List Group */}
												<ListGroup flush>
													{checklistItems.map((item, index) => (
														<ListGroupItem className="d-flex row px-0 py-4" key={index}>
															<Col xl="7" lg="8" md="8" sm="7">
																<h6 className="activation-checklist__label mb-1">
																	{item.label}
																	{!item.complete && item.estimatedMinutes > 0 && (
																		<Badge theme="info">
																			~{item.estimatedMinutes} min
																		</Badge>
																	)}
																</h6>
																<div className="activation-checklist__meta">
																	<span className="mr-2">{item.description}</span>
																</div>
															</Col>
															<Col
																lg="3"
																md="4"
																sm="5"
																style={{ padding: 6, paddingTop: 8 }}>
																{item.complete ? (
																	<Button
																		pill
																		theme="success"
																		onClick={
																			item.completeButtonLink &&
																			(() =>
																				props.history.push(
																					item.completeButtonLink,
																				))
																		}>
																		<i
																			className="material-icons"
																			style={{
																				fontSize: 18,
																				verticalAlign: 'sub',
																			}}>
																			check_circle
																		</i>{' '}
																		{item.completeButtonLabel || `Complete`}
																	</Button>
																) : (
																	<Button
																		pill
																		theme="white"
																		disabled={
																			item.enabled !== undefined && !item.enabled
																		}
																		onClick={() => {
																			if (item.buttonCallback) {
																				item.buttonCallback();
																			} else {
																				props.history.push(item.buttonLink);
																			}
																		}}>
																		<span
																			style={{
																				fontSize: 18,
																				verticalAlign: 'sub',
																			}}>
																			{item.buttonIcon}
																		</span>{' '}
																		{item.buttonLabel}
																	</Button>
																)}
															</Col>
														</ListGroupItem>
													))}
												</ListGroup>
											</CardBody>

											{/* Footer */}
											<CardFooter className="border-top">
												<Row />
											</CardFooter>
										</Card>
										<Modal centered size="md" open={justWentLive}>
											<ModalHeader>
												<span>
													<span role="img" aria-label="party">
														🎉
													</span>{' '}
													Your Channel Is Live!
												</span>
											</ModalHeader>
											<ModalBody>
												<ul>
													<li>
														Your fans can sign up at{' '}
														<strong>
															<a
																href={`https://manifestfinancial.com/${currentApp.appCode}`}
																target="_blank"
																rel="noopener noreferrer">
																manifestfinancial.com/{currentApp.appCode}
															</a>
														</strong>
													</li>
													<li>
														To celebrate, we've given you a free all-channel{' '}
														{currentApp.category} subscription! Just use this same Manifest
														login in the app.
													</li>
													<li>
														Any questions?{' '}
														<strong>
															<a href="mailto:support@manifestfinancial.com">Email us.</a>
														</strong>
													</li>
												</ul>
											</ModalBody>
											<ModalFooter style={{ textAlign: 'center', display: 'flex' }}>
												<div style={{ flex: 1 }} />
												<Button theme="accent" size="sm" onClick={gotoAudio}>
													Add More Audio
												</Button>
												<Button theme="accent" size="sm" onClick={gotoPost}>
													Create A Post
												</Button>
												<Button theme="accent" size="sm" onClick={gotoVideo}>
													Upload A Video
												</Button>
												<div style={{ flex: 1 }} />
											</ModalFooter>
										</Modal>
									</>
								);
							}
						}}
					</Subscribe>
				</Col>
			</Row>
		</Container>
	);
};
