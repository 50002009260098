export const LAYOUT_TYPES = {
	DEFAULT: 'DEFAULT',
	HEADER_NAVIGATION: 'HEADER_NAVIGATION',
	ICON_SIDEBAR: 'ICON_SIDEBAR',
};
export const COUNTRY_OPTIONS = [
	{
		value: '',
		label: 'Please select country',
	},
	{
		value: 'US',
		label: 'US',
	},
	{
		value: 'CA',
		label: 'CA',
	},
];
