var formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',

	// These options are needed to round to whole numbers if that's what you want.
	//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const currency = num => {
	// return `$${Number(num).toFixed(2)}`;
	return formatter.format(num);
};

export const roundCurrency = num => {
	return `$${Math.round(Number(num)).toLocaleString()}`;
};

export const artistShareOfMemberPrice = (price, display) => {
	let stripeFee = Math.round(price * 0.029 + 30);
	let nerveFee = 155;
	let artistShare = (price - stripeFee - nerveFee) / 100;
	if (display) {
		return currency(artistShare);
	} else {
		return artistShare;
	}
};

export const artistShareOfVipPrice = (price, display) => {
	let stripeFee = Math.round(price * 0.029 + 30);
	let nerveFee = 200;
	let artistShare = (price - stripeFee - nerveFee) / 100;
	if (display) {
		return currency(artistShare);
	} else {
		return artistShare;
	}
};
