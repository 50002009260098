import React from 'react';
import ReactTable from 'react-table';
import FuzzySearch from 'fuzzy-search';
import dateFormat from 'dateformat';
import { Container, Row, Col, ButtonGroup, Button, Card, CardHeader, CardBody, FormSelect, InputGroup, InputGroupAddon, InputGroupText, FormInput } from 'shards-react';

import PageTitle from '../../components/common/PageTitle';
import RangeDatePicker from '../../components/common/RangeDatePicker';
import getTransactionHistoryData from '../../data/transaction-history-data';

class TransactionHistory extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			pageSizeOptions: [5, 10, 15, 20, 25, 30],
			pageSize: 10,
			tableData: [],
		};

		this.searcher = null;

		this.getStatusClass = this.getStatusClass.bind(this);
		this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
		this.handleFilterSearch = this.handleFilterSearch.bind(this);
		this.handleItemEdit = this.handleItemEdit.bind(this);
		this.handleItemDelete = this.handleItemDelete.bind(this);
		this.handleItemConfirm = this.handleItemConfirm.bind(this);
		this.handleItemViewDetails = this.handleItemViewDetails.bind(this);
	}

	componentWillMount() {
		const tableData = getTransactionHistoryData();

		this.setState({
			...this.state,
			tableData,
		});

		// Initialize the fuzzy searcher.
		this.searcher = new FuzzySearch(tableData, ['customer', 'status'], {
			caseSensitive: false,
		});
	}

	/**
	 * Returns the appropriate status class for the `Status` column.
	 */
	getStatusClass(status) {
		const statusMap = {
			Cancelled: 'danger',
			Complete: 'success',
			Pending: 'warning',
		};

		return `text-${statusMap[status]}`;
	}

	/**
	 * Handles the page size change event.
	 */
	handlePageSizeChange(e) {
		this.setState({
			...this.state,
			pageSize: e.target.value,
		});
	}

	/**
	 * Handles the global search.
	 */
	handleFilterSearch(e) {
		this.setState({
			...this.state,
			tableData: this.searcher.search(e.target.value),
		});
	}

	/**
	 * Mock method for editing transactions.
	 */
	handleItemEdit(row) {
		alert(`Editing transaction "${row.original.id}"!`);
	}

	/**
	 * Mock method for deleting transactions.
	 */
	handleItemDelete(row) {
		alert(`Deleting transaction "${row.original.id}"!`);
	}

	/**
	 * Mock method for confirming transactions.
	 */
	handleItemConfirm(row) {
		alert(`Confirming transaction "${row.original.id}"!`);
	}

	/**
	 * Mock method for confirming transactions.
	 */
	handleItemViewDetails(row) {
		alert(`Viewing details for "${row.original.id}"!`);
	}

	render() {
		const { tableData, pageSize, pageSizeOptions } = this.state;
		const tableColumns = [
			{
				Header: '#',
				accessor: 'id',
				maxWidth: 60,
				className: 'text-center',
			},
			{
				Header: 'Date',
				accessor: 'date',
				className: 'text-center',
				minWidth: 200,
				Cell: (row) => dateFormat(new Date(row.original.date), 'dddd, mmmm dS, yyyy'),
			},
			{
				Header: 'Customer',
				accessor: 'customer',
				className: 'text-center',
			},
			{
				Header: 'Products',
				accessor: 'products',
				maxWidth: 100,
				className: 'text-center',
			},
			{
				Header: 'Status',
				accessor: 'status',
				maxWidth: 100,
				Cell: (row) => <span className={this.getStatusClass(row.original.status)}>{row.original.status}</span>,
				className: 'text-center',
			},
			{
				Header: 'Total',
				accessor: 'total',
				maxWidth: 100,
				Cell: (row) => <span className='text-success'>{row.original.total}</span>,
				className: 'text-center',
			},
			{
				Header: 'Actions',
				accessor: 'actions',
				maxWidth: 300,
				minWidth: 180,
				sortable: false,
				Cell: (row) => (
					<ButtonGroup size='sm' className='d-table mx-auto'>
						<Button theme='white' onClick={() => this.handleItemConfirm(row)}>
							<i className='material-icons'>&#xE5CA;</i>
						</Button>
						<Button theme='white' onClick={() => this.handleItemViewDetails(row)}>
							<i className='material-icons'>&#xE870;</i>
						</Button>
						<Button theme='white' onClick={() => this.handleItemEdit(row)}>
							<i className='material-icons'>&#xE254;</i>
						</Button>
						<Button theme='white' onClick={() => this.handleItemDelete(row)}>
							<i className='material-icons'>&#xE872;</i>
						</Button>
					</ButtonGroup>
				),
			},
		];

		return (
			<Container fluid className='main-content-container px-4 pb-4'>
				<Row noGutters className='page-header py-4'>
					<PageTitle title='Transaction History' subtitle='Dashboards' className='text-sm-left mb-3' />
					<Col sm='4' className='d-flex ml-auto my-auto'>
						<RangeDatePicker className='justify-content-end' />
					</Col>
				</Row>
				<Card className='p-0'>
					<CardHeader className='p-0'>
						<Container fluid className='file-manager__filters border-bottom'>
							<Row>
								{/* Filters :: Page Size */}
								<Col className='file-manager__filters__rows d-flex' md='6'>
									<span>Show</span>
									<FormSelect size='sm' value={this.state.pageSize} onChange={this.handlePageSizeChange}>
										{pageSizeOptions.map((size, idx) => (
											<option key={idx} value={size}>
												{size} rows
											</option>
										))}
									</FormSelect>
								</Col>

								{/* Filters :: Search */}
								<Col className='file-manager__filters__search d-flex' md='6'>
									<InputGroup seamless size='sm' className='ml-auto'>
										<InputGroupAddon type='prepend'>
											<InputGroupText>
												<i className='material-icons'>search</i>
											</InputGroupText>
										</InputGroupAddon>
										<FormInput onChange={this.handleFilterSearch} />
									</InputGroup>
								</Col>
							</Row>
						</Container>
					</CardHeader>
					<CardBody className='p-0'>
						<div className=''>
							<ReactTable columns={tableColumns} data={tableData} pageSize={pageSize} showPageSizeOptions={false} resizable={false} />
						</div>
					</CardBody>
				</Card>
			</Container>
		);
	}
}

export default TransactionHistory;
