export class BankingAdminPermissionGroups {
	static groups = {};
}

const getUnpackedPermissions = (permissions = [], groups = BankingAdminPermissionGroups.groups) => {
	const result = [];
	permissions.forEach(permission => {
		if (!groups[permission]) {
			result.push(permission);
		} else {
			result.push(...groups[permission].map(p => p));
		}
	});
	return [...new Set(result)];
};

export const validatePermissions = (permissions, required) => {
	const unpackedPermissions = getUnpackedPermissions(permissions);
	return getUnpackedPermissions(required).every(p => unpackedPermissions.includes(p));
};
