import React, { useState, useEffect, useRef } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	Button,
	Form,
	FormGroup,
	FormInput,
	FormSelect,
	Modal,
	ModalBody,
	InputGroupText,
	InputGroupAddon,
	InputGroup,
	Alert,
} from 'shards-react';
import { Subscribe } from 'unstated';
import Session from '../../data/Session';
import ScreenLoader from '../../components/ScreenLoader';
import RequirePermissions from '../../components/RequirePermissions';

import PageTitle from '../../components/common/PageTitle';
import API from '../../data/API';
import ReactTable from 'react-table';
import { toTitleCase } from 'utils/string';

const associatedTypesList = ['user', 'enterprise', 'third-party'];

const AffiliatesList = ({ history }) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	const [submiting, setSubmiting] = useState(false);
	const [newSlug, setNewSlug] = useState('');
	const [associatedId, setAssociatedId] = useState('');
	const [associatedType, setAssociatedType] = useState(null);
	const [displayName, setDisplayName] = useState('');

	const [modalOpen, setModalOpen] = useState(false);

	const [showLinkCopiedAlert, setShowLinkCopiedAlert] = useState(false);

	const timeoutRef = useRef(null);

	const toggleModal = () => {
		setModalOpen(!modalOpen);
	};

	const onRefresh = async () => {
		setLoading(true);
		try {
			await getList();
		} catch (error) {
			console.error('Error fetching affiliate details:', error);
		}
		setLoading(false);
	};

	useEffect(() => {
		(async () => {
			await onRefresh();
		})();
	}, []);

	const createNewAffiliate = async () => {
		setSubmiting(true);
		try {
			await API.post(`/banking-admin/affiliate/create`, {
				slug: newSlug,
				associatedId: associatedId,
				associatedType: associatedType,
				displayName,
			});
			await getList();
		} catch (error) {
			console.error('Error adding new slug:', error);
			// TODO: error handling
			alert('Error adding new slug:', error);
		}
		setSubmiting(false);
		toggleModal();
	};

	const tableColumns = [
		{
			Header: 'Display Name',
			accessor: 'displayName',
			className: 'text-center d-flex align-items-center',
			maxWidth: 150,
			sortable: false,
		},
		{
			sortable: false,
			accessor: 'slug',
			Header: 'Link',
			className: 'text-center d-flex align-items-center',
			// maxWidth: 100,
			Cell: row => (
				<div>
					{process.env.REACT_APP_AFFILIATE_PREFIX_URL}/{row.original.slug}
				</div>
			),
		},
		{
			sortable: false,
			// accessor: 'slug',
			Header: '',
			className: 'text-center d-flex align-items-center',
			maxWidth: 150,
			Cell: row => (
				<div
					className="copy-link"
					onClick={() => copyLink(row.original.slug)}
					style={{
						textDecorationLine: 'underline',
						cursor: 'pointer',
					}}>
					Copy Link
				</div>
			),
		},
	];

	const getList = async () => {
		const response = await API.get(`/banking-admin/affiliate/list`);
		console.log('response.data', response);
		if (response) {
			setData(response.items);
		}
	};

	const copyLink = async link => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		await navigator.clipboard.writeText(`${process.env.REACT_APP_AFFILIATE_PREFIX_URL}/${link}`);
		setShowLinkCopiedAlert(true);
		timeoutRef.current = setTimeout(() => {
			setShowLinkCopiedAlert(false);
		}, 2000);
	};

	return (
		<Container className="page-container">
			<Subscribe to={[Session]}>
				{({ state: { user } }) => {
					if (!user) {
						return <ScreenLoader />;
					} else {
						return (
							<>
								<Row noGutters className="page-header py-4 d-flex justify-content-between">
									<PageTitle title="Affiliate" subtitle="Banking" className="text-sm-left mb-3 p-0" />
								</Row>
								<Alert theme="success" open={showLinkCopiedAlert}>
									Affiliate link copied to clipboard
								</Alert>

								<Modal open={modalOpen} toggle={toggleModal}>
									<ModalBody>
										<Col lg="12" md="12">
											<Row className="px-3">
												<Col lg="12" md="12">
													<Form onSubmit={e => e.preventDefault()}>
														<FormGroup>
															<label htmlFor="displayName">Display Name: </label>
															<FormInput
																id="displayName"
																type="text"
																value={displayName}
																onChange={event => setDisplayName(event.target.value)}
															/>
														</FormGroup>
														<FormGroup>
															<label htmlFor="addNewSlug">Link: </label>
															<InputGroup>
																<InputGroupAddon type="prepend">
																	<InputGroupText>
																		{process.env.REACT_APP_AFFILIATE_PREFIX_URL}/
																	</InputGroupText>
																</InputGroupAddon>
																<FormInput
																	id="addNewSlug"
																	type="text"
																	value={newSlug}
																	onChange={event => setNewSlug(event.target.value)}
																/>
															</InputGroup>
														</FormGroup>
														<FormGroup>
															<label htmlFor="accociateType">Type: </label>
															<FormSelect
																id="accociateType"
																type="text"
																value={associatedType ?? 'Please select associate type'}
																onChange={event =>
																	setAssociatedType(event.target.value)
																}>
																<option value={null} disabled>
																	Please select associate type
																</option>
																{associatedTypesList.map((type, index) => (
																	<option key={index} value={type}>
																		{type}
																	</option>
																))}
															</FormSelect>
														</FormGroup>

														<FormGroup>
															<label htmlFor="accociateId">
																{associatedType
																	? toTitleCase(associatedType)
																	: 'Associated'}{' '}
																ID:{' '}
															</label>
															<FormInput
																id="accociateId"
																type="text"
																value={associatedId}
																onChange={event => setAssociatedId(event.target.value)}
															/>
														</FormGroup>
														<Button
															pill
															theme="accent"
															className="d-table mx-auto"
															onClick={createNewAffiliate}
															loading={submiting}
															disabled={!(associatedId && associatedType && newSlug)}>
															Create
														</Button>
													</Form>
												</Col>
											</Row>
										</Col>
									</ModalBody>
								</Modal>
								<RequirePermissions permissions={['business.read']} showRequiredPermissions>
									<Row>
										<Col lg="12" md="12">
											<Card small className="mb-3">
												<CardHeader className="border-bottom d-flex flex-row justify-content-end">
													<RequirePermissions
														permissions={['business.write']}
														showRequiredPermissions>
														<div
															style={{
																minWidth: 260,
																maxWidth: 380,
																alignSelf: 'flex-end',
																display: 'flex',
																justifyContent: 'flex-end',
															}}>
															<Button onClick={toggleModal} theme="secondary">
																Add New Affiliate
															</Button>
														</div>
													</RequirePermissions>
												</CardHeader>

												<CardBody className="p-0">
													<ReactTable
														className="-highlight"
														NoDataComponent={() =>
															data.length <= 0 && !loading ? (
																<div className="rt-noData">No result found</div>
															) : null
														}
														loadingText="Loading"
														loading={loading}
														columns={tableColumns}
														data={data}
														resizable={false}
														manual
														usePagination={false}
														showPaginationBottom={false}
														getTdProps={() => ({
															style: { borderLeft: `none`, borderRight: `none` },
														})}
														getTrProps={(state, rowInfo) => {
															if (rowInfo && rowInfo.row) {
																return {
																	onClick: e => {
																		if (e.target.className === 'copy-link') {
																			return;
																		}
																		history.push(
																			`/profile/affiliates/details/${rowInfo.original.id}`,
																		);
																	},
																	style: {
																		cursor: 'pointer',
																	},
																};
															} else {
																return {};
															}
														}}
													/>
												</CardBody>
											</Card>
										</Col>
									</Row>
								</RequirePermissions>
							</>
						);
					}
				}}
			</Subscribe>
		</Container>
	);
};

export default AffiliatesList;
