import React, { useState, useEffect } from 'react';
import {
	Row,
	Col,
	Card,
	CardBody,
	CardHeader,
	Button,
	Modal,
	ModalBody,
	ModalHeader,
	FormInput,
	FormSelect,
} from 'shards-react';
import PasswordAndCommentModal, { useToggleModal } from '../../../components/common/PasswordAndCommentModal';
import RequirePermissions from '../../../components/RequirePermissions';

import API from '../../../data/API';
import { COUNTRY_OPTIONS } from '../../../utils/constants';

const AddressDisplay = ({ userId }) => {
	const [open, toggle] = useToggleModal();

	const [address, setAddress] = useState({
		streetLine1: '',
		streetLine2: '',
		city: '',
		state: '',
		country: '',
		postalCode: '',
	});
	const [fieldFirstBlur, setFieldFirstBlur] = useState({
		streetLine1: false,
		streetLine2: false,
		city: false,
		state: false,
		country: false,
		postalCode: false,
	});
	const [stateLabel, setStateLabel] = useState('State');
	const [cityLabel, setCityLabel] = useState('City');
	const [postalCodeLabel, setPostalCodeLabel] = useState('ZIP Code');
	const [countryIndex, setCountryIndex] = useState((address.country.toUpperCase() === 'CA') === 'CA' ? 0 : 1);
	const [isValid, setIsValid] = useState(false);

	const [mode, setMode] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setCountryIndex(address.country.toUpperCase() === 'CA' ? 0 : 1);
		setIsValid(validateField('all-fields'));
	}, [address, countryIndex]);

	useEffect(() => {
		if (countryIndex === 0) {
			//CA
			setStateLabel('Province');
			setCityLabel('Municipality');
			// setTaxIdMask('999-999-999');
			setPostalCodeLabel('Postal Code');
		} else if (countryIndex === 1) {
			//US
			setStateLabel('State');
			setCityLabel('City');
			// setTaxIdMask('999-99-9999');
			setPostalCodeLabel('ZIP Code');
		}
		// setPersonalInfo({
		// 	...address,
		// 	tin: formatPersonalInfo('tin', address.tin, { countryIndex }),
		// 	postalCode: formatPersonalInfo('postalCode', address.postalCode, { countryIndex }),
		// 	mailingPostalCode: formatPersonalInfo('postalCode', address.mailingPostalCode, { countryIndex }),
		// });
		if (address.postalCode.length > 0) {
			setFieldFirstBlur({ ...fieldFirstBlur, postalCode: true });
		}
	}, [countryIndex]);

	const formatAddress = (field, value, option = { reverse: false, countryIndex: 1 }) => {
		const { reverse, countryIndex } = option;
		let _value = value;

		switch (field) {
			case 'country':
				return _value.toUpperCase();
			case 'postalCode':
				if (countryIndex === 1) {
					_value = _value.replace(/[\D]+/gi, '');
					if (_value.length > 5) {
						_value = _value.replace(/(\d{5})(\d+)/, '$1');
					}
				}
				return _value;

			default:
				return _value;
		}
	};

	const validateField = field => {
		const fields = Object.keys(address);

		switch (field) {
			case 'streetLine1':
				return address[field];
			case 'streetLine2':
				return true;
			case 'city':
				return address[field];
			case 'state':
				return address[field];
			case 'country':
				return address[field];
			case 'postalCode':
				let postalCodeRegex = /\d{5}/;
				if (countryIndex === 0) {
					postalCodeRegex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
				}
				return address[field] && postalCodeRegex.test(address[field]);
			case 'all-fields':
				return fields.every(field => validateField(field));
			default:
				return true;
		}
	};

	const submitAction = async (password, comment) => {
		setLoading(true);
		await API.patch(`/banking-admin/user/address/${userId}`, {
			password,
			comment,
			payload: address,
		});
		setLoading(false);
	};

	const viewAddress = async () => {
		setLoading(true);
		const { address } = await API.get(`/banking-admin/user/address/${userId}`);
		setAddress(address);
		setLoading(false);
		setMode('view');
	};

	const onAddressChange = (field, value) => {
		const _value = formatAddress(field, value, { countryIndex });
		setAddress({
			...address,
			[field]: _value,
		});
	};

	return (
		<>
			<PasswordAndCommentModal actionLabel="change address" onConfirm={submitAction} toggle={open} />
			<Row className="py-4 d-flex justify-content-between">
				<Col
					xs="12"
					sm="12"
					md="12"
					lg="12"
					xl="12"
					// className="col d-flex align-items-center justify-content-between"
				>
					<Card small className="mb-3">
						<CardHeader className="border-bottom col d-flex align-items-center justify-content-between">
							<h6 className="m-0">Address</h6>
							<div>
								{mode === 'view' ? (
									<RequirePermissions permissions="user.write">
										<Button onClick={() => setMode('edit')} disabled={loading}>
											Edit
										</Button>
									</RequirePermissions>
								) : mode === 'edit' ? (
									<RequirePermissions permissions="user.write">
										<Button theme="success" onClick={toggle} disabled={loading || !isValid}>
											Save
										</Button>
									</RequirePermissions>
								) : (
									<RequirePermissions permissions="user.read">
										<Button onClick={viewAddress} disabled={loading}>
											View
										</Button>
									</RequirePermissions>
								)}
							</div>
						</CardHeader>
						{mode ? (
							<CardBody className="p-0">
								<RequirePermissions
									showRequiredPermissions
									permissions={mode === 'view' ? 'user.read' : 'user.write'}>
									<Row className="m-2">
										<Col md="6">
											<span className="text-semibold text-fiord-blue">Street Line 1</span>
											<FormInput
												size="md"
												className="mb-3"
												placeholder="Street Line 1"
												value={address.streetLine1}
												required
												disabled={mode !== 'edit' || loading}
												onChange={event => onAddressChange('streetLine1', event.target.value)}
												invalid={fieldFirstBlur.postalCode && !validateField('streetLine1')}
												onBlur={() =>
													setFieldFirstBlur({ ...fieldFirstBlur, streetLine1: true })
												}
											/>
										</Col>
									</Row>
									<Row className="m-2">
										<Col md="6">
											<span className="text-semibold text-fiord-blue">Street Line 2</span>
											<FormInput
												size="md"
												className="mb-3"
												placeholder="Street Line 2"
												value={address.streetLine2}
												required
												disabled={mode !== 'edit' || loading}
												onChange={event => onAddressChange('streetLine2', event.target.value)}
												invalid={fieldFirstBlur.postalCode && !validateField('streetLine2')}
												onBlur={() =>
													setFieldFirstBlur({ ...fieldFirstBlur, streetLine2: true })
												}
											/>
										</Col>
									</Row>
									<Row className="m-2">
										<Col md="6">
											<span className="text-semibold text-fiord-blue">{cityLabel}</span>
											<FormInput
												size="md"
												className="mb-3"
												placeholder={cityLabel}
												value={address.city}
												required
												disabled={mode !== 'edit' || loading}
												onChange={event => onAddressChange('city', event.target.value)}
												invalid={fieldFirstBlur.postalCode && !validateField('city')}
												onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, city: true })}
											/>
										</Col>
									</Row>
									<Row className="m-2">
										<Col md="6">
											<span className="text-semibold text-fiord-blue">{stateLabel}</span>
											<FormInput
												size="md"
												className="mb-3"
												placeholder={stateLabel}
												value={address.state}
												required
												disabled={mode !== 'edit' || loading}
												onChange={event => onAddressChange('state', event.target.value)}
												invalid={fieldFirstBlur.postalCode && !validateField('state')}
												onBlur={() => setFieldFirstBlur({ ...fieldFirstBlur, state: true })}
											/>
										</Col>
									</Row>

									<Row className="m-2">
										<Col md="6">
											<span className="text-semibold text-fiord-blue">Country</span>
											{mode === 'edit' ? (
												<FormSelect
													size="md"
													className="mb-3"
													placeholder="Country"
													value={address.country}
													disabled={mode !== 'edit' || loading}
													onChange={event => onAddressChange('country', event.target.value)}>
													{COUNTRY_OPTIONS.map(({ value, label }) => (
														<option key={value + label} value={value}>
															{label}
														</option>
													))}
												</FormSelect>
											) : (
												<FormInput
													size="md"
													className="mb-3"
													placeholder="Country"
													disabled
													value={address.country}
													required
												/>
											)}
										</Col>
									</Row>
									<Row className="m-2">
										<Col md="6">
											<span className="text-semibold text-fiord-blue">{postalCodeLabel}</span>
											<FormInput
												size="md"
												className="mb-3"
												placeholder={postalCodeLabel}
												value={address.postalCode}
												required
												disabled={mode !== 'edit' || loading}
												onChange={event => onAddressChange('postalCode', event.target.value)}
												invalid={fieldFirstBlur.postalCode && !validateField('postalCode')}
												onBlur={() =>
													setFieldFirstBlur({ ...fieldFirstBlur, postalCode: true })
												}
											/>
										</Col>
									</Row>
								</RequirePermissions>
							</CardBody>
						) : null}
					</Card>
				</Col>
			</Row>
		</>
	);
};

const styles = {
	cardContainer: {
		// minWidth: 558,
		height: 200,
		background: '#F1F1F1',
		display: 'flex',
		flexDirection: 'column',
		marginTop: 20,
		flex: 1,
	},
	cardTitle: {
		height: 76,
		borderBottom: '1px solid #CCCCCC',
		fontWeight: '500',
		fontSize: 18,
		color: '#000',
		paddingLeft: 26,
		paddingRight: 26,
		alignItems: 'center',
		display: 'flex',
	},
	cardBody: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		paddingLeft: 26,
		paddingRight: 26,
	},
	itemWrapper: {
		marginRight: 50,
		marginTop: 10,
	},
	itemLabel: {
		fontSize: 12,
		fontWeight: 'normal',
		color: '#000',
		textTransform: 'capitalize',
		whiteSpace: 'nowrap',
	},
	itemValue: {
		marginTop: 6,
		fontSize: 18,
		fontWeight: '500',
		color: '#000',
		textTransform: 'capitalize',
	},
};
export default AddressDisplay;
