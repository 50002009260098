import React, { Component } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	Button,
	FormInput,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
} from 'shards-react';
import AppsContainer from '../../data/AppsContainer';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import API from '../../data/API';
import waitForContainer from '../../utils/waitForContainer';
import CopyButton from '../../components/CopyButton';

export default class SocialAccounts extends Component {
	state = {
		loading: true,
	};

	componentDidMount = async () => {
		let currentApp = await waitForContainer(AppsContainer, 'currentApp');
		let { socialAccounts = {}, socialAccountVerification = {} } = currentApp;
		let { instagram = '', twitter = '', facebook = '' } = socialAccounts;
		let { instagramEmoji = 'A', twitterEmoji = 'A', facebookEmoji = 'A' } = socialAccountVerification;

		this.setState(
			{
				instagram,
				twitter,
				facebook,
				instagramEmoji,
				twitterEmoji,
				facebookEmoji,
				saveEnabled: false,
				loading: false,
			},
			this.updateValidation,
		);
	};

	submit = async () => {
		let { instagram, twitter, facebook, saveEnabled } = this.state;

		if (!saveEnabled) return;

		let appId = AppsContainer.getInstance().state.currentApp._id;

		try {
			this.setState({ loading: true });
			await API.post(`/admin/${appId}/social-accounts`, {
				instagram,
				twitter,
				facebook,
			});

			AppsContainer.getInstance().refresh();
			this.props.history.push(`/dashboard`);
		} catch (e) {
			this.setState({ loading: false });
			alert(`Oops! ${e.message}`);
		}
	};

	isValid = () => {
		let { instagram, twitter, facebook } = this.state;
		let count = (instagram.length > 0 ? 1 : 0) + (twitter.length > 0 ? 1 : 0) + (facebook.length > 0 ? 1 : 0);
		return count >= 2;
	};

	updateValidation = () => {
		this.setState({ saveEnabled: this.isValid() });
	};

	onFieldChange = (event, field) => {
		let newState = {};
		newState[field] = event.target.value;
		this.setState(newState, this.updateValidation);
	};

	onKeyUp = event => {
		if (event.key === 'Enter') {
			this.submit();
		}
	};

	render = () => {
		return (
			<Container className="page-container verify-social-accounts">
				{this.state.loading ? (
					<ScreenLoader />
				) : (
					<div>
						<Row noGutters className="page-header py-4">
							<PageTitle
								title="Verify Social Accounts"
								subtitle="Artist Info"
								className="text-sm-left mb-3"
							/>
						</Row>
						<Row>
							<Col lg="9" md="12">
								<Card small className="mb-3">
									<CardBody style={{ padding: 30 }}>
										<p>
											Before we can charge your fans for your content, we need to verify your
											identity. We do this by checking that you have control of your official
											social media accounts.
										</p>
										<p>
											Enter <strong>at least 2</strong> of your official username(s) below. For
											each account, you'll be given a randomized emoji character to temporarily
											add to your social media bio. Once our team verifies this change, you can
											change it right back. If you need to verify your account through other
											means, please <a href="mailto:support@manifestfinancial.com">contact us</a>.
										</p>
									</CardBody>
								</Card>
								<Card small className="mb-3">
									<CardHeader className="border-bottom d-flex">
										<h6 className="m-0">Instagram</h6>
									</CardHeader>
									<CardBody style={{ paddingBottom: 0 }}>
										<InputGroup size="lg" className="mb-3">
											<InputGroupAddon type="prepend">
												<InputGroupText>instagram.com/</InputGroupText>
											</InputGroupAddon>
											<FormInput
												placeholder="username"
												value={this.state.instagram}
												onChange={event => this.onFieldChange(event, 'instagram')}
												onKeyUp={this.onKeyUp}
											/>
										</InputGroup>
										{this.state.instagram.length > 0 && (
											<h6 style={{ marginBottom: 20 }}>
												To verify ownership,&nbsp;
												<CopyButton
													size="sm"
													theme="white"
													pill
													style={{ width: 130 }}
													copyText={this.state.instagramEmoji}>
													copy this {this.state.instagramEmoji} emoji
												</CopyButton>
												&nbsp;and include it in your{' '}
												<a
													style={{ textDecoration: 'underline' }}
													href="https://www.instagram.com/accounts/edit/"
													rel="noopener noreferrer"
													target="_blank">
													Instagram bio
												</a>
												.
											</h6>
										)}
									</CardBody>
								</Card>
								<Card small className="mb-3">
									<CardHeader className="border-bottom d-flex">
										<h6 className="m-0">Twitter</h6>
									</CardHeader>
									<CardBody style={{ paddingBottom: 0 }}>
										<InputGroup size="lg" className="mb-3">
											<InputGroupAddon type="prepend">
												<InputGroupText>twitter.com/</InputGroupText>
											</InputGroupAddon>
											<FormInput
												placeholder="username"
												value={this.state.twitter}
												onChange={event => this.onFieldChange(event, 'twitter')}
												onKeyUp={this.onKeyUp}
											/>
										</InputGroup>
										{this.state.twitter.length > 0 && (
											<h6 style={{ marginBottom: 20 }}>
												To verify ownership,&nbsp;
												<CopyButton
													size="sm"
													theme="white"
													pill
													style={{ width: 130 }}
													copyText={this.state.twitterEmoji}>
													copy this {this.state.twitterEmoji} emoji
												</CopyButton>
												&nbsp;and include it in your{' '}
												<a
													style={{ textDecoration: 'underline' }}
													href="https://twitter.com/settings/profile"
													rel="noopener noreferrer"
													target="_blank">
													Twitter bio
												</a>
												.
											</h6>
										)}
									</CardBody>
								</Card>
								<Card small className="mb-3">
									<CardHeader className="border-bottom d-flex">
										<h6 className="m-0">Facebook</h6>
									</CardHeader>
									<CardBody style={{ paddingBottom: 0 }}>
										<InputGroup size="lg" className="mb-3">
											<InputGroupAddon type="prepend">
												<InputGroupText>facebook.com/</InputGroupText>
											</InputGroupAddon>
											<FormInput
												placeholder="username"
												value={this.state.facebook}
												onChange={event => this.onFieldChange(event, 'facebook')}
												onKeyUp={this.onKeyUp}
											/>
										</InputGroup>
										{this.state.facebook.length > 0 && (
											<h6 style={{ marginBottom: 20 }}>
												To verify ownership,&nbsp;
												<CopyButton
													size="sm"
													theme="white"
													pill
													style={{ width: 130 }}
													copyText={this.state.facebookEmoji}>
													copy this {this.state.facebookEmoji} emoji
												</CopyButton>
												&nbsp;and include it in your{' '}
												<a
													style={{ textDecoration: 'underline' }}
													href={`https://www.facebook.com/${this.state.facebook}`}
													rel="noopener noreferrer"
													target="_blank">
													Facebook bio
												</a>
												.
											</h6>
										)}
									</CardBody>
								</Card>
							</Col>
							<Col lg="3" md="12">
								<Card small>
									<CardBody>
										<Row>
											<Col sm="12">
												<Button
													theme="accent"
													size="sm"
													style={{ width: '100%' }}
													disabled={!this.state.saveEnabled}
													onClick={this.submit}>
													Save
												</Button>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</div>
				)}
			</Container>
		);
	};
}
