import React, { useEffect, useState } from 'react';
import {
	Row,
	Col,
	Card,
	CardBody,
	CardHeader,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	FormInput,
	FormTextarea,
	CardFooter,
} from 'shards-react';
import Tip from '../../../components/Tip';
import colors from '../../../utils/colors';
import moment from 'moment';
import ReactTable from 'react-table';
import API from '../../../data/API';
import FileDropzone from '../../../components/file-manager-list/FileDropzone';
import RequirePermissions from '../../../components/RequirePermissions';

import axios from 'axios';
const tableColumns = [
	{
		id: 'date',
		Header: 'Date',
		accessor: row => moment(row.date).format('MM/DD/YY'),
		className: 'text-left d-flex align-items-center',
		maxWidth: 80,
	},
	{
		Header: 'Subject',
		accessor: 'subject',
		className: 'text-left d-flex align-items-center justify-content-start',
	},
	{
		Header: 'From',
		accessor: 'from',
		className: 'text-left d-flex align-items-center justify-content-start',
		maxWidth: 180,
	},
];

const SecureMessageDisplay = ({ userId }) => {
	const [messages, setMessages] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedMessage, setSelectedMessage] = useState(null);

	const [replyOpen, setReplyOpen] = useState(false);
	const [replySubject, setReplySubject] = useState('');
	const [replyMessage, setReplyMessage] = useState('');

	const [attachments, setAttachments] = useState([]);
	const downloandAttachment = async (fileKey, fileName) => {
		const data = await API.download(`/banking-admin/secureMessage/attachment/${fileKey}`);

		const url = window.URL.createObjectURL(new Blob([data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', fileName);
		document.body.appendChild(link);
		link.click();
		await API.get(`/banking-admin/secureMessage/attachment/${fileKey}`);
	};

	const onAttachmentSelected = files => {
		setAttachments([...attachments, ...files]);
	};

	const removeAttachment = index => {
		let array = [...attachments];
		array.splice(index, 1);
		setAttachments(array);
	};

	const toggleNewMessage = () => {
		toggle();
		setReplySubject('New Message');
		setReplyMessage('');
	};

	const toggleReply = () => {
		toggle();
		setReplySubject('RE:' + selectedMessage.subject);
		setReplyMessage('');
	};

	const toggle = () => {
		setAttachments([]);
		setReplyOpen(!replyOpen);
	};

	const submitReply = async () => {
		if (loading) {
			return;
		}
		setLoading(true);
		let formData = new FormData();
		formData.append('userId', userId);
		formData.append('subject', replySubject);
		formData.append('message', replyMessage);
		attachments.forEach(file => {
			formData.append(`attachments`, file, file.name);
		});

		try {
			await API.multipartPost(`/banking-admin/secureMessage/newMessage/`, formData);
		} catch (error) {}
		setReplyOpen(!replyOpen);
		await getMessages();
		setLoading(false);
	};

	const selectMessage = async id => {
		if (loading) {
			return;
		}

		setLoading(true);
		try {
			const response = await API.get(`/banking-admin/secureMessage/message/${id}`);

			if (response.message) {
				setSelectedMessage(response.message);
			}
		} catch (error) {}
		setLoading(false);
	};

	const getMessages = async () => {
		if (loading) {
			return;
		}
		setLoading(true);
		try {
			const response = await API.get(`/banking-admin/secureMessage/list/${userId}`);
			if (response.messages) {
				setMessages(response.messages);
			}
		} catch (error) {}
		setLoading(false);
	};
	useEffect(() => {
		getMessages();
	}, []);

	const humanFileSize = (bytes, si = false, dp = 1) => {
		const thresh = si ? 1000 : 1024;

		if (Math.abs(bytes) < thresh) {
			return bytes + ' B';
		}

		const units = si
			? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
			: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
		let u = -1;
		const r = 10 ** dp;

		do {
			bytes /= thresh;
			++u;
		} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

		return bytes.toFixed(dp) + ' ' + units[u];
	};
	return (
		<>
			<Modal open={replyOpen} toggle={toggle}>
				<Row className="p-4 d-flex">
					<FormInput
						// style={{ maxWidth: 400, height: 46 }}
						value={replySubject}
						onChange={event => setReplySubject(event.target.value)}
						// onKeyUp={this.onKeyUp}
					/>
				</Row>
				<Row className="p-4 d-flex">
					<FormTextarea
						id="intro"
						size="lg"
						rows="15"
						value={replyMessage}
						onChange={event => setReplyMessage(event.target.value)}
					/>
				</Row>
				<Row className="p-4 d-flex">
					{attachments.map(({ name, size }, index) => {
						return (
							<div
								key={name + index}
								style={{
									display: 'flex',
									width: '100%',
									justifyContent: 'space-between',
								}}>
								<span
									style={{
										width: '60%',
										display: 'inline-block',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									}}>
									{name}
								</span>
								<span style={{ color: 'blue' }}>{humanFileSize(size)}</span>
								<span
									onClick={() => removeAttachment(index)}
									style={{ color: 'red', cursor: 'pointer' }}>
									[remove]
								</span>
							</div>
						);
					})}
				</Row>
				<Row className="p-4 d-flex">
					<div style={{ padding: 0, width: '100%', marginBottom: 12 }}>
						<FileDropzone
							label="Upload attachments"
							onFileSelect={onAttachmentSelected}
							// TODO: change accept attachment types
							// types="JPG and PNG"
							accept=""
						/>
					</div>
				</Row>
				<Row className="p-4 d-flex justify-content-end">
					<Button
						className="justify-self-end"
						onClick={submitReply}
						disabled={loading || !replySubject || !replyMessage}
						theme="success">
						Submit
					</Button>
				</Row>
			</Modal>
			<Row className="py-4 d-flex justify-content-between">
				<Col lg={selectedMessage ? '5' : '12'} md="12">
					<Card small className="mb-3">
						<CardHeader className="border-bottom d-flex justify-content-between align-items-center">
							<h6 className="m-0">Messages</h6>
							<div className="d-flex justify-content-between align-items-center">
								<Button
									onClick={getMessages}
									disabled={loading}
									style={{
										marginRight: 5,
									}}
									theme="success">
									Refresh
								</Button>
								<RequirePermissions permissions={['message.write']}>
									<Button disabled={loading} theme="accent" onClick={toggleNewMessage}>
										New Message
									</Button>
								</RequirePermissions>
							</div>
						</CardHeader>
						<CardBody className="p-0">
							<ReactTable
								getTdProps={() => ({
									style: { borderLeft: `none`, borderRight: `none` },
								})}
								// className="-highlight"
								columns={tableColumns}
								data={messages}
								resizable={false}
								usePagination={true}
								getTrProps={(state, rowInfo) => {
									if (rowInfo && rowInfo.row) {
										return {
											onClick: async e => {
												await selectMessage(rowInfo.original.id);
												await getMessages();
											},
											style: {
												background:
													selectedMessage && rowInfo.original.id === selectedMessage.id
														? '#f0f0f0'
														: 'white',
												cursor: 'pointer',
												fontWeight: rowInfo.original.isRead ? '300' : '600',
											},
										};
									} else {
										return {};
									}
								}}
							/>
						</CardBody>
					</Card>
				</Col>
				{selectedMessage ? (
					<Col lg="7" md="12">
						<Card small className="mb-3">
							<CardHeader className="border-bottom  d-flex justify-content-between align-items-center">
								<h6 className="m-0">{selectedMessage.subject}</h6>
								<RequirePermissions permissions={['message.write']}>
									<Button theme="accent" onClick={toggleReply}>
										Reply
									</Button>
								</RequirePermissions>
							</CardHeader>
							<CardBody>
								<div className="m-2">{moment(selectedMessage.date).format('MM/DD/YYYY hh:mm')}</div>
								<pre>{selectedMessage.message}</pre>
							</CardBody>
							<CardFooter>
								{selectedMessage.attachments.map(({ fileName, fileKey }, index) => {
									return (
										<div
											key={fileKey + index}
											style={{
												display: 'flex',
												width: '100%',
												justifyContent: 'space-between',
											}}>
											<span
												style={{
													width: '80%',
													display: 'inline-block',
													whiteSpace: 'nowrap',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
												}}>
												{fileName}
											</span>
											<span
												onClick={() => downloandAttachment(fileKey, fileName)}
												style={{ color: 'blue', cursor: 'pointer' }}>
												[download]
											</span>
										</div>
									);
								})}
							</CardFooter>
						</Card>
					</Col>
				) : null}
			</Row>
		</>
	);
};

const styles = {};
export default SecureMessageDisplay;
