import ExpiredStorage from 'expired-storage';
import API from '../data/API';
import { Container } from 'unstated';
import AppsContainer from './AppsContainer';

let tokenKey = 'NERVE_TOKEN';

export default class Session extends Container {
	static _instance = null;

	static getInstance = () => {
		return Session._instance;
	};

	constructor(props) {
		super(props);
		Session._instance = this;
		this.expiredStorage = new ExpiredStorage();

		let token = this.expiredStorage.getItem(tokenKey);
		let isLoggedIn = true;
		if (!token) {
			token = sessionStorage.getItem(tokenKey);
		}
		if (!token) {
			isLoggedIn = false;
			token = null;
		}
		this.state = { token, isLoggedIn, user: null };

		this.refreshUser();
	}

	refreshUser = async () => {
		let user = await API.get(`/user/profile`);
		this.setState({ user });
	};

	clear = callback => {
		sessionStorage.removeItem(tokenKey);
		this.expiredStorage.removeItem(tokenKey);
		this.setState({ token: null, isLoggedIn: false, user: null }, callback);
	};

	setToken = (token, rememberMe, callback) => {
		this.clear(() => {
			if (rememberMe) {
				this.expiredStorage.setItem(tokenKey, token, 60 * 60 * 24 * 30);
			} else {
				this.expiredStorage.setItem(tokenKey, token, 60 * 60 * 5);
				// i need to think through this... i wanted to use session, but the stripe callback opens another tab, which doesn't have access
				//sessionStorage.setItem(tokenKey, token);
			}
			let type = 'basic';
			try {
				type = JSON.parse(atob(token.split('.')[1])).type;
			} catch (e) {}
			let isLoggedIn = type === 'admin';

			this.setState({ token, isLoggedIn }, () => {
				callback && callback();
				this.refreshUser();
				AppsContainer.getInstance().refresh();
			});
		});
	};

	getToken = () => {
		return this.state.token;
	};
}
