import React, { Component } from 'react';
import { Container, Row, Col, Nav, Collapse } from 'shards-react';
import AppsContainer from '../../data/AppsContainer';
import ScreenLoader from '../../components/ScreenLoader';
import waitForContainer from '../../utils/waitForContainer';

export default class extends Component {
	componentDidMount = () => {
		//man,
		setTimeout(async () => {
			this.props.history.push(`/profile/banking-admin`);
		}, 10);
	};

	render = () => (
		<Container>
			<Row>
				<Col>
					<Nav tabs className="border-0 flex-column flex-lg-row">
						<ScreenLoader />;
					</Nav>
				</Col>
			</Row>
		</Container>
	);
}
