import React, { Component } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	FormTextarea,
	Button,
	FormInput,
	FormCheckbox,
	DatePicker,
	FormSelect,
	FormRadio,
	InputGroup,
	InputGroupAddon,
} from 'shards-react';
import { Subscribe } from 'unstated';
import AppsContainer from '../../data/AppsContainer';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import ImageDropzone from '../../components/ImageDropzone';
import 'react-circular-progressbar/dist/styles.css';
import Tip from '../../components/Tip';
import API from '../../data/API';
import waitForContainer from '../../utils/waitForContainer';
import moment from 'moment';

export default class NewPost extends Component {
	state = {
		title: '',
		body: '',
		linkUrl: '',
		linkLabel: '',
		artworkFile: null,
		existingArt: null,
		saveEnabled: false,
		loading: true,
		postId: null,
		isLive: true,
		publishDate: null,
		visibility: 'all',
		location: null,
		locationRadiusInMiles: 0,
		locationSearchText: '',
		searchingLocations: false,
	};

	componentDidMount = async () => {
		await waitForContainer(AppsContainer, 'currentApp');

		if (this.props.match.params && this.props.match.params.postId) {
			let { postId } = this.props.match.params;
			let posts = await waitForContainer(AppsContainer, 'posts');
			let {
				title,
				body,
				linkUrl,
				linkLabel,
				media: existingArt,
				isLive,
				publishDate,
				visibility,
				location,
				locationRadiusInMiles,
			} = posts.find(item => item._id === postId);
			this.setState(
				{
					title,
					body,
					linkUrl,
					linkLabel,
					postId,
					existingArt,
					isLive,
					publishDate: publishDate ? new Date(publishDate) : null,
					visibility: visibility || 'all',
					location,
					loading: false,
					locationRadiusInMiles,
				},
				this.updateValidation
			);
		} else {
			this.setState({ loading: false });
		}
	};

	delete = async () => {
		if (!window.confirm(`Are you sure you want to delete this post?`)) return;

		let { _id: appId, appCode } = AppsContainer.getInstance().state.currentApp;
		let { postId } = this.state;

		this.setState({ loading: true });

		try {
			await API.del(`/admin/${appId}/post/${postId}`);
			await AppsContainer.getInstance().updateCurrentApp();
			this.props.history.push(`/channel/${appCode}/posts`);
		} catch (e) {
			this.setState({ loading: false });
			alert(`Oops! ${e.message}`);
		}
	};

	submit = async () => {
		let {
			postId,
			title,
			body,
			linkUrl,
			linkLabel,
			artworkFile: media,
			saveEnabled,
			isLive,
			publishDate,
			visibility,
			location,
			locationRadiusInMiles,
		} = this.state;

		if (!saveEnabled) return;

		this.setState({ loading: true });

		if (postId) {
			let appId = AppsContainer.getInstance().state.currentApp._id;

			try {
				let formData = new FormData();
				formData.append('title', title);
				formData.append('isLive', isLive);
				formData.append('publishDate', isLive ? null : publishDate);
				formData.append('visibility', visibility);
				formData.append('locationRadiusInMiles', locationRadiusInMiles);
				if (location) formData.append('location', JSON.stringify(location));
				if (body) formData.append('body', body);
				if (linkUrl) formData.append('linkUrl', linkUrl);
				if (linkLabel) formData.append('linkLabel', linkLabel);
				if (media) formData.append('media', media);
				await API.multipartPost(`/admin/${appId}/post/${postId}`, formData);

				await AppsContainer.getInstance().updateCurrentApp();
				this.props.history.push(`/channel/${AppsContainer.getInstance().state.currentApp.appCode}/posts`);
			} catch (e) {
				this.setState({ loading: false });
				alert(`Oops! ${e.message}`);
			}
		} else {
			let appId = AppsContainer.getInstance().state.currentApp._id;

			try {
				let formData = new FormData();
				formData.append('title', title);
				formData.append('isLive', isLive);
				formData.append('publishDate', isLive ? null : publishDate);
				formData.append('visibility', visibility);
				formData.append('locationRadiusInMiles', locationRadiusInMiles);
				if (location) formData.append('location', JSON.stringify(location));
				if (body) formData.append('body', body);
				if (linkUrl) formData.append('linkUrl', linkUrl);
				if (linkLabel) formData.append('linkLabel', linkLabel);
				if (media) formData.append('media', media);
				await API.multipartPost(`/admin/${appId}/post`, formData);

				await AppsContainer.getInstance().updateCurrentApp();
				this.props.history.push(`/channel/${AppsContainer.getInstance().state.currentApp.appCode}/posts`);
			} catch (e) {
				this.setState({ loading: false });
				alert(`Oops! ${e.message}`);
			}
		}
	};

	isValid = () => {
		let { title, visibility, location } = this.state;
		return !!title && (visibility === 'all' || !!location);
	};

	updateValidation = () => {
		this.setState({ saveEnabled: this.isValid() });
	};

	onAlbumImageChange = async artworkFile => {
		this.setState({ artworkFile }, this.updateValidation);
	};

	onFieldChange = (event, field) => {
		let newState = {};
		newState[field] = event.target.value;
		this.setState(newState, this.updateValidation);
	};

	onKeyUp = event => {
		if (event.key === 'Enter') {
			this.submit();
		}
		return false;
	};

	onLocationSearchKeyUp = event => {
		if (event.key === 'Enter') {
			this.searchForLocation();
		}
		return false;
	};

	searchForLocation = async () => {
		if (this.state.locationSearchText.trim() === '') {
			alert(`Enter the city and state you'd like to target with this post.`);
			return;
		}
		try {
			this.setState({ searchingLocations: true });
			let appId = AppsContainer.getInstance().state.currentApp._id;
			let location = await API.post(`/admin/${appId}/location`, {
				location: this.state.locationSearchText,
			});
			this.setState(
				{ location, locationRadiusInMiles: 50, locationSearchText: '', searchingLocations: false },
				this.updateValidation
			);
		} catch (e) {
			this.setState({ searchingLocations: false });
			alert(`Oops! ${e.message}`);
		}
	};

	toggleIsLive = () => {
		this.setState({ isLive: !this.state.isLive });
	};

	onPublishDateChange = date => {
		if (!date) {
			let publishDate = null;
			this.setState({ publishDate });
		} else {
			let publishDate = this.state.publishDate;
			if (!publishDate) publishDate = new Date();
			publishDate.setMinutes(0);
			publishDate.setSeconds(0);
			publishDate.setMilliseconds(0);
			publishDate.setFullYear(date.getFullYear());
			publishDate.setMonth(date.getMonth());
			publishDate.setDate(date.getDate());
			this.setState({ publishDate });
		}
	};

	onPublishTimeChange = e => {
		let value = e.target.value;
		if (value === '') {
			let publishDate = null;
			this.setState({ publishDate });
		} else {
			let publishDate = this.state.publishDate;
			if (!publishDate) publishDate = new Date();
			publishDate.setMinutes(0);
			publishDate.setSeconds(0);
			publishDate.setMilliseconds(0);
			publishDate.setHours(value);
			this.setState({ publishDate });
		}
	};

	futureDateFilter = date => {
		let now = new Date();
		return moment(date).isSame(now, 'day') || moment(date).isAfter(now, 'day');
	};

	onVisbilityChange = visibility => {
		this.setState({ visibility }, this.updateValidation);
	};

	onClearLocation = () => {
		this.setState({ location: null }, this.updateValidation);
	};

	render = () => (
		<Container className='page-container album-detail'>
			{this.state.loading ? (
				<ScreenLoader />
			) : (
				<Subscribe to={[AppsContainer]}>
					{({ state: { currentApp } }) => (
						<div>
							<Row noGutters className='page-header py-4'>
								<PageTitle
									title={this.state.postId ? 'Edit Post' : 'Add New Post'}
									subtitle='Connect'
									className='text-sm-left mb-3'
								/>
							</Row>
							<Row>
								<Col lg='9' md='12'>
									<Card small className='mb-3'>
										<CardBody style={{ paddingBottom: 0 }}>
											<FormInput
												size='lg'
												className='mb-3'
												placeholder='Title (required)'
												value={this.state.title}
												onChange={event => this.onFieldChange(event, 'title')}
												onKeyUp={this.onKeyUp}
											/>
											<FormTextarea
												style={{ marginTop: 8, marginBottom: 0 }}
												placeholder='Body'
												size='lg'
												className='mb-3'
												rows='20'
												value={this.state.body}
												onChange={event => this.onFieldChange(event, 'body')}
											/>
										</CardBody>
									</Card>
									<Card small className='mb-3'>
										<CardHeader className='border-bottom d-flex' style={{ alignItems: 'baseline' }}>
											<h6 className='m-0' style={{ flex: 1 }}>
												Add A Link
											</h6>
											<Tip text='Give your members early access to tickets + merch.' />
										</CardHeader>
										<CardBody style={{ paddingBottom: 0 }}>
											<FormInput
												size='lg'
												className='mb-3'
												placeholder='Button Label'
												value={this.state.linkLabel}
												onChange={event => this.onFieldChange(event, 'linkLabel')}
												onKeyUp={this.onKeyUp}
											/>
											<FormInput
												size='lg'
												type='url'
												className='mb-3'
												placeholder='URL'
												value={this.state.linkUrl}
												onChange={event => this.onFieldChange(event, 'linkUrl')}
												onKeyUp={this.onKeyUp}
											/>
										</CardBody>
									</Card>
									{this.state.postId && (
										<Button
											outline
											theme='danger'
											className='mt-3 d-none d-lg-block'
											size='sm'
											onClick={this.delete}>
											Delete
										</Button>
									)}
								</Col>
								<Col lg='3' md='12'>
									<Card small>
										<div className='album-art'>
											<ImageDropzone
												label='Drop image here'
												defaultImage={this.state.existingArt}
												onFileSelect={this.onAlbumImageChange}
												accept='.png, .jpg'
											/>
										</div>

										<CardBody>
											<Row>
												<Col
													sm='6'
													style={{
														paddingTop: 5,
														marginBottom: -5,
													}}>
													<FormCheckbox
														checked={this.state.isLive}
														onChange={this.toggleIsLive}
														toggle
														small>
														Live
													</FormCheckbox>
												</Col>
												<Col sm='6'>
													<Button
														theme='accent'
														size='sm'
														style={{ width: '100%' }}
														disabled={!this.state.saveEnabled}
														onClick={this.submit}>
														Save
													</Button>
												</Col>
											</Row>
											{!this.state.isLive && (
												<Row className='mt-4'>
													<Col sm='12'>
														<strong className='text-muted form-text d-block mb-2'>
															Auto-publish (optional)
														</strong>
														<DatePicker
															size='md'
															selected={this.state.publishDate}
															onChange={this.onPublishDateChange}
															placeholderText='Publish Date'
															dropdownMode='select'
															filterDate={this.futureDateFilter}
														/>
														<FormSelect
															size='md'
															className='w-100 mt-2'
															value={moment(this.state.publishDate).format('HH')}
															onChange={this.onPublishTimeChange}>
															<option value=''>Publish Time</option>
															<option value='09'>9:00 a.m.</option>
															<option value='10'>10:00 a.m.</option>
															<option value='11'>11:00 a.m.</option>
															<option value='12'>12:00 p.m.</option>
															<option value='13'>1:00 p.m.</option>
															<option value='14'>2:00 p.m.</option>
															<option value='15'>3:00 p.m.</option>
															<option value='16'>4:00 p.m.</option>
															<option value='17'>5:00 p.m.</option>
															<option value='18'>6:00 p.m.</option>
															<option value='19'>7:00 p.m.</option>
															<option value='20'>8:00 p.m.</option>
															<option value='21'>9:00 p.m.</option>
															<option value='22'>10:00 p.m.</option>
															<option value='23'>11:00 p.m.</option>
															<option value='00'>12:00 a.m.</option>
															<option value='01'>1:00 a.m.</option>
															<option value='02'>2:00 a.m.</option>
															<option value='03'>3:00 a.m.</option>
															<option value='04'>4:00 a.m.</option>
															<option value='05'>5:00 a.m.</option>
															<option value='06'>6:00 a.m.</option>
															<option value='07'>7:00 a.m.</option>
															<option value='08'>8:00 a.m.</option>
														</FormSelect>
													</Col>
												</Row>
											)}
										</CardBody>
									</Card>
									<Card small className='mt-3'>
										<CardBody>
											<Row>
												<Col sm='12' style={{ marginBottom: -5 }}>
													<strong className='text-muted form-text d-block mb-2 mt-0'>Notify</strong>
													<FormRadio
														checked={this.state.visibility === 'all'}
														onChange={() => this.onVisbilityChange('all')}>
														All Subscribers
													</FormRadio>
													<FormRadio
														checked={this.state.visibility === 'location'}
														onChange={() => this.onVisbilityChange('location')}>
														Local Subscribers
													</FormRadio>
													{this.state.visibility === 'location' && (
														<>
															{!this.state.location && (
																<InputGroup seamless className='mb-3'>
																	<FormInput
																		autoFocus
																		size='md'
																		className='ml-4'
																		placeholder='City, State'
																		disabled={this.state.searchingLocations}
																		value={this.state.locationSearchText}
																		onChange={event =>
																			this.onFieldChange(event, 'locationSearchText')
																		}
																		onKeyUp={event => this.onLocationSearchKeyUp(event)}
																	/>
																	<InputGroupAddon type='append'>
																		<Button
																			theme='white'
																			onClick={this.searchForLocation}
																			style={{ paddingHorizontal: 12 }}>
																			Set
																		</Button>
																	</InputGroupAddon>
																</InputGroup>
															)}
															{this.state.location && (
																<>
																	<span className='d-flex mb-2 ml-4'>
																		<i className='material-icons mr-1' style={{ fontSize: 18 }}>
																			room
																		</i>
																		{this.state.location.name}
																		<span
																			className='ml-auto'
																			onClick={this.onClearLocation}
																			style={{
																				textDecoration: 'underline',
																				cursor: 'pointer',
																				fontWeight: '300',
																				fontSize: 11,
																				lineHeight: 2,
																			}}>
																			Clear
																		</span>
																	</span>
																	<span className='d-flex mb-2 ml-4'>
																		<i
																			className='material-icons mr-1'
																			style={{ fontSize: 18, marginTop: 4 }}>
																			zoom_out_map
																		</i>
																		<FormSelect
																			size='sm'
																			style={{ height: 30, padding: '0 28px 0 8px', fontSize: 11 }}
																			value={this.state.locationRadiusInMiles}
																			onChange={event =>
																				this.onFieldChange(event, 'locationRadiusInMiles')
																			}>
																			<option value='25'>Local (within 25 mi)</option>
																			<option value='50'>Metro (within 50 mi)</option>
																			<option value='250'>Regional (within 250 mi)</option>
																		</FormSelect>
																	</span>
																</>
															)}
														</>
													)}
												</Col>
											</Row>
										</CardBody>
									</Card>
									{this.state.postId && (
										<Button
											outline
											theme='danger'
											className='mt-3 d-block d-lg-none'
											size='sm'
											onClick={this.delete}>
											Delete
										</Button>
									)}
								</Col>
							</Row>
						</div>
					)}
				</Subscribe>
			)}
		</Container>
	);
}
