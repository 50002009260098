import React from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'shards-react';

const SocialTabDisplay = ({ profiles }) => {
	return (
		<>
			{profiles.length === 0 && (
				<Row className="py-4 d-flex justify-content-between">
					<Col xs="12">
						<Card small className="mb-3">
							<CardBody className="m-5" style={{ textAlign: 'center' }}>
								No profiles
							</CardBody>
						</Card>
					</Col>
				</Row>
			)}
			{profiles.map(profile => {
				return (
					<Row className="py-4 d-flex justify-content-between" key={profile.id}>
						<Col xs="12">
							<Card small className="mb-3">
								<CardHeader className="border-bottom">
									<Row className="mx-3" style={{ alignItems: 'flex-end' }}>
										<h6 className="m-0">{profile.platformName}</h6>
										<a class="ml-4" href={profile.url} target="_blank">
											{profile.url}
										</a>
									</Row>
								</CardHeader>
								<CardBody className="p-3 pl-5 p3-5">
									<Row className="mb-4">
										<Col xs="12">
											<Row></Row>
											<Row>Verified: {profile.isVerified ? 'Yes' : 'No'}</Row>
											<Row>Business Account: {profile.isBusiness ? 'Yes' : 'No'}</Row>
										</Col>
									</Row>
									<Row>
										<Col xs="4">
											{profile.nickname ? <Row>NickName: {profile.nickname}</Row> : null}
											{profile.username ? <Row>Username: {profile.username}</Row> : null}
											{profile.fullName ? <Row>Full Name: {profile.fullName}</Row> : null}
											{profile.firstName ? <Row>First Name: {profile.firstName}</Row> : null}
											{profile.lastName ? <Row>Last Name: {profile.lastName}</Row> : null}
										</Col>
										<Col xs="4">
											{profile.followerCount ? (
												<Row>Followers: {Number(profile.followerCount).toLocaleString()}</Row>
											) : null}
											{profile.subscriberCount ? (
												<Row>
													Subscribers: {Number(profile.subscriberCount).toLocaleString()}
												</Row>
											) : null}
											{profile.followingCount ? (
												<Row>Following: {Number(profile.followingCount).toLocaleString()}</Row>
											) : null}
										</Col>
										<Col xs="4">
											{profile.contentCount ? (
												<Row>
													Content Count: {Number(profile.contentCount).toLocaleString()}
												</Row>
											) : null}
											{profile.contentGroupCount ? (
												<Row>
													Content Group Count:{' '}
													{Number(profile.contentGroupCount).toLocaleString()}
												</Row>
											) : null}
											{profile.watchTimeInHours ? (
												<Row>
													Watch Time (hours):{' '}
													{Number(profile.watchTimeInHours).toLocaleString()}
												</Row>
											) : null}
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				);
			})}
		</>
	);
};
export default SocialTabDisplay;
