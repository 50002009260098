import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import ImageTools from '../utils/ImageTools';
const imageTools = new ImageTools();

export default class ImageDropzone extends Component {
	state = {
		source: null,
	};

	constructor(props) {
		super(props);
		this.state = {
			source: props.defaultImage ? `url(${props.defaultImage.uri})` : null,
		};
	}

	handleFileDrop = async files => {
		let file = files[0];
		let resized = await imageTools.makeSquare(file, this.props.forceWidth, this.props.forceHeight, this.props.method);

		var reader = new FileReader();
		reader.onload = e => {
			let source = `url(${e.target.result})`;
			this.setState({ source });
		};
		reader.readAsDataURL(resized);
		this.props.onFileSelect(resized);
	};

	deleteImage = () => {
		this.setState({ source: null });
		this.props.onFileSelect(null);
	};

	render() {
		return (
			<Dropzone onDrop={this.handleFileDrop}>
				{({ getRootProps, getInputProps, isDragActive }) => {
					return (
						<div className='image-drop-zone'>
							<div
								{...getRootProps()}
								className='background'
								style={{
									backgroundImage: this.state.source,
									backgroundColor: isDragActive ? '#FFF' : '#000',
								}}
							/>
							<input {...getInputProps()} accept={this.props.accept} />
							{!this.state.source && (
								<span
									className='label'
									style={{
										color: isDragActive ? '#000' : '#CCC',
									}}>
									{this.props.label}
								</span>
							)}
							{this.state.source && this.props.overlayColor && (
								<div
									style={{
										backgroundColor: this.props.overlayColor,
										position: 'absolute',
										left: 0,
										right: 0,
										top: 0,
										bottom: 0,
										pointerEvents: 'none',
									}}
								/>
							)}
							{this.props.allowRemoval && this.state.source && (
								<i
									className='material-icons'
									style={{
										position: 'absolute',
										color: 'black',
										fontSize: 20,
										right: 8,
										top: 8,
										cursor: 'pointer',
									}}
									onClick={this.deleteImage}>
									delete_forever
								</i>
							)}
						</div>
					);
				}}
			</Dropzone>
		);
	}
}

ImageDropzone.defaultProps = {
	label: 'Drop image here',
	forceWidth: 1024,
	forceHeight: 1024,
	method: 'cover',
	allowRemoval: false,
};
