import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';

import routes from './routes';
import withTracker from './withTracker';
import ScrollToTop from './components/ScrollToTop';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/main.scss';

import Session from './data/Session';
import AppsContainer from './data/AppsContainer';
import API from './data/API';

import { BankingAdminPermissionGroups } from './utils/bankingAdminPermissions';
import ScreenLoader from './components/ScreenLoader';
import RequirePermissions from './components/RequirePermissions';

export default () => {
	const [configLoading, setConfigLoading] = useState(true);
	useEffect(() => {
		const fetchConfig = async () => {
			const { config } = await API.get('/config/admin');
			BankingAdminPermissionGroups.groups = config.BANKING_ADMIN_PERMISSION_GROUPS;
			setConfigLoading(false);
		};
		fetchConfig();
	}, []);

	return configLoading ? (
		<ScreenLoader />
	) : (
		<Router basename={process.env.REACT_APP_BASENAME || ''}>
			<ScrollToTop>
				{routes.map((route, index) => {
					return (
						<Route
							key={index}
							path={route.path}
							exact={route.exact}
							component={withTracker(props => {
								if (!route.public && !Session.getInstance().state.isLoggedIn)
									return <Redirect to="/login" />;
								if (route.redirectToDashboardIfLoggedIn && Session.getInstance().state.isLoggedIn)
									return <Redirect to="/profile" />;
								if (props.match && props.match.params && props.match.params.appCode) {
									AppsContainer.getInstance().setCurrentAppCode(props.match.params.appCode);
								} else {
									AppsContainer.getInstance().clearCurrentApp();
								}

								if (props.match && props.match.params && props.match.params.npo) {
									AppsContainer.getInstance().setCurrentNpoId(props.match.params.npo);
								} else {
									AppsContainer.getInstance().clearCurrentNpo();
								}

								return (
									<RequirePermissions showRequiredPermissions permissions={route.permissions}>
										<route.layout {...props}>
											<route.component {...props} />
										</route.layout>
									</RequirePermissions>
								);
							})}
						/>
					);
				})}
			</ScrollToTop>
		</Router>
	);
};
