import React, { Component } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	Button,
	Badge,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	FormInput,
	Form,
	FormGroup,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	FormCheckbox,
	FormFeedback,
} from 'shards-react';
import AppsContainer from '../../data/AppsContainer';
import Session from '../../data/Session';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import waitForContainer from '../../utils/waitForContainer';
import { NavLink, Link } from 'react-router-dom';
import API from '../../data/API';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import queryString from 'query-string';
import { possessiveForApp } from '../../utils/pronouns';
import InfoBox from '../../components/InfoBox';

const labelForRole = role =>
	({
		bandMember: 'Band Member',
		songwriter: 'Songwriter',
		manager: 'Manager',
		label: 'Label',
		other: 'Contributor',
	}[role]);

const ChannelItem = ({ app }) => (
	<Col lg="4" md="4" sm="4" style={{ margin: 0, padding: 10 }}>
		<Link to={`/channel/${app.appCode}`}>
			<Card small>
				<div
					className="channel-art"
					style={{
						backgroundImage: app.theme && app.theme.hero && `url('${app.theme.hero.uri}')`,
					}}>
					{!app.verified && (
						<Badge pill theme="primary">
							Pre-Launch
						</Badge>
					)}
					<h3>{app.name}</h3>
				</div>
			</Card>
		</Link>
	</Col>
);

const NpoItem = ({ npo }) => (
	<Col lg="4" md="4" sm="4" style={{ margin: 0, padding: 10 }}>
		<Link to={`/npo/${npo._id}`}>
			<Card small>
				<div
					className="channel-art"
					style={{
						backgroundColor: '#FFF',
						backgroundImage: npo.profile && npo.profile.logo && `url('${npo.profile.logo.uri}')`,
					}}>
					{!npo.verified && (
						<Badge pill theme="primary">
							Pre-Launch
						</Badge>
					)}
					<h3>{npo.name}</h3>
				</div>
			</Card>
		</Link>
	</Col>
);

const ChannelGrid = ({ apps }) => (
	<Card small className="mb-3 content-grid" style={{ overflow: 'auto' }}>
		{apps.length === 0 ? (
			<div
				style={{
					display: 'flex',
					flex: 1,
					alignItems: 'center',
					justifyContent: 'center',
					paddingTop: 120,
					paddingBottom: 120,
				}}>
				Click the "+Add Channel" button to add your first channel!
			</div>
		) : (
			apps.map((app, index) => <ChannelItem app={app} index={index} key={index} />)
		)}
	</Card>
);

const NpoGrid = ({ npos }) => (
	<Card small className="mb-3 content-grid" style={{ overflow: 'auto' }}>
		{npos.map((npo, index) => (
			<NpoItem npo={npo} index={index} key={index} />
		))}
	</Card>
);

const SplitGrid = ({ splits }) =>
	splits.map(split => (
		<Card small className="mb-3" key={split._id}>
			<CardHeader className="border-bottom">
				<h6 className="m-0">
					{split.app.name} - {labelForRole(split.role)}
				</h6>
			</CardHeader>
			<CardBody>
				<Row>
					<Col md="3">
						<span className="text-semibold text-fiord-blue">My Take</span>
						<div className="text-semibold" style={{ fontSize: 28 }}>
							{split.percent}%
						</div>
					</Col>
				</Row>
			</CardBody>
		</Card>
	));

export default class Home extends Component {
	state = {
		loading: true,
		apps: [],
		name: '',
		appCode: '',
		appCodeIsAvailable: true,
		agreeToTerms: false,
		showingCreateModal: false,
		createLoading: false,
		submitEnabled: false,
		inviteSplit: null,
	};

	componentDidMount = async () => {
		let apps = await waitForContainer(AppsContainer, 'apps');
		let npos = await waitForContainer(AppsContainer, 'npos');
		let { splitInviteToken } = queryString.parse(this.props.location.search);
		let user = await waitForContainer(Session, 'user');
		let inviteSplit = null;

		if (splitInviteToken) {
			try {
				let { split } = await API.get(`/admin/splits/token/${splitInviteToken}`);
				inviteSplit = split;
			} catch (e) {
				alert(`Oops! ${e.message}`);
			}
		}

		let { splits } = await API.get(`/admin/splits/`);

		this.setState({
			loading: false,
			apps: apps,
			npos: npos,
			name: '',
			appCode: '',
			appCodeManuallySet: false,
			appCodeIsAvailable: true,
			agreeToTerms: false,
			showingCreateModal: false,
			createLoading: false,
			submitEnabled: false,
			user,
			inviteSplit,
			splitInviteToken,
			splits,
		});
	};

	acceptInvite = async () => {
		try {
			await API.post(`/admin/splits/token/${this.state.splitInviteToken}`);
			this.props.history.push(`/profile/home`);
			setTimeout(() => this.componentDidMount(), 100);
		} catch (e) {
			alert(`Oops! ${e.message}`);
		}
	};

	dismissInvite = () => {
		this.props.history.push(`/profile/home`);
		setTimeout(() => this.componentDidMount(), 100);
	};

	gotoConnectBank = () => {
		this.props.history.push(`/profile/bank`);
	};

	isValid = () => {
		let { name, appCode, agreeToTerms, appCodeIsAvailable } = this.state;
		console.log(name, appCode, agreeToTerms, appCodeIsAvailable);
		return name.length > 0 && appCode.length > 0 && agreeToTerms && appCodeIsAvailable;
	};

	updateValidation = () => {
		this.setState({ saveEnabled: this.isValid() });
	};

	onNameChange = event => {
		let newState = {};
		newState.name = event.target.value;
		if (!this.state.appCodeManuallySet) {
			newState.appCode = newState.name.toLowerCase().replace(/[^0-9a-z]/gi, '-');
		}
		this.setState(newState, this.checkAvailabilityDebounced);
	};

	onCheckChange = field => {
		let newState = {};
		newState[field] = !this.state[field];
		this.setState(newState, this.updateValidation);
	};

	onAppCodeChange = event => {
		let appCode = event.target.value.toLowerCase();
		appCode = appCode.replace(/[^0-9a-z\-]/gi, '');
		this.setState({ appCode, appCodeManuallySet: true }, this.checkAvailabilityDebounced);
	};

	checkAvailability = async () => {
		let { appCode } = this.state;

		if (appCode === '') {
			this.setState({ appCodeIsAvailable: true }, this.updateValidation);
			return;
		}

		try {
			await API.get(`/app/${appCode}`);
			this.setState({ appCodeIsAvailable: false }, this.updateValidation);
		} catch (e) {
			this.setState({ appCodeIsAvailable: true }, this.updateValidation);
		}
	};

	checkAvailabilityDebounced = AwesomeDebouncePromise(this.checkAvailability, 500);

	create = async () => {
		if (!this.isValid()) return;
		let { name, appCode } = this.state;

		try {
			this.setState({ createLoading: true });
			await API.post(`/admin/channel/`, { name, appCode, category: 'music' });
			await AppsContainer.getInstance().refresh();
			setTimeout(() => this.props.history.push(`/channel/${appCode}`), 10);
		} catch (e) {
			alert(`Oops! ${e.message}`);
			this.setState({ createLoading: false });
		}
	};

	render = () => {
		return (
			<Container className="page-container channels">
				{this.state.loading ? (
					<ScreenLoader />
				) : (
					<>
						{(this.state.splits.length > 0 || this.state.apps.length > 0) &&
							(!this.state.user.stripe || !this.state.user.stripe.accountId) && (
								<InfoBox
									message="Until you connect your bank, you will not be able to receive payments."
									ctaButtonLabel="Connect Your Bank"
									onCtaButtonClick={this.gotoConnectBank}
								/>
							)}
						<Row noGutters className="page-header py-4 d-flex">
							<PageTitle title="Home" subtitle="Manifest Admin" className="text-sm-left mb-3" />
						</Row>
						{this.state.npos.length > 0 && (
							<>
								<h6 style={{ marginTop: 10 }}>My Nonprofit</h6>
								<Row>
									<Col lg="9" md="12">
										<NpoGrid npos={this.state.npos} />
									</Col>
									<Col lg="3" md="12" />
								</Row>
							</>
						)}
						<h6 style={{ marginTop: 10 }}>My Channels</h6>
						<Row>
							<Col lg="9" md="12">
								<ChannelGrid apps={this.state.apps} />
							</Col>
							<Col lg="3" md="12">
								<Card small>
									<CardBody>
										<Row>
											<Col sm="12">
												<Button
													theme="dark"
													size="sm"
													style={{ width: '100%' }}
													tag={NavLink}
													to="#"
													onClick={() => this.setState({ showingCreateModal: true })}>
													+ Add Channel
												</Button>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>

						{this.state.splits.length > 0 && (
							<>
								<h6 style={{ marginTop: 10 }}>My Splits</h6>
								<Row>
									<Col lg="9" md="12">
										<SplitGrid splits={this.state.splits} />
									</Col>
									<Col lg="3" md="12" />
								</Row>
							</>
						)}
					</>
				)}
				<Modal centered size="md" open={this.state.showingCreateModal} toggle={() => {}}>
					<ModalHeader toggle={() => this.setState({ showingCreateModal: false })}>
						<span>New Channel</span>
					</ModalHeader>
					<ModalBody>
						<Form>
							<FormGroup>
								<label htmlFor="artistNameInput">Performer/Group Name</label>
								<FormInput
									autoFocus
									type="text"
									id="artistNameInput"
									placeholder="The Rolling Stones"
									value={this.state.name}
									onChange={event => this.onNameChange(event)}
									onKeyUp={this.onKeyUp}
									autoComplete="chrome-off"
								/>
							</FormGroup>
							<FormGroup>
								<label htmlFor="appUrlInput">Your Fan Sign-Up Link</label>
								<InputGroup>
									<InputGroupAddon type="prepend">
										<InputGroupText>manifestfinancial.com/</InputGroupText>
									</InputGroupAddon>
									<FormInput
										type="text"
										id="appUrlInput"
										placeholder="stones"
										autoComplete="chrome-off"
										invalid={!this.state.appCodeIsAvailable}
										value={this.state.appCode}
										onChange={this.onAppCodeChange}
										onKeyUp={this.onKeyUp}
									/>
									{!this.state.appCodeIsAvailable && (
										<FormFeedback>This Manifest link is taken.</FormFeedback>
									)}
								</InputGroup>
							</FormGroup>
							<FormGroup>
								<FormCheckbox
									checked={this.state.agreeToTerms}
									onChange={() => this.onCheckChange('agreeToTerms')}>
									I agree to the{' '}
									<a
										href="https://site.manifestfinancial.com/legal/channel-terms"
										target="_blank"
										rel="noopener noreferrer">
										Channel Terms
									</a>
									.
								</FormCheckbox>
							</FormGroup>
						</Form>
					</ModalBody>
					<ModalFooter>
						<Button
							theme="accent"
							size="sm"
							onClick={this.create}
							disabled={this.state.createLoading || !this.state.saveEnabled}>
							{this.state.createLoading ? 'one sec...' : 'Create'}
						</Button>
					</ModalFooter>
				</Modal>
				<Modal centered size="md" open={!!this.state.inviteSplit} toggle={() => {}}>
					{this.state.inviteSplit && (
						<>
							<ModalHeader toggle={this.dismissInvite}>
								Split from {this.state.inviteSplit.app.name}
							</ModalHeader>
							<ModalBody>
								<p style={{ marginBottom: 20 }}>
									The Manifest channel <em>{this.state.inviteSplit.app.name}</em> has added a{' '}
									<b>{labelForRole(this.state.inviteSplit.role)}</b> split for you. You will receive{' '}
									<b>{this.state.inviteSplit.percent}%</b> of{' '}
									{possessiveForApp(this.state.inviteSplit.app)} Manifest subscription revenue. We
									will notify you at the email address below if your split is modified.
								</p>
							</ModalBody>
							<ModalFooter>
								<p style={{ marginBottom: 0, fontSize: 12 }}>
									Accept this split as <em>{this.state.user.email}</em>
								</p>
								<Button theme="accent" size="sm" onClick={this.acceptInvite}>
									Accept
								</Button>
							</ModalFooter>
						</>
					)}
				</Modal>
			</Container>
		);
	};
}
