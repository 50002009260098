import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'shards-react';
import ScreenLoader from '../../components/ScreenLoader';
import PageTitle from '../../components/common/PageTitle';
import queryString from 'query-string';
import API from '../../data/API';
import Session from '../../data/Session';

export default class ConnectStripeFinish extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			message: '',
			details: null,
			buttonLabel: '',
		};

		let interval;
		interval = setInterval(async () => {
			let user = Session.getInstance().state.user;
			if (user) {
				clearInterval(interval);
				try {
					let { state, code } = queryString.parse(this.props.location.search);
					await API.post(`/admin/bank/stripe-connection`, { state, code });
					Session.getInstance().refreshUser();
					this.setState({
						loading: false,
						message: `✨ Nice! Your bank account has been linked!`,
						details: `Your payments will magically appear in your bank account.`,
						buttonLabel: `Back to Home`,
						buttonTarget: `/profile`,
					});
				} catch (e) {
					this.setState({
						loading: false,
						message: `Whoops! We couldn't connect your account.`,
						details: e.message + ' Please try again in order to get paid.',
						buttonLabel: 'Try Again',
						buttonTarget: '/profile/bank',
					});
				}
			}
		}, 500);
	}

	render = () => (
		<Container className='page-container'>
			<Row noGutters className='page-header py-4'>
				<PageTitle title='Connect My Bank' subtitle='Payments' className='text-sm-left mb-3' />
			</Row>
			<Row>
				<Col>
					{this.state.loading ? (
						<ScreenLoader />
					) : (
						<Card>
							<CardBody
								className='d-flex'
								style={{
									flexDirection: 'column',
									alignItems: 'center',
									justifyContent: 'center',
									paddingTop: 120,
									paddingBottom: 120,
									textAlign: 'center',
								}}>
								<h4>{this.state.message}</h4>
								<p>{this.state.details}</p>
								<Button
									pill
									theme='success'
									onClick={() => this.props.history.push(this.state.buttonTarget)}
									style={{ paddingLeft: 50, paddingRight: 50, height: 40, marginTop: 10 }}>
									{this.state.buttonLabel}
								</Button>
							</CardBody>
						</Card>
					)}
				</Col>
			</Row>
		</Container>
	);
}
